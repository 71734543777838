import React, { useState, useEffect } from "react";
import {
  Collapse,
  Container,
  Box,
  Divider,
  Typography,
  Grid,
  ListItem,
  ListItemText,
  IconButton,
  styled,
  Card,
  CardHeader,
  CardContent,
  List,
  Paper,
  Button,
  // DownloadIcon
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from "react-redux";
import inProgress from "../styles/logo/appInProgress.png";
import error from "../styles/logo/appError.png";
import completed from "../styles/logo/appCompleted.png";
import palette from "../styles/palette";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetList } from "react-admin";
import axios from "axios";
import getToken from "../utils/getAuthToken";
import styles from './Overview.module.css';
import { useTranslation } from 'react-i18next';


const Overview = () => {
  const { t : itranslate } = useTranslation();

  const { data: notifications } = useGetList("notifications", {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "createdAt", order: "DESC" },
  });
  
  const [expiryModels, setExpiryModels] = useState([]);
  const [expirySCs, setExpirySCs] = useState([]);
  const [AIvalidating, setAIvalidating] = useState(0);
  const [AIerror, setAIerror] = useState(0);
  const [AIvalidated, setAIvalidated] = useState(0);
  const [revokedItems, setRevokedItems] = useState([]);
  const [rc_id, setRc_id] = useState("");

  const userInfo = useSelector((state) => state.userInfo.value);
  // const boxStyle = {
  //   border: `1.5px solid ${palette.border}`,
  //   borderRadius: "15px",
  //   boxShadow: `0 8px 10px ${palette.shadow}`,
  // };

  // const classes = {
  //   overview: {
  //     fontFamily: "Frutiger_bold",
  //     fontSize: "1.2rem",
  //     paddingLeft: "30px",
  //   },
  //   welcomeBox: {
  //     ...boxStyle,
  //     display: "flex",
  //     padding: "20px 50px",
  //     margin: "30px 0px",
  //   },
  //   primary: {
  //     color: palette.primary,
  //     fontFamily: "Frutiger_bold",
  //     fontSize: "1.2rem",
  //     marginBottom: "7px",
  //   },
  //   secondary: {
  //     color: palette.secondary,
  //     fontSize: "1.1rem",
  //   },
  //   applicationCountContainer: {
  //     display: "flex",
  //     justifyContent: "space-between",
  //     gap: "30px",
  //   },
  //   applicationCount: {
  //     ...boxStyle,
  //     display: "flex",
  //     alignItems: "center",
  //     gap: "15px",
  //     padding: "20px",
  //     flex: 1,
  //   },
  //   countDescription: {
  //     fontFamily: "Frutiger_bold",
  //   },
  //   count: {
  //     fontSize: "0.8rem",
  //     color: palette.secondary,
  //   },
  //   number: {
  //     fontFamily: "Frutiger_bold",
  //     fontSize: "1.3rem",
  //     color: palette.primary,
  //     paddingRight: "5px",
  //   },
  //   statisticsBox: {
  //     ...boxStyle,
  //     padding: "20px 50px 10px 50px",
  //     margin: "30px 0px",
  //   },
  //   statisticsHeader: {
  //     display: "flex",
  //     alignItems: "flex-end",
  //   },
  //   statisticsArray: {
  //     display: "flex",
  //   },
  //   statistics: {
  //     fontSize: "0.8rem",
  //     color: palette.secondary,
  //     padding: "12.5px 30px",
  //   },
  //   revokeBox: {
  //     ...boxStyle,
  //     padding: "20px 50px 10px 50px",
  //     margin: "30px 0px",
  //   },
  //   revokeHeader: {
  //     display: "flex",
  //     alignItems: "flex-end",
  //     marginBottom: 2,
  //   },
  //   revokeStatistics: {
  //     fontSize: "0.8rem",
  //     color: palette.secondary,
  //     padding: "12.5px 30px",
  //   },
  //   revokeListUl: {
  //     // marginTop: "8px", 
  //     paddingLeft: "0px",
  //     fontSize: ".8em", 
  //     color: palette.menuHeader,
  //   },
  //   revokeListItem: {
  //     fontSize: ".8em", 
  //     marginLeft: "20px",
  //   },
  // };

  useEffect(() => {
    const requestConfigs = [
      { type: "LE", stateUpdater: setExpiryModels },
      { type: "SC", stateUpdater: setExpirySCs },
    ];

    axios
      .get(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/metrics/expiry`, {
        headers: {
          authorization: getToken(),
        },
      })
      .then((res) => {
        setExpiryModels(res.data.expiredModels);
        setExpirySCs(res.data.expiredSCs);
        setAIvalidating(res.data.AIvalidating);
        setAIerror(res.data.AIerror);
        setAIvalidated(res.data.AIvalidated);
      });
  }, []);


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/metrics/revoked`, {
        headers: {
          authorization: getToken(),
        },
      })
      .then((res) => {
        setRevokedItems(res.data.revoked_models);
        setRc_id(res.data.rc_id);
      });
  }, []);


  const ExpandMore = styled((props) => {
    //https://mui.com/material-ui/react-card/
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));


  const downloadFile = async (id) => window.open(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/${id}?token=${getToken()}`);


  const ApplicationCount = (props) => {
    const { type } = props;
    const countDescription = (type) => {
      switch (type) {
        case "inProgress":
          // return "AI Validation In Progress";
          return `${itranslate("overview.AI Validation In Progress")}`;
        case "error":
          // return "Error found during validation";
          return `${itranslate("overview.Error found during validation")}`;
        case "completed":
          // return "AI validation Completed";
          return `${itranslate("overview.AI validation Completed")}`;
        default:
          return "Unknown Error";
      }
    };

    const logo = (type) => {
      switch (type) {
        case "inProgress":
          return inProgress;
        case "error":
          return error;
        case "completed":
          return completed;
        default:
          return "Unknown Error";
      }
    };

    const count = (type) => {
      switch (type) {
        case "inProgress":
          return AIvalidating;
        case "error":
          return AIerror;
        case "completed":
          return AIvalidated;
        default:
          return "Unknown Error";
      }
    };

    return (
      <Box
        sx={{
          border: `1.5px solid ${palette.border}`,
          borderRadius: "15px",
          boxShadow: `0 8px 10px ${palette.shadow}`,
          display: "flex",
          alignItems: "center",
          gap: "15px",
          padding: "20px",
          flex: 1,
        }}
      >
        <img
          src={logo(type)}
          // style={{ width: "50px" }}
          className={styles.overview_img}
          alt="AI Validation In progress"
        />
        <Box>
          <Typography sx={{ fontFamily: "Frutiger_bold" }}>
            {countDescription(type)}
          </Typography>
          <Typography sx={{ fontSize: "0.8rem", color: palette.secondary }}>
            <span className={styles.overview_number}>{count(type)}</span>
            {/* <span 
              style={{
                fontFamily: "Frutiger_bold",
                fontSize: "1.3rem",
                color: palette.primary,
                paddingRight: "5px",
              }}
            >{count(type)}</span> */}
            {/* Application(s) */}
            {itranslate("overview.Application(s)")}
          </Typography>
        </Box>
      </Box>
    );
  };

  const StatisticsBox = (props) => {
    const [expanded, setExpanded] = useState(true);
    const { type } = props;

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const statisticsType = (type) => {
      switch (type) {
        case "models":
          // return "Expiry - Models";
          return `${itranslate("overview.Expiry - Models")}`;
        case "sc":
          // return "Expiry - Safety Components";
          return `${itranslate("overview.Expiry - Safety Components")}`;
        default:
          // return "Expiry - Unknown Type";
          return `${itranslate("overview.Expiry - Unknown Type")}`;
      }
    };
    return (
      <Box
        sx={{
          border: `1.5px solid ${palette.border}`,
          borderRadius: "15px",
          boxShadow: `0 8px 10px ${palette.shadow}`,
          padding: "20px 50px 10px 50px",
          margin: "30px 0px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <ExpandMore
            sx={{ color: "black" }}
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>

          <Typography
            sx={{
              color: palette.primary,
              fontFamily: "Frutiger_bold",
              fontSize: "1.2rem",
              marginBottom: "7px",
            }}
          >{statisticsType(type)}</Typography>
        </Box>
        
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Grid container>
            {type === "models" &&
              (expiryModels.length === 0 ? (
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: palette.secondary,
                    padding: "12.5px 30px",
                  }}
                >
                  {/* No expired/soon to be expired models found */}
                  {itranslate("overview.No expired/soon to be expired models found")}
                </Typography>
              ) : (
                expiryModels.map((model) => (
                  <Grid item xs={3} key={model._id}>
                    <ListItem>
                      <ListItemText
                        primaryTypographyProps={{
                          sx: { fontSize: "1.2rem" },
                        }}
                        primary={`${model.model_code} - ${model.provided_model_name}`}
                      />
                      <Divider orientation="vertical" flexItem />
                    </ListItem>
                  </Grid>
                ))
              ))}
            {type === "sc" &&
              (expirySCs.length === 0 ? (
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: palette.secondary,
                    padding: "12.5px 30px",
                  }}
                >
                  {/* No expired/soon to be expired components found */}
                  {itranslate("overview.No expired/soon to be expired components found")}
                </Typography>
              ) : (
                expirySCs.map((comp) => (
                  <Grid item xs={3} key={comp._id}>
                    <ListItem>
                      <ListItemText
                        primaryTypographyProps={{
                          sx: { fontSize: "1.2rem" },
                        }}
                        primary={`${comp.component_code} - ${comp.provided_comp_name}`}
                        secondary={comp.expiry}
                      />
                      <Divider orientation="vertical" flexItem />
                    </ListItem>
                  </Grid>
                ))
              ))}
          </Grid>
        </Collapse>
      </Box>
    );
  };



  const RevokedItemsBox = ({ revokedItems, check_rc_id }) => {
    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = () => setExpanded(!expanded);

    return (
      <Box
        sx={{
          border: `1.5px solid ${palette.border}`,
          borderRadius: "15px",
          boxShadow: `0 8px 10px ${palette.shadow}`,
          padding: "20px 50px 10px 50px",
          margin: "30px 0px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-end", marginBottom: 2 }} >
          <ExpandMore
            sx={{ color: "black" }}
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <Typography
            sx={{
              color: palette.primary,
              fontFamily: "Frutiger_bold",
              fontSize: "1.2rem",
              marginBottom: "7px",
            }}
          >
            {/* Revoked Items */}
            {itranslate("overview.Revoked Items")}
          </Typography>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>

          {revokedItems.map((item) => (
            item.rc_items
              .filter((rc_item) => rc_item.rc_id === check_rc_id)
              .map((filteredRcItem) => (
                <Grid container key={filteredRcItem._id}>
                  <Paper sx={{ display: "flex", marginTop: 1, marginBottom: 3, padding: '10px' }} elevation={3} variant="elevation">

                    {filteredRcItem.manufacturer.length > 0 && (
                      <Box sx={{ display: "flex", flexDirection: "column", marginRight: 5 }}>
                        <ul
                          sx={{
                            paddingLeft: "0px",
                            fontSize: ".8em",
                            color: palette.menuHeader,
                          }}
                        >
                          <Typography sx={`${filteredRcItem.manufacturer.length > 0 ? "display: block" : "display: none"}`}>
                            {/* Manufacturer */}
                            {itranslate("overview.Manufacturer")}
                          </Typography>
                        </ul>

                        {filteredRcItem.manufacturer.map((manufacturer) => (
                          <Typography key={manufacturer._id}>
                            {manufacturer.manufacturer_code} - {manufacturer.provided_manufacturer_name}
                          </Typography>
                        ))}
                      </Box>
                    )}

                    {filteredRcItem.models.length > 0 && (
                      <Box sx={{ display: "flex", flexDirection: "column", marginRight: 5 }}>
                        <ul
                          sx={{
                            paddingLeft: "0px",
                            fontSize: ".8em",
                            color: palette.menuHeader,
                          }}
                        >
                          <Typography>
                            {/* Models */}
                            {itranslate("overview.Models")}
                          </Typography>
                        </ul>

                        {filteredRcItem.models.map((model) => (
                          <Typography key={model._id}>
                            {model.model_code}/{model.unique_id_lastDigit} - {model.provided_model_name}
                          </Typography>
                        ))}
                      </Box>
                    )}

                    {filteredRcItem.components.length > 0 && (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <ul
                          sx={{
                            paddingLeft: "0px",
                            fontSize: ".8em",
                            color: palette.menuHeader,
                          }}
                        >
                          <Typography>
                            {/* Components */}
                            {itranslate("overview.Components")}
                          </Typography>
                        </ul>

                        {filteredRcItem.components.map((component) => (
                          <Typography key={component._id} sx={{ marginTop: '2px' }}>
                            {component.component_code} - {component.provided_comp_name}
                          </Typography>
                        ))}
                      </Box>
                    )}

                    {item.signedRevocationLetter.filter(letter => letter.rc_id === check_rc_id).map((letter) => (
                      <Button
                        startIcon={<DownloadIcon />}
                        // variant="outlined"
                        onClick={() => downloadFile(letter.signed_revocation_letter_id)}
                        sx={{ marginLeft: '20px', borderRadius: '10px' }}
                      >
                        {/* Download Letter */}
                        {itranslate("overview.Download Letter")}
                      </Button>
                    ))}

                  </Paper>
                </Grid>
              ))
          ))}
        </Collapse>
      </Box>
    );
  }


  return (
    <Container maxWidth="xl" sx={{ padding: "40px 0px" }}>
      <Typography
        sx={{
          fontFamily: "Frutiger_bold",
          fontSize: "1.2rem",
          paddingLeft: "30px"
        }}
      >
        {/* Overview */}
        {itranslate("overview.Overview")}
      </Typography>
      <Box
        sx={{
          border: `1.5px solid ${palette.border}`,
          borderRadius: "15px",
          boxShadow: `0 8px 10px ${palette.shadow}`,
          display: "flex",
          padding: "20px 50px",
          margin: "30px 0px",
        }}
      >
        <Box sx={{ padding: "8.5px 0px", minWidth: "200px" }}>
          <Typography
            sx={{
              color: palette.primary,
              fontFamily: "Frutiger_bold",
              fontSize: "1.2rem",
              marginBottom: "7px",
            }}
          > {itranslate("overview.Hello")}, {userInfo?.rc}!</Typography>
          <Typography sx={{ color: palette.secondary, fontSize: "1.1rem" }}>
            {/* Welcome to TAPAS */}
            {itranslate("overview.Welcome to TAPAS")} 
          </Typography>
        </Box>
        <Box sx={{ padding: "8.5px 0px" }}>
          <Typography sx={{ color: palette.secondary, fontSize: "1.1rem" }}></Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-around", gap: "30px" }}>
        <ApplicationCount type="inProgress" />
        <ApplicationCount type="error" />
        <ApplicationCount type="completed" />
      </Box>

      <StatisticsBox type="models" />
      <StatisticsBox type="sc" />

      {revokedItems && revokedItems.length > 0 && (
        <RevokedItemsBox revokedItems={revokedItems} check_rc_id={rc_id} />
      )}      
      
    </Container>
  );
};

export default Overview;
