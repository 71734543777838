import { TextField, IconButton, Typography } from "@mui/material";
import { Delete, Add, Close } from "@mui/icons-material";
import React, { forwardRef } from "react";

const TextInputRevoke = forwardRef((props, ref) => {
  const {
    paragraph,
    handleText,
    deleteSection,
    handleFocus,
    appendix,
    setText,
  } = props;

  const sectionTitle = (section) => {
    switch (section) {
      case "heading":
        return "Heading";
      case "body":
        return "Body";
      default:
        return "";
    }
  };

  const handleSelect = (e, i, paragraph, subIndex) => {
    const selectedText = window.getSelection().toString();
    const text = {
      text: selectedText,
      range: [e.target.selectionStart, e.target.selectionEnd],
      index: i,
      // subIndex: subIndex,
      section: paragraph,
    };
    setText(text);
  };

  return (
    <div 
      style={{ 
        width: "100%", 
        height: "85vh", 
        overflow: "auto",
        overflowX: "hidden",
      }}>
      <div style={{ width: "100%" }}>
        {appendix?.revocation_letter[paragraph] &&
          appendix?.revocation_letter[paragraph].map((text, key) => {
            return (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #ababab",
                  borderRadius: "5px",
                  padding: "3px 3px 3px 10px",
                  margin: "20px 0px",
                  backgroundColor: "#ebebeb8c",
                }}
                key={key}
              >
                <IconButton
                  sx={{
                    alignSelf: "flex-end",
                    backgroundColor: "#ff234ab8",
                    padding: "5px",
                  }}
                  onClick={() => deleteSection(key, paragraph)}
                >
                  <Close sx={{ fontSize: "20px" }} />
                </IconButton>

                <TextField
                  fullWidth
                  autoFocus={
                    ref.current.index === key && ref.current.type === paragraph
                      ? true
                      : false
                  }
                  onFocus={handleFocus}
                  onSelect={(e) => {
                    handleSelect(e, key, paragraph);
                  }}
                  sx={{
                    minWidth: "300px",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                  key={`${paragraph}-${key + 1}`}
                  id={`${paragraph}-${key + 1}`}
                  label={`${sectionTitle(paragraph)} ${key + 1}`}
                  value={text}
                  variant="outlined"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                    handleText(
                      e.target.value,
                      key,
                      paragraph,
                      e.target.selectionStart,
                      key
                    );
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
});

export default TextInputRevoke;
