import React, { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  CreateBase,
  ResourceContextProvider,
  useCreate,
  useNotify,
  Title,
  useGetList,
  Confirm,
} from "react-admin";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputLabel,
  Typography,
  StepButton,
  ButtonGroup,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import SaveDraft from "../../utils/applications/SaveDraft";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { ManufacturerDisplay } from "../../components/manufacturer/ManufacturerDisplay";
import { ModelDisplay } from "../../components/model/ModelDisplay";
import { DocumentStack } from "../../components/document/DocumentStack";
import { ComponentDisplay } from "../../components/component/ComponentDisplay";
import { CompletionCheck } from "../../components/create/CompletionCheck";
import * as static_fields from "../../utils/static_fields.json";
import variable_fields from "../../utils/variable_fields.json";
import { UpdateModeSelector } from "../../components/update/UpdateModeSelector";
import FailedDataValidation from "../../components/create/pageValidation";
import {
  SelectRC,
  SelectAction,
  SelectLEtype,
  SelectMachine,
  SelectApplicationType,
  SelectScType,
  SelectSystemType,
} from "../../components/create/SelectInputs";
import getToken from "../../utils/getAuthToken";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../../redux/uploadSlice";
import { confirmAlert } from "react-confirm-alert";

const ApplicationCreate = (props) => {
  const navigate = useNavigate();
  const notify = useNotify();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo.value);
  const { id } = useParams();
  
  const initialState = {
    file_code: uuidv4(),
    manufacturer: [],
    // only creating new manufacturers in the application will add to this array
    //add in just manufacturer code diff with ref
    model: [],
    app_type: {
      type: "",
      components: {},
    },
    //by selecting here, will determine the number of steps => to take,
    // selecting app type will also edit the components that determine the component step
    other_documents: [],
    dialog: [],
  };

  const autoSaved = JSON.parse(localStorage.getItem("tapas_application_draft"));
  const [upload, setUpload] = useState(initialState);
  const [draftlist, setDraftlist] = useState([]);
  const [isValidated, setIsValidated] = useState(false);
  const [currentDraft, setCurrentDraft] = useState([]);
  const [proceedConfirm, setProceedConfirm] = useState(false);

  const [applicationFlow, setApplicationFlow] = useState({
    action: "new",
    system_type: "lift",
    application_type: "add sc",
    sc_type: "standAloneSc",
  });

  const [radio, setRadio] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [stepChosen, setStepChosen] = useState(0);
  const [unfinishedSteps, setUnfinishedSteps] = useState([]);
  const [skipped, setSkipped] = useState(new Set());
  const [disableRadio, setDisableRadio] = useState(false);
  const [create, { isLoading: isCreating }] = useCreate();
  const [options, setOptions] = useState([]);
  const [optional, setOptional] = useState({});

  // PerPage: 0 result in .limit(0) in backend, which returns all data instead of the default range (0-25)
  // GR can choose RC in a select box, which changes upload.rc_id
  // null for non GR (RC) as backend will filter by rc_id automatically with user's token
  const getListParams = {
    ...(userInfo?.role === "EMSDGR" && {
      filter: {
        rc_id: upload?.rc_id,
      },
    }),
    pagination: { page: null, perPage: null },
  };

  const getListOptions =
    userInfo?.role === "EMSDGR" ? { enabled: Boolean(upload.rc_id) } : null;

  const { data: manufacturersLoad } = useGetList(
    "manufacturers",
    getListParams,
    getListOptions
  );

  const { data: modelsLoad } = useGetList(
    "models",
    getListParams,
    getListOptions
  );

  const { data: componentsLoad } = useGetList(
    "components",
    // "components/getAll",
    getListParams,
    getListOptions
  );

  const steps_short = [
    "Select operation",
    "Add Manufacturer",
    "Add Safety Components",
    "Upload Additional Documents",
    "Complete Process",
  ];
  
  const steps = [
    "Select operation",
    "Add Manufacturer",
    "Add Models",
    "Add Safety Components",
    "Upload Additional Documents",
    "Complete Process",
  ];

  useEffect(() => {
    setDisableRadio(
      upload?.model?.length > 0 ||
        upload?.manufacturer?.length > 0 ||
        Object.values(upload?.app_type?.components)?.some((x) => x.length > 0)
    );
  }, [radio, JSON.stringify(upload)]);

  useEffect(() => {
    if (componentsLoad) {
      setOptions(
        componentsLoad?.map((object) => {
          return {
            category: "Old",
            ...object,
          };
        })
      );
    }
  }, [componentsLoad]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/app_preSubmit?validationStatus=draft`,
        { headers: { authorization: getToken() } }
      )
      .then((res) => setDraftlist(res.data))
      .catch((err) => console.log(err));
  }, [currentDraft]);

  useEffect(() => {
    handleRadio("0");
    if (id) loadDraft(id);

    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.addEventListener("popstate", unloadCallback);
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  const isStepOptional = (step) => {
    if (radio === "9") {
      if (step === 3) return true;
    } else {
      if (step === 4) return true;
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleStepValidate = (mode, index) => {
    //3 mode: click the stepper directly, click next button, click back button
    setStepChosen(index);
    // this is for the confirm modal to track which step is chosen so they can setactivestep with that value
    const trackUnfinishedStep = (mode, step) => {
      // this will make the stepper UI display different label if steps are not finished
      if (mode === "add") {
        if (!unfinishedSteps.includes(step)) {
          setUnfinishedSteps((current) => [...current, step]);
        }
      } else if (mode === "remove") {
        if (unfinishedSteps.includes(step)) {
          const newArray = unfinishedSteps.filter(
            (existedStep) => existedStep !== step
          );
          setUnfinishedSteps(newArray);
        }
      }
    };
    const checkStep = (step, chosenStep) => {
      let data;
      if (step === "Add Manufacturer") {
        data = upload.manufacturer;
      } else if (step === "Add Models") {
        data = upload.model;
      } else if (step === "Add Safety Components") {
        data = upload.app_type;
      }
      // faileddatavalidation is a function imported from other module
      if (FailedDataValidation(step, data)) {
        // open the confirm modal
        setProceedConfirm(true);
        trackUnfinishedStep("add", step);
      } else {
        // Passed validation, let them proceed
        trackUnfinishedStep("remove", step);
        mode === "stepper"
          ? setActiveStep(chosenStep)
          : mode === "next"
          ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
          : setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    };
    if (radio !== "9") {
      //non SC application
      switch (activeStep) {
        case 1:
          checkStep("Add Manufacturer", index);
          break;
        case 2:
          checkStep("Add Models", index);
          break;
        case 3:
          checkStep("Add Safety Components", index);
          break;
        default: {
          mode === "stepper"
            ? setActiveStep(index)
            : mode === "next"
            ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
            : setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
      }
    } else if (radio === "9") {
      switch (activeStep) {
        case 1:
          checkStep("Add Manufacturer", index);
          break;
        case 2:
          checkStep("Add Safety Components", index);
          break;
        default: {
          mode === "stepper"
            ? setActiveStep(index)
            : mode === "next"
            ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
            : setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
      }
    }
  };

  const stepValidateModal = (
    <Confirm
      isOpen={proceedConfirm}
      title={
        (radio !== "9" && activeStep === 3) ||
        (radio === "9" && activeStep === 2)
          ? "Please confirm all your components"
          : "Missing mandatory inputs"
      }
      content={`This step is incomplete, proceed?`}
      onConfirm={() => {
        setActiveStep(stepChosen);
        setProceedConfirm(false);
      }}
      onClose={() => {
        setProceedConfirm(false);
      }}
    />
  );

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    handleStepValidate("next", activeStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    handleStepValidate("back", activeStep - 1);
  };

  const handleSend = () => {
    create(
      "applications",
      { data: upload },
      {
        onSuccess: () => (
          notify(
            "Sucessfully sent to AI, will take some time to reach Pending"
          ),
          navigate(`/applications`),
          localStorage.removeItem("tapas_application_draft")
        ),
        onError: (e) => notify(e.response.data),
      }
    );
  };

  const handleValidate = () => {
    let draft = upload;
    draft.optional = optional;
    create(
      "app_preSubmit/validate",
      { data: draft },
      {
        onSuccess: () => (
          notify(
            "Your application is saved and is validating by AI, please check application (validating) for more information"
          ),
          navigate(`/applications`),
          localStorage.removeItem("tapas_application_draft")
        ),
        onError: () => notify(`Some error occured, please try again`),
      }
    );
  };

  const loadUpdateApplicationDraft = (draft) => {
    dispatch(saveData(draft));
    const query = draft.changedManufacturer
      ? `?manufacturer=${draft.changedManufacturer}`
      : "";
    navigate(`/applications_${draft.update_type}/${draft.update_upon}${query}`);
  };

  const loadDraft = async (id, type) => {
    try {
      let draft = {};
      if (type === "autoSaved") draft = autoSaved.upload;
      else {
        const res = await axios.get(
          `${process.env.REACT_APP_ENV_ENDPOINT}/lift/app_preSubmit/${id}`,
          {
            headers: { authorization: getToken() },
          }
        );
        draft = res.data;
      }

      if (draft.update_upon) {
        if (type === "autoSaved") draft.update = autoSaved.update;
        loadUpdateApplicationDraft(draft);
        return;
      }
      setUpload(draft);
      let Atype = draft.app_type.type;
      const typeToRadioMap = {
        ElectricTractionLiftMachineRoom: "0",
        HydraulicLift: "1",
        StairLift: "2",
        ServiceLift: "3",
        Escalator: "4",
        PassengerConveyor: "5",
        MVPS: "6",
        ElectricalVerticalLiftingPlatform: "7",
        InclinedLift: "8",
        SafetyComponent: "9",
        ElectricTractionLiftRoomless: "10",
        Others: "11",
      };
      setRadio(typeToRadioMap[Atype] || "0");
      /* setOptional is for ComponentDisplay to render components correctly after switching draft */
      setOptional(draft?.optional || {});
      if (draft.title) setCurrentDraft([id, draft.title]); //autosaved draft do not have title
      if (
        draft.validationStatus === "validated" ||
        draft.validationStatus === "AIerror"
      ) {
        setIsValidated(true);
        if (Atype === "SafetyComponent") setActiveStep(4);
        else setActiveStep(5);
      } else {
        setIsValidated(false);
      }
    } catch (err) {
      console.log(err);
      notify(`Some error occured, please try again later`);
    }
  };

  const deleteDraft = (id, title) => {
    confirmAlert({
      title: "Confirm to delete draft",
      message: `Are you sure to do this. (title: ${title})`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            if (id === currentDraft[0]) {
              setCurrentDraft([]);
            }
            axios
              .delete(
                `${process.env.REACT_APP_ENV_ENDPOINT}/lift/app_preSubmit/${id}`,
                { headers: { authorization: getToken() } }
              )
              .then(() => {
                setDraftlist(draftlist.filter((slot) => slot._id !== id));
              })
              .catch((err) => {
                console.log(err);
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleApplicationFlow = (
    action,
    system_type,
    application_type,
    sc_type
  ) => {
    // handleRadio is for changing the stepper (hiding add models) when user want to add stand alone SC / Change the list of type of machine for NEW
    // action determine New Or Update
    // systemtype determine the list of model user can choose for brand new and update
    // applicationtype is for Update only, it determines which mode can user choose and which systemtype can user choose
    // sc_type is for "Add Safety component" part (Update only)
    let newFlow;
    if (action) {
      newFlow =
        action === "new"
          ? { ...applicationFlow, action: action, system_type: "lift" }
          : action === "update" && {
              action: action,
              system_type: "lift",
              application_type: "add sc",
              sc_type: "standAloneSc",
            };
      action === "new" ? handleRadio("0") : handleRadio("9");
    } else if (system_type) {
      // 2 systemtype:
      // lift - If action is new, user stay in this component and choose type of machine based on system_type, if action is update, it controls the type of model shown to the user for them to update
      // escalator - same as lift
      // sc - (Update only) for update safety component (cert change/range extension)
      newFlow = { ...applicationFlow, system_type: system_type };
      if (applicationFlow.action === "new")
        system_type === "lift"
          ? handleRadio("0")
          : system_type === "escalator" && handleRadio("4");
    } else if (application_type) {
      newFlow = {
        ...applicationFlow,
        application_type: application_type,
        system_type: "lift",
      };
    } else if (sc_type) {
      // 2 sc_type
      // standAloneSc - (action === update) Let user add new stand-alone Safety component OR update stand-alone component
      // leModelSc - (action === update) let user update component that is attached to LE model/old standalone SC (navigate to applicationEdit component)
      newFlow = { ...applicationFlow, sc_type: sc_type };
      sc_type === "standAloneSc" && handleRadio("9");
    }
    setApplicationFlow(newFlow);
  };

  const newStandAloneOrModel =
    (applicationFlow.action === "update" &&
      applicationFlow.application_type === "add sc" &&
      applicationFlow.sc_type === "standAloneSc") ||
    applicationFlow.action === "new";

  const handleRadio = (value) => {
    var obj;
    switch (value) {
      case "0": {
        obj = initialState;
        obj.app_type.type = "ElectricTractionLiftMachineRoom";
        obj.app_type.components = {
          ...variable_fields.app_type.ElectricTractionLiftMachineRoom,
        };
        break;
      }
      case "1": {
        obj = initialState;
        obj.app_type.type = "HydraulicLift";
        obj.app_type.components = { ...variable_fields.app_type.HydraulicLift };
        break;
      }
      case "2": {
        obj = initialState;
        obj.app_type.type = "StairLift";
        obj.app_type.components = { ...variable_fields.app_type.StairLift };
        break;
      }
      case "3": {
        obj = initialState;
        obj.app_type.type = "ServiceLift";
        obj.app_type.components = { ...variable_fields.app_type.ServiceLift };
        break;
      }
      case "4": {
        obj = initialState;
        obj.app_type.type = "Escalator";
        obj.app_type.components = { ...variable_fields.app_type.Escalator };
        break;
      }
      case "5": {
        obj = initialState;
        obj.app_type.type = "PassengerConveyor";
        obj.app_type.components = {
          ...variable_fields.app_type.PassengerConveyor,
        };
        break;
      }
      case "6": {
        obj = initialState;
        obj.app_type.type = "MVPS";
        obj.app_type.components = { ...variable_fields.app_type.MVPS };
        break;
      }
      case "7": {
        obj = initialState;
        obj.app_type.type = "ElectricalVerticalLiftingPlatform";
        obj.app_type.components = {
          ...variable_fields.app_type.ElectricalVerticalLiftingPlatform,
        };
        break;
      }
      case "8": {
        obj = initialState;
        obj.app_type.type = "InclinedLift";
        obj.app_type.components = { ...variable_fields.app_type.InclinedLift };
        break;
      }
      case "9": {
        obj = initialState;
        obj.app_type.type = "SafetyComponent";
        obj.app_type.components = {
          ...variable_fields.app_type.SafetyComponent,
        };
        break;
      }
      case "10": {
        obj = initialState;
        obj.app_type.type = "ElectricTractionLiftRoomless";
        obj.app_type.components = {
          ...variable_fields.app_type.ElectricTractionLiftRoomless,
        };
        break;
      }
      case "11": {
        obj = initialState;
        obj.app_type.type = "Others";
        obj.app_type.components = {
          ...variable_fields.app_type.Others,
        };
        break;
      }
      default: {
        obj = initialState;
        obj.app_type.type = "ElectricTractionLiftMachineRoom";
        obj.app_type.components = {
          ...variable_fields.app_type.ElectricTractionLiftMachineRoom,
        };
        break;
      }
    }
    if (userInfo.role === "EMSDGR" && upload.rc_id) {
      obj.rc_id = upload.rc_id;
    }
    setUpload(obj);
    setRadio(value);
  };

  const handleNavManufacturer = () => {
    setActiveStep(1);
  };

  return (
    <ResourceContextProvider {...props} value="applications">
      <Title
        title={
          applicationFlow.action === "update"
            ? "- Update Application"
            : "- New Application"
        }
      />
      <CreateBase
        {...props}
        basePath="applications_new"
        redirect="/applications"
      >
        <Box sx={{ width: "100%", mt: 1 }}>
          <Stepper nonLinear activeStep={activeStep} sx={{ mt: 2, mb: 3 }}>
            {radio === "9"
              ? steps_short.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">Optional</Typography>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  if (unfinishedSteps.includes(label)) {
                    labelProps.optional = (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        Unfinished
                      </Typography>
                    );
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepButton
                        disabled={!newStandAloneOrModel}
                        {...labelProps}
                        color="inherit"
                        onClick={() => handleStepValidate("stepper", index)}
                      >
                        <StepLabel>{label}</StepLabel>
                      </StepButton>
                    </Step>
                  );
                })
              : steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">Optional</Typography>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  if (unfinishedSteps.includes(label)) {
                    labelProps.optional = (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        Unfinished
                      </Typography>
                    );
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepButton
                        disabled={!newStandAloneOrModel}
                        {...labelProps}
                        color="inherit"
                        onClick={() => handleStepValidate("stepper", index)}
                      >
                        <StepLabel>{label}</StepLabel>
                      </StepButton>
                    </Step>
                  );
                })}
          </Stepper>
          {stepValidateModal}
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            {/* place to put flex accordion */}
            {activeStep === 0 && (
              <Card sx={{}}>
                <CardHeader title="New Application" />
                <CardContent>
                  <SelectAction
                    action={applicationFlow.action}
                    handleApplicationFlow={handleApplicationFlow}
                    disableRadio={disableRadio}
                  />
                  {applicationFlow.action === "new" && (
                    <React.Fragment>
                      {userInfo.role === "EMSDGR" && (
                        <SelectRC upload={upload} setUpload={setUpload} />
                      )}
                      <SelectLEtype
                        system_type={applicationFlow.system_type}
                        handleApplicationFlow={handleApplicationFlow}
                        disableRadio={disableRadio}
                      />
                      {applicationFlow.system_type !== "sc" && (
                        <SelectMachine
                          system_type={applicationFlow.system_type}
                          handleRadio={handleRadio}
                          disableRadio={disableRadio}
                          radio={radio}
                        />
                      )}
                    </React.Fragment>
                  )}

                  {applicationFlow.action === "update" && (
                    <React.Fragment>
                      {userInfo.role === "EMSDGR" && (
                        <SelectRC upload={upload} setUpload={setUpload} />
                      )}
                      <SelectApplicationType
                        application_type={applicationFlow.application_type}
                        handleApplicationFlow={handleApplicationFlow}
                        disableRadio={disableRadio}
                      />
                      {applicationFlow.application_type === "add sc" && (
                        // Add Safety Component
                        <React.Fragment>
                          <SelectScType
                            sc_type={applicationFlow.sc_type}
                            handleApplicationFlow={handleApplicationFlow}
                            disableRadio={disableRadio}
                          />
                          {applicationFlow.sc_type === "standAloneSc" && (
                            <SelectLEtype
                              system_type={applicationFlow.system_type}
                              handleApplicationFlow={handleApplicationFlow}
                              disableRadio={disableRadio}
                            />
                          )}
                          {applicationFlow.sc_type === "leModelSc" && (
                            <UpdateModeSelector
                              upload={upload}
                              userInfo={userInfo}
                              applicationFlow={applicationFlow}
                              components={componentsLoad}
                              models={modelsLoad}
                            />
                          )}
                        </React.Fragment>
                      )}
                      {/* Manufacturer name change */}
                      {applicationFlow.application_type ===
                        "manu name change" && (
                        <UpdateModeSelector
                          userInfo={userInfo}
                          applicationFlow={applicationFlow}
                          components={componentsLoad}
                          models={modelsLoad}
                        />
                      )}
                      {(applicationFlow.application_type === "update cert" ||
                        applicationFlow.application_type === "range ext") && (
                        // Cert update/Range extension
                        <React.Fragment>
                          <SelectSystemType
                            system_type={applicationFlow.system_type}
                            handleApplicationFlow={handleApplicationFlow}
                            disableRadio={disableRadio}
                          />
                          {applicationFlow.system_type === "sc" && (
                            <SelectScType
                              sc_type={applicationFlow.sc_type}
                              handleApplicationFlow={handleApplicationFlow}
                              disableRadio={disableRadio}
                            />
                          )}
                          <UpdateModeSelector
                            userInfo={userInfo}
                            applicationFlow={applicationFlow}
                            components={componentsLoad}
                            models={modelsLoad}
                          />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}

                  {/*{autoSaved && process.env.REACT_APP_PHASE_TWO && (
                    <div style={{ boxShadow: "0" }}>
                      <InputLabel sx={{ mt: 4 }}>Auto Saved Draft</InputLabel>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => loadDraft(undefined, "autoSaved")}
                      >
                        Load Auto Saved Draft
                      </Button>
                    </div>
                  )}*/}

                  {draftlist.length > 0 && (
                    <div style={{ boxShadow: "0" }}>
                      <InputLabel sx={{ mt: 4 }}>Saved Draft</InputLabel>
                      <Grid container spacing={1}>
                        {draftlist.map((slot) => {
                          return (
                            <Grid item xs="auto" key={slot._id}>
                              <ButtonGroup
                                variant="contained"
                                color="primary"
                                aria-label="split button"
                              >
                                <Button onClick={() => loadDraft(slot._id)}>
                                  {slot.title}
                                </Button>
                                <Button
                                  color="error"
                                  onClick={() =>
                                    deleteDraft(slot._id, slot.title)
                                  }
                                >
                                  <DeleteIcon />
                                </Button>
                              </ButtonGroup>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  )}
                </CardContent>
              </Card>
            )}

            {/* filter for unique code */}
            {activeStep === 1 && (
              <ManufacturerDisplay
                show={false}
                edit={false}
                add={true}
                isValidated={isValidated}
                title={"Add Manufacturer(s)"}
                existing={manufacturersLoad || []}
                upload={upload}
                setUpload={setUpload}
                template={static_fields}
                variable={variable_fields}
              />
            )}
            {activeStep === 2 && radio !== "9" && (
              <ModelDisplay
                show={false}
                edit={false}
                add={true}
                isValidated={isValidated}
                title={"Add Model(s)"}
                existing={modelsLoad || []}
                upload={upload}
                setUpload={setUpload}
                template={static_fields}
                variable={variable_fields}
                nav={handleNavManufacturer}
              />
            )}
            {((activeStep === 3 && radio !== "9") ||
              (activeStep === 2 && radio === "9")) && (
              <ComponentDisplay
                applicationFlow={applicationFlow}
                show={false}
                edit={false}
                add={true}
                isValidated={isValidated}
                title={"Add Safety Component(s)"}
                options={options}
                setOptions={setOptions}
                optional={optional}
                setOptional={setOptional}
                upload={upload}
                setUpload={setUpload}
                template={static_fields}
                variable={variable_fields}
                nav={handleNavManufacturer}
              />
            )}
            {((activeStep === 4 && radio !== "9") ||
              (activeStep === 3 && radio === "9")) && (
              <DocumentStack
                show={false}
                edit={false}
                add={true}
                title={"Upload Additional Documents"}
                upload={upload}
                setUpload={setUpload}
                static={static_fields}
                variable={variable_fields}
              />
            )}
            {((activeStep === 5 && radio !== "9") ||
              (activeStep === 4 && radio === "9")) && (
              <CompletionCheck
                upload={upload}
                setUpload={setUpload}
                isCreating={isCreating}
                handleSend={handleSend}
                handleValidate={handleValidate}
                radio={radio}
              />
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                alignItems: "center",
                mb: 2,
              }}
            >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant="contained"
                color="primary"
              >
                Back
              </Button>
              <SaveDraft
                draftType="new"
                upload={upload}
                setUpload={setUpload}
                optional={optional}
                isValidated={isValidated}
                currentDraft={currentDraft}
                setCurrentDraft={setCurrentDraft}
              />
              <Box sx={{ flex: "1 1 auto" }} />

              {((radio !== "9" && activeStep !== steps.length - 1) ||
                (radio === "9" && activeStep !== steps_short.length - 1)) && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!newStandAloneOrModel}
                  onClick={() => {
                    handleNext();
                  }}
                >
                  {activeStep === steps.length - 1 ? "" : "Next"}
                </Button>
              )}
            </Box>
          </>
        </Box>
      </CreateBase>
    </ResourceContextProvider>
  );
};

export default ApplicationCreate;
