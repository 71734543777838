import React from "react";
import { Button, TextField, Popper, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const ManufacturerNameInput = function (props) {
  //onChange trigger: when user select an option
  //onInputChange trigger: when user type in the input field
  const {
    mode,
    edit,
    manufacturer_id,
    value,
    setValue,
    inputValue,
    setInputValue,
    upload,
    existing,
    handleChange,
  } = props;
  const AutoCompleteManufacturerSelect = function (props) {
    return (
      <Popper
        {...props}
        style={{ maxWidth: "fit-content", minWidth: 300 }}
        placement="bottom-start"
      />
    );
  };

  const filter = createFilterOptions({
    stringify: (option) => option.provided_name + option.manufacturer_code,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Autocomplete
        value={value}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        PopperComponent={AutoCompleteManufacturerSelect}
        getOptionDisabled={(option) => option.inputValue === ""}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue({
              provided_name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              provided_name: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
            .filter((manu) =>
              upload?.app_type?.type === "SafetyComponent"
                ? manu.type !== "Model"
                : manu
            )
            .filter((option) => {
              const changedManufacturers = Object.keys(
                upload.changes?.manufacturer || {}
              );
              //check manufacturerDisplay line 333, same filter
              //let user add existed but hidden manufacturer, but not allow user to add existed and shown manufacturer
              //we handle the duplicate manufacturer in the backend
              const newlyAdded = upload.manufacturer.filter((manu) =>
                edit?.mode === "f"
                  ? !manu._id
                  : edit?.clarifying === "f"
                  ? !manu._id || changedManufacturers?.includes(manu._id)
                  : manu
              );
              return newlyAdded.every(
                (manu) => manu.manufacturer_code !== option.manufacturer_code
              );
            });

          const { inputValue } = params;

          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.provided_name
          );

          if (!isExisting) {
            filtered.push({
              inputValue,
              provided_name:
                inputValue === ""
                  ? "Add new manufacturer by typing here"
                  : `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={existing}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.provided_name;
        }}
        renderOption={(props, option) => (
          <li
            {...props}
            key={`${
              option.manufacturer_code ? option.manufacturer_code : option.id
            }: ${option.provided_name}`}
            style={{
              display: "flex",
              flexDirection: "column",
              border: "2px black solid",
              borderRadius: "5px",
              marginBottom: "5px",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {option.manufacturer_code
                ? `${option.manufacturer_code}: ${option.provided_name}`
                : option.provided_name}
            </Typography>
            <Typography sx={{ mb: 1 }}>
              {option.short_name || option.provided_name}
            </Typography>
            {option.alias_name?.length > 0 && (
              <Typography sx={{ mb: 1 }} variant="caption">
                Aliases: {option.alias_name.join(", ")}
              </Typography>
            )}
            <Typography variant="caption" sx={{ fontStyle: "italic", mb: 2 }}>
              {option?.certsPopulated?.[0]?.cert_corrections
                ?.iso_manufacturer_address ||
                option?.certsPopulated?.[0]?.cert_type_metadata
                  ?.iso_manufacturer_address}
            </Typography>
          </li>
        )}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Input Manufacturer Name Here" />
        )}
      />
      <Button
        disabled={value === null}
        size="small"
        sx={{ ml: 1, height: "48px", mt: "6px" }}
        variant="outlined"
        onClick={() => {
          if (mode === "edit") handleChange("editName", manufacturer_id);
          else handleChange("addManuLoad");
          setValue(null);
          setInputValue("");
        }}
      >
        {mode === "edit" ? "Edit Name" : "Add Manufacturer"}
      </Button>
    </div>
  );
};

export default ManufacturerNameInput;
