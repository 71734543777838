import {
  Box,
  Collapse,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Close, Add } from "@mui/icons-material";
import _ from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* schena: {
    visible: false,
    header: "",
    tables: [
      {
        title: "",
        items: [
          {
            manufacturer: "",
            list: [{ model: "", tec: "", issue_date: "" }],
          },
        ],
      },
    ],
  }; */
function PartDinput(props) {
  const { appendix, setAppendix, application, userInfo, userRoles } = props;

  const handleChanges = (change, e, index, itemIndex, listIndex, type) => {
    try {
      const clone = _.cloneDeep(appendix.partD);

      switch (change) {
        case "visible":
          clone.visible = e.target.checked;
          break;
        case "header":
          clone.header = e.target.value;
          break;
        case "title":
          clone.tables[index].title = e.target.value;
          break;
        case "manufacturer":
          clone.tables[index].items[itemIndex].manufacturer = e.target.value;
          break;
        case "listItem":
          clone.tables[index].items[itemIndex].list[listIndex][type] =
            e.target.value;
          break;
        case "addTable":
          clone.tables.push({
            title: "",
            items: [
              {
                manufacturer: "",
                list: [{ model: "", tec: "", issue_date: "" }],
              },
            ],
          });
          break;
        case "addItem":
          clone.tables[index].items.push({
            manufacturer: "",
            list: [{ model: "", tec: "", issue_date: "" }],
          });
          break;
        case "addListItem":
          clone.tables[index].items[itemIndex].list.push({
            model: "",
            tec: "",
            issue_date: "",
          });
          break;
        case "deleteTable":
          clone.tables.splice(index, 1);
          break;
        case "deleteItem":
          clone.tables[index].items.splice(itemIndex, 1);
          break;
        case "deleteListItem":
          clone.tables[index].items[itemIndex].list.splice(listIndex, 1);
          break;
      }

      setAppendix({
        ...appendix,
        partD: clone,
      });
    } catch (error) {
      console.error(error);
    }
  };


  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))
  

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <FormControlLabel
        disabled={isDisabled} 
        checked={appendix?.partD?.visible || false}
        onChange={(e) => handleChanges("visible", e)}
        label="Show Part D"
        control={<Checkbox />}
      />
      <TextField
        disabled={isDisabled} 
        label="Title"
        fullWidth
        variant="standard"
        onChange={(e) => handleChanges("header", e)}
        value={appendix?.partD?.header || ""}
      />
      {appendix?.partD?.tables.map((table, index) => {
        return (
          <Accordion defaultExpanded key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              sx={{
                backgroundColor: "lightgray",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>{table.title || "Untitled"}</Typography>
                <Button
                  disabled={isDisabled} 
                  color="error"
                  onClick={() => handleChanges("deleteTable", undefined, index)}
                >
                  Delete
                </Button>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "center" }}>
              <TextField
                disabled={isDisabled} 
                label="Table title"
                fullWidth
                variant="standard"
                onChange={(e) => handleChanges("title", e, index)}
                value={table.title || ""}
              />
              {table.items?.map((item, itemIndex) => {
                return (
                  <Card key={itemIndex} sx={{ mt: 2 }} variant="outlined">
                    <CardContent sx={{ "&:last-child": { pb: 1 } }}>
                      <Box
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <IconButton
                          disabled={isDisabled} 
                          sx={{
                            alignSelf: "flex-end",
                            backgroundColor: "#ff234ab8",
                            padding: "5px",
                          }}
                          onClick={() =>
                            handleChanges(
                              "deleteItem",
                              undefined,
                              index,
                              itemIndex
                            )
                          }
                        >
                          <Close sx={{ fontSize: "20px" }} />
                        </IconButton>
                      </Box>
                      <TextField
                        disabled={isDisabled} 
                        label="Manufacturer"
                        fullWidth
                        variant="standard"
                        onChange={(e) =>
                          handleChanges("manufacturer", e, index, itemIndex)
                        }
                        value={item.manufacturer || ""}
                      />
                      {item.list?.map((listItem, listIndex) => {
                        return (
                          <Box key={listIndex} sx={{ display: "flex" }}>
                            <TextField
                              disabled={isDisabled} 
                              fullWidth
                              label="Model"
                              variant="standard"
                              onChange={(e) =>
                                handleChanges(
                                  "listItem",
                                  e,
                                  index,
                                  itemIndex,
                                  listIndex,
                                  "model"
                                )
                              }
                              value={listItem.model || ""}
                            />
                            <TextField
                              disabled={isDisabled} 
                              fullWidth
                              label="Type Exam. Certificate"
                              variant="standard"
                              onChange={(e) =>
                                handleChanges(
                                  "listItem",
                                  e,
                                  index,
                                  itemIndex,
                                  listIndex,
                                  "tec"
                                )
                              }
                              value={listItem.tec || ""}
                            />
                            <TextField
                              disabled={isDisabled} 
                              fullWidth
                              label="Issue Date"
                              variant="standard"
                              onChange={(e) =>
                                handleChanges(
                                  "listItem",
                                  e,
                                  index,
                                  itemIndex,
                                  listIndex,
                                  "issue_date"
                                )
                              }
                              value={listItem.issue_date || ""}
                            />
                            <IconButton
                              onClick={() =>
                                handleChanges(
                                  "deleteListItem",
                                  undefined,
                                  index,
                                  itemIndex,
                                  listIndex
                                )
                              }
                              disabled={isDisabled} 
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        );
                      })}
                      <Button
                        color="inherit"
                        onClick={() =>
                          handleChanges(
                            "addListItem",
                            undefined,
                            index,
                            itemIndex
                          )
                        }
                        disabled={isDisabled} 
                      >
                        <Add sx={{ fontSize: "0.8rem" }} />
                        Add list item
                      </Button>
                    </CardContent>
                  </Card>
                );
              })}
              <Button
                color="inherit"
                onClick={() => handleChanges("addItem", undefined, index)}
                disabled={isDisabled} 
              >
                <Add sx={{ fontSize: "0.8rem" }} />
                Add item
              </Button>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Button
        sx={{ alignSelf: "center" }}
        onClick={() => handleChanges("addTable")}
        disabled={isDisabled} 
      >
        Add table
      </Button>
    </Box>
  );
}

export default PartDinput;
