import { useTranslate } from "react-admin"
const TableTitle = ({data, ...props}) => {
	const translate = useTranslate()
	return (
		<div
			className={props.className}
		>
			<div>{translate("phase2.ru.enhancedTableHead.Model Code")} : {data?.model_code}</div>
			<div>{translate("phase2.ru.enhancedTableHead.Model Name")} : {data?.provided_model_name}</div>
		</div>
	)
}


export default TableTitle