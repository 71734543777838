import { useState, useEffect } from "react";
import {
  Show,
  SimpleShowLayout,
  DateField,
  Datagrid,
  List,
  TextField,
  Create,
  useRecordContext,
  Edit,
  EditButton,
  SimpleForm,
  TextInput,
  SelectInput,
  useDataProvider,
  useRefresh,
  useUnselectAll,
  ShowButton,
  FunctionField,
  DateInput,
  NumberInput,
  Toolbar,
  SaveButton,
} from "react-admin";
import DeleteWithConfirmButton from "../buttons/DeleteWithConfirmButton";

// usehooks
import { useMediaQuery } from "@uidotdev/usehooks";
import { useTranslation } from 'react-i18next';


export const RCShow = () => {
  const { t : itranslate } = useTranslation();
  return (
    <Show>
      <SimpleShowLayout>
        <TextField 
          // label="RC Code" 
          label={itranslate('rc.RC Code')}
          source="name" 
        />
        <TextField 
          // label="Maximum Application" 
          label={itranslate('rc.Maximum Application')}
          source="maximum_application" 
        />
        <TextField 
          // label="RC Number (lift)" 
          label={itranslate('rc.RC Number (lift)')}
          source="rc_number_lift" 
        />
        <TextField 
          // label="RC Number (escalator)" 
          label={itranslate('rc.RC Number (escalator)')}
          source="rc_number_escalator" 
        />
        <FunctionField
          // label="Address"
          label={itranslate('rc.Address')}
          render={(record) => Object.values(record?.address || {})?.join(" ")}
        />
        <TextField 
          // label="telephone" 
          label={itranslate('rc.telephone')}
          source="telephone" 
        />
        <TextField 
          // label="fax" 
          label={itranslate('rc.fax')}
          source="fax" 
        />
        <DateField 
          // label="Expiry Date (lift)" 
          label={itranslate('rc.Expiry Date (lift)')}
          source="expiry_date_lift" 
        />
        <DateField
          // label="Expiry Date (escalator)"
          label={itranslate('rc.Expiry Date (escalator)')}
          source="expiry_date_escalator"
        />
      </SimpleShowLayout>
    </Show>
  )
}


export const RCList = () => {
  const { t : itranslate } = useTranslation()

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const isMediumDevice = useMediaQuery(
    "only screen and (min-width : 769px) and (max-width : 992px)"
  );
  const isLargeDevice = useMediaQuery(
    "only screen and (min-width : 993px) and (max-width : 1200px)"
  );
  const isExtraLargeDevice = useMediaQuery(
    "only screen and (min-width : 1201px)"
  );

  const rcFilters = [
    <TextInput
      source="q" label="company name or name" alwaysOn
      sx={
        isExtraLargeDevice ? {
          width: "50em",
        } : isLargeDevice ? {
          width: "40em",
        } : isMediumDevice ? {
          width: "35em",
        } : isSmallDevice ? {
          width: "25em",
        } : {
          width: "15em",
        }
      }
    />
  ];

  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("rcs");
  
  const RcBulkActionButtons = (props) => (
   
    <DeleteWithConfirmButton
      {...props}
      customDeleteLogic={async (selectedIds) => {
        try {
          await dataProvider.updateMany("rcs", {
            ids: selectedIds,
            data: { active: false },
          });
          unselectAll();
          refresh();
        } catch (error) {
          console.log(error);
        }
      }}
    />
  );

  return (
    <List
      filters={rcFilters} 
      // title={`- RCs`}
      title={`- ${itranslate('appBar.RCs')}`}
      sx={
        isExtraLargeDevice ? {
        } : isLargeDevice ? {
        } : isMediumDevice ? {
          mt: "3em",
        } : isSmallDevice ? {
          mt: "3em",
        } : {
          mt: "3em",
        }
      }
    >
      <Datagrid bulkActionButtons={<RcBulkActionButtons />}>
        <DateField 
          // label="Created At" 
          label={itranslate('rc.Created At')}
          source="created_at" 
        />
        <TextField 
          // label="Company" 
          label={itranslate('rc.Company')}
          source="address.company_short" 
        />
        <TextField 
          // label="RC Code" 
          label={itranslate('rc.RC Code')}
          source="name" 
        />
        <TextField
          // label="RC Number (lift)" 
          label={itranslate('rc.RC Number (lift)')}
          source="rc_number_lift" 
        />
        <TextField 
          // label="RC Number (escalator)" 
          label={itranslate('rc.RC Number (escalator)')}
          source="rc_number_escalator" 
        />
        <TextField 
          source="default_role_name" 
          // label="Role" 
          label={itranslate('rc.Role')}
        />
        <EditButton 
          // label={`${itranslate('button.Edit')}`}
          basePath="/rcs" 
          sx={{ padding: '0' }}
        />
        <ShowButton 
          // label={`${itranslate('button.Show')}`}
          basePath="/rcs" 
          sx={{ padding: '0' }}
        />
      </Datagrid>
    </List>
  );
};


const RCTitle = () => {
  const record = useRecordContext();
  return <span>RC {record ? `"${record?.title}"` : ""}</span>;
};



export const RCEdit = () => {
  const { t : itranslate } = useTranslation()

  const dataProvider = useDataProvider();
  const [roles, setRoles] = useState([]);
  const EditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const { data: teams } = await dataProvider.getList("team", {
          pagination: { page: 1, perPage: null },
        });

        const fetchedRoles = teams.flatMap((team) => team.engineers);
        setRoles(fetchedRoles);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRoles();
  }, [dataProvider]);

  return (
    <Edit title={<RCTitle />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput 
          sx={{ width: "300px" }}
          // label="Name" 
          label={itranslate('rc.Name')}
          source="name" 
        />
        <NumberInput 
          sx={{ width: "300px" }}
          // label="Maximum Application" 
          label={itranslate('rc.Maximum Application')}
          source="maximum_application" 
        />
        <SelectInput
          sx={{ width: "300px" }}
          // label="Role"
          label={itranslate('rc.Role')}
          source="default_role_id"
          choices={roles.map((option) => ({
            id: option._id,
            name: option.role_name,
          }))}
        />
        <TextInput 
          sx={{ width: "300px" }}
          // label="RC Number (lift)" 
          label={itranslate('rc.RC Number (lift)')}
          source="rc_number_lift" 
        />
        <TextInput
          sx={{ width: "300px" }}
          // label="RC Number (escalator)" 
          label={itranslate('rc.RC Number (escalator)')}
          source="rc_number_escalator" 
        />  

      <TextInput
        sx={{ width: "300px" }}
        // label="Company" 
        label={itranslate('rc.Company')}
        source="address.company" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Company Short" 
        label={itranslate('rc.Company Short')}s
        source="address.company_short" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Company Chinese" 
        label={itranslate('rc.Company Chinese')}
        source="address.company_chinese" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Flat" 
        label={itranslate('rc.Flat')}
        source="address.flat" 
      />
      <NumberInput 
        sx={{ width: "300px" }}
        // label="Floor" 
        label={itranslate('rc.Floor')}
        source="address.floor" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Block" 
        label={itranslate('rc.Block')}
        source="address.block" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Building" 
        label={itranslate('rc.Building')}
        source="address.building" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Building Chinese" 
        label={itranslate('rc.Building Chinese')}
        source="address.building_chinese" 
      />
      <NumberInput
        sx={{ width: "300px" }}
        // label="Street number (start)"
        label={itranslate('rc.Street number (start)')}
        source="address.street_start_no"
      />
      <NumberInput 
        sx={{ width: "300px" }}
        // label="Street number (end)" 
        label={itranslate('rc.Street number (end)')}
        source="address.street_end_no" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Street" 
        label={itranslate('rc.Street')}
        source="address.street" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Street Chinese" 
        label={itranslate('rc.Street Chinese')}
        source="address.street_chinese" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Estate" 
        label={itranslate('rc.Estate')}
        source="address.estate" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Estate Chinese" 
        label={itranslate('rc.Estate Chinese')}
        source="address.estate_chinese" 
      />
      <NumberInput 
        sx={{ width: "300px" }}
        // label="District Code" 
        label={itranslate('rc.District Code')}
        source="address.district_code" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="District" 
        label={itranslate('rc.District')}
        source="address.district_name" 
      />
      <TextInput
        sx={{ width: "300px" }}
        // label="District Chinese"
        label={itranslate('rc.District Chinese')}
        source="address.district_name_chinese"
      />
      <NumberInput 
        sx={{ width: "300px" }}
        // label="Telephone" 
        label={itranslate('rc.Telephone')}
        source="telephone" 
      />
      <NumberInput 
        sx={{ width: "300px" }}
        // label="Fax" 
        label={itranslate('rc.Fax')}
        source="fax" 
      />
      <DateInput 
        sx={{ width: "300px" }}
        // label="Expiry Date (lift)" 
        label={itranslate('rc.Expiry Date (lift)')}
        source="expiry_date_lift" 
      />
      <DateInput
        sx={{ width: "300px" }}
        // label="Expiry Date (escalator)"
        label={itranslate('rc.Expiry Date (escalator)')}
        source="expiry_date_escalator"
      />
{/* 
        <TextInput label="Company" source="address.company" />
        <TextInput label="Company Short" source="address.company_short" />
        <TextInput label="Company Chinese" source="address.company_chinese" />
        <TextInput label="Flat" source="address.flat" />
        <NumberInput label="Floor" source="address.floor" />
        <TextInput label="Block" source="address.block" />
        <TextInput label="Building" source="address.building" />
        <TextInput label="Building Chinese" source="address.building_chinese" />
        <NumberInput
          label="Street number (start)"
          source="address.street_start_no"
        />
        <NumberInput
          label="Street number (end)"
          source="address.street_end_no"
        />
        <TextInput label="Street" source="address.street" />
        <TextInput label="Street Chinese" source="address.street_chinese" />
        <TextInput label="Estate" source="address.estate" />
        <TextInput label="Estate Chinese" source="address.estate_chinese" />
        <NumberInput label="District Code" source="address.district_code" />
        <TextInput label="District" source="address.district_name" />
        <TextInput
          label="District Chinese"
          source="address.district_name_chinese"
        />
        <NumberInput label="Telephone" source="telephone" />
        <NumberInput label="Fax" source="fax" />
        <DateInput label="Expiry Date (lift)" source="expiry_date_lift" />
        <DateInput
          label="Expiry Date (escalator)"
          source="expiry_date_escalator"
        /> */}
      </SimpleForm>
    </Edit>
  );
};



export const RCCreate = (props) => {
  const { t : itranslate } = useTranslation();
  return (
    <Create {...props} resource="rcs">
    <SimpleForm>
      <TextInput 
        sx={{ width: "300px" }}
        // label="Name" 
        label={itranslate('rc.Name')}
        source="name" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="RC Number (lift)" 
        label={itranslate('rc.RC Number (lift)')}
        source="rc_number_lift" 
      />
      <TextInput
        sx={{ width: "300px" }}
        // label="RC Number (escalator)" 
        label={itranslate('rc.RC Number (escalator)')}
        source="rc_number_escalator" 
      />
      <TextInput
        sx={{ width: "300px" }}
        // label="Company" 
        label={itranslate('rc.Company')}
        source="address.company" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Company Short" 
        label={itranslate('rc.Company Short')}s
        source="address.company_short" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Company Chinese" 
        label={itranslate('rc.Company Chinese')}
        source="address.company_chinese" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Flat" 
        label={itranslate('rc.Flat')}
        source="address.flat" 
      />
      <NumberInput 
        sx={{ width: "300px" }}
        // label="Floor" 
        label={itranslate('rc.Floor')}
        source="address.floor" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Block" 
        label={itranslate('rc.Block')}
        source="address.block" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Building" 
        label={itranslate('rc.Building')}
        source="address.building" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Building Chinese" 
        label={itranslate('rc.Building Chinese')}
        source="address.building_chinese" 
      />
      <NumberInput
        sx={{ width: "300px" }}
        // label="Street number (start)"
        label={itranslate('rc.Street number (start)')}
        source="address.street_start_no"
      />
      <NumberInput 
        sx={{ width: "300px" }}
        // label="Street number (end)" 
        label={itranslate('rc.Street number (end)')}
        source="address.street_end_no" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Street" 
        label={itranslate('rc.Street')}
        source="address.street" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Street Chinese" 
        label={itranslate('rc.Street Chinese')}
        source="address.street_chinese" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Estate" 
        label={itranslate('rc.Estate')}
        source="address.estate" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="Estate Chinese" 
        label={itranslate('rc.Estate Chinese')}
        source="address.estate_chinese" 
      />
      <NumberInput 
        sx={{ width: "300px" }}
        // label="District Code" 
        label={itranslate('rc.District Code')}
        source="address.district_code" 
      />
      <TextInput 
        sx={{ width: "300px" }}
        // label="District" 
        label={itranslate('rc.District')}
        source="address.district_name" 
      />
      <TextInput
        sx={{ width: "300px" }}
        // label="District Chinese"
        label={itranslate('rc.District Chinese')}
        source="address.district_name_chinese"
      />
      <NumberInput 
        sx={{ width: "300px" }}
        // label="Telephone" 
        label={itranslate('rc.Telephone')}
        source="telephone" 
      />
      <NumberInput 
        sx={{ width: "300px" }}
        // label="Fax" 
        label={itranslate('rc.Fax')}
        source="fax" 
      />
      <DateInput 
        sx={{ width: "300px" }}
        // label="Expiry Date (lift)" 
        label={itranslate('rc.Expiry Date (lift)')}
        source="expiry_date_lift" 
      />
      <DateInput
        sx={{ width: "300px" }}
        // label="Expiry Date (escalator)"
        label={itranslate('rc.Expiry Date (escalator)')}
        source="expiry_date_escalator"
      />
    </SimpleForm>
  </Create>
  )
}
