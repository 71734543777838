import { useEffect, useState, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';


// components
import EnhancedTableHead from "./ComponentListTable/EnhancedTableHead.jsx";
import InputSearchBar from "./ComponentListTable/InputSearchBar"
import ComponentPerspectiveShow from './show.jsx';

// custom hooks
import { getComponentListForRu, getOneComponentForRu } from "../../../../../../admin/useHooks/useComponents/index.jsx";

import { headCells } from '../../../../../../_components/CompRestrictedUrlMgt/CreateStage2/constants/headCells.js'
import { ShowBtn } from '../../../../../../_hoc/Buttons/ShowBtn.jsx';
import { useTranslation } from 'react-i18next';

const BackBtn = styled(Button)(({ theme }) => ({
    color: "#2A598F",
    backgroundColor: "white",
    '&:hover': {
        color: "white",
        backgroundColor: "#2A598F",
    },
    variant: "outlined",
}));

const component_types = {
    ACOP: "Ascending car overspeed protection means",
    Buffer: "Buffer",
    CarDoorLockingDevice: "Car door locking device",
    LandingDoorLockingDevice: "Landing door locking device",
    OverspeedGovernor: "Overspeed Governor",
    Pallet: "Pallet",
    SafetyCircuitElectricalComponent: "Safety circuit containing electrical components",
    SafetyGear: "Safety Gear",
    Step: "Step",
    UCMP: "Unintended car movement protection means",
    RaptureValve: "Rapture Valve",
    TractionMachine: "Traction Machine",
    Controller: "Controller",
    Others: "Others",
}


export default function ComponentPerspective({ ruResult, handleGetPDFFile }) {
    const { t } = useTranslation("pages", { keyPrefix: "typeApprovalRecord" });
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('client_user');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [isLoading, setIsLoading] = useState(true);
    const [openLoadingModal, setOpenLoadingModal] = useState(true)

    const [totalCount, setTotalCount] = useState(0)
    const [range, setRange] = useState(`[0, 9]`)
    const [filter, setFilter] = useState(`{}`)
    const [sort, setSort] = useState(`["component_code", "DESC"]`)

    const [dataComponents, setDataComponents] = useState([])
    const [componentPerspectiveShowData, setComponentPerspectiveShowData] = useState({})
    const [showView, setShowView] = useState(false)

    // setRange when page changes
    useEffect(() => {
        setRange(`[${page * rowsPerPage},${page * rowsPerPage + rowsPerPage - 1}]`)
    }, [page, rowsPerPage])

    // setSort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort(`["${property}","${isAsc ? "DESC" : "ASC"}"]`)
    };

    function fixedSortingIssue(property) {
        let arr = headCells.components.map(v => v.id)
        if (arr.includes(property[0])) {
            setSort(`["${property[0]}","${property[1]}"]`)
        } else {
            setSort(`["component_code","DESC"]`)
        }
    }

    useEffect(() => {
        fixedSortingIssue(JSON.parse(sort))
        getComponentListForRu(filter, range, sort, ruResult?.encrypted_ruNo).then((res) => {
            setDataComponents(res?.data)
            setTotalCount(+ res?.headers["x-total-count"] || 9999999)
        }).catch((err) => {
        }).finally(() => {
            setIsLoading(false)
        })
    }, [filter, range, sort])


    useEffect(() => {
        if (isLoading === false) {
            setOpenLoadingModal(false)
        } else {
            setOpenLoadingModal(true)
        }

    }, [dataComponents])


    // setPage
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // set rowsPerPage
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, rowsPerPage - dataComponents?.length) : 0;


    // row btn handlers
    const handleView = (rowInfo) => {
        getOneComponentForRu(rowInfo?._id, ruResult.encrypted_ruNo).then((res) => {
            setComponentPerspectiveShowData(res?.data)

            setShowView(true)
        }).catch((err) => {
        })
    }



    return (
        <Container component="main" maxWidth="xl" 
            sx={{ 
                mt: 4, 
                mb: 4
            }}
        >
            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoadingModal}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }

            {
                !showView && (
                    <Box sx={{ width: '100%',
                        boxShadow: "0 0 0 2rem rgba(246,248,249)",
                    }}>
                        <Paper sx={{ width: '100%', mb: 2, overflowX: "auto" }}>

                            {
                                !isLoading && dataComponents?.length > 0 && (
                                    <InputSearchBar
                                        setFilter={setFilter}
                                    />
                                )
                            }

                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                >

                                    {
                                        !isLoading && dataComponents?.length > 0 &&
                                        (
                                            <>
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={dataComponents?.length}
                                                    tableHeadCells={headCells?.components}
                                                />
                                                <TableBody>
                                                    {dataComponents?.length > 0 && dataComponents.map((row, index) => {
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        return (
                                                            <TableRow
                                                                key={row._id}
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                >
                                                                    {row.component_code}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        maxWidth: "8em"
                                                                    }}
                                                                >{row.provided_comp_name}</TableCell>

                                                                <Tooltip 
                                                                    title={component_types[row.type]}
                                                                    followCursor={true}
                                                                    arrow
                                                                >

                                                                    <TableCell
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            whiteSpace: "nowrap",
                                                                            textOverflow: "ellipsis",
                                                                            overflow: "hidden",
                                                                            maxWidth: "8em"
                                                                        }}
                                                                    >
                                                                            {component_types[row.type]}
                                                                    </TableCell>
                                                                </Tooltip>


                                                                <TableCell
                                                                    size="small"
                                                                    sx={{ 
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        width: "3em"
                                                                    }}
                                                                >
                                                                    {/* view btn */}
                                                                    
                                                                    <ShowBtn 
                                                                        handleView={handleView}
                                                                        row={row}
                                                                        code={row.component_code}
                                                                        key={row._id}
                                                                    />



                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}

                                                    {/* fill up the empty dataModel for last page's items of the list */}
                                                    {emptyRows > 0 && (
                                                        <TableRow
                                                            style={{
                                                                height: 60 * emptyRows,
                                                            }}
                                                        >
                                                            <TableCell colSpan={6} />
                                                        </TableRow>
                                                    )}

                                                </TableBody>
                                            </>
                                        )
                                    }
                                </Table>
                            </TableContainer>

                            {!isLoading && dataComponents?.length === 0 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        <h1 className='my-72 text-2xl'>
                                            {t("No Component Found")}
                                        </h1>
                                    </div>
                                </Box>
                            )}

                            {
                                (!isLoading && dataComponents?.length !== 0) && (
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />

                                )
                            }
                        </Paper>
                    </Box>
                )
            }

            {
                showView && componentPerspectiveShowData && (
                    <>
                        <BackBtn 
                            sx={{
                                border: '1px solid #2A598F',
                            }}
                            onClick={() => setShowView(false)}
                        >
                            {t("Back")}
                        </BackBtn>
                        <ComponentPerspectiveShow
                            componentPerspectiveShowData={componentPerspectiveShowData}
                            ruResult={ruResult}
                            handleGetPDFFile={handleGetPDFFile}
                        />
                    </>
                )
            }
        </Container>
    );
}