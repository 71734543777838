import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import keyToField from "../../utils/keyToField";
import renderChinese from "../../utils/pdf/renderChinese";
import moment from "moment";
import { fixInvalidDates } from "../../utils/misc/fixInvalidDates";
import renderSymbol from "../../utils/pdf/renderSymbol";

const styles = StyleSheet.create({
  tableContainer: {
    padding: "5px 0px",
    marginRight: "-15px",
  },
  tableHead: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "none",
    fontFamily: "Frutiger_bold",
    fontSize: "11px",
    marginRight: "-45px",
    marginBottom: "15px",
  },
});

const PartCCompTable = ({
  appendix,
  application,
  component_type,
  romanize,
  index,
  manufacturers,
  liftModels,
  components,
  certificates,
  simpleDisplay,
}) => {

  if (!certificates || !appendix || !application) return null;

  
  // get unique manufacturers by manufacturer code
  let uniqueManufacturers = [];
  const manufacturerCodes = new Set();
  
  for (const manufacturer of manufacturers) {
    if (!manufacturerCodes.has(manufacturer.manufacturer_code)) {
      manufacturerCodes.add(manufacturer.manufacturer_code);
      uniqueManufacturers.push(manufacturer);
    }
  };
  // sort the manufacturers alphabetically
  uniqueManufacturers.sort((a, b) => a.provided_name.localeCompare(b.provided_name));
  // console.log("🚀 ~ uniqueManufacturers:", uniqueManufacturers)



  const checkLiftStandard = [
    "HydraulicLift",
    "ElectricTractionLiftMachineRoom",
    "ElectricTractionLiftRoomless",
  ].includes(application.app_type?.type);

  const checkEscalatorStandard = ["Escalator", "PassengerConveyor"].includes(application.app_type?.type);


  // used later to store the unique keys to remove duplicates
  const unique_Keys = new Set();


  return (
    <View style={styles.tableContainer}>
      <Text
        style={{
          textDecoration: "underline",
          fontFamily: "Frutiger_bold",
          fontSize: "11px",
        }}
      >{`${romanize(liftModels.length + index + 1)}. Safety Components - ${
        keyToField[component_type]
      }`}</Text>
      <View style={styles.tableHead}>
        <Text style={{ width: "33.3333%", paddingLeft: "6px" }}>Model</Text>
        <Text style={{ width: "33.3333%" }}>Type Exam. Certificate</Text>
        <Text style={{ width: "33.3333%" }}>Issue Date</Text>
      </View>

        {/* group the components by manufacturer code */}
        {uniqueManufacturers?.map((manu, i) => {
          // {manufacturers?.map((manu, i) => {
            // const componentsOfManu = components[component_type].filter((compModel) => compModel.manufacturer_id === manu._id);
          const componentsOfManu = components[component_type].filter((compModel) => compModel.manufacturer_code === manu.manufacturer_code);


          function getCertList(component) {
            const certs = certificates.filter(
              (tec) =>
                tec?.reference === component.component_code &&
                tec.component_type === component_type &&
                tec.type === "ComponentTypeCert" &&
                !appendix.hideCerts?.includes(tec.id) &&
                !appendix.hideComponentCodes?.includes(tec.reference)
            );
            return certs;
          }



          /***************************  Certificates  ****************************/

          // combine all the certificates of the manufacturer
          let combinedCerts = componentsOfManu.reduce((acc, comp) => {
            const certs = getCertList(comp);
            return [...acc, ...certs];
          }, []);


          // remove any duplicates by certificate id
          const uniqueIds = new Set();
          let uniquefilteredCerts = combinedCerts.filter(cert => {
            
            if (uniqueIds.has(cert.id)) {
              return false; // Exclude duplicates
            }
            uniqueIds.add(cert.id); // Add to Set
            return true; // Keep this item
          });


          // remove further duplicates by component_type, reference (component code), description (component name), and tec_certificate_number
          const uniqueIdss = new Set();
          const uniquefilteredCertss = uniquefilteredCerts.filter(item => {
            // Call simpleDisplay to get the value for comparison
            const displayValue = simpleDisplay(item.id, "tec_certificate_number"); 
            
            // Construct a unique key based on multiple fields
            const uniqueKey = `${item.component_type}|${item.reference}|${item.description}|${displayValue}`;

            if (uniqueIdss.has(uniqueKey)) {
              return false; // Exclude duplicates
            }
            
            uniqueIdss.add(uniqueKey); // Add unique key to the Set
            return true; // Keep this item
          });

          /***************************  Certificates  ****************************/


        

          /***************************  Components  ****************************/
        
          // get the certs for each component
          const filteredComp = componentsOfManu.filter((component) => {
            const certs = getCertList(component);
            return certs.length > 0;
          });


          // remove any duplicates first by component code
          // then combine the type_test_certificate_id arrays, and then remove duplicates
          let uniquefilteredComps = Array.from(filteredComp.reduce((map, comp) => {
            if (!map.has(comp.component_code)) {
              map.set(comp.component_code, { ...comp, type_test_certificate_id: [] });
            }
            // Combine and remove duplicates using a Set
            const existingIds = map.get(comp.component_code).type_test_certificate_id;
            const combinedIds = new Set([...existingIds, ...comp.type_test_certificate_id]);
            map.get(comp.component_code).type_test_certificate_id = [...combinedIds];
            
            return map;
          }, new Map()).values());

          // sort the components alphabetically
          uniquefilteredComps.sort((a, b) => a.provided_comp_name.localeCompare(b.provided_comp_name));

          /***************************  Components  ****************************/


        // if manufacturer have components
        // if (filteredComp.length > 0) {
        //   return (
        //     <View
        //       key={i}
        //       style={{ paddingBottom: "10px", marginRight: "-45px" }}
        //     >
        //       <Text
        //         style={{
        //           width: "40%",
        //           fontFamily: "Frutiger_bold",
        //           fontSize: "11px",
        //           paddingLeft: "3px",
        //           ...renderChinese(manu.provided_name),
        //         }}
        //         hyphenationCallback={(word) => [word]} // Prevents hyphenation
        //       >
        //         {manu?.short_name || manu?.provided_name}
        //       </Text>
              
        //       {filteredComp.map((component) => {
        //         const certs = getCertList(component);

        //         return certs.map((cert, i) => (
        //           <View
        //             key={i}
        //             style={{
        //               display: "flex",
        //               flexDirection: "row",
        //               alignItems: "center",
        //               ...(!cert.historical && { fontFamily: "Frutiger_italic" }),
        //             }}
        //           >
        //             <Text style={{ width: "33.3333%", paddingLeft: "6px" }}>
        //               {component.provided_comp_name}
        //             </Text>
        //             <Text style={{ width: "33.3333%" }}>
        //               {simpleDisplay(cert.id, "tec_type_exam") ||
        //                 simpleDisplay(cert.id, "tec_certificate_number")}
        //             </Text>
        //             <Text style={{ width: "33.3333%" }}>
        //               {simpleDisplay(cert.id, "tec_issue_date") &&
        //                 renderSymbol(
        //                   fixInvalidDates(
        //                     simpleDisplay(cert.id, "tec_issue_date")
        //                   )
        //                 )}
        //               {checkLiftStandard && cert.comply_standard?.lift && (
        //                 <>
        //                   {" "}
        //                   [<Text style={{ fontFamily: "DejaVu Sans" }}>Ƨ</Text>]
        //                 </>
        //               )}
        //               {checkEscalatorStandard &&
        //                 cert.comply_standard?.escalator &&
        //                 `[#]`}
        //             </Text>
        //           </View>
        //         ));
        //       })}
        //     </View>
        //   );
        // }


        // const unique_Keys = new Set();

        if (uniquefilteredComps.length > 0) {
          return (
            <View
              key={i}
              style={{ paddingBottom: "10px", marginRight: "-45px" }}
            >
              <Text
                style={{
                  width: "40%",
                  fontFamily: "Frutiger_bold",
                  fontSize: "11px",
                  paddingLeft: "3px",
                  ...renderChinese(manu.provided_name),
                }}
                hyphenationCallback={(word) => [word]} // Prevents hyphenation
              >
                {manu?.short_name || manu?.provided_name}
              </Text>
              
              {uniquefilteredComps.map((component) => {
                return component.type_test_certificate_id.map((certId) => {

                  // only render the cert belong to the component
                  const tec = uniquefilteredCerts.find((tec) => tec.id === certId);
                  if (!tec) return null; // Skip if no matching tec



                  const displayValue = simpleDisplay(tec.id, "tec_certificate_number");

                  // Create a unique key from the specified fields
                  const uniqueKey = `${component_type}|${component.manufacturer_code}|${component.provided_comp_name}|${displayValue}`;

                  // Check for duplicates
                  if (unique_Keys.has(uniqueKey)) {
                    return null; // Skip rendering this duplicate
                  };

                  unique_Keys.add(uniqueKey); // Add the unique key to the Set


                  
                  return (
                    <View
                      key={tec.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        ...(!tec.historical && { fontFamily: "Frutiger_italic" }),
                      }}
                    >
                      <Text style={{ width: "33.3333%", paddingLeft: "6px" }}>
                        {component.provided_comp_name}
                      </Text>
                      <Text style={{ width: "33.3333%" }}>
                        {simpleDisplay(tec.id, "tec_type_exam") ||
                          simpleDisplay(tec.id, "tec_certificate_number")}
                      </Text>
                      <Text style={{ width: "33.3333%" }}>
                        {simpleDisplay(tec.id, "tec_issue_date") &&


                        renderSymbol(
                          fixInvalidDates(
                            simpleDisplay(tec.id, "tec_issue_date")
                          )
                        )}
                      {checkLiftStandard && tec.comply_standard?.lift && (
                        <>
                          {" "}
                          [<Text style={{ fontFamily: "DejaVu Sans" }}>Ƨ</Text>]
                        </>
                      )}
                      {checkEscalatorStandard &&
                        tec.comply_standard?.escalator &&
                        `[#]`}
                    </Text>
                  </View>
                )

                })})}
            </View>
          );
        }


      })}
    </View>
  );
};

export default PartCCompTable;
