import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
} from "@mui/material";
// import keyToField from "../../utils/keyToField";

const PreviewRevokeApplication = ({
  upload,
  modelsLoad,
  componentsLoad,
  manufacturersData,
}) => {
  const selectedManufacturer = [];
  const selectedModels = [];
  const selectedSafetyComponents = [];


  // get data of selected manufactuers (with current, it should be one )
  for (const key in manufacturersData) {
    if (manufacturersData[key].manufacturer_code === upload.manufacturer) {
      selectedManufacturer.push(manufacturersData[key]);
    }
  }

  // get data for selected models, (can be multiple models, in array)
  for (const defaultModel in modelsLoad) {
    for (const selectedModel in upload.model) {
      const id = upload.model[selectedModel];
      const actualModelId = id.slice(0, -1);
      if (modelsLoad[defaultModel].id === actualModelId) {
        // Check if the model already exists in the selectedModels array
        const isAlreadySelected = selectedModels.some((model) => model.id === modelsLoad[defaultModel].id);
        if (!isAlreadySelected) {
          selectedModels.push(modelsLoad[defaultModel]);
        }
      }
    }
  }

  // get data for selected safety components, (can be multiple models, in array)
  for (const defaultSC in componentsLoad) {
    for (const selectedSC in upload.components) {
      if (componentsLoad[defaultSC].id === upload.components[selectedSC]) {
        selectedSafetyComponents.push(componentsLoad[defaultSC]);
      }
    }
  }

  return (
    <Box sx={{ display: "flex", gap: 4 }}>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Manufacturer
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {selectedManufacturer.map((manufacturer, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography>{manufacturer.manufacturer_code} - {manufacturer.provided_name}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Models
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {selectedModels.map((model, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography>{model.model_code} - {model.provided_model_name} </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Safety Component
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {selectedSafetyComponents.map((component, index) => (
              <TableRow key={index}>
                <TableCell>
                  {/* <Typography>{component.component_code} - {component.provided_comp_name} - {keyToField[component.type]}</Typography> */}
                  <Typography>{component.component_code} - {component.provided_comp_name} - {component.type}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PreviewRevokeApplication;
