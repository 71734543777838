import { useEffect, useState, cloneElement } from "react";
import {
  Show,
  SimpleShowLayout,
  Datagrid,
  List,
  TextField,
  FunctionField,
  Create,
  useRecordContext,
  ShowButton,
  useShowController,
  DateField,
  EditButton,
  ArrayInput,
  SimpleFormIterator,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
} from "react-admin";
import { ManufacturerDisplay } from "../components/manufacturer/ManufacturerDisplay";
import isGetOneData from "../utils/applications/isGetOneData";
import ManufacturerFilter from "./filters/ManufacturerFilter";
import manufacturersExporter from "./exporters/manufacturersExporter";
import keyToField from "../utils/keyToField.js";
import * as static_fields from "../utils/static_fields.json";
import ShortNameEditor from "../components/approval/ShortNameEditor.js";

import PreviewRcs from "../components/manufacturer/PreviewRcs";

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const Divider = () => (
  <hr
    style={{
      margin: "100px 0",
      height: "1px",
      border: "none",
      background: "#ccc",
    }}
  />
);

export const ManufacturerShow = () => {
  const { record } = useShowController();
  const [update, setUpdate] = useState(null);

  useEffect(() => {
    if (record && isGetOneData(record, "manufacturers"))
      setUpdate({ manufacturer: [record] });
  }, [record]);

  return (
    <Show title={<ManufacturerTitle />}>
      <SimpleShowLayout>
        {update && (
          <ManufacturerDisplay
            show={true}
            showOne={true}
            edit={false}
            add={false}
            title={""}
            upload={update}
            setUpload={setUpdate}
            template={static_fields}
          />
        )}
      </SimpleShowLayout>
    </Show>
  );
};


export const ManufacturerList = () => {
const { t : itranslate } = useTranslation();
  return (
    <List
      filters=<ManufacturerFilter />
      exporter={manufacturersExporter}
      // title={`- Manufacturers`}
      title={`- ${itranslate('appBar.Manufacturers')}`}
      sort={{ field: "approval_date", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Manufacturer Code" source="manufacturer_code" />
        <TextField label="Manufacturer Name" source="provided_name" />
        <FunctionField
          render={(record) => keyToField[record.type]}
          label="Scope of Manufacturer"
          source="type"
        />
        <FunctionField
          render={(record) =>
            record.certsPopulated?.[0]?.cert_corrections
              ?.iso_manufacturer_address ||
            record.certsPopulated?.[0]?.cert_type_metadata
              ?.iso_manufacturer_address
          }
          label="Manufacturer Address"
          source="certsPopulated.cert_type_metadata.iso_manufacturer_address"
        />
        {
          window.location.pathname === "/lift_emsd" && (
            <FunctionField
              render={(record) => {
                return <PreviewRcs record={record} />
              }}
              label="RC Name"
              source="rcsFromAppPopulated"
            />
          )
        }
        {
          window.location.pathname === "/lift_rc" && (
            <FunctionField
              render={(record) => {
                let rlc = record?.rcPopulated?.[0]?.rc_number_lift;
                let rec = record?.rcPopulated?.[0]?.rc_number_escalator;
                let company = record?.rcPopulated?.[0]?.address.company;
                let rc_id = record?.rcPopulated?.[0]?._id;
                return (
                  <Link
                    className="no-underline text-[#2a598f]"
                    to={`/rcs/${rc_id}/show`}
                  >
                    {`${rlc ? rlc + " / " : ""} ${rec ? rec + " / " : ""} ${company}`}
                  </Link>
                );
              }}
              label="RC Name"
              source="rcPopulated.name"
            />
          )
        }

        <DateField label="All Approval Date" source="approval_date" />
        {window.location.pathname === "/lift_emsd" && <EditButton sx={{ padding: '0' }}/>}
        <ShowButton sx={{ padding: '0' }}/>
      </Datagrid>
    </List>
  )
}


const ManufacturerTitle = () => {
  const record = useRecordContext();
  return <span>- Manufacturer {record?.manufacturer_code}</span>;
};

export const ManufacturerEdit = () => {
  // omit the delete button by using custom toolbar
  const ManufacturerEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit title={<ManufacturerTitle />}>
      <>
        <SimpleForm toolbar={<ManufacturerEditToolbar />}>
          <TextField source="provided_name" sx={{ fontSize: "1.2rem", mb: 3 }} />
          <TextInput source="short_name" />
          <ArrayInput source="alias_name" sx={{ py: 3 }}>
            <SimpleFormIterator inline>
              <TextInput
                format={(value) => (typeof value === "object" ? "" : value)}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </>
    </Edit>
  );
};

export const ManufacturerCreate = (props) => (
  <Create {...props} resource="manufacturers">
    <SimpleForm>
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="title" />
      <TextInput multiline source="body" />
    </SimpleForm>
  </Create>
);
