import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Modal,
  Paper,
  IconButton,
  Button,
  ButtonGroup,
  Tabs,
  Tab,
} from "@mui/material";
import { useNotify, Confirm, useRefresh } from "react-admin";
import { Close, FormatBold } from "@mui/icons-material";
import { PDFViewer } from "@react-pdf/renderer";
import { RevokeEditInput } from "./RevokeEditInput";
import { RevocationLetter } from "./RevocationLetter";
import RevocationLetterDownload, { RevocationLetterPDF } from "./RevocationLetterPDF";
import axios from "axios";
import getToken from "../../../utils/getAuthToken";


export const RevokeModel = ({open, isModalOpen, handleCloseModal, setRc_appendix, rc_appendix, application, rcItem, rcInfo, setAppendix, appendix, app_status}) => {
  // console.log("🚀 ~ RevokeModel ~ appendix:", appendix)
  const notify = useNotify();
  const refresh = useRefresh();
  const status = application?.status;

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  // console.log("🚀 ~ RevokeModel ~ showPDF:", showPDF)
  const [section, setSection] = useState("heading");

  const [text, setText] = useState({
    text: "",
    range: [],
    index: null,
    subIndex: null,
    section: null,
  });

  const [inputs, setInputs] = useState({
    our_reference: "",
    your_reference: "",
    reference_date: "",
    rc_name: "",
  })
  // console.log("🚀 ~ RevokeModel ~ inputs:", inputs)


  useEffect(() => {
    // if model is opened, we want to popuate the inputs
    if (isModalOpen && appendix.length > 0) {
      const data = appendix[0];
      if (data.inputs && Object.keys(data.inputs).length > 0) {
        setInputs(data.inputs);
      }
    };
    // if model is closed, we want to close the inputs
    if (!isModalOpen) {
      ClearInputs()
      if (status !== "Revoked") {
        setShowPDF(false)
      }
    };
  }, [isModalOpen, appendix]);


  const ClearInputs = () => {
    setInputs({
      our_reference: "",
      your_reference: "",
      reference_date: "",
      rc_name: "",
    })
  };


  const lastChange = useRef({
    index: 0,
    subSectionIndex: 0,
    type: "overviews",
    lineIndex: 0,
  });


  const handleSave = async () => {
    // console.log(appendix)
    // console.log(inputs)

    // Copy appendix to CombinedAppendix
    let CombinedAppendix = JSON.parse(JSON.stringify(appendix));

    // Add inputs to CombinedAppendix
    CombinedAppendix[0].inputs = inputs;
    // console.log("🚀 ~ handleSave ~ CombinedAppendix:", CombinedAppendix)

    try {
      if (!application.id) throw new Error("Application not found in database");

      const saveRevocation = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/revoke/revocation/${application.id}`,
        CombinedAppendix,
        { headers: { authorization: getToken() }}
      );

      if (saveRevocation) {
        notify("Successfully saved");
      return true;
      } else {
        throw new Error(
          "Error saving revocation letter, check with administrator or check browser console"
        );
      }
    } catch (error) {
      notify(error);
    }
  };



  const handleDialogCloseWithSave = async (save) => {
    try {
      if (save) {
        const isSaved = await handleSave();
        if (!isSaved) throw new Error("Error saving");
      } 
      setConfirmOpen(false);
      setAppendix([]);
      setRc_appendix({})
      handleCloseModal();
      refresh()
    } catch (err) {
      notify(err.message);
      setConfirmOpen(false);
      handleCloseModal();
      refresh()
    } finally {
      setConfirmOpen(false);
      handleCloseModal();
      refresh()
    }
  };


  const handleDialogCloseWithoutSave = () => {
    setConfirmOpen(false);
    ClearInputs();
    setAppendix([]);
    setRc_appendix({})
    handleCloseModal();
    refresh();
  };


  // load the appendix
  useEffect(() => {
    let temp = []
    if (rc_appendix) {
      temp.push(rc_appendix)
      setAppendix(temp)
    }
  }, [rc_appendix])


  useEffect(() => {
    if (status === "Revoked") {
      setShowPDF(true)
    }
  },[status])


  // fix the height of the pdf problem when using csp
  const handlePDFLoad = () => {
    const iframe = document.getElementsByClassName("revoke_pdf_iframe");
    if (iframe.length === 0) return 
    const iframeDoc = iframe[0].contentWindow.document;
    const embedTag = iframeDoc.getElementsByTagName("embed")[0];
    if (embedTag) {
      embedTag.style.position = "absolute";
      embedTag.style.left = 0;
      embedTag.style.top = 0;
    }
  }


  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Paper
        sx={{
          margin: "0",
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >

        {app_status === "Revoked" ? (
          <IconButton
            sx={{
              position: "absolute",
              right: 0,
              zIndex: 9999,
              backgroundColor: "#ff234ab8",
            }}
            onClick={handleCloseModal}
          >
            <Close sx={{ fontSize: "40px" }} />
          </IconButton>
        ) : (
          <IconButton
          sx={{
            position: "absolute",
            right: 0,
            zIndex: 9999,
            backgroundColor: "#ff234ab8",
          }}
          onClick={() => setConfirmOpen(true)}
        >
          <Close sx={{ fontSize: "40px" }} />
          <Confirm
            isOpen={confirmOpen}
            title="Save changes?"
            content="Click confirm to save, changes will be lost if cancelled."
            onConfirm={() => handleDialogCloseWithSave(true)}
            onClose={() => handleDialogCloseWithoutSave(false)}
          />
        </IconButton>
        )}

        {window.location.pathname === `/lift_emsd` &&
          application?.status !== "Revoked" && (
            <>
              <ButtonGroup
                variant="contained"
                color="inherit"
                sx={{ position: "absolute", left: 0, zIndex: 9999 }}
                size="small"
              >
                <Button onClick={() => handleSave()}>Save</Button>
              </ButtonGroup>
              <Box style={{ width: "50%" }}>
                <div style={{ overflowY: "scroll" }}>
                  <Tabs
                    variant="scrollable"
                    scrollButtons
                    value={section}
                    onChange={(e, value) => setSection(value)}
                    sx={{ mt: 5 }}
                  >
                    <Tab label="heading" value="heading" />
                    <Tab label="body" value="body" />
                    <Button onClick={() => setShowPDF((prevState) => !prevState)}>
                      {!showPDF ? "Review PDF" : "Preview"}
                    </Button>
                  </Tabs>
                </div>
                
                <RevokeEditInput
                  ref={lastChange}
                  appendix={appendix[0]}
                  setAppendix={setAppendix}
                  setText={setText}
                  section={section}
                />

              </Box>
            </>
          )}

        <Box sx={{ display: "flex", width: "50%", flexDirection: "column" }}>
          { showPDF ? (
            <>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                textDecoration: "none",
                height: "20px",
                marginY: "5px",
              }}
            >
              <RevocationLetterDownload 
                 appendix={appendix[0]}
                 application={application}
                 rcAddress={rcInfo.address}
                 rcName={rcInfo.name}
                 rcItem={rcItem}
                 format="pdf"
                 inputs={inputs}
                 setInputs={setInputs}
              />
            </Box>
            <PDFViewer 
              width="100%"
              height="100%"
              overflowY="scroll"
              className="revoke_pdf_iframe"
              onLoad={handlePDFLoad}
            >
              {/* <RevocationLetter */}
              <RevocationLetterPDF
                appendix={appendix[0]}
                application={application}
                rcAddress={rcInfo.address}
                rcName={rcInfo.name}
                rcItem={rcItem}
                format="pdf"
                inputs={inputs}
                setInputs={setInputs}
              />
            </PDFViewer>
            </>
            ) : (
              <Box sx={{width: "75%", overflowY: "scroll"}}>
                <RevocationLetter
                  appendix={appendix[0]}
                  application={application}
                  rcAddress={rcInfo.address}
                  rcName={rcInfo.name}
                  rcItem={rcItem}
                  format="plainHtml"
                  inputs={inputs}
                  setInputs={setInputs}
                />
              </Box>
            )}
        </Box>

      </Paper>
    </Modal>
  );
       
};
