import {
  Card,
  CardContent,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  CardHeader,
  Button,
  Autocomplete,
  TextField,
  ListItem,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetList } from "react-admin";
import { CircularProgress } from "@material-ui/core";
import keyToField from "../../utils/keyToField";

function RecordSelect(props) {
  const {
    app,
    handleTarget,
    handleMultipleTarget,
    rc_id,
    type,
    multiple,
    chosenComponents,
  } = props;
  const { data: modelsLoad, isLoading: isLoadingModel } = useGetList(
    "models",
    {
      pagination: { page: 1, perPage: 0 },
      filter: { rc_id },
    },
    {
      enabled: type === "model",
    }
  );
  const { data: componentsLoad, isLoading: isLoadingComponent } = useGetList(
    "components",
    {
      pagination: { page: 1, perPage: 0 },
      filter: { rc_id, standalone: true },
    },
    {
      enabled: type === "component",
    }
  );

  const autocompleteProps = {
    disablePortal: true,
    renderInput: (params) => <TextField {...params} fullWidth />,
    freeSolo: true,
  };

  const lists = type === "model" ? modelsLoad : componentsLoad;

  if (!lists) return <CircularProgress />;
  return (
    <>
      <InputLabel sx={{ mt: 4 }} id="loader">
        Choose an {type}
      </InputLabel>

      {multiple ? (
        //sc select
        <>
          <Autocomplete
            {...autocompleteProps}
            multiple
            filterSelectedOptions
            value={chosenComponents}
            id="multi_comp_select"
            options={componentsLoad.map((record) => {
              return {
                id: record.id,
                app_id: record.application_id,
                name: `${
                  record?.manufacturersPopulated?.[0]?.provided_name
                } - ${record.provided_comp_name} (${keyToField[record.type]})`,
                rc_id: record.rc_id,
              };
            })}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                <ListItem {...props} key={option.id}>
                  {option.name}
                </ListItem>
              );
            }}
            getOptionLabel={(option) => option.name || ""}
            onChange={(e, newValue) => {
              handleMultipleTarget(newValue, componentsLoad);
            }}
          />
        </>
      ) : (
        <Autocomplete
          {...autocompleteProps}
          id="application_select"
          value={app}
          options={lists.map((record) => {
            return {
              id:
                record.application_id +
                (type === "component" ? `?component=${record.id}` : ""),
              deleteRequestId:
                type === "component" ? record.id : record.application_id,
              label: `${record?.manufacturersPopulated?.[0]?.provided_name} - ${
                record.provided_model_name || record.provided_comp_name
              } (${record.component_code || record.model_code}) (${
                type === "component" ? keyToField[record.type] : ""
              })`,
              manufacturers: record?.manufacturersPopulated,
            };
          })}
          renderOption={(props, option) => {
            return (
              <ListItem {...props} key={option.id}>
                {option.label}
              </ListItem>
            );
          }}
          onChange={(e, newValue) => {
            handleTarget(newValue);
          }}
        />
      )}
    </>
  );
}

export default RecordSelect;
