import { useEffect, useState, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';

// components
import EnhancedTableHead from "./ComponentListTable/EnhancedTableHead.jsx";
import InputSearchBar from "./ComponentListTable/InputSearchBar"
// import ComponentPerspectiveShow from './show.jsx';
import CompPopper from '../../../../../../_hoc/CompPopper/index.jsx';

// custom hooks
import { getComponentListForRuSummary } from "../../../../../../admin/useHooks/useComponents/index.jsx";

import { headCells } from '../../../../CreateStage2/constants/headCells.js'

const ViewBtn = styled(Button)(({ theme }) => ({
    backgroundColor: "#0288d1",
    color: "white",
    '&:hover': {
        backgroundColor: "#01579b",
    },
}));

const BackBtn = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#616161",
    '&:hover': {
        backgroundColor: "#434343",
    },
}));

const component_types = {
    ACOP: "Ascending car overspeed protection means",
    Buffer: "Buffer",
    CarDoorLockingDevice: "Car door locking device",
    LandingDoorLockingDevice: "Landing door locking device",
    OverspeedGovernor: "Overspeed Governor",
    Pallet: "Pallet",
    SafetyCircuitElectricalComponent: "Safety circuit containing electrical components",
    SafetyGear: "Safety Gear",
    Step: "Step",
    UCMP: "Unintended car movement protection means",
    RaptureValve: "Rapture Valve",
    TractionMachine: "Traction Machine",
    Controller: "Controller",
    Others: "Others",
}


export default function SummaryComponentPerspective({ sc_id_arr, certsChosenForScPerspective }) {
    // table pagination
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('client_user');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [isLoading, setIsLoading] = useState(true);
    const [openLoadingModal, setOpenLoadingModal] = useState(true)

    const [totalCount, setTotalCount] = useState(0)
    const [range, setRange] = useState(`[0, 4]`)
    const [filter, setFilter] = useState(`{}`)
    const [sort, setSort] = useState(`["component_code", "DESC"]`)

    const [dataComponents, setDataComponents] = useState([])
    const [showView, setShowView] = useState(false)

    // setRange when page changes
    useEffect(() => {
        setRange(`[${page * rowsPerPage},${page * rowsPerPage + rowsPerPage - 1}]`)
    }, [page, rowsPerPage])

    // setSort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort(`["${property}","${isAsc ? "DESC" : "ASC"}"]`)
    };

    function fixedSortingIssue(property) {
        let arr = headCells.components.map(v => v.id)
        if (arr.includes(property[0])) {
            setSort(`["${property[0]}","${property[1]}"]`)
        } else {
            setSort(`["component_code","DESC"]`)
        }
    }

    useEffect(() => {
        fixedSortingIssue(JSON.parse(sort))
        getComponentListForRuSummary(filter, range, sort, { sc_id_arr, certsChosenForScPerspective }).then((res) => {
            setDataComponents(res?.data)
            setTotalCount(+ res?.headers["x-total-count"] || 9999999)
        }).catch((err) => {
        }).finally(() => {
            setIsLoading(false)
        })
    }, [filter, range, sort])

    useEffect(() => {
        if (isLoading === false) {
            setOpenLoadingModal(false)
        } else {
            setOpenLoadingModal(true)
        }

    }, [dataComponents, isLoading])

    // setPage
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // set rowsPerPage
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, rowsPerPage - dataComponents?.length) : 0;

    return (
        <Container component="main" maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoadingModal}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }

            {
                !showView && (
                    <Box sx={{ 
                            width: '100%',
                            boxShadow: "0 0 0 2rem rgba(246,248,249)",
                        }}
                    >
                        <Paper sx={{ width: '100%', mb: 2, overflowX: "auto" }}>
                            {/* <InputSearchBar
                                setFilter={setFilter}
                            /> */}
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                >

                                    {
                                        !isLoading && dataComponents?.length > 0 &&
                                        (
                                            <>
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={dataComponents?.length}
                                                    tableHeadCells={headCells?.components}
                                                />
                                                <TableBody>
                                                    {dataComponents?.length > 0 && dataComponents.map((row, index) => {
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        return (
                                                            <TableRow
                                                                key={row._id}
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                >
                                                                    {row.component_code}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        maxWidth: "8em"
                                                                    }}
                                                                >{row.provided_comp_name}</TableCell>

                                                                <Tooltip 
                                                                    title={component_types[row.type]}
                                                                    followCursor={true}
                                                                    arrow
                                                                >

                                                                    <TableCell
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            whiteSpace: "nowrap",
                                                                            textOverflow: "ellipsis",
                                                                            overflow: "hidden",
                                                                            maxWidth: "8em"
                                                                        }}
                                                                    >
                                                                            {row.type.split(/(?=[A-Z])/).join(" ")}
                                                                    </TableCell>
                                                                </Tooltip>


                                                                <TableCell
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        maxWidth: "8em"
                                                                    }}
                                                                >
                                                                    {row?.filtered_tec_ids?.length}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        maxWidth: "8em"
                                                                    }}
                                                                >
                                                                    {row?.manufacturersPopulated.filter((manu) => {
                                                                        return certsChosenForScPerspective?.iso?.includes(manu?.iso_certificate_id)
                                                                    }).length}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}

                                                    {/* fill up the empty dataModel for last page's items of the list */}
                                                    {emptyRows > 0 && (
                                                        <TableRow>
                                                            <TableCell colSpan={6} />
                                                        </TableRow>
                                                    )}

                                                </TableBody>
                                            </>
                                        )
                                    }
                                </Table>
                            </TableContainer>

                            {!isLoading && dataComponents?.length === 0 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        <h1 className='my-60 text-3xl'>
                                            No component
                                        </h1>
                                    </div>
                                </Box>
                            )}

                            {
                                !isLoading && dataComponents?.length > 0 && (
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                )
                            }
                        </Paper>
                    </Box>
                )
            }
            
        </Container>
    );
}