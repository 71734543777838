const FailedDataValidation = (step, data) => {
  let failed = false;

  if (step === "Add Manufacturer") {
    let ExistedManufacturer = [];
    const brandNewManufacturer = data.filter((manu) => {
      if (!manu.manufacturer_code) {
        return manu;
      } else {
        ExistedManufacturer.push(manu);
      }
    });

    const missingMandatoryDocument = brandNewManufacturer.some((manu) => {
      // because all mandatory file should not be "", the only one non-mandatory file is other_man_doc which is [] when empty
      let temp = structuredClone(manu);
      const mandatory = [
        "iso_certificate_id",
        "history",
        "organization_man",
        "size_location_cap",
        "product_range_yearly_production",
        "type",
      ];
      return !mandatory.every((field) => temp[field] !== "");
    });

    const missingMandatoryDocument_existManu = ExistedManufacturer.some(
      (manu) => {
        //Only iso cert is required for existing manufacturer
        let temp = structuredClone(manu);
        if (temp.iso_certificate_id === "") return true;
      }
    );

    if (missingMandatoryDocument || missingMandatoryDocument_existManu)
      failed = true;
  } else if (step === "Add Models") {
    data.map((model) => {
      const mandatoryFile = [
        "calculation",
        "compatibility_cert_id",
        "compliance_confirmation",
        "install_operation_maint",
        "maintenance_schedule",
        "arr_training_tech_support",
        "EMC_compliance",
      ].filter((field) =>
        model.type === "Others" ? field !== "compatibility_cert_id" : field
      );

      if (model.manufacturer_files.length === 0) {
        failed = true;
      }
      // no manu files
      if (
        // no mandatory document
        model.manufacturer_files.some(
          (fileSet) =>
            !mandatoryFile.every((file) =>
              Array.isArray(fileSet[file])
                ? fileSet[file].length > 0
                : fileSet[file] !== ""
            )
        )
      )
       {
        failed = true;
       }
      //Either upload full quality cert or type test cert
      if (
        model.manufacturer_files.some(
          (fileSet) =>
            fileSet.full_quality_certificate_id?.length === 0 &&
            fileSet.type_test_certificate_id.length === 0
        )
      )
        {
          failed = true;
        }
    });

    data.forEach((model) => {
      // no mandatiry input
      if (model.type === "Others" && (!model.rated_load || !model.rated_speed))
      {
        failed = true;
      }
     // if model type is stairlift and balancing factor and rope_factor is not mandatory 
     else if (
        model.LE_type === "L" &&
        model.type === "StairLift" &&
        (!model.rated_load ||
          !model.rated_speed ||
          !model.maximum_travel_of_height 
          // ||
          // !model.rope_factor ||
          // model.rope_factor.includes("_")
        )  
      )
        {
          console.log("1...")
          failed = true;
        }
      else if (
        model.LE_type === "L" &&
        (model.type !== "Others" && model.type !== "StairLift") && 
        (!model.rated_load ||
          !model.rated_speed ||
          !model.maximum_travel_of_height ||
          !model.rope_factor ||
          !model.balancing_factor ||
          //default value is "_:_"
          model.rope_factor.includes("_"))
      )
        {
          console.log("2...")
          failed = true;
        }
      else if (
        model.LE_type === "E" &&
        (!model.rated_speed ||
          !model.maximum_rise ||
          !model.angle_of_inclination)
      )
       {
          failed = true;
       }
    });
  } else if (step === "Add Safety Components") {
    if (
      Object.keys(data.components).length === 0 &&
      ![
        "MVPS",
        "StairLift",
        "ElectricalVerticalLiftingPlatform",
        "Others",
      ].includes(data.type)
    )
      failed = true;

    if (Object.keys(data.components).length > 0)
      Object.values(data.components).map((componentArray) => {
        // One of the SC type didn't have component
        if (componentArray.length === 0) failed = true;

        if (componentArray.length > 0) {
          if (!componentArray.every((comp) => comp.manufacturer_id))
            failed = true;

          if (
            // the confirmed key is generated by handlechange("confirmComponentAddition"), check componentDisplay.js
            componentArray.some((comp) => {
              let temp = structuredClone(comp);
              return temp.confirmed !== true;
            })
          )
            failed = true;
        }
      });
  }

  if (step !== "Add Safety Components" && data.length === 0) {
    failed = true;
  }

  return failed;
};

export default FailedDataValidation;
