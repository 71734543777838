import {
  Box,
  ListItem,
  Button,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";
import keyToField from "../../../utils/keyToField";
import _ from "lodash";

function HideColumns(props) {
  const {
    appendix,
    setAppendix,
    application,
    standalones,
    userInfo,
    userRoles,
  } = props;

  const handleHide = (field, compType) => {
    setAppendix({
      ...appendix,
      SCHideColumns: {
        ...(appendix.SCHideColumns || {}),
        [compType]: [...(appendix.SCHideColumns?.[compType] || []), field],
      },
    });
  };

  const handleUnhide = (field, compType) => {
    setAppendix({
      ...appendix,
      SCHideColumns: {
        ...(appendix.SCHideColumns || {}),
        [compType]: (appendix.SCHideColumns?.[compType] || []).filter(
          (f) => f !== field
        ),
      },
    });
  };

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
    (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
    (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
    (userInfo?.role === "EMSDEngineer" &&
      application?.status === "Pending E" &&
      !userRoles.includes(application?.current_role_id));

  const componentTypes = _.uniq([
    ...Object.keys(application.app_type?.components || {}),
    ...(standalones?.components?.map((c) => c.type) || []),
  ]);

  const tableFields = {
    ACOP: [
      "tec_car_load",
      "tec_balancing_factor",
      "tec_rated_load",
      "tec_rated_speed",
      "tec_tripping_speed",
      "tec_rope_factor",
    ],
    Buffer: ["tec_total_mass", "tec_impact_speed"],
    CarDoorLockingDevice: ["tec_door_type", "tec_contact_rating"],
    LandingDoorLockingDevice: ["tec_door_type", "tec_contact_rating"],
    OverspeedGovernor: ["tec_rated_speed", "tec_tripping_speed"],
    Pallet: ["tec_depth", "tec_width", "tec_inclination_angle"],
    SafetyCircuitElectricalComponent: ["tec_circuit_description"],
    SafetyGear: ["tec_total_mass", "tec_rated_speed", "tec_tripping_speed"],
    Step: ["tec_depth", "tec_width", "tec_inclination_angle"],
    UCMP: [
      "tec_car_load",
      "tec_balancing_factor",
      "tec_rated_load",
      "tec_rated_speed",
      "tec_tripping_speed",
      "tec_rope_factor",
    ],
    TractionMachine: ["tec_rated_load", "tec_rated_speed"],
    Controller: ["tec_rated_load", "tec_rated_speed"],
  };

  return (
    <Box>
      {componentTypes
        .sort((a, b) => {
          const order = [
            "Buffer",
            "OverspeedGovernor",
            "LandingDoorLockingDevice",
            "CarDoorLockingDevice",
            "SafetyGear",
            "ACOP",
            "UCMP",
            "SafetyCircuitElectricalComponent",
          ];
          function customSort(item) {
            const index = order.indexOf(item);
            return index !== -1 ? index : order.length;
          }
          return customSort(a) - customSort(b);
        })
        .map((compType) => {
          return (
            <Box key={compType} sx={{ mb: 2 }}>
              <Typography variant="h6">{keyToField[compType]}</Typography>
              {tableFields[compType].map((field) => {
                const isHidden =
                  appendix.SCHideColumns?.[compType]?.includes(field);

                return (
                  <ListItem
                    secondaryAction={
                      <>
                        <Button
                          onClick={() =>
                            isHidden
                              ? handleUnhide(field, compType)
                              : handleHide(field, compType)
                          }
                          aria-label={isHidden ? "Unhide" : "Hide"}
                          disabled={isDisabled}
                        >
                          {isHidden ? "Unhide" : "Hide"}
                        </Button>
                      </>
                    }
                  >
                    <ListItemText primary={keyToField[field]} />
                  </ListItem>
                );
              })}
              <Divider />
            </Box>
          );
        })}
    </Box>
  );
}

export default HideColumns;
