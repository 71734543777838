import DescriptionIcon from '@mui/icons-material/Description';

import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
export function ShowFileBtn({ handleGetPDFFile, id, ...props }) {
    const { t } = useTranslation("pages", { keyPrefix: "typeApprovalRecord" });


    return (
        <span
            {...props}
            className='cursor-pointer'
            onClick={() => handleGetPDFFile(id)}
            aria-label={`view file`}
        >
            <IconButton
                onClick={() => handleGetPDFFile(id)}
                aria-label={`view file`}
            >
                <DescriptionIcon 
                    className='text-blue-btn-color'
                />
            </IconButton>
            <span
                className='text-blue-btn-color'
            >
                {t("show")}
            </span>
        </span>
    )
}