import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./english";
import chineseMessages from "./chinese";

const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === "ch" ? chineseMessages : englishMessages),
  "en",
  {
    allowMissing: true,
  }
);

export default i18nProvider;

