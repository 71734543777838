import { List, ListItem, ListItemText, Typography } from "@mui/material";
import formatDate from "../../utils/misc/formatDate";

const ApprovalHistory = (props) => {
  const { upload, sx } = props;

  return (
    <List sx={{ ...sx }}>
      <Typography variant="h6">Approval History:</Typography>
      {upload?.approval_history?.length > 0 ? (
        upload?.approval_history?.map((history, index) => (
          <ListItem key={index} sx={{ p: 0 }}>
            <ListItemText
              primary={
                formatDate(history.date, true) +
                (history.approved_by
                  ? ` - Approved by ${history.approved_by}`
                  : history.passed_from || history.passed_to
                  ? ` - Passed from ${history.passed_from || ""} to ${
                      history.passed_to || ""
                    }`
                  : "")
              }
              secondary={`Remark: ${history.remark || ""}`}
            />
          </ListItem>
        ))
      ) : (
        <Typography variant="caption">No approval history</Typography>
      )}
    </List>
  );
};

export default ApprovalHistory;
