import { visuallyHidden } from '@mui/utils';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import React from 'react';

import { useTranslate } from 'react-admin';
function EnhancedTableHead(props) {
    const { 
      onSelectAllClick, 
      order, orderBy, 
      numSelected, rowCount, 
      onRequestSort, 
      tableHeadCells, 
      tecTotalCount,
      numCertsSelected, 
      onSelectAllCertsClick,
      modelSec
    } =
    props;
      const translate = useTranslate();
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(e) => onSelectAllClick(e, modelSec._id)}
              inputProps={{
                'aria-label': 'select all rows',
              }}
              aria-checked={numSelected > 0 && numSelected === rowCount ? true : numSelected > 0 && numSelected < rowCount ? 'mixed' : false}
            />
          </TableCell>
          
          {
            tableHeadCells.map((headCell) => (
              <React.Fragment key={headCell.id}>
                {headCell.label === "Documents" && (
                  <TableCell
                    key={`documents-${headCell.id}`}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    role="columnheader"
                    scope='col'
                    aria-sort={orderBy === headCell.id ? order === "desc" ? "descending" : "ascending" : false}
                  >
                    <Checkbox
                      color="primary"
                      indeterminate={numCertsSelected > 0 && numCertsSelected < rowCount}
                      checked={tecTotalCount > 0 && numCertsSelected === tecTotalCount}
                      onChange={(e) => onSelectAllCertsClick(e, modelSec._id)}
                      inputProps={{
                        'aria-label': 'select all rows',
                      }}
                      aria-checked={numCertsSelected > 0 && numCertsSelected === tecTotalCount ? true : numCertsSelected > 0 && numCertsSelected < tecTotalCount ? 'mixed' : false}
                    />
                    <TableSortLabel
                      // active={orderBy === headCell.id}
                      // direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                      hideSortIcon={true}
                    >
                      {translate(`phase2.ru.enhancedTableHead.${headCell.label}`)}
                      {/* {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null} */}
                    </TableSortLabel>
                  </TableCell>
                )}
                {headCell.label !== "Documents" && (
                  <TableCell
                    key={`!documents-${headCell.id}`}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    role="columnheader"
                    scope='col'
                    aria-sort={orderBy === headCell.id ? order === "desc" ? "descending" : "ascending" : false}
                  >
                    <TableSortLabel
                      // active={orderBy === headCell.id}
                      // direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                      hideSortIcon={true}
                    >
                      {translate(`phase2.ru.enhancedTableHead.${headCell.label}`)}
                      {/* {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}  */}
                    </TableSortLabel>
                  </TableCell>
                )}
              </React.Fragment>
            ))
          }
      
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    numCertsSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    onSelectAllCertsClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

export default EnhancedTableHead