import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import getToken from "../../utils/getAuthToken";
import {
  CreateBase,
  Title,
  ResourceContextProvider,
  useGetList,
  // useGetOne,
  useNotify,
  TextField,
  DateField,
  List,
  Datagrid,
  FunctionField,
  // Pagination,
  useCreate,
  useRefresh,
} from "react-admin";
// import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  // CardHeader,
  Typography,
  Backdrop,
  CircularProgress,
  Popper,
  IconButton,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import SelectRevokeAction from "./SelectRevokeAction";
import useRevokeHook from "../../useHooks/useRevokeHook";
import PreviewRevokeApplication from "./PreviewRevokeApplication";
import { RevokeModel } from "./revocationLetter/RevokeModel"
import { v4 as uuidv4 } from "uuid";
import Dropzone from "react-dropzone";
import DeletCertWithConfirmButton from "../../buttons/DeleteCertWithConfirmButton.";

import palette from "../../styles/palette";
import "../../styles/revoke/main.css";

import { getOneRC } from "../../useHooks/useRcs/index";
import { useSelector, useDispatch } from "react-redux";
import { setComponents, setModels, setManufacturers, setRcs } from "../../redux/revoke/revokeSlice";

import SelectSafetyComponent from "./SelectSafetyComponent";
import SelectModel from "./SelectModel";
import SelectManufacturer from "./SelectManufacturer";
import SelectRC from "./SelectRC";
import SelectSComponentFromManufacturer from "./SelectSComponentFromManufacturer";
import SelectSComponentFromRC from "./SelectSComponentFromRC";
import SelectModelsFromManufacturerOrRC from "./SelectModelsFromManufacturerOrRC";

import { RevocationButton } from "./buttons/RevocationButton";
import { TransferButton } from "./buttons/TransferButton";
import RevokeDeleteHasIconConfirmButton from "../../buttons/RevokeDeleteHasIconConfirmButton";
import { useTranslation } from 'react-i18next';


const buttonColor = {
  border: "1px solid gray",
  bgcolor: "rgb(233,233,237)",
  fontWeight: "bolder",
  color: "rgb(36,36,37)",
  borderRadius: "3px",
  appeareance: "none",
  "-webkit-appearance": "none",
  "-moz-appearance": "none",
  "-ms-appearance": "none",
  "& .MuiTypography-root.MuiTypography-body1":
    {
      padding: "1px",
      fontSize: "0.7em",
      fontFamily: "Nunito",
      textAlign: "center",
      fontWeight: "500",
      lineHeight: "1.68",
    },
    ":hover": {
      bgcolor: "#e3e3e3",
      cursor: 'default'
  }
}



export const ShowRevokeApplication = () => {
  const { t : itranslate } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [application, setApplication] = useState({});
  const [rc_appendix, setRc_appendix] = useState({});
  // console.log("🚀 ~ ShowRevokeApplication ~ rc_appendix:", rc_appendix)

  const [appendix, setAppendix] = useState([]);
  const [rcInfo, setRcInfo] = useState({});

  const [file, setFile] = useState(null);
  
  const userInfo = useSelector((state) => state.userInfo.value);

  // Getting the user's all possible roles. Storing the team_role_id(s)
  const [userRoles, setUserRoles] = useState([]);
  
  const [create, { isLoading: uploadingFile }] = useCreate();
  const notify = useNotify();
  const navigate = useNavigate();
  const refresh = useRefresh();

  // control popper
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => setAnchorEl(anchorEl ? null : e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const info_id = open ? 'info-popper' : undefined;


  const returnAllRecord = { pagination: { page: 1, perPage: 0 } };
  const { data: teamLoad } = useGetList("team", returnAllRecord);
  // console.log("🚀 ~ ShowRevokeApplication ~ teamLoad:", teamLoad)


  const handleOpenModal = (applicationData) => {
    // console.log("🚀 ~ handleOpenModal ~ applicationData:", applicationData)
    setApplication(applicationData);
    applicationData && applicationData?.record?.revocationLetter?.length > 0 && applicationData?.record?.revocationLetter.map((item) => {
      if (item?.rc_id === applicationData.rc_item.rc_id) {
        setRc_appendix(item);
      }
    });
    setIsModalOpen(true);
  };


  // closes the model
  const handleCloseModal = () => setIsModalOpen(false);
  

  // handles upload of revocation letter
  const handleUpload = async (files, record, rc_id) => {
    const types = ["application/pdf"];
    let selected = files[0];
    if (selected && types.includes(selected.type)) {
      setFile(selected);
    } else {
      setFile(null);
      notify("Please select a pdf file");
      return;
    }
    const file_code = uuidv4();
    create(
      "files",
      { data: files,
        meta: {
          description: "file",
          file_code,
      }},
      {
        onSuccess: async (id, data) => {
          try {
            const res = await axios.patch(
              `${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/${id.data[0].id}`,
              {},
              { headers: { authorization: getToken() }}
            );
            let signed_revocation_letter_id = res.data._id;
            try {
              const res = await axios.post(
                `${process.env.REACT_APP_ENV_ENDPOINT}/lift/revoke/signedRevocationLetter/${record.id}`,
                { rc_id, signed_revocation_letter_id },
                { headers: { authorization: getToken() }}
              );
              console.log(res.data);
              refresh();
            } catch (err) {
              console.log(err);
            }
            notify("file saved");
            refresh();
          } catch (err) {
            console.log(err);
          }
        },
        onError: () => {
          notify("some error occurred");
        },
      }
    );
  };

  const downloadFile = async (id) => window.open(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/${id}?token=${getToken()}`);


  // delete a revoke application
  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/revoke/${id}`,
        { headers: { authorization: getToken() } }
      );
      if (res.status === 200) refresh();
    } catch (err) {
      console.log(err);
    }
  };

  // delete an uploaded revocation letter
  const handleDeleteLetter = async (letterId, revokeId) => {
    try {
     const res = await axios.post(
       `${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/deleteLetter`,
       { letterId, revokeId },
       { headers: { authorization: getToken() }}
     );
     // console.log(res.data)
     refresh();
    } catch (err) {
     console.log(err)
  }};


  // Getting the user's all possible roles. Storing the team_role_id(s)
  useEffect(() => {
    let temp = []

    if (userInfo.team_role_id) {
      temp.push(userInfo.team_role_id);
    }
  
    if (userInfo.doubling_roles) {
      userInfo.doubling_roles.forEach((role) => {
        temp.push(role.team_role_id);
      });
    }
    setUserRoles(temp)
  }, [userInfo]);


  // when a modal is opened, the "rc_appendix" will be updated for that particular rc. We then want to get the rc info.
  useEffect(() => {
    const fetchRC = async () => {
      if (Object.keys(rc_appendix).length > 0) {
        const data = await getOneRC(rc_appendix?.rc_id)
        // console.log("🚀 ~ fetchRC ~ data:", data)
        setRcInfo(data)
      } 
    };
    fetchRC();
  },[rc_appendix]);



  return (
    <> 
    {userInfo?.role !== "Inspector" && (
      <Button variant="contained" onClick={() => navigate("/revoke/create")} >
        {/* + Create */}
        + {itranslate('revoke.Create')}
      </Button>
    )}
      <ResourceContextProvider value="revoke">
      {window.location.pathname === `/lift_emsd` && (
        <List 
          // title={`- Revoke`} 
          // title={`- ${itranslate('appBar.Revoke')}`}
          title={`- ${itranslate('revoke.Revoke')}`}
          exporter={false}
        >
          <Datagrid
            style={{
              position: "relative",
              zIndex: 0,
            }}
            bulkActionButtons={false}
          >
              
            <DateField label="Created At" source="created_at" />

            <DateField label="Revoke Date" source="approval_date" />

            <FunctionField
              label="RC(s) involved"
              render={(record) => (
                <div className="revoke__revocationLetter">
                  {record && typeof record?.rc_items != "undefined" && record?.rc_items.length > 0 && record?.rc_items.map(rec => (
                    <React.Fragment key={rec.rc_id}>
                      <div 
                        style={{ fontSize: ".8em", marginTop: "8px" }}
                      >
                        {rec?.rc_name + " / " + rec?.rc_company}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}
            />

            <FunctionField
              label="Manufacturer to be revoked"
              render={(record) => (
                <div>
                  {record && typeof record?.rc_items !== "undefined" && record?.rc_items.length > 0 && record?.rc_items.map((rec) => (
                    <React.Fragment key={rec.rc_id}>
                      <div 
                      style={{ 
                        marginTop: "8px", 
                        fontSize: ".8em", 
                        color: palette.menuHeader 
                      }}>
                        {rec.manufacturer && rec.manufacturer.length > 0 && (rec?.rc_name + " / " + rec?.rc_company)}
                      </div>
                      <div>
                        {rec.manufacturer && rec.manufacturer.length > 0 && rec.manufacturer.map((manu) => (
                          <ul key={manu._id}>
                            <li style={{ fontSize: ".8em"}}>
                              {manu.manufacturer_code} - {manu.provided_manufacturer_name}
                            </li>
                          </ul>
                        ))}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}
            />


            <FunctionField
              label="Model(s) to be revoked"
              render={(record) => (
                <div>
                  {record && typeof record?.rc_items !== "undefined" && record?.rc_items.length > 0 && record?.rc_items.map((rec) => (
                    <React.Fragment key={rec.rc_id}>
                      <div style={{ 
                        marginTop: "8px", 
                        fontSize: ".8em", 
                        color: palette.menuHeader 
                      }}>
                        {rec.models && rec.models.length > 0 && (rec?.rc_name + " / " + rec?.rc_company)}
                      </div>
                      <div>
                        {rec.models && rec.models.length > 0 && rec.models.map((model) => (
                          <ul key={model.model_id}>
                            <li 
                              style={{ fontSize: ".8em"}}
                            >
                              {model.model_code}/{model.unique_id_lastDigit} - {model.provided_model_name} 
                            </li>
                          </ul>
                        ))}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}
            />

            <FunctionField
              label="Component(s) to be revoked"
              render={(record) => (
                <div>
                  {record && typeof record?.rc_items !== "undefined" && record?.rc_items.length > 0 && record?.rc_items.map((rec) => (
                    <React.Fragment key={rec.rc_id}>
                      <div style={{ marginTop: "8px", fontSize: ".8em", color: palette.menuHeader }}>
                        {rec.components && rec.components.length > 0 && (rec?.rc_name + " / " + rec?.rc_company)}
                      </div>
                      <div>
                        {rec.components && rec.components.length > 0 && rec.components.map((component) => (
                          <ul key={component.component_id}>
                            <li style={{ fontSize: ".8em"}}>
                              {component.component_code} - {component.provided_comp_name} - {component.type}
                            </li>
                          </ul>
                        ))}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}
            />


            <TextField sx={{ marginRight: "30px" }} label="Status" source="status" />

            <TextField label="Role Assigned" source="current_role_name" />

            <FunctionField
              label="Revocation Letter"
              render={(record) => {
                const canEdit =
                  ((record?.status ==="Pending E" || record?.status === "Pending SE") &&
                  !userRoles.includes(record?.current_role_id)) ||
                  ((record?.status === "Pending" && !(userInfo?.role === "EMSDGR" || userInfo?.role === "EMSDEngineer"))) ||
                  ((record?.status === "Revoked" && !(userInfo?.role === "EMSDSE" || userInfo?.role === "EMSDEngineer"))) ||
                  userInfo?.role === "Inspector" ||
                  userInfo?.role === "TapasAdmin" || 
                  userInfo?.role === "EMSDCE"

                return (
                  <div className="revoke__revocationLetter">
                  {record && typeof record?.rc_items != "undefined" && record?.rc_items.length > 0 && record?.rc_items.map(rec => (
                    <React.Fragment key={rec.rc_id}>
                      <div>
                        <div style={{ fontSize: ".8em", color: palette.menuHeader }}>{rec?.rc_name + " / " + rec?.rc_company}</div>
                        {!canEdit && (
                          <button 
                            className="revoke__revocationLetter-btn" 
                            onClick={() => handleOpenModal({ record, rc_item: rec })}
                            >Click
                          </button>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}}
            />
    

            <FunctionField
              label="Upload Letter"
              render={(record) => {
                const { signedRevocationLetter, rc_items } = record;

                const canEdit =
                  ((record?.status ==="Pending E" || record?.status === "Pending SE") &&
                  !userRoles.includes(record?.current_role_id)) ||
                  ((record?.status === "Pending" && !(userInfo?.role === "EMSDGR" || userInfo?.role === "EMSDEngineer"))) ||
                  ((record?.status === "Revoked" && !(userInfo?.role === "EMSDSE" || userInfo?.role === "EMSDEngineer"))) ||
                  userInfo?.role === "Inspector" ||
                  userInfo?.role === "TapasAdmin" || 
                  userInfo?.role === "EMSDCE"

                return (
                  <> 
                    {/* <button onClick={() => console.log(record)}>record</button> */}
                    {Array.isArray(rc_items) && rc_items.length > 0 && rc_items.map(rec => {
                      const matchedLetter = signedRevocationLetter.find(letter => letter.rc_id === rec.rc_id);
                      return (
                        <React.Fragment key={rec.rc_id}>
                          <div style={{ fontSize: ".8em", color: palette.menuHeader }}>{rec?.rc_name + " / " + rec?.rc_company}</div>
                          {matchedLetter ? (
                            <div className="revoke__revocationLetter-downloadbtn">
                              <button onClick={() => downloadFile(matchedLetter.signed_revocation_letter_id)}>
                                Download
                              </button>
                              <RevokeDeleteHasIconConfirmButton
                                sx={{ padding: '0', color: palette.secondary }}
                                size={{ fontSize: "1.4rem" }}
                                customDeleteLogic={() => handleDeleteLetter(matchedLetter.signed_revocation_letter_id, record.id)}
                                record={record}
                                userInfo={userInfo}
                                userRoles={userRoles}
                              />
                            </div>
                          ) : (
                            <div className="revoke__revocationLetter-upload">
                              {!canEdit && (
                                <>
                                  <Dropzone
                                    onDrop={(acceptedFiles) => handleUpload(acceptedFiles, record, rec.rc_id)}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <>
                                        <div style={{ textAlign: "center" }}>
                                          <Box
                                            sx={buttonColor}
                                          >
                                            <div {...getRootProps()}>
                                              <input {...getInputProps()} />
                                              <Typography>Upload</Typography>
                                            </div>
                                          </Box>
                                        </div>
                                      </>
                                    )}
                                  </Dropzone>

                                  <Box>
                                    <IconButton
                                      aria-describedby={info_id} 
                                      type="button" 
                                      // sx={{ color: "white", backgroundColor: "orangered", width: "30px", height: "30px", marginLeft: "5px" }}
                                      sx={{ color: "orangered" }}
                                      onClick={handleClick}
                                    >
                                      <InfoIcon />
                                    </IconButton>
                                    <Popper sx={{ zIndex: 100000 }} id={info_id} open={open} anchorEl={anchorEl}>
                                      <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', display: 'flex' }}>
                                        <Box>
                                          <Typography variant="body2" sx={{ fontSize: "1rem" }}>
                                            {"(1) Under Revocation Letter column, press 'Click' and edit the letter if necessary. Select 'Preview' if in PDF mode"}
                                          </Typography> 
                                          <Typography variant="body2" sx={{ fontSize: "1rem" }}>
                                            {"(2) Download the revocation letter. Select 'Review PDF' if in HTML mode."}
                                          </Typography>
                                          <Typography variant="body2" sx={{ fontSize: "1rem" }}>
                                            {"(3) Upload the downloaded revocation letter by pressing 'Upload'."}
                                          </Typography>
                                        </Box>
                                        <IconButton 
                                          type="button" 
                                          onClick={handleClose} 
                                          sx={{ width: "10px", height: "10px", marginLeft: "15px" }} 
                                        > <CancelIcon />
                                        </IconButton>
                                      </Box>
                                    </Popper>
                                  </Box>
                                </>
                                )}
                              </div>               
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                )
              }}
            />
        
     
            <FunctionField
              label="Delete"
              render={(record) =>
                record.status !== "Revoked" && (
                  <DeletCertWithConfirmButton
                    sx={{ fontSize: "1rem" }}
                    label="Delete"
                    customDeleteLogic={() => handleDelete(record.id)}
                    record={record}
                    userInfo={userInfo}
                    userRoles={userRoles}
                  />
                )
              }
            />
        

            <FunctionField
              label="Transfer"
              render={(record) => {
                if(record.status === 'Pending E') {
                  return (
                    <TransferButton 
                      type={"Pending E"}
                      teams={teamLoad}
                      id={record.id}
                      userInfo={userInfo}
                      record={record}
                      userRoles={userRoles}
                    />
                  )
                }
                if(record.status === 'Pending SE') {
                  return (
                    <TransferButton 
                      type={"Pending SE"}
                      teams={teamLoad}
                      id={record.id}
                      userInfo={userInfo}
                      record={record}
                      userRoles={userRoles}
                    />
                  )
                }
              }}
            />
            

            <FunctionField
              label="Revoke"
              render={(record) => {
                if(record.status === 'Pending') {
                  return (
                    <RevocationButton 
                      type={"Pending"}
                      teams={teamLoad}
                      id={record.id}
                      previousStatus={record.status}
                      userInfo={userInfo}
                      record={record}
                      userRoles={userRoles}
                    />
                  )
                }
                if(record.status === 'Pending E') {
                  return (
                    <RevocationButton 
                      type={"Pending E"}
                      teams={teamLoad}
                      id={record.id}
                      previousStatus={record.status}
                      userInfo={userInfo}
                      record={record}
                      userRoles={userRoles}
                    />
                  )
                }
                if(record.status === 'Pending SE') {
                  return (
                    <RevocationButton 
                      type={"Approved"}
                      teams={teamLoad}
                      id={record.id}
                      previousStatus={record.status}
                      userInfo={userInfo}
                      record={record}
                      userRoles={userRoles}
                    />
                  )
                }  
              }}
            />


              {/* <FunctionField
                label="Click"
                render={(record) =>{
                  return (
                    <>
                      <button onClick={() => console.log(record)}>click</button>
                    </>
                )}}
              />  */}

          </Datagrid>
        </List>
      )}

        <RevokeModel
          open={isModalOpen}
          rc_appendix={rc_appendix}
          setRc_appendix={setRc_appendix}
          handleCloseModal={handleCloseModal}
          isModalOpen={isModalOpen}
          application={application?.record}
          rcItem={application?.rc_item}
          rcInfo={rcInfo}
          setAppendix={setAppendix}
          appendix={appendix}
          app_status={application?.record?.status}
        />
        
      </ResourceContextProvider>
    </>
  );
};





export const CreateRevokeApplication = () => {
  const navigate = useNavigate();
  const notify = useNotify();
  const dispatch = useDispatch();

  // hook for creation a revoke application
  const { revokeCreation } = useRevokeHook();

  const [activeStep, setActiveStep] = useState(0);
  const [applicationFlow, setApplicationFlow] = useState({ action: "manufacturer" });

  // stores all the manufacturers
  const [manufacturersLoad, setManufacturersLoad] = useState(null);
  
  // sets the manufacturer code after it has been selected by the user in step 1
  const [selectedManufacturerCode, setSelectedManufacturerCode] = useState();
  // after the user selects a manufacturer, this will then trigger useEffect to get the list of manufacturer ids associated with that manufacturer code
  const [selectedManufacturerIds, setSelectedManufacturerIds] = useState();

  // used in step 3, if user selects a manufacturer, the user will be able to select the SCs associated with that manufacturer
  const [filteredSC, setFilteredSC] = useState();

  // stores all the RCs
  const [rcLoad, setRcLoad] = useState(null);

  // sets the RC after it has been selected by the user in step 1
  const [selectedRC, setSelectedRC] = useState();

  // this is used to get all records when using the useGetList hook
  const returnAllRecord = { pagination: { page: 1, perPage: 0 } };

  const { data: manufacturersData } = useGetList("manufacturers", returnAllRecord);
  
  // this is calling lift/revoke/models -> revokeGetModels
  // fetches all the models
  const { data: modelsLoad } = useGetList("revoke/models", returnAllRecord);

  // fetches all the RCs
  const { data: RCData } = useGetList("rcs", returnAllRecord);
 
  // fetches all the models for selected manufacturer (by manufacturer code)
  const { data: modelsMatchedManu } = useGetList("models/revoke", {
    filter: { "manufacturersPopulated.manufacturer_code": `${selectedManufacturerCode}`},
  });

  // fetches all the models for selected RC (by rc_id)
  const { data: modelsMatchedRC } = useGetList("models/revoke", {
    filter: { rc_id: `${selectedRC}`},
  });

  // fetches all the components for selected RC (by rc_id) 
  const { data: SCMatchedRC } = useGetList("components", {
    filter: {rc_id: `${selectedRC}`},
  });

  // fetches all safety components (SCs)
  // let { data: componentsResp } = useGetList("components", returnAllRecord );
  let { data: componentsResp } = useGetList("revoke/components", returnAllRecord);


  const [isComponentsFetched, setIsComponentsFetched] = useState(false);
  const [isModelsFetched, setIsModelsFetched] = useState(false);
  const [isManufacturersFetched, setIsManufacturersFetched] = useState(false);
  const [isRCsFetched, setIsRCsFetched] = useState(false);
  const [loading, setLoading] = useState(true);

  const [isCreating, setIsCreating] = useState(false)


  const steps_titles = [
    "New Revoke Application",
    "Choose Model(s)",
    "Choose Safety Component(s)",
    "Review Your Choice(s)",
  ];

  const steps_short = [
    "Select Manufacturer",
    "Select Model",
    "Select Safety Component",
    "Complete Process",
  ];

  const initialState = {
    manufacturer: [],
    model: [],
    components: [],
  };

  // this is what we'll use to store the manuafctiurer, model(s) and SC(s) to be revoked
  const [upload, setUpload] = useState(initialState);
  // console.log("🚀 ~ CreateRevokeApplication ~ upload:", upload)

  const handleSelectManufacturer = (value) => setSelectedManufacturerCode(value);

  const handleSelectRC = (value) => setSelectedRC(value);


  const Spinner = () => (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" size={40}/>
    </Backdrop>
  )

  // check if all data is fecthed before we hide the spinner
  useEffect(() => {
    if (
      isComponentsFetched &&
      isModelsFetched &&
      isManufacturersFetched &&
      isRCsFetched
    ) {
      setLoading(false);
    }
  }, [isComponentsFetched, isModelsFetched, isManufacturersFetched, isRCsFetched]);
  

  // sets the data in redux and mark if the data is fetched
  useEffect(() => {
    async function fetchData() {
      if (!isManufacturersFetched && manufacturersData?.length > 0) {
        dispatch(setManufacturers(manufacturersData));
        setIsManufacturersFetched(true);
      }
      if (!isModelsFetched && modelsLoad?.length > 0) {
        dispatch(setModels(modelsLoad));
        setIsModelsFetched(true);
      }
      if (!isComponentsFetched && componentsResp?.length > 0) {
        dispatch(setComponents(componentsResp));
        setIsComponentsFetched(true);
      }
      if (!isRCsFetched && RCData?.length > 0) {
        dispatch(setRcs(RCData));
        setIsRCsFetched(true);
      }
    }
    fetchData();
  }, [componentsResp, modelsLoad, manufacturersData, RCData, isComponentsFetched, isModelsFetched, isManufacturersFetched, isRCsFetched]);



  useEffect(() => {
    setManufacturersLoad(manufacturersData);
    setRcLoad(RCData);
  }, [
    modelsMatchedManu,
    modelsMatchedRC,
    manufacturersData,
    RCData,
    applicationFlow,
  ]);


  const handleStepValidate = (mode, index) => {
    const checkStep = (chosenStep) => {
      mode === "stepper"
        ? setActiveStep(chosenStep)
        : mode === "next" &&
          activeStep === 0 &&
          applicationFlow.action === "safetycomponent"
        ? setActiveStep((prevActiveStep) => prevActiveStep + 2)
        : mode === "back" &&
          activeStep === 2 &&
          applicationFlow.action === "safetycomponent"
        ? setActiveStep((prevActiveStep) => prevActiveStep - 2)
        : mode === "next"
        ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
        : setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    switch (activeStep) {
      case 0:
        checkStep("Select Manufacturer", index);
        break;
      case 1:
        checkStep("Select Model", index);
        break;
      case 2:
        checkStep("Select Safety Component", index);
        break;
      default: {
        mode === "stepper"
          ? setActiveStep(index)
          : mode === "next"
          ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
          : setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }
  };

  const handleNext = () => handleStepValidate("next", activeStep + 1);
  
  const handleBack = () => handleStepValidate("back", activeStep - 1);



  const handleApplicationFlow = (action) => {
    setUpload(initialState);
    let newFlow = null;
    const validActions = ["manufacturer", "model", "safetycomponent", "rc"];

    if (validActions.includes(action)) {
      newFlow = {
        action: action,
      };

      if (action === "manufacturer") {
        newFlow = {
          ...newFlow,
          manufacturerAction: action,
        };
      }
    }
    setApplicationFlow(newFlow);
  };



  const handleRevokeCreation = async () => {
    try {
      setIsCreating(true);
      const data = await revokeCreation(upload);
      // console.log("🚀 ~ handleRevokeCreation ~ data:", data)
      if (data) {
        navigate(`/revoke`);
        notify("Revoke application created");
      } else {
        notify("Error creating revoke application");
        console.log("Error creating revoke application");
      }
    } catch (err) {
      console.log(err);
      notify("Error creating revoke application");
    } finally {
      setIsCreating(false);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ENV_ENDPOINT}/lift/manufacturers/ids`,
          {
            params: {
              manufacturer_code: selectedManufacturerCode,
            },
            headers: {
              authorization: getToken(),
            },
          }
        );

        const matchedIds = response.data;

        setSelectedManufacturerIds(matchedIds.data.map((item) => item.id));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (selectedManufacturerCode) {
      fetchData();
    }
  }, [selectedManufacturerCode]);


  useEffect(() => {
    // this gets all safety components assocated with the selected manufacturer, using the selected manufacturer id(s)
    const SCs = componentsResp?.filter((component) => selectedManufacturerIds?.includes(component.manufacturer));
    setFilteredSC(SCs);
  }, [componentsResp, selectedManufacturerCode, selectedManufacturerIds]);


  return (
    <>
      {Spinner()}

      <Button
        onClick={() => navigate(`/revoke`)}
        sx={{ mr: 1 }}
        variant="contained"
        color="primary"
      >
        Back to Revoke List
      </Button>

      <ResourceContextProvider value="revokeApplication">
        <Title title="- New Revoke Application" />
        <CreateBase basePath="applications_new" redirect="/applications">
          <Box sx={{ width: "100%", mt: 1 }}>
            <Typography sx={{ mt: 2, mb: 1, fontWeight: "bold" }}>Step {activeStep + 1}: {steps_titles[activeStep]}</Typography>

            {activeStep === 0 && (
              <Card>
                {/* <CardHeader title="New Revoke Application" /> */}
                <CardContent>
                  <SelectRevokeAction
                    action={applicationFlow.action}
                    handleApplicationFlow={handleApplicationFlow}
                  />
                  {applicationFlow.action === "manufacturer" && (
                    <SelectManufacturer
                      // load={manufacturersLoad}
                      handleSelection={handleSelectManufacturer}
                      setUpload={setUpload}
                      upload={upload}
                    />
                  )}
                  {applicationFlow.action === "rc" && (
                    <SelectRC
                      handleSelection={handleSelectRC}
                      setUpload={setUpload}
                      upload={upload}
                    />
                  )}
                </CardContent>
              </Card>
            )}


            {activeStep === 1 && (
              <Card>
                {/* <CardHeader title="Choose Model(s)" /> */}
                <CardContent>
                  {upload?.manufacturer?.length > 0 ? (
                    <SelectModelsFromManufacturerOrRC
                      upload={upload}
                      setUpload={setUpload}
                      modalsLoad={
                        applicationFlow.action === "manufacturer"
                          ? modelsMatchedManu
                          : modelsMatchedRC
                      }
                    />
                  ) : upload?.rc?.length > 0 ? (
                    <SelectModelsFromManufacturerOrRC
                      upload={upload}
                      setUpload={setUpload}
                      modalsLoad={
                        applicationFlow.action === "manufacturer"
                          ? modelsMatchedManu
                          : modelsMatchedRC
                      }
                    />
                  ) : (
                    <SelectModel
                      upload={upload}
                      setUpload={setUpload}
                    />
                  )}
                </CardContent>
              </Card>
            )}


            {activeStep === 2 && (
              <Card>
                {/* <CardHeader title="Choose Safety Component(s)" /> */}
                <CardContent>
                  {upload.manufacturer?.length > 0 ? (
                    <SelectSComponentFromManufacturer
                      upload={upload}
                      setUpload={setUpload}
                      safetyComponentLoad={
                        filteredSC.length > 0
                          ? filteredSC
                          : componentsResp
                      }
                    />
                  ) : upload.rc?.length > 0 ? (
                    <SelectSComponentFromRC
                      upload={upload}
                      setUpload={setUpload}
                      safetyComponentLoad={SCMatchedRC}
                    />
                  ) : (
                    <SelectSafetyComponent
                      upload={upload}
                      setUpload={setUpload}  
                    />
                  )}
                </CardContent>
              </Card>
            )}

            
            {/* last step - review */}
            {activeStep === steps_short.length - 1 && (
              <Card>
                {/* <CardHeader title="Review Your Changes" /> */}
                <CardContent>
                  <PreviewRevokeApplication
                    upload={upload}
                    modelsLoad={modelsLoad}
                    componentsLoad={componentsResp}
                    manufacturersData={manufacturersData}
                  />
                </CardContent>
              </Card>
            )}


              {/* Buttons */}
              <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                alignItems: "center",
                mb: 2,
              }}
            >
              {/* show back button */}
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant="contained"
                color="primary"
              >
                Previous Step
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />


              {/* show next button */}
              { 
                (applicationFlow.action === "manufacturer" && upload?.manufacturer?.length > 0 && activeStep !== steps_short.length - 1) ||
                (applicationFlow.action === "rc" && upload?.rc?.length > 0 && activeStep !== steps_short.length - 1) ||
                (applicationFlow.action !== "manufacturer" && applicationFlow.action !== "rc" && activeStep !== steps_short.length - 1) ? 
                (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    {activeStep === steps_short.length - 1 ? "" : "Next Step"}
                  </Button>
                ) : null
              }
                
              
              {/* last step - show submit button */}
              {activeStep === steps_short.length - 1 && (upload?.manufacturer.length > 0 || upload?.model.length > 0 || upload?.components.length > 0) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleRevokeCreation()}
                  disabled={isCreating}
                >
                  {activeStep === steps_short.length - 1 && "Submit Revoke Application"}
                </Button>
              )}

            </Box>

          </Box>
        </CreateBase>
      </ResourceContextProvider>
    </>
  );
};





export const EditRevokeApplication = (props) => {}

// export const EditRevokeApplication = (props) => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const notify = useNotify();
//   const { revokeCreation, revokeGetList } = useRevokeHook();
//   const [activeStep, setActiveStep] = useState(0);
//   const [manufacturersLoad, setManufacturersLoad] = useState(null);
//   const [rcLoad, setRcLoad] = useState(null);

//   const [applicationFlow, setApplicationFlow] = useState({
//     action: "manufacturer",
//   });

//   const [selectedManufacturerCode, setSelectedManufacturerCode] = useState();
//   const [selectedRC, setSelectedRC] = useState();

//   const returnAllRecord = { pagination: { page: 1, perPage: 0 } };

//   const { data: manufacturersData } = useGetList(
//     "manufacturers",
//     returnAllRecord
//   );

//   const { data: modelsLoad } = useGetList("models", returnAllRecord);
//   const { data: componentsLoad } = useGetList("components", returnAllRecord);
//   const { data: RCData } = useGetList("rcs", returnAllRecord);
//   const { data: revokeData } = useGetOne("revoke", { id: id });

//   const { data: modelsMatchedManu } = useGetList("models", {
//     filter: {
//       "manufacturersPopulated.manufacturer_code": `${selectedManufacturerCode}`,
//     },
//   });

//   const { data: modelsMatchedRC } = useGetList("models", {
//     filter: {
//       rc_id: `${selectedRC}`,
//     },
//   });

//   const { data: SCMatchedRC } = useGetList("components", {
//     filter: {
//       rc_id: `${selectedRC}`,
//     },
//   });

//   const { data: componentsResp } = useGetList("components");

 

//   const steps_short = [
//     "Select Manufacturer",
//     "Select Model",
//     "Select Safety Component",
//     "Complete Process",
//   ];
  

//   const initialState = {
//     manufacturer: [],
//     model: [],
//     components: [],
//   };

//   const [upload, setUpload] = useState(initialState);


//   const handleSelectManufacturer = (value) => {
//     setSelectedManufacturerCode(value);
//   };

//   const handleSelectRC = (value) => {
//     setSelectedRC(value);
//   };

//   const handleStepValidate = (mode, index) => {
//     const checkStep = (chosenStep) => {
//       mode === "stepper"
//         ? setActiveStep(chosenStep)
//         : mode === "next" &&
//           activeStep === 0 &&
//           applicationFlow.action === "safetycomponent"
//         ? setActiveStep((prevActiveStep) => prevActiveStep + 2)
//         : mode === "back" &&
//           activeStep === 2 &&
//           applicationFlow.action === "safetycomponent"
//         ? setActiveStep((prevActiveStep) => prevActiveStep - 2)
//         : mode === "next"
//         ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
//         : setActiveStep((prevActiveStep) => prevActiveStep - 1);
//     };

//     switch (activeStep) {
//       case 0:
//         checkStep("Select Manufacturer", index);
//         break;
//       case 1:
//         checkStep("Select Model", index);
//         break;
//       case 2:
//         checkStep("Select Safety Component", index);
//         break;
//       default: {
//         mode === "stepper"
//           ? setActiveStep(index)
//           : mode === "next"
//           ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
//           : setActiveStep((prevActiveStep) => prevActiveStep - 1);
//       }
//     }
//   };

//   const handleApplicationFlow = (action) => {
//     setUpload(initialState);

//     let newFlow = null;

//     const validActions = ["manufacturer", "model", "safetycomponent", "rc"];

//     if (validActions.includes(action)) {
//       newFlow = {
//         action: action,
//       };

//       if (action === "manufacturer") {
//         newFlow = {
//           ...newFlow,
//           manufacturerAction: action,
//         };
//       }
//     }
//     setApplicationFlow(newFlow);
//   };

//   useEffect(() => {
//     setManufacturersLoad(manufacturersData);
//     setRcLoad(RCData);
//   }, [manufacturersData, RCData]);


//   const handleNext = () => handleStepValidate("next", activeStep + 1);

//   const handleBack = () => handleStepValidate("back", activeStep - 1);


//   const handleUpdate = () => {
//     const data = revokeCreation(upload);
//     if (data) {
//       navigate(`/revoke`);
//       notify("Revoke Application Updated");
//     }
//   };

//   useEffect(() => {
//     setUpload(revokeData);
//   }, [revokeData]);

//   return (
//     <>
//       <Button
//         onClick={() => {
//           revokeGetList();
//         }}
//         sx={{ mr: 1 }}
//         variant="contained"
//         color="primary"
//       >
//         View Pending Revoke Items
//       </Button>
      

//       <ResourceContextProvider value="revokeApplication">
//         <Title title="- Edit Revoke Application" />
//         <CreateBase basePath="applications_new" redirect="/applications">
//           <Box sx={{ width: "100%", mt: 1 }}>
//             <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>


//             {activeStep === 0 && (
//               <Card>
//                 <CardHeader title="Edit Revoke Application" />
//                 <CardContent>
//                   <SelectRevokeAction
//                     action={applicationFlow.action}
//                     handleApplicationFlow={handleApplicationFlow}
//                   />

//                   {applicationFlow.action === "manufacturer" && upload && (
//                     <SelectManufacturer
//                       load={manufacturersLoad}
//                       handleSelection={handleSelectManufacturer}
//                       setUpload={setUpload}
//                       upload={upload}
//                       title="manufacturer"
//                     />
//                   )}
//                   {applicationFlow.action === "rc" && (
//                     <SelectManufacturer
//                       load={rcLoad}
//                       handleSelection={handleSelectRC}
//                       setUpload={setUpload}
//                       upload={upload}
//                       title="rc"
//                     />
//                   )}
//                 </CardContent>
//               </Card>
//             )}


//             {activeStep === 1 && (
//               <Card>
//                 <CardHeader title="Choose Model(s)" />
//                 <CardContent>
//                   {upload[0]?.manufacturer?.length > 0 ? (
//                     <SelectModelsFromManufacturer
//                       upload={upload}
//                       setUpload={setUpload}
//                       handleApplicationFlow={handleApplicationFlow}
//                       modalsLoad={
//                         applicationFlow.action === "manufacturer"
//                           ? modelsMatchedManu
//                           : modelsMatchedRC
//                       }
//                     />
//                   ) : upload[0]?.rc?.length > 0 ? (
//                     <SelectModelsFromManufacturer
//                       upload={upload}
//                       setUpload={setUpload}
//                       handleApplicationFlow={handleApplicationFlow}
//                       modalsLoad={
//                         applicationFlow.action === "manufacturer"
//                           ? modelsMatchedManu
//                           : modelsMatchedRC
//                       }
//                     />
//                   ) : (
//                     <SelectModel
//                       upload={upload}
//                       setUpload={setUpload}
//                       handleApplicationFlow={handleApplicationFlow}
//                       modalsLoad={modelsLoad}
//                     />
//                   )}
//                 </CardContent>
//               </Card>
//             )}


//             {activeStep === 2 && (
//               <Card>
//                 <CardHeader title="Choose Safety Component(s)" />
//                 <CardContent>
//                   {upload.manufacturer?.length > 0 ? (
//                     <SelectSComponentFromManufacturer
//                       upload={upload}
//                       setUpload={setUpload}
//                       handleApplicationFlow={handleApplicationFlow}
//                       safetyComponentLoad={
//                         componentsResp?.length > 0
//                           ? componentsResp
//                           : componentsLoad
//                       }
//                     />
//                   ) : upload.rc?.length > 0 ? (
//                     <SelectSComponentFromManufacturer
//                       upload={upload}
//                       setUpload={setUpload}
//                       handleApplicationFlow={handleApplicationFlow}
//                       safetyComponentLoad={SCMatchedRC}
//                     />
//                   ) : (
//                     <SelectSafetyComponent
//                       upload={upload}
//                       handleApplicationFlow={handleApplicationFlow}
//                       safetyComponentLoad={componentsLoad}
//                       setUpload={setUpload}
//                     />
//                   )}
//                 </CardContent>
//               </Card>
//             )}

            
//             {/* last step */}
//             {activeStep === steps_short.length - 1 && (
//               <Card>
//                 <CardHeader title="Review Your Changes" />
//                 <CardContent>
//                   <PreviewRevokeApplication
//                     upload={upload}
//                     modelsLoad={modelsLoad}
//                     componentsLoad={componentsLoad}
//                     manufacturersData={manufacturersData}
//                   />
//                 </CardContent>
//               </Card>
//             )}


//             {/* Buttons */}
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "row",
//                 pt: 2,
//                 alignItems: "center",
//                 mb: 2,
//               }}
//             >
//               <Button
//                 disabled={activeStep === 0}
//                 onClick={handleBack}
//                 sx={{ mr: 1 }}
//                 variant="contained"
//                 color="primary"
//               >
//                 Back
//               </Button>
//               <Box sx={{ flex: "1 1 auto" }} />
//               {(applicationFlow.action === "manufacturer" &&
//                 upload?.manufacturer?.length > 0 &&
//                 activeStep !== steps_short.length - 1) ||
//               (applicationFlow.action === "rc" &&
//                 upload?.rc?.length > 0 &&
//                 activeStep !== steps_short.length - 1) ||
//               (applicationFlow.action !== "manufacturer" &&
//                 applicationFlow.action !== "rc" &&
//                 activeStep !== steps_short.length - 1) ? (
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleNext}
//                 >
//                   {activeStep === steps_short.length - 1 ? "" : "Next"}
//                 </Button>
//               ) : null}

//               {activeStep === steps_short.length - 1 && (
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => {
//                     handleUpdate();
//                   }}
//                 >
//                   {activeStep === steps_short.length - 1 && "Update"}
//                 </Button>
//               )}
//             </Box>
//           </Box>
//         </CreateBase>
//       </ResourceContextProvider>
//     </>
//   );
// };
