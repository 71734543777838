import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  TextField,
} from "@mui/material";
import { useNotify } from "react-admin";
import FailedDataValidation from "./pageValidation";
import ValidationResult from "./ValidationResult";
import keyToField from "../../utils/keyToField";
import SummaryDownload from "./ApplicationSummary";
import { useSelector } from "react-redux";
import formatDate from "../../utils/misc/formatDate";
import CertificateInput from "../../pages/recordManagement/CertificateInput";
import DiscardButton from "../../buttons/DiscardButton";

export const CompletionCheck = (props) => {
  const {
    recordManagement,
    upload,
    setUpload,
    handleSend,
    handleValidate,
    radio,
    isCreating,
  } = props;
  const userInfo = useSelector((state) => state.userInfo.value);
  const [mandatoryFulfilled, setMandatoryFulfilled] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const notify = useNotify();

  useEffect(() => {
    if (radio !== "9") {
      if (
        !FailedDataValidation("Add Manufacturer", upload.manufacturer) &&
        !FailedDataValidation("Add Models", upload.model) &&
        !FailedDataValidation("Add Safety Components", upload.app_type)
      )
        setMandatoryFulfilled(true);
    } else {
      if (
        !FailedDataValidation("Add Manufacturer", upload.manufacturer) &&
        !FailedDataValidation("Add Safety Components", upload.app_type)
      )
        setMandatoryFulfilled(true);
    }
  }, [upload]);

  const checkBeforeSend = (mode) => {
    if (!confirmed) {
      notify(
        `Please confirm the information is correct by ticking the checkbox`
      );
    } else if (mandatoryFulfilled) {
      if (mode === "send") {
        handleSend();
      } else if (mode === "validate") {
        handleValidate();
      }
    } else {
      if (mode === "send") {
        notify(
          `Error creating record, ensure the criteria check has all been passed!`
        );
      } else if (mode === "validate") {
        notify(
          `Error submitting record for validation, ensure the criteria check has all been passed!`
        );
      }
    }
  };

  const modelManufacturerList = upload.manufacturer
    .filter((manu) => manu.type !== "SafetyComponent")
    .map((manu) => manu.provided_name)
    .join(", ");

  const scManufacturerList = upload.manufacturer
    .filter((manu) => manu.type !== "Model")
    .map((manu) => manu.provided_name)
    .join(", ");

  const modelComponentsList = upload.model.map((model, i) => {
    const allComps = Object.values(upload.app_type.components).flat();
    return (
      <div key={i}>
        <Typography>{model.provided_model_name}:</Typography>

        {allComps.map((comp, i) => {
          if (comp.model_id === model.provided_model_name) {
            return (
              <Typography key={i}>
                {keyToField[comp.type]}{" "}
                <span style={{ color: "#959595" }}>|</span>{" "}
                {comp.provided_comp_name}{" "}
              </Typography>
            );
          }
        })}
      </div>
    );
  });

  const modelDetails = (model) => {
    return (
      <Grid item xs={2.5} key={model.provided_model_name}>
        <Typography sx={{ textDecoration: "underline", fontWeight: "bold" }}>
          {model.provided_model_name}
        </Typography>
        {Object.keys(model)
          .filter(
            (keys) =>
              keys !== "manufacturer_files" &&
              keys !== "LE_type" &&
              keys !== "provided_model_name"
          )
          .map((field, i) => {
            const translated = keyToField[field] ?? field;
            const translatedValue = keyToField[model[field]] ?? model[field];
            return (
              <Typography key={i}>
                <span>{translated}: </span>
                <span style={{ fontWeight: "bold" }}>
                  {Array.isArray(translatedValue)
                    ? translatedValue.join(", ")
                    : translatedValue}
                </span>
              </Typography>
            );
          })}
      </Grid>
    );
  };

  const ValidateButton = (props) => {
    return (
      <Button
        color="warning"
        variant="outlined"
        onClick={() => checkBeforeSend("validate")}
        disabled={isCreating}
        {...props}
      >
        Process to AI validation
      </Button>
    );
  };

  return (
    <Card>
      <CardHeader title="Application Summary" />
      <CardContent>
        <SummaryDownload upload={upload} userInfo={userInfo} />
        <Stack spacing={3} direction="column">
          <Typography sx={{ fontWeight: "bold", fontStyle: "italic" }}>
            Confirmation
          </Typography>
          <Divider orientation="horizontal" flexItem />
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              Application Summary
            </Typography>
            {upload.temp_application_number && (
              <Typography>
                Temporary application number: {upload.temp_application_number}
              </Typography>
            )}
            <Typography>
              Date and Time: {formatDate(new Date(), true)}
            </Typography>
            <Typography>
              Created By: {userInfo.username} ({userInfo.rc})
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontWeight: "bold" }}>Type of system:</Typography>
            <Typography>
              {keyToField[upload.app_type.type] ?? upload.app_type.type}
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              Model Manufacturers:
            </Typography>
            <Typography>{modelManufacturerList}</Typography>
          </div>
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              Safety Component Manufacturers:
            </Typography>
            <Typography>{scManufacturerList}</Typography>
          </div>
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              Models and Components:
            </Typography>
            {modelComponentsList}
          </div>
          <div>
            <Typography sx={{ fontWeight: "bold" }}>Model Details:</Typography>
            <Grid container>
              {upload.model.map((model) => modelDetails(model))}
            </Grid>
          </div>
          {recordManagement && (
            <CertificateInput upload={upload} setUpload={setUpload} />
          )}
          <FormGroup>
            <Divider />
            <FormControlLabel
              sx={{
                width: "60%",
                alignItems: "flex-start",
                mt: 3,
                pointerEvents: "none",
              }}
              control={
                <Checkbox
                  sx={{ pt: 0, pointerEvents: "auto" }}
                  onClick={() => setConfirmed((current) => !current)}
                />
              }
              label="I declare that all information in this application is correct, complete and true. I also understand that a person who knowingly and wilfully makes a statement or gives information which he knows to be false or does not believe to be true shall be guilty of an offence under law. "
            />
            <Typography
              display="inline"
              sx={{
                color: "red",
                fontStyle: "italic",
                fontSize: "0.75rem",
                verticalAlign: "top",
                ml: 4,
              }}
            >
              *Required
            </Typography>
          </FormGroup>
          {recordManagement ? (
            <div>
              <Button
                color="primary"
                variant="outlined"
                disabled={isCreating}
                onClick={() => checkBeforeSend("send")}
              >
                Send
              </Button>
            </div>
          ) : !upload?.aiFeedBack || upload?.aiFeedBack?.length === 0 ? (
            //User has not done AI validation
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <ValidateButton sx={{ mr: 1 }} />
                {upload.validationErrorCount >= 3 && (
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={isCreating}
                    onClick={() => checkBeforeSend("send")}
                  >
                    Send (Submit to EMSD)
                  </Button>
                )}
              </Box>
              {upload._id && <DiscardButton id={upload._id} />}
            </div>
          ) : (
            //User finished AI validation
            <ValidationResult
              upload={upload}
              setUpload={setUpload}
              handleSend={() => checkBeforeSend("send")}
              validate={<ValidateButton sx={{ mr: 1 }} />}
              isCreating={isCreating}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
