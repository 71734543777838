import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Tooltip from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { useTranslate } from 'react-admin';

function EnhancedTableToolbar({ numSelected, handleClearAll }) {
  const translate = useTranslate();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 && (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {translate("phase2.ru.enhancedTableToolbar.numSelected")}
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Clear All Selection">
          <IconButton
            onClick={() => handleClearAll()}
            aria-label='Clear All Selection'
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleClearAll: PropTypes.func.isRequired
};

export default EnhancedTableToolbar