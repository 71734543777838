import React, { useState } from "react";
import {
  Popper,
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Typography,
  ClickAwayListener,
  ListItem,
  ListItemText,
  TextField,
  List,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import {
  Loading,
  useGetList,
  useCreate,
  useUpdate,
  useDelete,
  useNotify,
  useRefresh,
} from "react-admin";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

function Notification(props) {
  const { data: notifications, isLoading } = useGetList("notifications", {
    pagination: { page: 1, perPage: 0 },
    sort: { field: "createdAt", order: "DESC" },
  });
  const userInfo = useSelector((state) => state.userInfo.value);
  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { isLoading: isCreating }] = useCreate();
  const [update, { isLoading: isUpdating }] = useUpdate();
  const [deleteOne, { isLoading: isDeleting }] = useDelete();
  const [anchorEl, setAnchorEl] = useState(null);
  const [content, setContent] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    create(
      "notifications",
      { data: { content } },
      {
        onSuccess: () => {
          notify("Message created");
          refresh();
          setContent("");
        },
        onError: () => notify(`Error creating messages`),
      }
    );
  };
  const handleEdit = (id, content) => {
    setEditingId(id);
    setEditedContent(content);
  };
  const handleSave = (id) => {
    update(
      "notifications",
      { id, data: { content: editedContent } },
      {
        onSuccess: () => {
          setEditingId(null);
          setEditedContent("");
          refresh();
        },
        onError: () => {
          notify(`Error updating messages`);
          setEditingId(null);
          setEditedContent("");
        },
      }
    );
  };
  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete notification",
      message: `Are you sure to do this.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteOne(
              "notifications",
              { id },
              {
                onSuccess: () => {
                  refresh();
                },
                onError: () => notify(`Error deleting messages`),
              }
            );
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const open = Boolean(anchorEl);
  const id = open ? "notification-popper" : undefined;

  return (
    <Box>
      <IconButton onClick={handleClick} aria-describedby={id}>
        <NotificationsIcon sx={{ color: "black" }} />
      </IconButton>
      <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseUp">
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          sx={{
            backgroundColor: "white",
            zIndex: 999,
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            width: "350px",
            height: "400px",
            overflowY: "auto",
          }}
          disablePortal
        >
          <Typography sx={{ fontFamily: "Frutiger_bold" }}>
            Notifications
          </Typography>
          {window.location.pathname === "/lift_emsd" && (
            <form
              onSubmit={handleSubmit}
              style={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                inputProps={{ style: { fontSize: "1rem" } }}
                InputLabelProps={{ style: { fontSize: "1rem" } }}
                fullWidth
                variant="standard"
                label="New message"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                multiline
              />
              <Button color="primary" type="submit">
                Send
              </Button>
            </form>
          )}
          {isLoading ? (
            <Loading />
          ) : (
            <List>
              {notifications?.map((n, index) => {
                return (
                  <React.Fragment key={n.id}>
                    <ListItem
                      sx={{
                        pl: 0,
                      }}
                      secondaryAction={
                        userInfo.user_id === n.user_id &&
                        (editingId === n.id ? (
                          <IconButton
                            size="small"
                            sx={{ p: 0 }}
                            onClick={() => handleSave(n.id)}
                          >
                            <SaveIcon />
                          </IconButton>
                        ) : (
                          <ButtonGroup>
                            <IconButton
                              size="small"
                              sx={{ p: 0 }}
                              onClick={() => handleEdit(n.id, n.content)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              sx={{ p: 0 }}
                              onClick={() => handleDelete(n.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ButtonGroup>
                        ))
                      }
                    >
                      <ListItemText
                        primaryTypographyProps={{ style: { fontSize: "1rem" } }}
                        primary={
                          editingId === n.id ? (
                            <TextField
                              inputProps={{ style: { fontSize: "1rem" } }}
                              variant="standard"
                              fullWidth
                              value={editedContent}
                              onChange={(e) => setEditedContent(e.target.value)}
                              multiline
                            />
                          ) : (
                            n.content
                          )
                        }
                      />
                    </ListItem>
                    {index !== notifications.length - 1 && (
                      <Divider component="li" />
                    )}
                  </React.Fragment>
                );
              })}
            </List>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
}

export default Notification;
