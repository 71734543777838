import { Box, Checkbox, FormControlLabel } from "@mui/material";

function RangeExtensionCheckbox(props) {
  const { upload, setUpload, sx } = props;
  //check mandatoryFields.json
  //if mandatoryFileSet is 1, use c_lift1 / c_escalator1  in updateValidation.js
  //if mandatoryFileSet is 2, use c_lift2 / c_escalator2  in updateValidation.js
  if (!upload) return null;

  const model = upload.model[0];
  const type = model.LE_type === "L" ? "c_lift" : "c_escalator";

  const handleCheckboxChange = (value) => {
    setUpload({ ...upload, mandatoryFileSet: value });
  };

  return (
    <Box sx={sx}>
      <FormControlLabel
        checked={upload.mandatoryFileSet === `${type}2`}
        label="Upload model files"
        control={<Checkbox />}
        onChange={() => handleCheckboxChange(`${type}2`)}
      />
      <FormControlLabel
        checked={upload.mandatoryFileSet === `${type}1`}
        label="Upload Safety Component files"
        control={<Checkbox />}
        onChange={() => handleCheckboxChange(`${type}1`)}
      />
    </Box>
  );
}

export default RangeExtensionCheckbox;
