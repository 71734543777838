import { ButtonGroup, Button } from "@mui/material";
import { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from 'react-i18next';


const Chart = ({ data, rangeChange }) => {
  const { t : itranslate } = useTranslation();
  const [seven, setSeven] = useState(0);

  return (
    <div
      style={{
        flex: 4,
        padding: "10px",
        // "-webkit-box-shadow": "2px 4px 10px 1px rgba(0,0,0,0.47)",
        boxShadow: "2px 4px 10px 1px rgba(201,201,201,0.47)",
        color: "gray",
      }}
    >
      <ButtonGroup
        style={{ marginBottom: "20px" }}
        variant="text"
        aria-label="text primary button group"
      >
        <Button
          sx={
            seven === 0
              ? {
                color: "#2a598f!important",
              }
              : {
                color: "gray",
              }
          }
          disabled={seven === 0}
          onClick={() => {
            setSeven(0);
            rangeChange("6");
          }}
        >
          {/* {`Last 6 Months`} */}
          {itranslate(`dashboard.Last 6 Months`)}
        </Button>
        
        <Button
          sx={
            seven === 1
              ? {
                color: "#2a598f!important",
              }
              : {
                color: "gray",
              }
          }
          disabled={seven === 1}
          onClick={() => {
            setSeven(1);
            rangeChange("3");
          }}
        >
          {/* {`Last 3 Months`} */}
          {itranslate(`dashboard.Last 3 Months`)}
        </Button>

        <Button
          sx={
            seven === 2
              ? {
                color: "#2a598f!important",
              }
              : {
                color: "gray",
              }
          }
          disabled={seven === 2}
          onClick={() => {
            setSeven(2);
            rangeChange("1");
          }}
        >
          {/* {`Last Month`} */}
          {itranslate(`dashboard.Last Month`)}
        </Button>

        <Button
          sx={
            seven === 3
              ? {
                color: "#2a598f!important",
              }
              : {
                color: "gray",
              }
          }
          disabled={seven === 3}
          onClick={() => {
            setSeven(3);
            rangeChange("0");
          }}
        >
          {/* {`This Month`} */}
          {itranslate(`dashboard.This Month`)}
        </Button>

      </ButtonGroup>
      <ResponsiveContainer width="100%" aspect={2 / 1}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="Total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#2a598f" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#2a598f" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" stroke="rgb(228,225,225)" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Total"
            stroke="#2a598f"
            fillOpacity={1}
            fill="url(#Total)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
