import { TextField, InputLabel, Box, Typography } from "@mui/material";
import React from "react";

function DescriptionInput(props) {
  const { upload, setUpload } = props;
  const handleText = (e) => {
    setUpload({
      ...upload,
      description: e.target.value,
    });
  };
  return (
    <Box sx={{ mt: 4 }}>
      <InputLabel id="description">
        Remark about changes made
        <Typography
          component="span"
          sx={{
            color: "red",
            fontStyle: "italic",
            fontSize: "0.75rem",
            verticalAlign: "top",
          }}
        >
          *Required
        </Typography>
      </InputLabel>
      <TextField
        labelId="description"
        value={upload?.description}
        onChange={handleText}
        variant="outlined"
        sx={{ width: "600px" }}
      />
    </Box>
  );
}

export default DescriptionInput;
