import { useEffect, useState } from "react";
import {
  useShowController,
  Show,
  SimpleShowLayout,
  Datagrid,
  List,
  ReferenceField,
  TextField,
  FunctionField,
  Create,
  useRecordContext,
  ShowButton,
} from "react-admin";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import static_fields from "../utils/static_fields.json";
import { ComponentShowOne } from "../components/component/ComponentShowOne";
import ComponentFilter from "./filters/ComponentFilter";
import componentsExporter from "./exporters/componentsExporter";
import keyToField from "../utils/keyToField.js";
import isGetOneData from "../utils/applications/isGetOneData.js";
import { useTranslation } from 'react-i18next';


export const ComponentShow = () => {
  const { error, isLoading, record } = useShowController();

  const [update, setUpdate] = useState(null);

  useEffect(() => {
    if (record && isGetOneData(record, "components")) setUpdate(record);
  }, [record]);

  return (
    <Show title={<ComponentTitle />}>
      <SimpleShowLayout>
        {update && (
          <ComponentShowOne upload={update} template={static_fields} />
        )}
      </SimpleShowLayout>
    </Show>
  );
};

export const ComponentList = () => {
  const { t : itranslate } = useTranslation()
  const [isStandalone, setIsStandalone] = useState(false);
  return (
    <List
      filters=<ComponentFilter />
      exporter={componentsExporter}
      // title={`- Components`}
      title={`- ${itranslate('appBar.Components')}`}
      sort={{ field: "approval_date", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false} isRowSelectable={(x) => false}>
        <TextField label="Component Code" source="component_code" />
        <TextField label="Component Name" source="provided_comp_name" />
        <FunctionField
          label="Type"
          source="type"
          render={(record) => keyToField[record.type] ?? record.type}
        />
        <ReferenceField
          source="model_id"
          reference="models"
          label="Lift/Escalator Model Type"
          link={false}
          emptyText="N/A"
        >
          <FunctionField
            render={(record) => keyToField[record.type]}
            source="type"
          />
        </ReferenceField>

        <ReferenceField
          source="model_id"
          reference="models"
          label="Lift/Escalator Model"
          link="show"
          emptyText="None"
        >
          <FunctionField render={(record) => {
            return record.provided_model_name
          }} />
          {/* <TextField source="provided_model_name" /> */}
        </ReferenceField>

        {/* if the component is standalone, display "Yes", else "No" in the standalone column */}
        <ReferenceField
          source="model_id"
          reference="models"
          label="Standalone"
          link="show"
          emptyText="Yes"
        >
          <FunctionField render={() => {
            return "No"
          }} />
        </ReferenceField>


        {window.location.pathname === `/lift_emsd` && (
          <ReferenceField label="RC" source="rc_id" reference="rcs" link="show">
            <FunctionField
              render={(record) => {
                let rcDisplay = "";
                rcDisplay = record?.rc_number_lift;
                if (record?.rc_number_escalator)
                  rcDisplay += " / " + record?.rc_number_escalator;
                if (record?.address?.company)
                  rcDisplay += " / " + record?.address?.company;
                return rcDisplay || record.name;
              }}
            />
          </ReferenceField>
        )}
        <ReferenceField
          source="manufacturer"
          reference="manufacturers"
          link="show"
        >
          <TextField source="provided_name" />
        </ReferenceField>
        <ShowButton sx={{ padding: '0' }}/>
      </Datagrid>
    </List>
  )
};

const ComponentTitle = () => {
  const record = useRecordContext();
  return <span>Component {record?.component_code}</span>;
};

export const ComponentEdit = () => (
  <Edit title={<ComponentTitle />}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>

      <TextInput source="title" />
      <TextInput multiline source="body" />
    </SimpleForm>
  </Edit>
);

export const ComponentCreate = (props) => (
  <Create {...props} resource="components">
    <SimpleForm>
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="title" />
      <TextInput multiline source="body" />
    </SimpleForm>
  </Create>
);
