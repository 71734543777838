import { Box, Slider, Typography } from "@mui/material";
import keyToField from "../../../utils/keyToField";
import palette from "../../../styles/palette";
import _ from "lodash";

function LineBreakInput(props) {
  const {
    appendix,
    setAppendix,
    application,
    userInfo,
    userRoles,
    standalones,
  } = props;

  const handleSliderChange = (event, newValue, compType) => {
    let clone = structuredClone(appendix);
    clone["SCLineBreak"] = { ...(clone["SCLineBreak"] || {}) };
    // margin range from 0 to 300 px
    clone["SCLineBreak"][compType] = newValue * 3;
    setAppendix({ ...clone });
  };

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
    (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
    (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
    (userInfo?.role === "EMSDEngineer" &&
      application?.status === "Pending E" &&
      !userRoles.includes(application?.current_role_id));

  const componentTypes = _.uniq([
    ...Object.keys(application.app_type?.components || {}),
    ...(standalones?.components?.map((c) => c.type) || []),
  ]);

  return (
    <Box>
      {componentTypes
        .sort((a, b) => {
          const order = [
            "Buffer",
            "OverspeedGovernor",
            "LandingDoorLockingDevice",
            "CarDoorLockingDevice",
            "SafetyGear",
            "ACOP",
            "UCMP",
            "SafetyCircuitElectricalComponent",
          ];
          function customSort(item) {
            const index = order.indexOf(item);
            return index !== -1 ? index : order.length;
          }
          return customSort(a) - customSort(b);
        })
        .map((compType) => {
          return (
            <Box key={compType} sx={{ mb: 2 }}>
              <Typography>{keyToField[compType]}</Typography>
              <Box display="flex" alignItems="center">
                <Typography variant="caption" sx={{ color: palette.secondary }}>
                  Bottom margin length
                </Typography>
                <Slider
                  disabled={isDisabled}
                  sx={{ width: "50%", ml: 2 }}
                  value={appendix["SCLineBreak"]?.[compType] / 3 || 6}
                  onChange={(e, newValue) =>
                    handleSliderChange(e, newValue, compType)
                  }
                />
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}

export default LineBreakInput;
