import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import keyToField from "../../utils/keyToField";
import msToDays from "../../utils/misc/msToDays";
import axios from "axios";
import getToken from "../../utils/getAuthToken";
import { useState, useEffect } from "react";
import palette from "../../styles/palette";
import { useGetOne, useUpdate, useNotify } from "react-admin";
import RefreshIcon from "@mui/icons-material/Refresh";

/* manufacturer style*/
/* top menu has a + button and a select to select previous approved manufacturers */
/* press/selecting loads a accordion, that has the required inside and fileStack/Grid with | text | fileInput |  inside  each one has delete */

export const ApplicationInfo = (props) => {
  const { id } = useParams();
  const [update, { isLoading: isUpdating }] = useUpdate();
  const notify = useNotify();
  const { latestApp, upload, setOpenAppendix, userInfo, userRoles } = props;
  const [started, setStarted] = useState(null);
  const [justification, setJustification] = useState("");
  const [pledgeTime, setpledgeTime] = useState(null);
  const handleSync = () => {
    update(
      "apps/sync",
      { id },
      {
        onSuccess: (res) => {
          notify(`Synced successfully!`);
          window.location.reload();
        },
        onError: (e) => notify(e.response.data),
      }
    );
  };

  const handleTimer = (action) => {
    axios
      .post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/apps/pledge_time/${upload.id}`,
        { action, justification },
        {
          headers: { authorization: getToken() },
        }
      )
      .then((res) => {
        setStarted(res.data.pledge_time.started);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (upload.pledge_time) {
      async function refreshTimer() {
        const res = await axios.get(
          `${process.env.REACT_APP_ENV_ENDPOINT}/lift/apps/refresh_time/${id}`,
          {
            headers: { authorization: getToken() },
          }
        );
        setpledgeTime(res.data.pledge_time);
        setStarted(upload.pledge_time?.started);
        setJustification(upload.pledge_time?.justification || "");
      }
      refreshTimer();
    }
  }, [upload, id]);

  // // disable the button if the user is not EMSDGR (GR) or Inspector, or if the user's role(s) is not equal to the app's current role id, but the application's current role name is not GR.
  // const isButtonDisabled =
  //   (userInfo?.role === "EMSDGR" && //GR and current role name is not GR and not pending
  //     (upload?.status.includes("Pending E") ||
  //       upload?.status.includes("Pending SE") ||
  //       upload?.status.includes("Pending CE")) &&
  //     upload?.current_role_name !== "GR") ||
  //   userInfo?.role === "Inspector" || //Inspector
  //   (userInfo?.role !== "EMSDGR" && //Check roleId except GR
  //     !userRoles.includes(upload?.current_role_id));

  // // when application is "Pending" or "Pending E", Engineer Role can click on "Edit Appendix / Approval Letter" button.
  // // This is because they should be at least View the Appenidx
  // const isButtonDisabled2 =
  //   (userInfo?.role === "EMSDGR" && //GR and current role name is not GR and not pending
  //     (upload?.status === "Pending E" ||
  //       upload?.status === "Pending SE" ||
  //       upload?.status === "Pending CE")) ||
  //   ((upload?.status === "Pending SE" || upload?.status === "Pending CE") &&
  //     !userRoles.includes(upload?.current_role_id)) ||
  //   userInfo?.role === "Inspector" ||
  //   (userInfo?.role === "EMSDCE" &&
  //     (upload?.status === "Pending" || upload?.status === "Pending E")) ||
  //   (userInfo?.role === "EMSDSE" && upload?.status === "Pending") ||
  //   (userInfo?.role === "EMSDSE" &&
  //     upload?.status === "Pending E" &&
  //     !userRoles.includes(upload?.current_role_id));



    // disable the button if the user is not EMSDGR (GR) or Inspector, or if the user's role(s) is not equal to the app's current role id, but the application's current role name is not GR.
    const isButtonDisabled =
    (userInfo?.role === "EMSDGR" && //GR and current role name is not GR and not pending
      (upload?.status.includes("Pending E") ||
        upload?.status.includes("Pending SE") ||
        upload?.status.includes("Pending CE") ||
        upload?.status.includes("ReturningClarifying")) &&
      upload?.current_role_name !== "GR") ||
    userInfo?.role === "Inspector" || //Inspector
    (userInfo?.role !== "EMSDGR" && //Check roleId except GR
      !userRoles.includes(upload?.current_role_id));

  // when application is "Pending" or "Pending E", Engineer Role can click on "Edit Appendix / Approval Letter" button.
  // This is because they should be at least View the Appenidx
  const isButtonDisabled2 =
    (userInfo?.role === "EMSDGR" && //GR and current role name is not GR and not pending
      (upload?.status === "Pending E" ||
        upload?.status === "Pending SE" ||
        upload?.status === "Pending CE")) ||
    ((upload?.status === "Pending SE" || upload?.status === "Pending CE") &&
      !userRoles.includes(upload?.current_role_id)) ||
    userInfo?.role === "Inspector" ||
    (userInfo?.role === "EMSDCE" &&
      (upload?.status === "Pending" || upload?.status === "Pending E" || upload?.status === "ReturningClarifying")) ||
    (userInfo?.role === "EMSDSE" && upload?.status === "Pending") ||
    (userInfo?.role === "EMSDSE" && upload?.status === "ReturningClarifying") ||
    (userInfo?.role === "EMSDSE" &&
      upload?.status === "Pending E" &&
      !userRoles.includes(upload?.current_role_id)) 


  const Row = (props) => {
    const { label, value } = props;
    return (
      <Box display="flex" justifyContent="space-between">
        <Typography>{label}:</Typography>
        <Typography sx={{ minWidth: "500px" }}>
          {value}{" "}
          {window.location.pathname === "/lift_emsd" &&
            label === "Update Of" &&
            !["Approved", "Clarifying", "Withdrawn"].includes(upload.status) &&
            latestApp && (
              <>
                <Typography
                  component="span"
                  sx={{
                    color:
                      latestApp?.application_number !== value ? "red" : "green",
                  }}
                >
                  (latest application: {latestApp?.application_number || ""})
                </Typography>
                <IconButton
                  sx={{ px: 0 }}
                  onClick={handleSync}
                  disabled={isUpdating}
                >
                  <RefreshIcon />
                  <Typography>Refresh</Typography>
                </IconButton>
              </>
            )}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Card sx={{}}>
        <CardHeader title="Application Information" />
        <CardContent>
          <Stack
            spacing={2}
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            sx={{ "&:first-of-type": { mt: 1 } }}
          >
            <Row label="Created By" value={upload.owner_id?.username} />
            <Row
              label="RC"
              value={
                upload.rc_id[
                  upload.model[0]?.LE_type === "E"
                    ? "rc_number_escalator"
                    : "rc_number_lift"
                ] || upload.rc_id.name
              }
            />
            <Row
              label="Application Process"
              value={
                upload.update_type ? upload.update_type : "New Application"
              }
            />
            <Row
              label="Application Type"
              value={keyToField[upload.app_type.type]}
            />
            <Row label="Application No." value={upload.application_number} />

            {upload.update_upon && (
              <Row label="Update Of" value={upload.update_upon} />
            )}
            {upload.autogeneratedBy && (
              <Row
                label="Autogenerated By"
                value={upload.autogeneratedBy.application_number}
              />
            )}
            <Row
              label="Application Date"
              value={new Date(upload.created_at).toLocaleString("en-HK", {
                dateStyle: "full",
                timeStyle: "long",
              })}
            />
            <Row
              label="Last Updated"
              value={new Date(upload.updatedAt).toLocaleString("en-HK", {
                dateStyle: "full",
                timeStyle: "long",
              })}
            />
            {upload.status === "Approved" && (
              <Row
                label="Approval Date"
                value={new Date(upload.approval_date).toLocaleString("en-HK", {
                  dateStyle: "full",
                  timeStyle: "long",
                })}
              />
            )}
            {upload.status === "Withdrawn" && (
              <Row
                label="Withdraw Date"
                value={
                  upload.withdrawn_info?.date
                    ? new Date(upload.withdrawn_info.date).toLocaleString(
                        "en-HK",
                        {
                          dateStyle: "full",
                          timeStyle: "long",
                        }
                      )
                    : ""
                }
              />
            )}
            {upload.status === "Withdrawn" && (
              <Row label="Withdraw By" value={upload.withdrawn_info?.by} />
            )}
            {window.location.pathname === `/lift_emsd` &&
              !["Approved", "Withdrawn"].includes(upload.status) && (
                <Row label="Role Assigned" value={upload.current_role_name} />
              )}
            {upload.pledge_time &&
              window.location.pathname === "/lift_emsd" && (
                <Box display="flex" justifyContent="space-between">
                  <Typography>Pledge Time:</Typography>
                  <Box>
                    <Typography sx={{ minWidth: "500px" }}>
                      {pledgeTime && msToDays(pledgeTime.accumulatedTime)}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <TextField
                        disabled={!started}
                        value={justification}
                        onChange={(e) => setJustification(e.target.value)}
                        variant="standard"
                        label="Justification"
                      />
                      {!["Approved", "Clarifying", "Withdrawn"].includes(
                        upload.status
                      ) &&
                        started !== null && (
                          <Button
                            disabled={isButtonDisabled}
                            sx={{ p: 0 }}
                            onClick={() =>
                              handleTimer(started ? "Pause" : "Start")
                            }
                          >
                            {started ? "Pause" : "Start"}
                          </Button>
                        )}
                    </Box>
                  </Box>
                </Box>
              )}
            {(upload?.status?.includes("Pending") ||
              upload?.status === "ReturningClarifying") &&
              window.location.pathname !== "/lift_rc" &&
              !["Approved", "Clarifying", "Withdrawn"].includes(
                upload.status
              ) && (
                <Button
                  sx={{
                    ml: 2,
                    backgroundColor: palette.shadow,
                    color: "black",
                    mb: 2,
                    border: "1px solid black",
                    mt: 3,
                  }}
                  disabled={
                    !(userInfo?.role === "TapasAdmin") && isButtonDisabled2
                  }
                  onClick={() => {
                    setOpenAppendix(true);
                  }}
                >
                  Edit Appendix / Approval Letter
                </Button>
              )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
