import { TextField, IconButton, Button, Typography, Box } from "@mui/material";
import { Delete, Add, Close } from "@mui/icons-material";
import React from "react";

const TextInput = (props) => {
  const {
    paragraph,
    deleteSection,
    appendix,
    setAppendix,
    setText,
    applicationType,
    application,
    userInfo,
    userRoles
  } = props;

  const sectionTitle = (section, applicationType) => {
    switch (section) {
      case "overviews":
        return "Overviews";
      case "conditions":
        return "Conditions";
      case "remarks":
        return applicationType !== "SafetyComponent"
          ? "Remarks (Part B)"
          : "Remarks";
      case "remarks2":
        return "Remarks (Part C)";
      case "approvalLetterSubject":
        return "Subject";
      case "approvalLetterBody":
        return "Body";
      default:
        return "";
    }
  };

  const handleSelect = (e, i, paragraph, subIndex) => {
    const selectedText = window.getSelection().toString();
    const text = {
      text: selectedText,
      range: [e.target.selectionStart, e.target.selectionEnd],
      index: i,
      subIndex: subIndex,
      section: paragraph,
    };
    setText(text);
  };

  const handleText = (value, i, type, textIndex, subSectionIndex) => {
    let section = structuredClone(appendix[type]);
    section[i][subSectionIndex] = value;
    setAppendix({ ...appendix, [type]: section });
  };

  const addSubSection = (i, type) => {
    let section = structuredClone(appendix[type]);
    section[i] = Array.isArray(section[i])
      ? [...section[i], ""]
      : [section[i], ""];
    setAppendix({ ...appendix, [type]: section });
  };

  const handleSubText = (value, i, type, subIndex, subSectionIndex) => {
    let section = structuredClone(appendix[type]);
    section[i][subIndex][subSectionIndex] = value;
    setAppendix({ ...appendix, [type]: section });
  };

  const addSubSubSection = (i, subIndex, type) => {
    let section = structuredClone(appendix[type]);
    section[i][subIndex] = Array.isArray(section[i][subIndex])
      ? [...section[i][subIndex], ""]
      : [section[i][subIndex], ""];
    setAppendix({ ...appendix, [type]: section });
  };

  const deleteSubText = (i, type, subSectionIndex) => {
    let section = structuredClone(appendix[type]);
    if (section[i].length === 1) {
      section = section.filter((section, index) => index !== i);
    } else {
      section[i] = section[i].filter(
        (subSection, index) => index !== subSectionIndex
      );
    }
    setAppendix({ ...appendix, [type]: section });
  };

  const deleteSubSubText = (i, subIndex, type, subSectionIndex) => {
    let section = structuredClone(appendix[type]);
    if (section[i][subIndex].length === 1) {
      section[i] = section[i].filter((subSection, index) => index !== subIndex);
    } else {
      if (section[i][subIndex].length === 2) {
        //change from [a, b] to a
        section[i][subIndex] = section[i][subIndex][0];
      } else {
        section[i][subIndex] = section[i][subIndex].filter(
          (subSection, index) => index !== subSectionIndex
        );
      }
    }
    setAppendix({ ...appendix, [type]: section });
  };

  // appendix[paragraph] = [ [subSection, subSection, subSection], [subSection, subSection, subSection], [subSection, subSection, subSection] ]
  // Where subSection is a string or an array of strings (sub-subsections)

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))

  return (
    <div style={{ width: "100%" }}>
      {appendix[paragraph]?.map((text, i) => {
        return (
          <Box
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              border: "1px solid #ababab",
              borderRadius: "5px",
              padding: "3px 3px 3px 10px",
              margin: "20px 0px",
              backgroundColor: "#ebebeb8c",
            }}
            key={`${paragraph}-${i + 1}`}
          >
            <IconButton
              disabled={isDisabled} 
              sx={{
                alignSelf: "flex-end",
                backgroundColor: "#ff234ab8",
                padding: "5px",
              }}
              onClick={() => deleteSection(i, paragraph)}
            >
              <Close sx={{ fontSize: "20px" }} />
            </IconButton>
            {text.map((subSection, subSectionIndex) => {
              const addSubSubSectionButton = (
                <Button
                  disabled={isDisabled} 
                  size="small"
                  color="inherit"
                  onClick={() =>
                    addSubSubSection(i, subSectionIndex, paragraph)
                  }
                  sx={{
                    alignSelf: "center",
                    fontSize: "0.8rem",
                    ml: 10,
                  }}
                >
                  <Add sx={{ fontSize: "0.8rem" }} />
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    Add sub-subsection
                  </Typography>
                </Button>
              );
              if (Array.isArray(subSection)) {
                return (
                  <Box sx={{ textAlign: "left" }} key={subSectionIndex}>
                    {subSection.map((subSubSection, subSubSectionIndex) => {
                      return (
                        <TextBox
                          key={subSubSectionIndex}
                          value={subSubSection}
                          label={`${sectionTitle(paragraph, applicationType)} ${i + 1
                            }`}
                          handleDelete={() =>
                            deleteSubSubText(
                              i,
                              subSectionIndex,
                              paragraph,
                              subSubSectionIndex
                            )
                          }
                          handleText={(e) => {
                            handleSubText(
                              e.target.value,
                              i,
                              paragraph,
                              subSectionIndex,
                              subSubSectionIndex
                            );
                          }}
                          sx={{
                            ml: subSubSectionIndex === 0 ? 5 : 10,
                          }}
                          application={application}
                          userInfo={userInfo}
                          userRoles={userRoles}
                        />
                      );
                    })}
                    {!["approvalLetterSubject", "approvalLetterBody"].includes(
                      paragraph
                    ) && addSubSubSectionButton}
                  </Box>
                );
              } else
                return (
                  <Box sx={{ textAlign: "left" }} key={subSectionIndex}>
                    <TextBox
                      i={i}
                      label={`${sectionTitle(paragraph, applicationType)} ${i + 1
                        }`}
                      paragraph={paragraph}
                      value={subSection}
                      handleText={(e) => {
                        handleText(
                          e.target.value,
                          i,
                          paragraph,
                          e.target.selectionStart,
                          subSectionIndex
                        );
                      }}
                      handleSelect={(e) => {
                        handleSelect(e, i, paragraph, subSectionIndex);
                      }}
                      handleDelete={() =>
                        deleteSubText(i, paragraph, subSectionIndex)
                      }
                      sx={{
                        ...(subSectionIndex !== 0 && {
                          ml: 5,
                        }),
                      }}
                      application={application}
                      userInfo={userInfo}
                      userRoles={userRoles}
                    />
                    {subSectionIndex !== 0 &&
                      !["approvalLetterSubject", "approvalLetterBody"].includes(
                        paragraph
                      ) &&
                      addSubSubSectionButton}
                  </Box>
                );
            })}
            <Button
              disabled={isDisabled} 
              size="small"
              color="inherit"
              onClick={() => addSubSection(i, paragraph)}
              sx={{ alignSelf: "center" }}
            >
              <Add />
              <Typography>Add sub-section</Typography>
            </Button>
          </Box>
        );
      })}
    </div>
  );
};

const TextBox = (props) => {
  const {
    i,
    paragraph,
    value,
    handleText,
    handleSelect,
    handleDelete,
    label,
    sx,
    application,
    userInfo,
    userRoles
  } = props;


  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <TextField
        disabled={isDisabled} 
        fullWidth
        onSelect={handleSelect}
        sx={{
          minWidth: "300px",
          backgroundColor: "white",
          borderRadius: "5px",
          ...sx,
        }}
        id={`${paragraph}-${i + 1}`}
        label={label}
        value={value}
        variant="outlined"
        multiline
        maxRows={4}
        onChange={handleText}
      />
      <IconButton
        disabled={isDisabled} 
        size="small"
        onClick={handleDelete}
        sx={{ alignSelf: "center", padding: "1px" }}
      >
        <Delete />
      </IconButton>
    </Box>
  );
};

export default TextInput;
