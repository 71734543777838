
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
//mui tooltip
import Tooltip from '@mui/material/Tooltip';


import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';

// components
import EnhancedTableHead from "./ModelListTable/EnhancedTableHead.jsx";
import InputSearchBar from "./ModelListTable/InputSearchBar"

// custom hooks
import {getModelListForRu, getOneModelForRu} from "../../../../../../admin/useHooks/useModels/index.jsx";

import {headCells} from '../../../../../../_components/CompRestrictedUrlMgt/CreateStage2/constants/headCells.js'
import ModelPerspectiveShow from './show.jsx';
import { ShowBtn } from '../../../../../../_hoc/Buttons/ShowBtn.jsx';
import { useTranslation } from 'react-i18next';

const BackBtn = styled(Button)(({ theme }) => ({
    color: "#2A598F",
    backgroundColor: "white",
    '&:hover': {
        color: "white",
        backgroundColor: "#2A598F",
    },
    variant: "outlined",
}));


export default function ModelPerspective({ ruResult, handleGetPDFFile }) {
    const { t } = useTranslation("pages", {keyPrefix: "typeApprovalRecord"});
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('client_user');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [isLoading, setIsLoading] = useState(true);
    const [openLoadingModal, setOpenLoadingModal] = useState(true)

    const [totalCount, setTotalCount] = useState(0)
    const [range, setRange] = useState(`[0, 9]`)
    const [filter, setFilter] = useState(`{}`)
    const [sort, setSort] = useState(`["model_code", "DESC"]`)

    const [dataModel, setDataModel] = useState([])
    const [modelPerspectiveShowData, setModelPerspectiveShowData] = useState({})    
    const [showView, setShowView] = useState(false)

    const [manusChosen, setManusChosen] = useState([])

    // setRange when page changes
    useEffect(() => {
        setRange(`[${page * rowsPerPage},${page * rowsPerPage + rowsPerPage - 1}]`)
    }, [page, rowsPerPage])

    // setSort
    const handleRequestSort = (event, property) => {
        event.preventDefault()
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort(`["${property}","${isAsc ? "DESC" : "ASC"}"]`)
    };


    function fixedSortingIssue(property){
        let arr = headCells.models.map(v => v.id)
        if(arr.includes(property[0])){
            setSort(`["${property[0]}","${property[1]}"]`)
        } else {
            setSort(`["model_code","DESC"]`)
        }
    }

    // handle query value update
    useEffect(() => {
        fixedSortingIssue(JSON.parse(sort))

        getModelListForRu(filter, range, sort, ruResult?.encrypted_ruNo).then((res) => {
            setDataModel(res?.data)
            setTotalCount( + res?.headers["x-total-count"] || 999999)
        }).catch((err) => {
        }).finally(() => {
            setIsLoading(false)
        })
    }, [filter, range, sort])


    useEffect(() => {
        // e.g.
        // change to 1st page after deletion makes ruResults empty
        
        if(isLoading === false) {
            setOpenLoadingModal(false)
            setManusChosen(ruResult.model_manu_sc_id_arr.flatMap(v => v.relatedManufacturersChosen.map(v2 => v2._id)))
        } else {
            setOpenLoadingModal(true)
        }

    }, [dataModel])


    // setPage
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // set rowsPerPage
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, rowsPerPage - dataModel?.length) : 0;


    // view btn handler
    const handleView = (rowInfo) => {
        getOneModelForRu(rowInfo?._id, ruResult.encrypted_ruNo).then((res) => {
            setModelPerspectiveShowData(res?.data)
            setShowView(true)
        }).catch((err) => {
        })
    }


    return (
        <Container component="main" maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoadingModal}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }

            {
                !showView && (
                    <Box sx={{ width: '100%', 
                        boxShadow: "0 0 0 2rem rgba(246,248,249)",
                    }}>
                        <Paper sx={{ width: '100%', mb: 2, overflowX: "auto" }}>
                            {
                                !isLoading && dataModel?.length > 0 && (
                                    <InputSearchBar
                                        setFilter={setFilter}
                                    />
                                )
                            }
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                >
                                    {
                                        !isLoading && dataModel?.length > 0 &&
                                        (
                                            <>
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    tableHeadCells={headCells?.models}
                                                />
                                                <TableBody>
                                                    {dataModel?.length > 0 && dataModel.map((row, index) => {
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        let rowTypeWithSpace = !row.type.includes(" ") ? row.type.split(/(?=[A-Z])/).join(" ") : row.type
                                                        return (
                                                            <TableRow
                                                                key={row._id}
                                                                tabIndex={-1}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                >
                                                                    {row.model_code}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{ 
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        maxWidth: "8em"
                                                                    }}
                                                                >{row.provided_model_name}</TableCell>

                                                                <Tooltip title={rowTypeWithSpace}
                                                                    placement="bottom"
                                                                    arrow
                                                                    followCursor
                                                                >
                                                                    <TableCell
                                                                        sx={{ 
                                                                            whiteSpace: "nowrap",
                                                                            textOverflow: "ellipsis",
                                                                            overflow: "hidden",
                                                                            maxWidth: "8em"
                                                                        }}
                                                                    >
                                                                        
                                                                                {rowTypeWithSpace}
                                                                    </TableCell>
                                                                </Tooltip>

                                                                <TableCell
                                                                    sx={{ 
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        maxWidth: "8em"
                                                                    }}
                                                                >
                                                                    {
                                                                        row.manufacturersPopulated.map((manu, index) => {
                                                                            
                                                                            return manusChosen.includes(manu._id) && (
                                                                                <Tooltip title={manu.provided_name}
                                                                                    key={index}
                                                                                    placement="bottom"
                                                                                    arrow
                                                                                    followCursor
                                                                                >
                                                                                    <div key={index}>{manu.provided_name}</div>
                                                                                </Tooltip>
                                                                            )
                                                                        })
                                                                    }
                                                                </TableCell>
                                                                
                                                                <TableCell
                                                                    size="small"
                                                                    sx={{ 
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        width: "3em"
                                                                    }}
                                                                >
                                                                    {/* view btn */}
                                                                    <ShowBtn 
                                                                        handleView={handleView}
                                                                        row={row}
                                                                        code={row.model_code}
                                                                        key={row._id}
                                                                    />

                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}

                                                    {/* fill up the empty dataModel for last page's items of the list */}
                                                    {emptyRows > 0 && (
                                                        <TableRow
                                                            style={{
                                                                height: 60 * emptyRows,
                                                            }}
                                                        >
                                                            <TableCell colSpan={6} />
                                                        </TableRow>
                                                    )}

                                                </TableBody>
                                            </>
                                        )
                                    }
                                </Table>
                            </TableContainer>

                            {!isLoading && dataModel?.length === 0 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        <h1 className='my-72 text-2xl'>
                                            {t("No L/E Model")}
                                        </h1>
                                    </div>
                                </Box>
                            )}

                            {
                                (!isLoading && dataModel?.length > 0) && (
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                )
                            }
                        </Paper>
                    </Box>
                ) 
            }
        
            {
                    showView && modelPerspectiveShowData && (
                        <>
                            <BackBtn 
                                sx={{
                                    border: '1px solid #2A598F',
                                }}
                                onClick={() => setShowView(false)}
                            >
                                {t("Back")}
                            </BackBtn>
                            <ModelPerspectiveShow 
                                modelPerspectiveShowData={modelPerspectiveShowData}
                                ruResult={ruResult}
                                handleGetPDFFile={handleGetPDFFile}
                            />
                        </>
                    )
            }
        </Container>
        
    );
}
