
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useNavigate, useLocation } from 'react-router-dom';

// components
import SummaryModelPerspective from './perspectives/ModelPerspective/index.jsx';
import SummaryComponentPerspective from './perspectives/ComponentPerspective/index.jsx';



// redux
import { useSelector } from 'react-redux';

import { useTranslate } from 'react-admin';
export default function CompSummary({model_manu_sc_id_arr, sc_id_arr, certsChosen, certsChosenForScPerspective}) {
    const location = useLocation();
    const translate = useTranslate();

    const optionStore = useSelector((state) => state.option.value);
    const option = structuredClone(optionStore);
    const [ perspective, setPerspective ] = useState(null);

    useEffect(() => {
        if(option){
            setPerspective(option);
        }
    }, [option])

    useEffect(() => {
        if(location?.state?.dataType === "duplicate"){
            if(model_manu_sc_id_arr?.length > 0)
                setPerspective("models");
            else if(sc_id_arr?.length > 0)
                setPerspective("components");
        }
    }, [location?.state?.dataType])

    if(perspective === null){
        return null
    }

    const handleChange = (event, newValue) => {
        setPerspective(newValue);
    };

    return (
        <Container
            maxWidth="xl"
            className='mt-6'
        >
            <Box
                className="w-full h-12 text-center"
            >
                <p className='font-bold text-xl font-sans'>
                    {translate("phase2.createRu.summary")}
                </p>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-around"}}>

                <Tabs
                    value={perspective}
                    onChange={handleChange}
                    aria-label="wrapped label tabs example"
                >
                    <Tab value="models" label={translate("phase2.createRu.Model")}
                        sx={{
                            width: "15rem",
                        }}/>
                    <Tab value="components" label={translate("phase2.createRu.Component")}
                        sx={{
                            width: "15rem",
                        }}
                    />
                </Tabs>
            </Box>
    

            <Container maxWidth="xl">

            {
                perspective === "models" && (
                <SummaryModelPerspective 
                    model_manu_sc_id_arr={model_manu_sc_id_arr}
                    certsChosen={certsChosen}
                />
                )
            }
            {
                perspective === "components" && (
                <SummaryComponentPerspective 
                    sc_id_arr={sc_id_arr}
                    certsChosenForScPerspective={certsChosenForScPerspective}
                />
                )
            }
            </Container>
        </Container>
    );
}