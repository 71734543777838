import { HashRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { Admin, Resource, CustomRoutes } from "react-admin";
import React, { useEffect } from "react";
import { QueryClient } from "react-query";

// mui
// import { CssBaseline } from '@mui/material';

// pages
import { UserCreate, UserShow, UserList, UserEdit } from "./pages/users";
import {
  ApplicationEdit,
  ApplicationList,
  ApplicationCreate,
  ApplicationShow,
} from "./pages/applications";
import AiValidating from "./pages/AiValidating";
import Dashboard from "./pages/Dashboard";
import Overview from "./pages/Overview";
import { ActionList } from "./pages/actions";
import PageRestrictedUrls from "./pages/PageRestrictedUrls/index";
import PageCreateRestrictedUrl from "./pages/PageRestrictedUrls/PageCreateRestrictedUrl";
import PageRuInfoShow from "./pages/PageRestrictedUrls/PageRuInfoShow";

// components
import {
  ShowRevokeApplication,
  CreateRevokeApplication,
  EditRevokeApplication,
} from "./components/revoke/revokeApplication";
import { CustomLayout } from "./components/layout/Layout";

// utils
import authProvider from "./utils/authProvider";
import dataProvider from "./utils/dataProvider";

import {
  ManufacturerEdit,
  ManufacturerList,
  ManufacturerShow,
} from "./pages/manufacturers";
import { ModelList, ModelShow } from "./pages/models";
import { ComponentList, ComponentShow } from "./pages/components";
import { RCList, RCShow, RCCreate, RCEdit } from "./pages/rcs";
import { TeamShow, TeamList, TeamCreate, TeamEdit } from "./pages/teams";
import theme from "./styles/theme";
import LoginPage from "./pages/login";
import Footer from "./components/layout/Footer";
import UserProfile from "./components/profile/UserProfile";
import FooterNotices from "./pages/FooterNotices";
import Mobile from "./components/mobile/Mobile";
import AllLogs from "./components/logs/AllLogs";
import RequireAuth from "./utils/RequireAuth";
import RecordManagement from "./pages/recordManagement/RecordManagement";
import RecordCreate from "./pages/recordManagement/RecordCreate";
import RecordEdit from "./pages/recordManagement/RecordEdit";
import ScRecordEdit from "./pages/recordManagement/ScRecordEdit";
import { RecordLogEdit } from "./pages/recordManagement/RecordLogEdit";
import Reports from "./components/reports/Reports";

import i18nProvider from "./utils/i18n/i18nProvider";
import ViewTextFile from "./components/licence/ViewTextFile";
// import { Buffer } from "buffer";

// import { v4 as uuid } from "uuid";
// const nonce = Buffer.from(uuid(), 'base64');
// let uuidNounce = uuid()

// generate hashes for nonce
// const sha256 = require('crypto-js/sha256');
// const hashScript = sha256(nonce.toString()).toString();
// const hashStyle = sha256(uuidNounce).toString();

const App = () => {
  // window.__CSP_NONCE__ = hashStyle

  // const globalStyles = `
  //   [data-mui-styles] {
  //     nonce: '${window.__CSP_NONCE__ ?? 'hash_placeholder'}';
  //   }
  // `

  useEffect(() => {
    const portal = window.location.pathname === "/lift_rc" ? "RC" : "EMSD";
    document.title = `TAPAS ${portal}`;
  }, []);

  const userInfo = useSelector((state) => state.userInfo.value);
  //Prevent refetching data on window focus, which caused re-render
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <HashRouter>
      {/* Wrap with HashRouter to support Link for the footer, replacing Admin's router */}
      <Admin
        //dashboard denote the page that has the path "/"
        dashboard={
          window.location.pathname === "/lift_emsd" ? Dashboard : Overview
        }
        queryClient={queryClient}
        theme={theme}
        layout={CustomLayout}
        i18nProvider={i18nProvider}

        authProvider={authProvider}
        dataProvider={dataProvider}
        loginPage={LoginPage}
        title="" //rendered in error page, otherwise it will render React Admin
      >
        {/* <style>{globalStyles}</style> */}
        <Resource
          name="users"
          list={<UserList userInfo={userInfo} />}
          show={UserShow}
          create={
            window.location.pathname === "/lift_rc" &&
              userInfo?.role === "RCAdmin"
              ? UserCreate
              : window.location.pathname === "/lift_emsd"
                ? UserCreate
                : null
          }
          edit={
            ((window.location.pathname === "/lift_rc" &&
              userInfo?.role === "RCAdmin") ||
              userInfo?.role === "TapasAdmin") &&
            UserEdit
          }
        />
        <Resource
          name="applications"
          list={ApplicationList}
          edit={ApplicationEdit} //comment out this line if you want to disable editing for EMSD build
          show={ApplicationShow}
          options={{ noEdit: true }}
        />

        <Resource
          name="revoke"
          list={ShowRevokeApplication}
          edit={EditRevokeApplication}
          create={
            window.location.pathname === "/lift_emsd" &&
            userInfo?.role !== "Inspector" &&
            CreateRevokeApplication
          }
          show={ApplicationShow}
          options={{ noEdit: true }}
        />
        <Resource
          name="v2/applications"
          list={ApplicationList}
          show={ApplicationShow}
          options={{ noEdit: true }}
        />

        <Resource
          name="manufacturers"
          list={ManufacturerList}
          edit={window.location.pathname === "/lift_emsd" && ManufacturerEdit}
          show={ManufacturerShow}
        />
        <Resource name="models" list={ModelList} show={ModelShow} />
        <Resource name="components" list={ComponentList} show={ComponentShow} />
        <Resource
          name="rcs"
          list={window.location.pathname === "/lift_emsd" && RCList}
          show={RCShow}
          create={RCCreate}
          edit={RCEdit}
        />

        <Resource
          name="team"
          list={window.location.pathname === "/lift_emsd" && TeamList}
          show={TeamShow}
          create={TeamCreate}
          edit={TeamEdit}
        />

        <CustomRoutes>
          <Route
            path="/applications_new/"
            element={<ApplicationCreate resource="applications" mode="new" />}
          />
          <Route
            path="/applications_new/:id"
            element={<ApplicationCreate resource="applications" mode="new" />}
          />
          <Route path="/applications_validating/" element={<AiValidating />} />
          {/*manufacturer name change */}
          <Route
            path="/applications_a/:id"
            element={
              <ApplicationEdit resource="applications" mode={{ mode: "a" }} />
            }
          />
          {/*model cert update */}
          <Route
            path="/applications_b/:id"
            element={
              <ApplicationEdit resource="applications" mode={{ mode: "b" }} />
            }
          />
          {/* model range extension */}
          <Route
            path="/applications_c/:id"
            element={
              <ApplicationEdit resource="applications" mode={{ mode: "c" }} />
            }
          />
          {/*update sc certificate */}
          <Route
            path="/applications_d/:id"
            element={
              <ApplicationEdit resource="applications" mode={{ mode: "d" }} />
            }
          />
          {/* sc range extension*/}
          <Route
            path="/applications_e/:id"
            element={
              <ApplicationEdit resource="applications" mode={{ mode: "e" }} />
            }
          />
          {/*new sc from new manufacturer */}
          <Route
            path="/applications_f/:id"
            element={
              <ApplicationEdit resource="applications" mode={{ mode: "f" }} />
            }
          />

          {/* Restricted Url Mgt */}
          {/* show model list for rc to select: create stage 1 */}
          <Route
            path="/createRestrictedUrl"
            element={<PageCreateRestrictedUrl />}
          />
          {/* <Route
            path="/createRestrictedUrl/models/:id/model"
            element={<PageCreateRestrictedUrl />}
          /> */}
          <Route path="/ruInfoShow/:ru_id" element={<PageRuInfoShow />} />
          <Route path="/restrictedUrls" element={<PageRestrictedUrls />} />

          {/* show logs on EMSD only */}
          <Route element={<RequireAuth />}>
            <Route path="/logs" element={<AllLogs />} />
            <Route path="/report" element={<Reports />} />
          </Route>
          <Route path="/revoke" element={<ShowRevokeApplication />} />
          <Route path="/configuration" element={<UserProfile />} />
          <Route path="/record_management" element={<RecordManagement />} />
          <Route path="/record_management/new" element={<RecordCreate />} />
          <Route path="/records/list/logs/:id" element={<RecordLogEdit />} />
          {["a", "b", "c", "d", "e", "f", "record"].map((mode) => (
            <Route
              key={mode}
              path={`/record_management/applications_${mode}/:id`}
              element={<RecordEdit resource="applications" mode={mode} />}
            />
          ))}
          <Route
            path="/record_management/component/:id"
            element={<ScRecordEdit resource="applications" />}
          />
        </CustomRoutes>

        <CustomRoutes noLayout>
          <Route path="/login/:route" element={<LoginPage />} />
          <Route path="/footer/:route" element={<FooterNotices />} />
          <Route path="/mobile" element={<Mobile />} />
          <Route path="/licence" element={<ViewTextFile />} />
        </CustomRoutes>

        <Resource name="actions" list={ActionList} />
      </Admin>

      <Footer />
    </HashRouter>
  );
};

export default App;
