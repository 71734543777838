import React, { useEffect, useRef } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const CompLogin = ({pwd, setPwd, handleSubmit, error, emsdLogo}) => {
  const passwordInputRef = useRef(null);
  const { t } = useTranslation("pages", { keyPrefix: "typeApprovalRecord" });
  useEffect(() => {
    passwordInputRef.current.focus();
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={emsdLogo} style={{ width: "200px", marginBottom: '15px' }} alt="EMSD logo" />
      <TextField
        type="password"
        label={t("Please enter token")}
        value={pwd}
        onChange={(e) => setPwd(e.target.value)}
        onKeyDown={handleKeyPress}
        sx={{ '& .MuiInput-underline:before': { borderBottom: 'none' }}}
        inputRef={passwordInputRef}
      />
      <Button variant="contained" sx={{ marginTop: '15px' }} onClick={handleSubmit}>
        {t("enter")}
      </Button>
      {error && <Typography color="error" sx={{ marginTop: '15px' }}>{error}</Typography>}
    </div>
  );
}

export default CompLogin