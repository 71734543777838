import { useEffect, useState, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { visuallyHidden } from '@mui/utils';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslate } from 'react-admin';
import {
    Typography
} from "@mui/material";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';

// components
import EnhancedTableHead from "../CompEnhancedTable/ComponentListTable/EnhancedTableHead.jsx";
import EnhancedTableToolbar from "../CompEnhancedTable/ComponentListTable/EnhancedTableToolbar.jsx";
import InputSearchBar from "../CompEnhancedTable/ComponentListTable/InputSearchBar"

// mui tree view
import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

// _hoc
import BasicModal from '../../../../_hoc/modals/BasicModal.jsx';

import {isEmpty} from 'lodash';

// custom hooks
import {getComponentList} from "../../../../admin/useHooks/useComponents/index.jsx";
import {useNavigate} from 'react-router-dom'

// CONSTANTS
import {headCells} from '../constants/headCells.js'

// redux
import { useSelector, useDispatch } from "react-redux";
import { setStage } from '../../../../admin/redux/stageSlice.js';
import { ShowFileBtn } from '../../../../_hoc/Buttons/ShowFileBtn.jsx';
import { ShowBtn } from '../../../../_hoc/Buttons/ShowBtn.jsx';


const BackBtn = styled(Button)(({ theme }) => ({
    color: "#434343",
    backgroundColor: "white",
    '&:hover': {
        color: "white",
        backgroundColor: "#434343",
    },
    variant:"outlined"
}));

export default function CreateStage2ComponentList({setNextBtnDisabled, nextBtnDisabled, 
    dataComponents, setDataComponents, 
    setSc_id_arr, sc_id_arr, setCertsChosenForScPerspective, certsChosenForScPerspective,
    handleGetPDFFile
}) {
    const translate = useTranslate();
    const [tableHeadCells, setTableHeadCells] = useState([])

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('client_user');

    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [isLoading, setIsLoading] = useState(true);
    const [openLoadingModal, setOpenLoadingModal] = useState(true)
    const [openBackOptionsModal, setOpenBackOptionsModal] = useState(false)

    const [totalCount, setTotalCount] = useState(0)
    const [range, setRange] = useState(`[0, 9]`)
    const [filter, setFilter] = useState(`{}`)
    const [sort, setSort] = useState(`["_id", "DESC"]`)

    const [selectedTec, setSelectedTec] = useState([])
    const [selectedIso, setSelectedIso] = useState([])

    const [tecs, setTecs] = useState([])
    const [isos, setIsos] = useState([])

    const [isExistingSummary, setIsExistingSummary] = useState(false)

    // useNavigate
    const navigate = useNavigate();

    // redux
    const dispatch = useDispatch();
    const stageStore = useSelector((state) => state.stage.value);
    const stage = structuredClone(stageStore);
    const optionStore = useSelector((state) => state.option.value);
    const option = structuredClone(optionStore);
    const ruPageDataStore = useSelector((state) => state.ruPageData.value);
    const ruPageData = structuredClone(ruPageDataStore);
    
    useEffect(() => {
        let checked = ruPageData?.model_manu_sc_id_arr?.length ||
        ruPageData?.sc_id_arr?.length
        setIsExistingSummary(checked)
    }, [])

    useEffect(() => {
        if(option === "components"){
            if(stage === "2_1"){
                setTableHeadCells(headCells.components)
            }
        }

        setSelected([])
        setSelectedTec([])
        setSelectedIso([])
        setTecs(dataComponents.flatMap((n) => n.type_test_certificate_id).filter(v=> !!v === true))
        setIsos(dataComponents.flatMap((n) => {
            if(n.iso_certificatePopulated && n.iso_certificatePopulated._id){
                return n.iso_certificatePopulated._id
            } else {
                return undefined
            }
        }).filter(v => v !== undefined))

    }, [stage])

    // setRange when page changes
    useEffect(() => {
        setRange(`[${page * rowsPerPage},${page * rowsPerPage + rowsPerPage - 1}]`)
    }, [page, rowsPerPage])

    // setSort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort(`["${property}","${isAsc ? "DESC" : "ASC"}"]`)
    };


    // handle query value update
    useEffect(() => {
        if(option === "components" && stage === "2_1"){
            // getComponentList
            getComponentList(filter, range, sort).then((res) => {
                    setDataComponents(res?.data)
                    setTotalCount( + res?.headers["x-total-count"] || 0)
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [filter, range, sort])


    useEffect(() => {
        if (dataComponents.length === 0 && isLoading === false) {
            setRange(`[0,9]`)
            setSort(`{"_id": "ASC"}`)
            setPage(0)
        }
        if(isLoading === false) {
            setOpenLoadingModal(false)
        } else {
            setOpenLoadingModal(true)
        }

    }, [dataComponents, isLoading])


    // seletAll rows
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = dataComponents.map((n) => n._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleSelectAllCertsClick = (event) => {
        if (event.target.checked) {
            const newSelectedTec = dataComponents.flatMap((n) => n.type_test_certificate_id)
            setSelectedTec(newSelectedTec);
            const newSelectedIso = dataComponents.flatMap((n) => n.iso_certificatePopulated._id)
            setSelectedIso(newSelectedIso)
            return;
        }
        setSelectedTec([]);
        setSelectedIso([]);
    }

    // select one
    const handleClick = (event, _id) => {
        const selectedIndex = selected.indexOf(_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };


    const handleDocOptionClick = (event, cert_type, cert_id, cert_ids) => {
        if(cert_type === "tec"){
            let selectedIndex = selectedTec.indexOf(cert_id);
            let newSelected = [];
    
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedTec, cert_id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedTec.slice(1));
            } else if (selectedIndex === selectedTec?.length - 1) {
                newSelected = newSelected.concat(selectedTec.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedTec.slice(0, selectedIndex),
                    selectedTec.slice(selectedIndex + 1),
                );
            }
            setSelectedTec(newSelected);
        } else if(cert_type === "iso"){
            const selectedIndex = selectedIso.indexOf(cert_id);
            let newSelected = [];
    
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedIso, cert_id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedIso.slice(1));
            } else if (selectedIndex === selectedIso?.length - 1) {
                newSelected = newSelected.concat(selectedIso.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedIso.slice(0, selectedIndex),
                    selectedIso.slice(selectedIndex + 1),
                );
            }
            setSelectedIso(newSelected);
        }
    };

    // setPage
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // set rowsPerPage
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;
    const isTecOptionSelectedFn = (id) => selectedTec.indexOf(id) !== -1;
    const isIsoOptionSelectedFn = (id) => selectedIso.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, rowsPerPage - dataComponents?.length) : 0;


    // row btn handlers
    const handleView = (rowInfo) => {
        // navigate(`/models/${rowInfo._id}/show`);
        window.open(`lift_rc#/components/${rowInfo._id}/show`, '_blank');
    }

    useEffect(() => {
        if(selected.length > 0){
            setNextBtnDisabled(false)
        } else {
            setNextBtnDisabled(true)
        }
        setSc_id_arr(selected)
    }, [selected, setNextBtnDisabled, setSc_id_arr])

    useEffect(() => {
        setCertsChosenForScPerspective({
            tec: selectedTec,
            iso: selectedIso
        })
    }, [selectedTec, selectedIso, setCertsChosenForScPerspective])

    const handleClearAll = () =>{
        setSelected([])
        setSelectedTec([])
        setSelectedIso([])
    }

    const handleSelectAllFoundItemsInAllPages = () => {
        if(selected.length > 0 || selectedTec.length > 0){
            setSelected([])
            setSelectedTec([])
            setSelectedIso([])
        } else {
            setIsLoading(true)
            setOpenLoadingModal(true)
            getComponentList(filter, `[0,9999999]`, sort).then((res) => {
                setSelected(res?.data.map((n) => n._id))
                setSelectedTec(res?.data?.flatMap(v => v.type_test_certificate_id))
                setSelectedIso(res?.data?.map(v => v.iso_certificatePopulated._id))
            }).catch((err) => {
            }).finally(() => {
                setIsLoading(false)
                setOpenLoadingModal(false)
            })
        }
    }

    return (
        <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
            {/* Backdrop */}
            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoadingModal}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }

            {/* menu options for the table */}
            <Box
                component={"div"}
                sx={{
                    height: "5em",
                    display: "flex",
                    justifyContent: "space-between",
                }}
                aria-label="menu for the table"
            >
				{/* BackBtn */}
				<Box
                    component={"div"}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                    aria-label="Back button and Help Icon"
                >
                    <BackBtn 
                        onClick={() => setOpenBackOptionsModal(true)}
                        size="small"
                        sx={{
                            mb: 6,
                            border: "1px solid #434343",
                        }}
                    >
                        {translate("button.back")}
                    </BackBtn>
                </Box>

				{
					(selected.length <= 0) && (
						<Button
							onClick={handleSelectAllFoundItemsInAllPages}
							aria-label='select all found items'
							variant="contained"
							color="primary"
							size="small"
							sx={{
								float: "right",
								mb: 6
							}}
						>
							{translate("phase2.createRu.selectAllFoundItemsInAllPages")}
						</Button>
					)
				}

				{
					selected.length > 0 && (
                        <>
                            <Typography
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    mr: 2
                                }}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                            >
                                <Typography
                                    bgcolor="#648BC5"
                                    component={"span"}
                                    height={"1.5em"}
                                    className='rounded text-white font-weight-bold text-center px-4'
                                >
                                    {selected.length} {translate("phase2.ru.enhancedTableToolbar.numSelected")}
                                </Typography>
                            </Typography>
                            <Button
                                onClick={handleSelectAllFoundItemsInAllPages}
                                aria-label='select all found items'
                                variant="outlined"
                                color="primary"
                                size="small"
                                sx={{
                                    float: "right",
                                    mb: 6
                                }}
                            >
                                {translate("phase2.createRu.deselect")}
                            </Button>
                        </>
					)
				}
			</Box>
 
            <Box sx={{ 
                    width: '100%',
                    boxShadow: "0 0 0 2rem rgba(246,248,249)",
                }}
            >
                <Paper sx={{ width: '100%', mb: 2, overflowX: "auto" }}>
                    <InputSearchBar
                        selected={selected}
                        numSelected={selected.length}
                        setFilter={setFilter}
                        handleClearAll={handleClearAll}
                    />
                                        {
                        (selected.length <= 0 || selected.every(v => v === undefined ||  v === null || v === "")) && !isLoading && (
                            <div className='text-red-500 text-center text-2xl my-3'>
                                {translate("phase2.createRu.selectAtLeastOne") + " " + translate("phase2.createRu.component")}
                            </div>
                        )
                    }
                    {
                        !(selected.length <= 0 || selected.every(v => v === undefined ||  v === null || v === ""))  && (
                            <div className='text-red-500 text-center text-2xl my-3 invisible h-8'>
                            </div>
                        )
                    }
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size='medium'
                        >

                            {
                                !isLoading && dataComponents?.length > 0 &&
                                (
                                    <>
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={dataComponents?.length}
                                            tableHeadCells={tableHeadCells}
                                            numCertsSelected={selectedTec.length + selectedIso.length}
                                            certsCount={tecs?.length + isos?.length}
                                            onSelectAllCertsClick={handleSelectAllCertsClick}
                                        />
                                        <TableBody>
                                            {dataComponents?.length > 0 && dataComponents.map((row, index) => {
                                                const isItemSelected = isSelected(row._id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                let tecIds = row.type_test_certificate_id
                                                let tecs = row.tecPopulated
                                                let isoId = row?.iso_certificatePopulated?._id
                                                const isIsoOptionSelected = isIsoOptionSelectedFn(isoId);

                                                return (
                                                    <TableRow
                                                        key={row._id}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                                onClick={(event) => handleClick(event, row._id)}
                                                                sx={{ cursor: 'pointer' }}
                                                                aria-checked={isItemSelected}
                                                            />
                                                            <Box component="span" id={labelId} sx={visuallyHidden}>
                                                                {isItemSelected ? "selected" : "not yet selected"}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            onClick={(event) => handleClick(event, row._id)}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            {row.component_code}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ 
                                                                cursor: 'pointer', 
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                maxWidth: "8em"
                                                            }}
                                                            onClick={(event) => handleClick(event, row._id)}
                                                        >{row.provided_comp_name}</TableCell>
                                                        <TableCell
                                                            sx={{ 
                                                                cursor: 'pointer', 
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                maxWidth: "8em"
                                                            }}
                                                            onClick={(event) => handleClick(event, row._id)}
                                                        >{row.type}</TableCell>

                                                        <TableCell>
                                                                        <TreeView
                                                                            sx={{
                                                                                flexGrow: 1,
                                                                                overflowY: 'auto',
                                                                            }}
                                                                            aria-label="file system navigator"
                                                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                                                            defaultExpandIcon={<ChevronRightIcon />}
                                                                        >
                                                                            <TreeItem nodeId="1" label="TEC">
                                                                                <ol>
                                                                                    {
                                                                                        !isEmpty(tecs) && tecs.length > 0 && tecs.map((tec, i) => {
                                                                                            const isTecOptionSelected = isTecOptionSelectedFn(tec._id);
                                                                                            return (
                                                                                                <li
                                                                                                    key={`${i}-${tec._id}`}
                                                                                                >
                                                                                                    <FormControlLabel
                                                                                                        label="tec"
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                color="primary"
                                                                                                                checked={isTecOptionSelected}
                                                                                                                inputProps={{
                                                                                                                    'aria-labelledby': labelId,
                                                                                                                }}
                                                                                                                onClick={(event) => handleDocOptionClick(event, 'tec', tec._id)}
                                                                                                                sx={{ cursor: 'pointer' }}
                                                                                                                aria-checked={isTecOptionSelected}
                                                                                                            />
                                                                                                        }
                                                                                                    >
                                                                                                        <Box component="span" id={labelId} sx={visuallyHidden}>
                                                                                                            {/* {isDocOptionSelectedFn ? "selected" : "not yet selected"} */}
                                                                                                        </Box>
                                                                                                    </FormControlLabel>
                                                                                                    <Box component="span" id={labelId}>
                                                                                                        {
                                                                                                            tec?.cert_type_metadata?.tec_certificate_number || "Not Available"
                                                                                                        }
                                                                                                    </Box>
                                                                                                    <ShowFileBtn
                                                                                                        handleGetPDFFile={handleGetPDFFile}
                                                                                                        id={tec._id}
                                                                                                    />
                                                                                                </li>
                                                                                            ) 
                                                                                        })
                                                                                    }
                                                                                </ol>
                                                                            </TreeItem>
                                                                            
                                                                            <TreeItem nodeId="2" label="ISO">
                                                                                <FormControlLabel
                                                                                    label="iso"
                                                                                    control={
                                                                                        <Checkbox
                                                                                            color="primary"
                                                                                            checked={isIsoOptionSelected}
                                                                                            inputProps={{
                                                                                                'aria-labelledby': labelId,
                                                                                            }}
                                                                                            onClick={(event) => handleDocOptionClick(event, 'iso', isoId)}
                                                                                            sx={{ cursor: 'pointer' }}
                                                                                            aria-checked={isIsoOptionSelected}
                                                                                        />
                                                                                    }
                                                                                >
                                                                                    <Box component="span" id={labelId} sx={visuallyHidden}>
                                                                                        {/* {isDocOptionSelectedFn ? "selected" : "not yet selected"} */}
                                                                                    </Box>
                                                                                </FormControlLabel>
                                                                                <Box component="span" id={labelId}>
                                                                                    {
                                                                                        row?.iso_certificatePopulated?.cert_type_metadata?.iso_cert_number || "Not Available" 
                                                                                    }
                                                                                </Box>
                                                                                <ShowFileBtn
                                                                                    handleGetPDFFile={handleGetPDFFile}
                                                                                    id={isoId}
                                                                                />
                                                                            </TreeItem>
                                                                        </TreeView>


                                                        </TableCell>

                                                        <TableCell
                                                            size="small"
                                                        >
                                                            <ShowBtn
                                                                handleView={handleView}
                                                                row={row}
                                                                code={row?.component_code}
                                                                size='small'
                                                            />
                                                            

                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}

                                            {/* fill up the empty dataModel for last page's items of the list */}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    className={`h-20`}
                                                >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}

                                        </TableBody>
                                    </>
                                )
                            }
                        </Table>
                    </TableContainer>

                    {!isLoading && dataComponents?.length === 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <h1>
                                    {translate("phase2.ru.noRuFound")}
                                </h1>
                            </div>
                        </Box>
                    )}

                    {
                        !isLoading && dataComponents?.length > 0 && (
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )
                    }
                </Paper>
            </Box>

            {/* Back option modal */}
            <BasicModal open={openBackOptionsModal} setOpen={setOpenBackOptionsModal}>
                <Box className='flex flex-col gap-3'
                    component={"div"}
                >
                    <Button
                        onClick={
                            () => navigate(`/restrictedUrls`)
                        }
                    >
                        {translate("phase2.createRu.gotoUrlTable")}
                        </Button>
                    <Button
                        onClick={() => {
                            dispatch(setStage("1"))
                        }}
                    >
                        {translate("phase2.createRu.gotoPerspectiveOptions")}
                        </Button>
                    <Button
                        onClick={() => {
                            dispatch(setStage("more options?"))
                        }}
                        disabled={
                            // make it object format and check if they both empty
                            !isExistingSummary
                        }
                    >
                        {`${translate("phase2.createRu.gotoSummary")} ( ${isExistingSummary ? translate("phase2.createRu.reviewSelectedOptions") : translate("phase2.createRu.nothingToReview") }  )`}
                        </Button>
                </Box>
            </BasicModal>
        </Container>
    );
}