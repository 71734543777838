import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionActions,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tabs,
  TextField,
  Tab,
  Grid,
} from "@mui/material";
import { Add, Close, Delete } from "@mui/icons-material";
import { ModelFields } from "./ModelFields";
import { useCreate, useNotify } from "react-admin";
import keyToField from "../../utils/keyToField";
import { PatternFormat } from "react-number-format";
import ModelExtraDetail from "./ModelExtraDetail";
import ModelNameInput from "./ModelNameInput";
import NameEditor from "../../utils/applications/NameEditor";
import { confirmAlert } from "react-confirm-alert";
import autoSave from "../../utils/applications/autoSave";
import RangeExtensionCheckbox from "../update/RangeExtensionCheckbox";
import ModelForm from "./ModelForm";

// this layer you add and remove model objects inside model array (which trigger + and - of tabs)
// within the file stack layer, you add manu_files inside each tab
export const ModelDisplay = (props) => {
  const {
    show,
    showOne,
    edit,
    add,
    isValidated,
    title,
    uploadCopy,
    upload,
    setUpload,
    update,
    setUpdate,
    template,
    existing,
    nav,
    filenames,
    userInfo_Role,
  } = props;


  const [create, { isLoading: uploadingFile }] = useCreate();

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [tab, setTab] = useState(0);

  const notify = useNotify();

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const templateDeterminant = (type) => {
    switch (type) {
      case "InclinedLift": {
        return template.model_l;
      }
      case "ElectricTractionLiftMachineRoom": {
        return template.model_l;
      }
      case "ElectricTractionLiftRoomless": {
        return template.model_l;
      }
      case "HydraulicLift": {
        return template.model_l;
      }
      case "StairLift": {
        return template.model_l;
      }
      case "ServiceLift": {
        return template.model_l;
      }
      case "MVPS": {
        return template.model_l;
      }
      case "ElectricalVerticalLiftingPlatform": {
        return template.model_l;
      }
      case "Escalator": {
        return template.model_e;
      }
      case "PassengerConveyor": {
        return template.model_e;
      }
      case "Others": {
        return template.others;
      }
      default: {
        return "";
      }
    }
  };

  const handleChange = async (
    action,
    index,
    key,
    file,
    low_index,
    extra_key,
    extra_value
  ) => {
    if (action === "addFileStack") {
      let change_arr = upload.model;
      change_arr[index].manufacturer_files =
        change_arr[index].manufacturer_files.length > 0
          ? [
              ...change_arr[index].manufacturer_files,
              {
                ...structuredClone(template.model_manufacturer_files),
                [key]: file,
                [extra_key]: extra_value,
              },
            ]
          : [
              {
                ...structuredClone(template.model_manufacturer_files),
                [key]: file,
                [extra_key]: extra_value,
              },
            ];
      const newUpload = { ...upload, model: change_arr };
      setUpload(newUpload);
      const newUpdate = { ...props.update, model: change_arr };
      edit && props.setUpdate(newUpdate);
      autoSave(newUpload, edit ? newUpdate : props.update, edit?.mode);
    }

    if (action === "addModel") {
      let newUpload = {
        ...upload,
        model: [
          ...upload.model,
          {
            type: upload.app_type.type,
            LE_type: templateDeterminant(upload.app_type.type).LE_type,
            manufacturer_files: [],
          },
        ],
      };

      setUpload(newUpload);

      let newUpdate = props.update;
      if (edit) {
        newUpdate = {
          ...props.update,
          model: [
            ...props.update.model,
            {
              type: props.update.app_type.type,
              LE_type: templateDeterminant(upload.app_type.type).LE_type,
              manufacturer_files: [],
            },
          ],
        };

        props.setUpdate(newUpdate);
      }
      autoSave(newUpload, newUpdate, edit?.mode);
    }

    if (action === "addModelLoad") {
      let newUpload = {
        ...upload,
        model: [
          ...upload.model,
          {
            manufacturer_files: [],
            manu_array: value.manu_array || [],
            LE_type: value.LE_type
              ? value.LE_type
              : templateDeterminant(upload.app_type.type).LE_type,
            type: upload.app_type.type,
            provided_model_name: value.provided_model_name,
            model_code: value.model_code,
          },
        ],
      };
      setUpload(newUpload);
      autoSave(newUpload, props.update, edit?.mode);
    }


    if (action === "editName") {
      let newUpload = { ...upload };

      let changedModel = newUpload.model[0];
      changedModel.provided_model_name = value.provided_model_name;
      changedModel.model_code = value.model_code || "";

      //Change model name in components
      // for (let componentType in newUpload.app_type.components) {
      //   let componentsList = newUpload.app_type.components[componentType];
      //   for (let comp of componentsList) {
      //     comp.model_id = value.provided_model_name;
      //   }
      // }

      for (let componentType in newUpload.app_type.components) {
        let componentsList = newUpload.app_type.components[componentType];
        let tempComponentsList = uploadCopy.app_type.components[componentType];
        for (let i = 0; i < componentsList.length; i++) {
          let comp = componentsList[i];
          let tempComp = tempComponentsList[i];
          comp.model_id = value.provided_model_name;
          
          // Set temp_id from temp_upload based on the corresponding model_id
          comp.temp_id = tempComp.model_id;
        }
      }

      setUpload(newUpload);
      autoSave(newUpload, props.update, edit?.mode);
    }



    if (action === "deleteModel") {
      confirmAlert({
        title: "Confirm to delete model",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              let newUpload = {
                ...upload,
                model: [...upload.model.filter((x, i) => i !== index)],
              };

              Object.values(newUpload.app_type.components).map(
                (componentArray, a) => {
                  componentArray.map((componentObj, b) => {
                    if (componentObj.model_id === key.provided_model_name) {
                      newUpload = {
                        ...newUpload,
                        app_type: {
                          ...newUpload.app_type,
                          components: {
                            ...newUpload.app_type.components,
                            [componentObj.type]: newUpload.app_type.components[
                              componentObj.type
                            ].filter((x, i) => i !== b),
                          },
                        },
                      };
                    }
                  });
                }
              );

              setUpload(newUpload);
              let newUpdate = props.update;
              if (edit) {
                newUpdate = {
                  ...props.update,
                  model: [...props.update.model.filter((x, i) => i !== index)],
                };
                props.setUpdate(newUpdate);
              }
              autoSave(newUpload, newUpdate, edit?.mode);
            },
          },
          {
            label: "No",
          },
        ],
      });
    }

    if (action === "deleteFileStack") {
      confirmAlert({
        title: "Confirm to delete manufacturer file",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              let change_arr = upload.model;
              change_arr[index].manufacturer_files = change_arr[
                index
              ].manufacturer_files.filter((x, i) => i !== low_index);
              const newUpload = { ...upload, model: change_arr };
              setUpload(newUpload);
              const newUpdate = { ...props.update, model: change_arr };
              edit && props.setUpdate(newUpdate);
              autoSave(newUpload, edit ? newUpdate : props.update, edit?.mode);
            },
          },
          {
            label: "No",
          },
        ],
      });
    }
    if (action === "editText") {
      let change_arr = upload.model;
      change_arr[index].manufacturer_files[low_index][key] = file;
      let newUpload = { ...upload, model: change_arr };
      setUpload(newUpload);
      let newUpdate = { ...props.update, model: change_arr };
      edit && props.setUpdate(newUpdate);
      autoSave(newUpload, edit ? newUpdate : props.update, edit?.mode);
    }

    if (action === "addFile") {
      const fileSize = file.reduce((acc, curr) => acc + curr.size, 0);
      if (fileSize > 104857600) {
        notify("Error: File size should be less than 100MB");
        return;
      }
      create(
        "files",
        {
          data: file,
          meta: {
            file_code: upload.file_code ?? props.update.file_code,
          },
        },
        {
          onSuccess: ({ id, data }) => {
            let change_arr = upload.model;
            let manufacturer_files =
              change_arr[index].manufacturer_files[low_index];
            let field = manufacturer_files[key];
            //Only save the recently uploaded file, filter all old one
            //only for update application (edit.mode)
            if (Array.isArray(field))
              manufacturer_files[key] = field
                .filter((file) => (edit.mode ? file.id : file))
                .concat(data);
            else manufacturer_files[key] = data[0];
            //Cannot exists together
            if (key === "type_test_certificate_id")
              manufacturer_files.full_quality_certificate_id = [];
            if (key === "full_quality_certificate_id")
              manufacturer_files.type_test_certificate_id = [];

            let newUpload = { ...upload, model: change_arr };
            setUpload(newUpload);
            let newUpdate = { ...props.update, model: change_arr };
            edit && props.setUpdate(newUpdate);
            autoSave(newUpload, edit ? newUpdate : props.update, edit?.mode);
          },
          onError: (e) => notify(e.response.data),
        }
      );
    }

    if (action === "deleteFile") {
      let change_arr = upload.model;
      //extra_key is the file index
      change_arr[index].manufacturer_files[low_index][key] = Array.isArray(
        change_arr[index].manufacturer_files[low_index][key]
      )
        ? change_arr[index].manufacturer_files[low_index][key].filter(
            (file, fileIndex) => fileIndex !== extra_key
          )
        : "";
      const newUpload = { ...upload, model: change_arr };
      setUpload(newUpload);
      const newUpdate = { ...props.update, model: change_arr };
      edit && props.setUpdate(newUpdate);
      autoSave(newUpload, edit ? newUpdate : props.update, edit?.mode);
    }
  };

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  // When edit is boolean, it is when the user edit the application such as those with status clarifying.
  // But if edit is an object {mode: ...}, user chose a different type of application under new application
  const allowDeleteModel = add;

  return (
    <>
      <Card sx={{}}>
        <CardHeader title={title} />

        <CardContent
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <>
            {/* clarifying / add new / disAllow more than 1 model */}
            {upload &&
              (["new", "record"].includes(edit.clarifying) || add) &&
              upload?.model.length === 0 && (
                <ModelNameInput
                  mode="add"
                  value={value}
                  setValue={setValue}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  upload={upload}
                  existing={existing}
                  handleChange={handleChange}
                  templateDeterminant={templateDeterminant}
                />
              )}
            {upload &&
              (edit.clarifying === "new" || add) &&
              upload?.model.length > 0 && (
                <NameEditor
                  onClick={() => {
                    setInputValue(upload.model[0].provided_model_name);
                  }}
                  nameInput={
                    <ModelNameInput
                      mode="edit"
                      value={value}
                      setValue={setValue}
                      inputValue={inputValue}
                      setInputValue={setInputValue}
                      upload={upload}
                      existing={existing}
                      handleChange={handleChange}
                      templateDeterminant={templateDeterminant}
                    />
                  }
                />
              )}
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", width: "200px" }}
              >
                <Tabs
                  value={tab}
                  onChange={handleTab}
                  orientation="vertical"
                  aria-label="basic tabs example"
                >
                  {upload &&
                    upload.model?.map((model, i) => {
                      return (
                        <Tab
                          label={
                            model.provided_model_name
                              ? model.provided_model_name
                              : `Model ${i}`
                          }
                          key={`model${i}-tab`}
                          iconPosition="start"
                          icon={
                            allowDeleteModel ? (
                              <Close
                                onClick={() =>
                                  handleChange("deleteModel", i, model)
                                }
                              />
                            ) : (
                              <></>
                            )
                          }
                          {...a11yProps(i)}
                        />
                      );
                    })}
                </Tabs>
              </Box>

              {upload &&
                upload.model?.map((el, i) => {
                  return (
                    <div
                      style={{ display: "contents" }}
                      key={`model${i}-container`}
                    >
                      <ModelTab
                        value={tab}
                        uploadingFile={uploadingFile}
                        templateDeterminant={templateDeterminant}
                        show={show}
                        showOne={showOne}
                        add={add}
                        isValidated={isValidated}
                        edit={edit}
                        existing={existing}
                        template={template}
                        upload={upload}
                        setUpload={setUpload}
                        update={update}
                        setUpdate={setUpdate}
                        modelIndex={i}
                        handleChange={handleChange}
                        nav={nav}
                        filenames={filenames}
                        userInfo_Role={userInfo_Role}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        </CardContent>
      </Card>
    </>
  );
};

function ModelTab(props) {
  const {
    children,
    existing,
    templateDeterminant,
    template,
    upload,
    setUpload,
    update,
    setUpdate,
    modelIndex,
    handleChange,
    value,
    add,
    isValidated,
    edit,
    show,
    showOne,
    nav,
    uploadingFile,
    filenames,
    userInfo_Role,
    ...other
  } = props;

  const [select, setSelect] = useState("");
  const allowDeleteModelFiles =
    add || ["new", "record"].includes(edit.clarifying);

  const modelFields = Object.keys(
    templateDeterminant(
      upload?.app_type?.type ?? upload?.model?.[modelIndex]?.type
    )
  )?.filter(
    (fieldname) =>
      fieldname !== "manufacturer_files" &&
      fieldname !== "LE_type" &&
      fieldname !== "provided_model_name"
  );

  return (
    <div
      role="tabpanel"
      hidden={value !== modelIndex}
      id={`simple-tabpanel-${modelIndex}`}
      aria-labelledby={`simple-tab-${modelIndex}`}
      className="flex-1 pl-2"
      {...other}
      key={`model${modelIndex}`}
    >
      {add ||
      ["c", "new", "record"].includes(edit.clarifying) ||
      edit.mode === "c" ? (
        <ModelForm
          upload={upload}
          setUpload={setUpload}
          update={update}
          setUpdate={setUpdate}
          add={add}
          edit={edit}
          modelIndex={modelIndex}
          fields={modelFields}
          isForEMSD={false}
        />
      ) : (
        <Grid container spacing={4} sx={{ width: "70%" }}>
          {modelFields.map((fieldname, i) => {
            if (
              // For update - add new SC to LE model, no need to show any fields of the model
              upload.model[modelIndex][fieldname] &&
              !(edit.mode === "f")
            ) {
              return (
                <Grid item xs={6} key={`model${modelIndex}-${fieldname}`}>
                  <Typography>
                    {keyToField[fieldname] ?? fieldname}:{" "}
                    {fieldname === "type"
                      ? keyToField[upload.model[modelIndex].type]
                      : fieldname === "rope_factor"
                      ? upload.model[modelIndex].rope_factor.join(", ")
                      : upload.model[modelIndex][fieldname] ?? "Not assigned"}
                  </Typography>
                </Grid>
              );
            }
          })}
        </Grid>
      )}

      {(edit?.mode || edit?.clarifying) === "c" && (
        <RangeExtensionCheckbox
          upload={upload}
          setUpload={setUpload}
          sx={{ mt: 3 }}
        />
      )}

      {upload?.model[modelIndex]?.manufacturer_files?.length > 0 && (
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          Manufacturer Files{" "}
        </Typography>
      )}

      {upload?.model[modelIndex]?.manufacturer_files?.map((mmf, i) => {
        // const manu = mmf.provided_name ? mmf.provided_name : `Manufacturer 1`;
        const manu = mmf?.provided_name || `Manufacturer 1`;
        const manuFailed =
          add &&
          upload?.aiFeedBack?.length > 0 &&
          upload?.aiFeedBack?.some(
            (item) => item.name === manu && item.field === "manufacturer_id"
          );
        const active = mmf.active;

        return (
          <Accordion
            sx={{ mt: 3 }}
            // defaultExpanded={!userInfo_Role && showOne && active}  // By Default, only expand if role not equal to "EMSDCE","EMSDSE"
            defaultExpanded={!userInfo_Role && !showOne}
            key={`model${modelIndex}-mmf${i}`}
            disabled={showOne && !active} // disabled if it has been revoked
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                backgroundColor: "lightgray",
                ...(add && manuFailed && { backgroundColor: "pink" }),
              }}
            >
              <Typography>
                {/* {mmf.provided_name
                  ? mmf.provided_name
                  : upload?.manufacturer?.filter((x) => x._id === mmf.manufacturer_id)[0].provided_name
                  ? upload?.manufacturer?.filter((x) => x._id === mmf.manufacturer_id)[0].provided_name
                  : upload?.model[modelIndex]?.manufacturersPopulated
                  ? upload?.model[modelIndex]?.manufacturersPopulated?.[i]?.provided_name
                  : `Manufacturer`} */}

                {mmf?.provided_name ||
                  upload?.manufacturer?.find((x) => x._id === mmf?.manufacturer_id)?.provided_name ||
                  (upload?.model[modelIndex]?.manufacturersPopulated?.[i]?.provided_name || `Manufacturer`)
                }

                {show && !active && (
                  <span style={{ color: "red" }}> (Revoked)</span>
                )}

              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ModelFields
                show={show}
                showOne={showOne}
                add={add}
                isValidated={isValidated}
                edit={edit}
                template={template.model_manufacturer_files}
                fileStackObject={mmf}
                handleChange={handleChange}
                modelIndex={modelIndex}
                fileStackIndex={i}
                upload={upload}
                uploadingFile={uploadingFile}
                filenames={filenames}
              />
            </AccordionDetails>
            <Divider orientation="horizontal" flexItem />
            <AccordionActions>
              {allowDeleteModelFiles && (
                <Delete
                  onClick={() =>
                    handleChange("deleteFileStack", modelIndex, "", "", i)
                  }
                />
              )}
            </AccordionActions>
          </Accordion>
        );
      })}

      {(add || ["new", "record"].includes(edit.clarifying)) && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ minWidth: "600px", mt: 4 }}>
            <InputLabel id="loader">
              Add Manufacturer-Specific Files (Minimum of 1)
            </InputLabel>
            <Select
              fullWidth
              labelId="loader"
              id="load"
              value={select}
              label="Manufacturer from Step 2"
              onChange={(e) => {
                if (e.target.value === `existing_navigation`) {
                  nav();
                } else setSelect(e.target.value);
              }}
            >
              {upload?.manufacturer
                ?.filter(
                  (x) =>
                    (x.type === "Both" || x.type === "Model") &&
                    upload.model[modelIndex]["manufacturer_files"].every(
                      (y) =>
                        y.manufacturer_id !== x.provided_name &&
                        y.manufacturer_id !== x._id
                    )
                )
                ?.map((manufacturer, i) => {
                  // Check if the manufacturer is associated with any inactive model
                  const isActiveModel = upload.model?.some((model) =>
                    model.manu_array?.some(
                      (manu) =>
                        manu.manufacturer_code ===
                          manufacturer.manufacturer_code && !manu.active
                    )
                  );

                  // Render the MenuItem only if the manufacturer is not associated with any inactive model
                  if (!isActiveModel) {
                    return (
                      <MenuItem
                        value={manufacturer.provided_name}
                        key={`model${modelIndex}-manufacturer${manufacturer.provided_name}`}
                      >
                        {manufacturer.provided_name}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              <MenuItem value={`existing_navigation`} key={`navigation`}>
                Use Manufacturer From Existing Project
              </MenuItem>
            </Select>
          </FormControl>

          <IconButton
            disabled={!select}
            size="small"
            sx={{ height: "50px", ml: 1, mt: 4 }}
            onClick={() => {
              handleChange(
                "addFileStack",
                modelIndex,
                "provided_name",
                select,
                "",
                "manufacturer_id",
                select
              );

              setSelect("");
            }}
          >
            <Add />
          </IconButton>
        </div>
      )}
      {showOne && <ModelExtraDetail model={upload.model[modelIndex]} />}
    </div>
  );
}
