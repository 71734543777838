import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Card,
  Typography,
  TextField,
} from "@mui/material";
import { useGetList } from "react-admin";
import { useEffect, useState } from "react";
import { useUpdate } from "react-admin";
import { useNavigate } from "react-router-dom";
import { useNotify } from "react-admin";
import { romanize } from "../../utils/romanize";
import keyToField from "../../utils/keyToField";
import DateStringInput from "../../utils/certEditUtils/DateStringInput";
import React from "react";
import CertificateInput from "./CertificateInput";
import failedUpdateValidation from "../../components/update/updateValidation";

function RecordUpdateCheck(props) {
  const navigate = useNavigate();
  const notify = useNotify();
  const { upload, update, setUpload, id, mode } = props;
  const [updateAPI, { isLoading: isUpdating }] = useUpdate();
  const mandatoryFulfilled = !failedUpdateValidation(update, mode, upload);

  const typeDeterminant = (path) => {
    switch (path) {
      case "applications_a": {
        return "Manufacturer Name Change";
      }
      case "applications_b": {
        return "Update lift/escalator model certificate";
      }
      case "applications_c": {
        return "Lift/escalator model range extension";
      }
      case "applications_d": {
        return "Update safety component certificate";
      }
      case "applications_e": {
        return "Safety component range extension";
      }
      case "applications_f": {
        return "Add new safety component";
      }
      default: {
        return "Brand new application";
      }
    }
  };

  const handleUpdate = () => {
    if (!upload?.description) return notify("Please fill in the remark");
    if (!mandatoryFulfilled)
      return notify("Please fill in all mandatory fields");
    updateAPI(
      "records/new",
      {
        id: id,
        data: upload,
        meta: {
          type: typeDeterminant(window.location.hash.split("/")[2]),
          update: update,
        },
      },
      {
        onSuccess: () => (
          navigate(`/applications`), notify("Record successfully edited")
        ),
        onError: (e) => notify(e.response.data),
      }
    );
  };

  return (
    <Card>
      <CardHeader title="Appendix certificates edit" />
      <CardContent>
        <CertificateInput upload={upload} setUpload={setUpload} />

        <Button
          disabled={isUpdating}
          sx={{ mt: 2 }}
          color="primary"
          variant="outlined"
          onClick={handleUpdate}
        >
          Send
        </Button>
      </CardContent>
    </Card>
  );
}

export default RecordUpdateCheck;
