import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Button,
  Pagination
} from "@mui/material";
import keyToField from "../../utils/keyToField";


const selectProps = {
  sx: { mt: 2 },
  fullWidth: true,
  label: "operation",
};


const fields = {
  "Electric Inclined Lift": "InclinedLift",
  "Electric Traction Lift (Machine Room)": "ElectricTractionLiftMachineRoom", 
  "Electric Traction Lift (Machine Roomless)": "ElectricTractionLiftRoomless",
  "Hydraulic Lift": "HydraulicLift",
  "Stairlift": "StairLift",
  "Service Lift": "ServiceLift",
  "Mechanized Vehicle Parking System (MVPS)": "MVPS",
  "Vertical Lifting Platform": "ElectricalVerticalLiftingPlatform",
  "Escalator":"Escalator",
  "Passenger Conveyor":"PassengerConveyor",
  "Others": " Others"
};


const SelectModelsFromManufacturerOrRC = ({ modalsLoad, setUpload, upload }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(modalsLoad?.length);

  const [searchValue, setSearchValue] = useState('');
  const [searchQueryResult, setSearchQueryResult] = useState('');

  const [totalPages, setTotalPages] = useState(Math.ceil(totalItems/ itemsPerPage));

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  let allModels = searchQueryResult !== "" ? searchQueryResult : modalsLoad;
  let sortedModels = allModels && [...allModels].sort((a, b) => a.model_code.localeCompare(b.model_code)); 
  const paginatedModels = sortedModels?.slice(startIndex, endIndex);


  useEffect(() => {
    setSelectedOptions(upload.model);
    setSelectAll(upload.model?.length === sortedModels?.length);
  }, [upload.model, sortedModels]);


  useEffect(() => {
    setCurrentPage(1)

    if (searchQueryResult.length === 0) {
      setTotalItems(totalItems)
    } else if (searchQueryResult.length > 0 && searchQueryResult !== "") {
      setTotalItems(searchQueryResult.length)
    } else {
      setTotalItems(0);
    };

    setTotalPages(Math.ceil(totalItems / itemsPerPage)); // Update totalPages
  }, [searchQueryResult, totalItems, itemsPerPage]);


  const handlePageChange = (e, newPage) => setCurrentPage(newPage);

  const handleItemsPerPageChange = (e) => setItemsPerPage(Number(e.target.value));


  const handleSearchChange = () => {
    sortedModels = modalsLoad;  // reset to all models when user press "enter" to search
  
    if (searchValue.trim() === '') {
      setSearchQueryResult(''); // Set the search query result to an empty string
    } else {
      let filteredModels = sortedModels.filter((model) => {
        const { model_code, provided_model_name, type, manufacturer_files } = model;
        const manufacturerName = manufacturer_files?.manufacturer_name || '';
  
        // filter the matched fields based on the search value. 
        // then using map() to extract the corresponding values into matchedValues
        const matchedValues = Object.entries(fields)
          .filter(([key, value]) => {
            return key.toLowerCase().includes(searchValue.toLowerCase().trim());
          })
          .map(([key, value]) => value);
  
        return (
          (model_code &&model_code.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (provided_model_name &&provided_model_name.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (type && type.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (manufacturerName && manufacturerName.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          // matchedValues.some() to check if any of the matched values are included in the "type" field.
          (matchedValues && matchedValues.some((matchedValue) => type.toLowerCase().includes(matchedValue.toLowerCase().trim())))
        );
      });
  
      setSearchQueryResult(filteredModels);
    }
  };


  const handleClearSearch = () => setSearchValue('');  // Clear the search field value
  

  const handleCheckboxChange = (e, id, modelCode, uniqueModelCode) => {
    const isChecked = e.target.checked;
    const newId = id + uniqueModelCode;

    setUpload((prevState) => {
      let updatedModel = [...prevState.model];

      if (isChecked) {
        updatedModel.push(newId);
      } else {
        updatedModel = updatedModel.filter((modelId) => modelId !== newId);
      };

      return {
        ...prevState,
        model: updatedModel,
      };
    });

    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => {
        return [...prevSelectedOptions, newId];
      });
    } else {
      setSelectedOptions((prevSelectedOptions) => {
        return prevSelectedOptions.filter((optionId) => optionId !== newId);
      });
    }
  };


  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    // console.log("isChecked", isChecked);

    setSelectedOptions(
      isChecked
        ? sortedModels.map((model) => model.id + model.manufacturer_files.unique_model_code)
        : []
    );

    setUpload((prevState) => ({
      ...prevState,
      model: isChecked
        ? sortedModels.map((model) => model.id + model.manufacturer_files.unique_model_code)
        : [],
    }));
  };
  

  return (
    <>
     <TextField
        sx={{ flex: 1, width: "40%" }}
        variant="filled"
        className="searchBox"
        label="Search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                sx={{
                  cursor: "pointer",
                  color: "#051B44",
                  fontSize: ".7em",
                  textTransform: "none",
                }}
                onClick={handleClearSearch}
              >
                Clear
              </Button>
              <Button
                onClick={handleSearchChange}
                aria-label='search'
                variant="contained"
                sx={{
                  backgroundColor: "#2A598F",
                  textTransform: "none",
                  '&:hover': {
                    backgroundColor: "#203C5B",
                  },
                  fontSize: ".7em",
                }}
              >
                Search
              </Button>
            </InputAdornment>
          ),
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearchChange()
          }
        }}
      />

      {paginatedModels && paginatedModels?.length > 0 && (
        <FormControl {...selectProps}>
          <TableContainer>
            <Table>

              <TableHead>
                <TableRow>
                  <TableCell sx={{width: "15%"}}>Model Code</TableCell>
                  <TableCell sx={{width: "20%"}}>Model Name</TableCell>
                  <TableCell sx={{width: "20%"}}>Type</TableCell>
                  <TableCell sx={{width: "25%"}}>Manufacturer Name</TableCell>
                  <TableCell sx={{width: "10%"}}>RC</TableCell>
                  <TableCell sx={{width: "10%"}} align="right">
                    {" "}
                    Select All
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                      inputProps={{ "aria-label": "Select All" }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {paginatedModels && paginatedModels.map((model) => (
                  <TableRow key={model.id + model.manufacturer_files.unique_model_code}>
                    <TableCell>{model.model_code}</TableCell>
                    <TableCell>{model.provided_model_name}</TableCell>
                    <TableCell>{keyToField[model.type]}</TableCell>
                    <TableCell>{model.manufacturer_files.manufacturer_name}</TableCell>
                    <TableCell>{model?.rcsPopulated[0]?.name + " / " + model?.rcsPopulated[0]?.address?.company}</TableCell>
                    <TableCell align="right">
                      <Checkbox
                        checked={selectedOptions.includes(model.id + model.manufacturer_files.unique_model_code)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            model.id,
                            model.model_code,
                            model.manufacturer_files.unique_model_code
                          )
                        }
                        inputProps={{"aria-label": model.provided_model_name}}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FormControl>
      )}

      {/* Pagination controls */}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
          <p style={{ marginRight: "10px" }}>Rows per page:</p>
          <Select
            variant='outlined'
            id="select-items-per-page"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            sx={{ minWidth: '40px', height: '30px'}}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </div>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />

      </div>
    </>
  );
};

export default SelectModelsFromManufacturerOrRC;