import React, { useState, useEffect } from 'react'
// import { getPDF } from "../../../../admin/useHooks/useDeAppendix";
import ShowPDF from "./ShowPDF";

import { Container, Paper, Button,
  Box
} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ModelPerspective from './perspectives/ModelPerspective/index';
import ComponentPerspective from './perspectives/ComponentPerspective/index';
import { useTranslation } from 'react-i18next';

const RestricedUrlWebpage = ({ ruResult, pwd, authToken, ruNo }) => {
  const {t} = useTranslation("pages", { keyPrefix: "typeApprovalRecord" });
  const [File, setFile] = useState(null);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [perspective, setPerspective] = useState("models");

  const handleGetPDFFile = async (certId) => {
    setFile(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/restrictedUrls/getFile/${certId}?at=${authToken}&ruNo=${ruNo}`);
  };

  useEffect(() => {
    if(File !== null) setOpenModal(true);
  },[File])

  const handleChange = (event, newValue) => {
    setPerspective(newValue);
  };

  if(!ruResult) return null;
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-around"}}>
        <Tabs
            value={perspective}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
        >
            <Tab value="models" label={t("Models")} sx={{
              width: "40rem",
            }}/>
            <Tab value="components" label={t("Components")} sx={{
              width: "40rem",
            }}/>
        </Tabs>
      </Box>

      <Container style={{ maxWidth: '100%' }}>
        {
          perspective === "models" && (
            <ModelPerspective 
              ruResult={ruResult} 
              handleGetPDFFile={handleGetPDFFile}
            />
          )
        }
        {
          perspective === "components" && (
            <ComponentPerspective 
              ruResult={ruResult} 
              handleGetPDFFile={handleGetPDFFile}
            />
          )
        }
        {
          openModal && 
              <ShowPDF
                File={File}
                setFile={setFile}
                openInfoModal={openInfoModal}
                setOpenInfoModal={setOpenInfoModal}
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
        }
      </Container>
    </>




    // <>
    //   {
    //     ruResult && (
    //       <Container component="main" maxWidth="lg" sx={{ minHeight: "90vh", mb: "2em" }}>
    //           {/* header */}
    //           <Box
    //               sx={{
    //                   marginTop:8,
    //                   display: "flex",
    //                   flexDirection: "column",
    //                   alignItems: "center",
    //               }}
    //           >
    //               {/* logo */}
    //               <img src={emsdLogo} style={{ width: "200px" }} alt="EMSD logo" />
    //               {/* title */}
    //               <Typography
    //                   component="h1"
    //                   variant="h5"
    //                   sx={{ fontFamily: "Frutiger_bold" }}
    //               >
    //                   Type Approval Record
    //               </Typography>
    //           </Box>
    //           <Divider variant="middle" sx={{ marginTop:3 }}/>
              
    //           {/* BODY */}
    //           {/* application, approval date, rc */}
    //           <Box
    //               sx={{
    //                   marginTop:3,
    //                   display: "flex",
    //                   flexDirection: "column",
    //                   alignItems: "center",
    //               }}
    //           >
    //               <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{border: 'none'}}>
    //                   <Grid item xs={12}>
    //                       <Item>Application Number: <strong>{application_number}</strong></Item>
    //                   </Grid>
    //                   <Grid item xs={12}>
    //                       <Item>Approval date: <strong>{approval_date.split('T')[0]}</strong></Item>
    //                   </Grid>
    //                   <Grid item xs={12}>
    //                       <Item>Registered Lift Contractor: <strong>{rc_info?.rc_company_name } {!!rc_info?.rc_number_lift && (' | ' + rc_info?.rc_number_lift)} {!!rc_info?.rc_number_escalator && (' | ' + rc_info?.rc_number_escalator)}</strong></Item>
    //                   </Grid>
    //               </Grid>
    //           </Box>
    //           <Divider variant="middle" sx={{ marginTop:3 }}/>

    //           {/* Model */}
    //           {
    //               models.length > 0 && models.map(((model,i) =>  
    //               <div key={model.provided_model_name + String(i)}>
    //                   {/* Lift model and attribute */}
    //                   <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} 
    //                       sx={{
    //                           border: 'none', 
    //                           marginTop:3,
    //                       }}>
    //                       <Grid item xs={12}>
    //                           <Item><strong>Lift Model: </strong>{model.provided_model_name}</Item>
    //                       </Grid>
    //                       <Grid item xs={12}>
    //                           {/* split the type name and replace with space */}
    //                           <Item><strong>Type:</strong>{model.type.trim().split(' ').join('').split(/(?=[A-Z])/).join(' ')}</Item>
    //                       </Grid>
    //                       <Grid item xs={12}>
    //                           <Item>
    //                               <strong>Attribute: </strong> 
    //                               {model.rated_speed && (
    //                                   <Item>rated speed:{model.rated_speed}</Item>
    //                               )}

    //                               {/* L type */}
    //                               {model.rated_load && (
    //                                   <Item>rated load:{model.rated_load}</Item>
    //                               )}
    //                               {model.maximum_travel_of_height && (
    //                                   <Item>maximum_travel_of_height: {model.maximum_travel_of_height}</Item>
    //                               )}
    //                               {model.rope_factor && (
    //                                   <Item>roping: {model.rope_factor}</Item>
    //                               )}
    //                               {model.balancing_factor && (
    //                                   <Item>balancing factor: {model.balancing_factor}</Item>
    //                               )}

    //                               {/* E type */}
    //                               {model.maximum_rise && (
    //                                   <Item>maximum_rise: {model.maximum_rise}</Item>
    //                               )}
    //                               {model.angle_of_inclination && (
    //                                   <Item>angle_of_inclination: {model.angle_of_inclination}</Item>
    //                               )}

    //                               {model.controller && (
    //                                   <Item>controller: {model.controller}</Item>
    //                               )}
    //                               {model.traction_drive_system && (
    //                                   <Item>traction machine: {model.traction_drive_system}</Item>
    //                               )}

    //                               {model.max_inclination && (
    //                                   <Item>max_inclination: {model.max_inclination}</Item>
    //                               )}
    //                           </Item>
    //                       </Grid>

    //                   </Grid>

    //                   {/* manufacturer */}
    //                   <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop:3 }}>
    //                           <Grid item xs={12}>
    //                               <Item><strong>Manufacturer </strong></Item>
    //                               <Item>
    //                                   {model?.manufacturer_files?.length > 0 && model?.manufacturer_files?.map(manuFile => 
    //                                       manuFile?.type_test_certificate_id?.length > 0 && manuFile?.type_test_certificate_id?.map((cert, i) => {
    //                                           let cert_type_metadata = cert?.cert_type_metadata
    //                                           let tec_manu_name = cert_type_metadata?.tec_manufacturer_name
    //                                           let tec_manu_addr = cert_type_metadata?.tec_manufacturer_address
    //                                           return (
    //                                               cert_type_metadata && <Item key={i}><strong>{`${manuFile?.unique_model_code}. `}</strong>{`${tec_manu_name} - ${tec_manu_addr}`}</Item>
    //                                           )
    //                                       })
    //                                   )}
    //                               </Item>
    //                           </Grid>
    //                   </Grid>

    //                   {/* model cert table */}
    //                   <TableContainer component={Paper}>
    //                       <Table sx={{ minWidth: 650 }} aria-label="simple table">
    //                           {/* col */}
    //                           <TableHead>
    //                               <TableRow>
    //                                   {columnNameManu.map((colName, i) => (
    //                                       <TableCell key={colName + String(i)}>{colName}</TableCell>
    //                                   ))}
    //                               </TableRow>
    //                           </TableHead>
    //                           {/* cert */}
    //                           <TableBody>
    //                           {model.manufacturer_files.map((v) => v?.type_test_certificate_id[0] !== null && v?.type_test_certificate_id?.map((row, i) => {
    //                               if(row == null) return null
    //                               let certMeta = row?.cert_type_metadata;

    //                               let approval_date = certMeta?.tec_issue_date || model.approval_date.split('T')[0];
    //                               return (
    //                                   <TableRow
    //                                       key={`row`+ String(i)}
    //                                       sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    //                                   >
    //                                       <TableCell align="left">{certMeta?.tec_certificate_number}</TableCell>
    //                                       <TableCell align="left">{v?.unique_model_code}</TableCell>
    //                                       <TableCell align="left">[{`${application_number} issued at ${approval_date}`}]</TableCell>
    //                                   </TableRow>
    //                               )
    //                           }))}
    //                           </TableBody>
    //                       </Table>
    //                   </TableContainer>
    //               </div>
    //               ))
    //           }

    //           <Divider variant="middle" sx={{ marginTop:3 }}/>

    //           {/* SC category */}
    //           {/* SC */}
    //           {rowsScType.map((record, i) => {
    //               return (
    //               <Box key={record.type + String(i)} sx={{mt: 3}}>
    //                   {/* type */}
    //                   {
    //                       record.type && record?.rowsScCert?.length > 0 && (
    //                           <>
    //                               <strong>Safety Component - {record.type}</strong>
    //                               <TableContainer key={record.type + String(i)} component={Paper}>
    //                                   <Table sx={{ minWidth: 650 }} aria-label="simple table">
    //                                       {/* col name */}
    //                                       <TableHead>
    //                                           <TableRow>
    //                                               { 
    //                                                   deAppendix.app_type !== "SafetyComponent" && colNameSc.map((colName, i) => (
    //                                                       <TableCell key={colName + String(i)}>{colName}</TableCell>
    //                                                   ))
    //                                               }
    //                                               {
    //                                                   deAppendix.app_type === "SafetyComponent" && colNameScForStandalone.map((colName, i) => (
    //                                                       <TableCell key={colName + String(i)}>{colName}</TableCell>
    //                                                   ))
    //                                               }
    //                                           </TableRow>
    //                                       </TableHead>
    //                                       {/* cert */}
    //                                       <TableBody>
    //                                           {
    //                                               record?.rowsScCert?.map((row, i) => {
    //                                                   let manu_provided_name = row?.manufacturer_id?.provided_name || "";
    //                                                   let provided_comp_name = row?.provided_comp_name || "";

    //                                                   // for sc cert, even if multiple certs, we only show one cert for each sc, so we grab the latest created one
    //                                                   let populated = row?.type_test_certificate_id?.length > 0 && row?.type_test_certificate_id[0].createdAt

    //                                                   if(!populated) return null;
    //                                                   let cert = row?.type_test_certificate_id?.length > 1 ? row?.type_test_certificate_id?.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0] : row?.type_test_certificate_id[0];
    //                                                   let Type_Exam_Certificate = cert?.cert_type_metadata?.tec_type_exam || cert?.cert_type_metadata?.tec_certificate_number;

    //                                                   let Approval_Reference = deAppendix.app_type === "SafetyComponent" && cert?.cert_type_metadata?.['Approval Reference']?.match(/[Ƨ]/) ? cert?.cert_type_metadata?.['Approval Reference'] + row.approval_date.split("T")[0] : cert?.cert_type_metadata?.['Approval Reference'];
    //                                                   let tec_issue_date = cert?.cert_type_metadata?.tec_issue_date || Approval_Reference
                                                      
    //                                                   return (
    //                                                       <TableRow
    //                                                           key={provided_comp_name + String(i) + String(i + 1)}
    //                                                           sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    //                                                       >
    //                                                           <TableCell key={manu_provided_name + String(i)} align="left">{manu_provided_name}</TableCell>
    //                                                           <TableCell key={provided_comp_name + String(i)} align="left">{provided_comp_name}</TableCell>
    //                                                           <TableCell key={Type_Exam_Certificate + String(i)} align="left">{Type_Exam_Certificate.split(/(issued)|(Issued)/)[0]}</TableCell>
    //                                                           <TableCell key={tec_issue_date + String(i)} align="left">{fixInvalidDates(String(tec_issue_date)) || "N/A"}</TableCell>
    //                                                           {/* <TableCell key={tec_issue_date + String(i)} align="left">{tec_issue_date}</TableCell> */}


    //                                                           {/* original version - loop all the certs for each sc model */}
    //                                                           {/* {row?.type_test_certificate_id?.map((v, i) =>{
    //                                                               let Type_Exam_Certificate = v?.cert_type_metadata?.tec_type_exam || v?.cert_type_metadata?.tec_certificate_number;
    //                                                               console.log("🚀 ~ file: index.jsx:301 ~ {row?.type_test_certificate_id?.map ~ v?.cert_type_metadata:", v?.cert_type_metadata)
    //                                                               let tec_issue_date = v?.cert_type_metadata?.tec_issue_date || v?.cert_type_metadata?.['Approval Reference'];
    //                                                               return (
    //                                                                   <>
    //                                                                       <TableCell key={Type_Exam_Certificate + String(i)} align="left">{Type_Exam_Certificate}</TableCell>
    //                                                                       <TableCell key={tec_issue_date + String(i)} align="left">{tec_issue_date}</TableCell>
    //                                                                   </>
    //                                                               )
    //                                                           })} */}

    //                                                           {/* testing */}
    //                                                           {/* {
    //                                                               row?.type_test_certificate_id.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((v) => {
    //                                                                   console.log(row?.provided_comp_name)
    //                                                                   console.log(v)
    //                                                                   return (
    //                                                                       <TableCell key={v?.cert_type_metadata?.tec_type_exam + String(i)} align="left">{v?.cert_type_metadata?.tec_type_exam}</TableCell>
    //                                                                   )
    //                                                               })
    //                                                           } */}
                                                              
                                                                          
    //                                                       </TableRow>
    //                                                   )
    //                                               })
    //                                           }

    //                                       </TableBody>
    //                                   </Table>
    //                               </TableContainer>
    //                           </>
    //                       )
    //                   }
    //               </Box>
    //               )
    //           })}
    //       </Container>
    //     )
    //   }
    // </>
  )
}

export default RestricedUrlWebpage