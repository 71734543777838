import React, { useState } from 'react';
import {
  AppBar,
  Logout,
  useLocaleState,
  UserMenu,
  useUserMenu,
  useRefresh
} from "react-admin";
import { Link, useLocation } from "react-router-dom";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import Notification from "../../pages/Notification";
import { Box, Button, Typography } from "@mui/material";
import logo from "../../styles/logo/emsd.png";
import palette from "../../styles/palette";

import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";


// It's important to pass the ref to allow MUI to manage the keyboard navigation
const ConfigurationMenu = React.forwardRef((props, ref) => {
  const { t : itranslate } = useTranslation();
  return (
    <MenuItem
      ref={ref}
      component={Link}
      // It's important to pass the props to allow MUI to manage the keyboard navigation
      {...props}
      to="/configuration"
    >
      <ListItemIcon>
        <SettingsOutlinedIcon />
      </ListItemIcon>
      <ListItemText>
        {/* Settings */}
        {itranslate("appBar.Settings")}
      </ListItemText>
    </MenuItem>
  );
});



const SwitchLanguage1 = React.forwardRef((props, ref) => {
  const [locale, setLocale] = useLocaleState();
  console.log("🚀 ~ SwitchLanguage ~ locale:", locale)
  const { onClose } = useUserMenu();
  const [open, setOpen] = useState(false);

  const handleLanguageChange = (language) => {
    setLocale(language);
    i18n.changeLanguage(language);
    setOpen(false);
    onClose(); 
    window.location.reload();
  };

  return (
    <>
      <MenuItem
        ref={ref}
        {...props}
        sx={{ color: "text.secondary" }}
        onClick={() => setOpen(true)} // Open the dialog
      >
        <ListItemIcon sx={{ minWidth: 5 }}>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText>Switch Language</ListItemText>
      </MenuItem>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Select Language</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleLanguageChange("en")}>English</Button>
          <Button onClick={() => handleLanguageChange("ch")}>Chinese</Button>
        </DialogActions>
      </Dialog>
    </>
  );
});



const SwitchLanguage = React.forwardRef((props, ref) => {
  const { t : itranslate } = useTranslation();
  const [locale, setLocale] = useLocaleState();
  const { onClose } = useUserMenu();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Set anchor to open menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Close menu
  };

  const handleLanguageChange = (language) => {
    setLocale(language);
    i18n.changeLanguage(language);
    handleClose(); // Close the menu after selection
    onClose(); 
    window.location.reload();
  };

  return (
    <>
      <MenuItem
        ref={ref}
        {...props}
        sx={{ color: "text.secondary" }}
        onClick={handleClick} // Open the menu instead of a dialog
      >
        <ListItemIcon sx={{ minWidth: 5 }}>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText>
          {/* Switch Language */}
          {itranslate("appBar.Switch Language")}
        </ListItemText>
      </MenuItem>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleLanguageChange('en')}>
          {/* English */}
          {itranslate("appBar.English")}
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange('ch')}>
          {/* Chinese */}
          {itranslate("appBar.Chinese")}
        </MenuItem>
      </Menu>
    </>
  );
});




const MyUserMenu = (props) => {
  return (
    <UserMenu {...props} icon={<PersonIcon />}>
      <ConfigurationMenu label="g" />
      {/* <SwitchLanguage /> */}
      <Logout />
    </UserMenu>
  );
};

export const MyAppBar = (props) => {
  const { t : itranslate } = useTranslation();

  const location = useLocation();
  const handleFontSize = (fontSize) => {
    let root = document.documentElement;
    root.style.fontSize = `${fontSize}px`;
  };

  return (
    <AppBar
      {...props}
      userMenu={<MyUserMenu />}
      sx={{
        // nonce: window.__CSP_NONCE__,
        boxShadow: "none",
        background: "white",
        borderBottom: `1.5px solid ${palette.border}`,
        color: "black",
        height: "60px",
        display: "flex",
        justifyContent: "center",

        '& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeLarge.RaLoadingIndicator-loadedIcon ': {
          pointerEvents: 'none',
          // background: "yellow"
        }
      }}
    >
      <Box flex="1" sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
        <img
          src={logo}
          className="w-[150px] mr-[15px]"
          alt="logo"
        />

        <Typography
          variant="h6"
          id="react-admin-title"
          sx={{ fontFamily: "Frutiger_bold" }}
        >
          {/* {window.location.pathname === "/lift_rc"
            ? `TAPAS Registered Contractor Portal    `
            : `TAPAS EMSD Portal    `} */}
          {window.location.pathname === "/lift_rc"
          ? `${itranslate("appBar.TAPAS Registered Contractor Portal")}    `
          : `${itranslate("appBar.TAPAS EMSD Portal")}     `}
        </Typography>
      </Box>

      {!location.pathname.includes("applications_new") &&
        window.location.pathname === "/lift_rc" && (
          <Button
            sx={{
              backgroundColor: `${palette.primary}`,
              fontWeight: "600",
              margin: "0px 15px",
            }}
            component={Link}
            to="/applications_new"
            variant="contained"
            key="button"
          >
            {/* + New Application */}
            + {itranslate("appBar.New Application")}
          </Button>
        )}

      <Typography sx={{ fontSize: "1.25rem" }} id="react-admin-title">
        {/* version 2.0.5 */}
        {itranslate("appBar.version")} 2.0.6
      </Typography>

      <ButtonGroup>
        <IconButton
          onClick={() => handleFontSize(14)}
          sx={{ fontSize: "1.2rem", height: "40px", width: "40px" }}
        >
          A
        </IconButton>
        <IconButton
          onClick={() => handleFontSize(16)}
          sx={{ fontSize: "1.5rem", height: "40px", width: "40px" }}
        >
          A
        </IconButton>
        <IconButton
          onClick={() => handleFontSize(18)}
          sx={{ height: "40px", width: "40px" }}
        >
          A
        </IconButton>
      </ButtonGroup>

      <Notification />
    </AppBar>
  );
};
