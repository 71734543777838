import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import getToken from "../../utils/getAuthToken";

function ShortNameEditor(props) {
  const notify = useNotify();
  const { manufacturers, upload, userInfo, userRoles } = props;
  const [shortNames, setShortNames] = useState([]);
  const handleShortNameChange = (e, manufacturerId) => {
    const clone = [...shortNames];
    const index = clone.findIndex((item) => item._id === manufacturerId);
    clone[index].short_name = e.target.value;
    setShortNames(clone);
  };
  const save = () => {
    axios
      .put(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/manufacturers/short_names`,
        shortNames,
        { headers: { authorization: getToken() } }
      )
      .then((res) => {
        notify("Short name saved");
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (!manufacturers || manufacturers.length === 0) return;
    const defaultShortNames = manufacturers.map((manufacturer) => {
      return { _id: manufacturer._id, short_name: manufacturer.short_name };
    }, {});
    setShortNames(defaultShortNames);
  }, [manufacturers]);


  // disable the button if the user is not EMSDGR (GR) or Inspector, but the application's current role name is not GR.
  const isButtonDisabled =
    (userInfo?.role === "EMSDGR" && //GR and current role name is not GR and not pending
      (upload?.status.includes("Pending E") ||
        upload?.status.includes("Pending SE") ||
        upload?.status.includes("Pending CE")) &&
      upload?.current_role_name !== "GR") ||
    userInfo?.role === "Inspector" || //Inspector
    (userInfo?.role !== "EMSDGR" && //Check roleId except GR
      !userRoles.includes(upload?.current_role_id));


  if (!manufacturers) return null;
  return (
    <TableContainer sx={{ mt: 5 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ fontFamily: "Frutiger_bold" }}>
          Manufacturers Short Name Edit
        </Typography>
        <Button
          disabled={isButtonDisabled}
          onClick={save} 
          variant="contained" 
          color="primary"
        >
          Save
        </Button>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", width: "35%" }}>
              Manufacturer
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", width: "65%" }}>
              Short Name
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {manufacturers
            ?.filter((manufacturer) => manufacturer.newManufacturer)
            .map((manufacturer) => {
              return (
                <TableRow key={manufacturer._id}>
                  <TableCell>{manufacturer.provided_name}</TableCell>
                  <TableCell>
                    <TextField
                      disabled={isButtonDisabled}
                      fullWidth
                      variant="outlined"
                      value={
                        shortNames.find((item) => item._id === manufacturer._id)
                          ?.short_name ?? ""
                      }
                      onChange={(e) =>
                        handleShortNameChange(e, manufacturer._id)
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ShortNameEditor;
