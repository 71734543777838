import { useState } from "react";
import * as React from "react"
import { useTranslation } from "react-i18next";
// mui
import {
    Container,
    TextField,
    Typography,
    Divider,
    Box,
    Button
} from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { getRankByEntityPeriodChannel } from "../../../admin/useHooks/statPublicDashboard/useStatPublic";
import { useEffect } from "react";


const Leaderboard = () => {
    // const { t } = useTranslation("pages", { keyPrefix: "statistic dashboard" });
    const { t : itranslate } = useTranslation();
    
    const [rankRange, setRankRange] = useState("6");
    const [channel, setChannel] = useState("all");
    const [entity, setEntity] = useState('Application');

    const [rankData, setRankData] = useState([]);

    useEffect(() => {
        getRankByEntityPeriodChannel(entity, rankRange, channel).then(res => {
            setRankData(res);
        }).catch(err => {
            console.log(err);
            setRankData([]);
        })
    }, [entity, rankRange, channel]);

    const handleChange = (event) => {
        setEntity(event.target.value);
    };

    const handleRankRangeChange = (e) => {
        setRankRange(e.target.value);
    }

    const handleChannelChange = (e) => {
        setChannel(e.target.value);
    }


    return (
        <>
            {/* line 1 input field*/}
            <Typography
                component="div"
                sx={{
                    display: "flex",
                }}
            >
                <Typography
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        pb: "1em",
                    }}
                >
                    {itranslate("statistic dashboard.Top 5 visits for")}
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-helper-label">{itranslate("statistic dashboard.Entity")}</InputLabel>
                    <Select
                        labelId="select-helper-label"
                        id="select-helper"
                        value={entity}
                        label="entity"
                        onChange={handleChange}
                    >
                        <MenuItem value="Application">{itranslate("statistic dashboard.Application")}</MenuItem>
                        <MenuItem value="Model">{itranslate("statistic dashboard.Model")}</MenuItem>
                    </Select>
                    <FormHelperText>{itranslate("statistic dashboard.Select entity type")}</FormHelperText>
                </FormControl>

            </Typography>

            {/* line 2 input field*/}
            <Typography
                component="div"
                sx={{
                    display: "flex",
                }}
            >
                <Typography
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        pb: "1em",
                    }}
                >
                    in
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-helper-label">{itranslate("statistic dashboard.Period")}</InputLabel>
                    <Select
                        labelId="select-helper-label"
                        id="select-helper"
                        value={rankRange}
                        label="rank_range"
                        onChange={handleRankRangeChange}
                    >
                        <MenuItem value="0">{itranslate("statistic dashboard.This Month")}</MenuItem>
                        <MenuItem value="1">{itranslate("statistic dashboard.Last Month")}</MenuItem>
                        <MenuItem value="3">{itranslate("statistic dashboard.Last 3 Months")}</MenuItem>
                        <MenuItem value="6">{itranslate("statistic dashboard.Last 6 Months")}</MenuItem>
                    </Select>
                    <FormHelperText>{itranslate("statistic dashboard.select how many months ago")}</FormHelperText>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-helper-label">{itranslate("statistic dashboard.Channel")}</InputLabel>
                    <Select
                        labelId="select-helper-label"
                        id="select-helper"
                        value={channel}
                        label="channel"
                        onChange={handleChannelChange}
                    >
                        <MenuItem value="all">{itranslate("statistic dashboard.All")}</MenuItem>
                        <MenuItem value="qrCode">{itranslate("statistic dashboard.QR Code")}</MenuItem>
                        <MenuItem value="ru">{itranslate("statistic dashboard.Restricted Url")}</MenuItem>
                    </Select>
                    <FormHelperText>{itranslate("statistic dashboard.Select channel")}</FormHelperText>
                </FormControl>
            </Typography>

            {/* Leader board */}
            <Box
                sx={{
                    // shadow box
                    boxShadow: "2px 4px 10px 1px rgba(201,201,201,0.47)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "background.paper",
                    p: 2,
                    m: 1,
                    borderRadius: 2,
                }}
            >

                <List >

                    {rankData && rankData?.result?.map((v, i) => {
                        return (
                        <React.Fragment 
                            key={i}
                        >
                            <ListItem
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div>
                                    <Typography
                                        sx={{
                                            textAlign: "center",
                                        }}
                                        component={"div"}
                                    >
                                        <Typography
                                            sx={{
                                                textAlign: "center",
                                            }}
                                            component="div"
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {/* {rankData.entity === "Application" ? "Application Number" : "Model Name"} */}
                                            {rankData.entity === "Application" ? itranslate("statistic dashboard.Application Number") : itranslate("statistic dashboard.Model Name")}
                                        </Typography>
                                        <div>
                                            {rankData.entity === "Application" ? i + 1 + ". " + v[0] : i + 1 + ". " + v[1].provided_model_name}
                                        </div>
                                    </Typography>
                                    {
                                        rankData.entity === "Application" &&
                                        <div>
                                            <Typography
                                                sx={{
                                                    textAlign: "center",
                                                }}
                                                component="div"
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {itranslate("statistic dashboard.RC Company")}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                                component="div"
                                                color="text.primary"
                                            >
                                                {v[1][1]?.address?.company || "N/A"}
                                            </Typography>
                                        </div>
                                    }

                                    {
                                        rankData.entity === "Model" &&
                                        <div>
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                                component={"div"}
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {itranslate("statistic dashboard.manufacturer name")}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                                component={"div"}
                                                color="text.primary"
                                            >
                                                {v[1]?.manufacturer_provided_name || "N/A"}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                                component={"div"}
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {itranslate("statistic dashboard.RC Company")}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                                component={"div"}
                                                color="text.primary"
                                            >
                                                {v[1]?.rc?.address?.company || "N/A"}
                                            </Typography>
                                        </div>
                                    }
                                </div>
                            </ListItem>
                            <Divider />
                        </React.Fragment>

                        )
                    })}

                </List>

            </Box>
        </>

    )
};

export default Leaderboard;