import React from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";

const selectProps = {
  sx: { mt: 2 },
  fullWidth: true,
  label: "operation",
};

const SelectRevokeAction = (props) => {
  const { handleApplicationFlow, action, disableRadio } = props;

  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 1 }} id="action">
        Choose type of revoke action
      </InputLabel>

      <Select
        {...selectProps}
        label={""}
        labelId="action"
        id="action"
        value={action}
        onChange={(e) => handleApplicationFlow(e.target.value)}
        disabled={disableRadio} 
      >
        <MenuItem value={"manufacturer"}>Select Manufacturer and/or its related Model(s) and Safety Component(s) to revoke</MenuItem>
        <MenuItem value={"rc"}>Select Model(s) and Safety Component(s) based on RC to revoke </MenuItem>
        <MenuItem value={"model"}>Select Model(s) and Safety Component(s) to revoke</MenuItem>
        <MenuItem value={"safetycomponent"}>Select Safety Component(s) to revoke</MenuItem>
      </Select>
    </React.Fragment>
  );
};

export default SelectRevokeAction;
