import React, { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import Typography from "@mui/material/Typography";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Box,
} from "@mui/material";
import keyToField from "../../utils/keyToField";
import { useParams } from "react-router-dom";
import failedUpdateValidation from "./updateValidation";
import ValidationResult from "../create/ValidationResult";
import axios from "axios";
import _ from "lodash";
import getToken from "../../utils/getAuthToken";
import AutogenSelect from "./AutogenSelect";
import failedClarifyingValidation from "./clarifyingValidation";
import DiscardButton from "../../buttons/DiscardButton";

export const UpdateCheck = (props) => {
  const notify = useNotify();
  const {
    edit,
    update,
    setUpdate,
    upload,
    setUpload,
    handleSend,
    handleValidate,
    isUpdating,
    isCreating,
    setIsLastPage,
    old,
  } = props;
  const [AIfeedback, setAIfeedback] = useState([]);
  const { id } = useParams();
  const mandatoryFulfilled =
    !failedUpdateValidation(update, edit.mode, upload) &&
    !failedClarifyingValidation(upload, edit.clarifying);

  const ValidateButton = (props) => {
    return (
      <Button
        color="warning"
        variant="outlined"
        disabled={isCreating || isUpdating}
        onClick={() => {
          if (mandatoryFulfilled) handleValidate();
          else notify("Please fill in all mandatory fields");
        }}
        {...props}
      >
        Process to AI validation
      </Button>
    );
  };

  useEffect(() => {
    setIsLastPage(true);
    return () => setIsLastPage(false);
  }, []);

  //The props "update" is populated by setUpdate when handleChange, therefore, if there is an update, it will shows up in update. The old data is stored in "old"

  return (
    <Card sx={{}}>
      <CardHeader title="Updated List" />
      <CardContent>
        <Stack direction="column" sx={{ "&:first-of-type": { mt: 1 } }}>
          <Typography sx={{ fontWeight: "bold", fontStyle: "italic", mb: 1 }}>
            Manufacturer Changes:
          </Typography>

          {update.manufacturer
            .filter((manufacturer) =>
              Object.values(manufacturer).some((field) =>
                Array.isArray(field)
                  ? field.some((file) => file?.id)
                  : field?.id
              )
            )
            .map((manufacturer, i) => {
              return (
                <div key={i}>
                  <Typography>{manufacturer.provided_name}: </Typography>
                  <Typography sx={{ color: "blue" }}>
                    {Object.values(manufacturer)
                      .reduce((a, c, i) => {
                        return (
                          Array.isArray(c) ? c.some((file) => file?.id) : c?.id
                        )
                          ? [
                              ...a,
                              keyToField[Object.keys(manufacturer)[i]] ??
                                Object.keys(manufacturer)[i],
                            ]
                          : a;
                      }, [])
                      .join(", ")}
                  </Typography>
                </div>
              );
            })}

          <Typography sx={{ fontWeight: "bold", fontStyle: "italic", mb: 1 }}>
            System Model Changes:
          </Typography>

          {old &&
            update.model.map((model, index) => {
              return (
                <React.Fragment key={index}>
                  <div>
                    <Typography>{model.provided_model_name}: </Typography>
                    <Typography sx={{ color: "blue" }}>
                      {Object.keys(model)
                        .filter(
                          (x) => x !== "__v" && x !== "manufacturer_files"
                        )
                        .reduce((changedField, currentField, i) => {
                          // new field, edited field, missing field
                          if (!old.model[index]) {
                            return ["New model"];
                          } else if (
                            !old.model[index].hasOwnProperty(currentField)
                          ) {
                            return [
                              ...changedField,
                              keyToField[currentField] ?? currentField,
                            ];
                          } else if (
                            !_.isEqual(
                              model[currentField],
                              old.model[index][currentField]
                            )
                          ) {
                            return [
                              ...changedField,
                              keyToField[currentField] ?? currentField,
                            ];
                          } else if (!model[currentField]) {
                            return [
                              ...changedField,
                              keyToField[currentField] ?? currentField,
                            ];
                          } else return changedField;
                        }, [])
                        .join(", ")}
                    </Typography>
                  </div>
                  {/* Only show change in files IF the model is old */}
                  {old.model[index] &&
                    model.manufacturer_files.map((mmf, i) => {
                      return (
                        <Typography
                          sx={{ color: "blue" }}
                          key={mmf.manufacturer_id}
                        >
                          {Object.values(mmf)
                            .reduce((a, c, i) => {
                              return (
                                Array.isArray(c)
                                  ? c.some((file) => file?.id)
                                  : c?.id
                              )
                                ? [
                                    ...a,
                                    keyToField[Object.keys(mmf)[i]] ??
                                      Object.keys(mmf)[i],
                                  ]
                                : a;
                            }, [])
                            .join(", ")}
                        </Typography>
                      );
                    })}
                </React.Fragment>
              );
            })}

          <Typography sx={{ fontWeight: "bold", fontStyle: "italic", mb: 1 }}>
            Component Changes:
          </Typography>

          {Object.keys(update.app_type.components)
            // Filter out the types of component that doesn't have newly added components OR new file uploaded in old component,
            // such that it doesn't have an _id
            .filter((compType) =>
              update.app_type.components[compType].some(
                (comp) =>
                  !comp._id || Object.values(comp).some((file) => file.id)
              )
            )
            .map((type) => {
              return (
                <div key={type} style={{ marginBottom: "1rem" }}>
                  <Typography
                    sx={{ textDecoration: "underline", fontStyle: "italic" }}
                  >
                    {keyToField[type] ?? type}
                  </Typography>
                  {update.app_type.components[type]
                    // Filter out the  component that isn't newly added / no new file uploaded, such that it doesn't have an id
                    .filter(
                      (comp) =>
                        !comp._id || Object.values(comp).some((file) => file.id)
                    )
                    .map((component, componentIndex) => {
                      return (
                        <div key={componentIndex}>
                          <Typography>
                            {component.provided_comp_name}:{" "}
                          </Typography>
                          <Typography sx={{ color: "blue" }}>
                            {Object.values(component)
                              .reduce((a, c, i) => {
                                return (
                                  Array.isArray(c)
                                    ? c.some((file) => file?.id)
                                    : c?.id
                                )
                                  ? [
                                      ...a,
                                      keyToField[Object.keys(component)[i]] ??
                                        Object.keys(component)[i],
                                    ]
                                  : a;
                              }, [])
                              .join(", ")}
                          </Typography>
                        </div>
                      );
                    })}
                </div>
              );
            })}

          {/* No certs = not yet AI validation */}
          {(!upload?.certs || upload.certs?.length === 0) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                {!["Clarifying", "AIerror"].includes(upload.status) && (
                  <ValidateButton sx={{ mr: 1 }} />
                )}
                {(upload.validationErrorCount >= 3 ||
                  ["Clarifying", "AIerror"].includes(upload.status)) && (
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={isUpdating || isCreating}
                    onClick={() => {
                      if (mandatoryFulfilled) handleSend();
                      else notify("Please fill in all mandatory fields");
                    }}
                  >
                    Send
                  </Button>
                )}
              </Box>
              {upload._id && <DiscardButton id={upload._id} />}
            </div>
          )}
          {upload?.aiFeedBack?.length > 0 && upload.certs?.length > 0 && (
            <>
              {/* {edit.mode === "d" && (
                <AutogenSelect
                  upload={upload}
                  update={update}
                  setUpdate={setUpdate}
                  id={id}
                />
              )} */}
              <ValidationResult
                upload={upload}
                setUpload={setUpload}
                isUpdating={isUpdating}
                isCreating={isCreating}
                handleSend={() => {
                  if (mandatoryFulfilled) handleSend();
                  else notify("Please fill in all mandatory fields");
                }}
                AIfeedback={AIfeedback}
                setAIfeedback={setAIfeedback}
                validate={<ValidateButton sx={{ mr: 1 }} />}
                application_type="update"
              />
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
