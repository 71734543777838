import { visuallyHidden } from '@mui/utils';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import React from 'react';

import { useTranslation } from 'react-i18next';

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, tableHeadCells } =
      props;

    const { t } = useTranslation("pages", { keyPrefix: "typeApprovalRecord" });
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {
            tableHeadCells.map((headCell, i) => (
              <React.Fragment key={i}>
                {headCell.label !== 'Documents' && (
                  <TableCell
                    key={`documents-${headCell.id}`}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    role="columnheader"
                    scope='col'
                    aria-sort={orderBy === headCell.id ? order === "desc" ? "descending" : "ascending" : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {t(headCell.label)}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                )}
              </React.Fragment>
            ))
          }
      
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    tableHeadCells: PropTypes.array.isRequired
  };

export default EnhancedTableHead