import React from "react";
import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useGetList } from "react-admin";

const selectProps = {
  sx: { mt: 2 },
  fullWidth: true,
  label: "operation",
};

const SelectRC = (props) => {
  //For emsd GR
  const { upload, setUpload } = props;
  const { data: rcs } = useGetList("rcs", {
    pagination: { page: 1, perPage: 0 },
  });
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="rc">
        Choose RC
      </InputLabel>
      <Select
        {...selectProps}
        labelId="rc"
        id="rc"
        value={upload.rc_id ?? ""}
        onChange={(e) => {
          setUpload((prev) => ({ ...prev, rc_id: e.target.value }));
        }}
      >
        {rcs &&
          rcs.map((rc) => {
            let rcDisplay = "";
            if (rc.address?.company_chinese)
              rcDisplay += rc.address?.company_chinese + " ";
            if (rc?.rc_number_lift) rcDisplay += rc?.rc_number_lift;
            if (rc?.rc_number_escalator)
              rcDisplay += " / " + rc?.rc_number_escalator;
            return (
              <MenuItem value={rc.id} key={rc.id}>
                {rcDisplay || rc.name}
              </MenuItem>
            );
          })}
      </Select>
    </React.Fragment>
  );
};

const SelectAction = (props) => {
  const { handleApplicationFlow, action, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="action">
        Choose type of application
      </InputLabel>
      <Select
        {...selectProps}
        labelId="action"
        id="action"
        value={action}
        onChange={(e) => handleApplicationFlow(e.target.value)}
        disabled={disableRadio}
      >
        <MenuItem value={"new"}>Brand new application</MenuItem>
        <MenuItem value={"update"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>Update application </Typography>
          </div>
        </MenuItem>
      </Select>
    </React.Fragment>
  );
};

const SelectLEtype = (props) => {
  const { handleApplicationFlow, system_type, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="model_type">
        Choose type of model
      </InputLabel>
      <Select
        {...selectProps}
        labelId="model_type"
        value={system_type}
        onChange={(e) => handleApplicationFlow(undefined, e.target.value)}
        disabled={disableRadio}
      >
        <MenuItem value={"lift"}>Lift</MenuItem>
        <MenuItem value={"escalator"}>Escalator</MenuItem>
      </Select>
    </React.Fragment>
  );
};

const SelectMachine = (props) => {
  const { system_type, handleRadio, radio, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="machine_type">
        Choose type of system
      </InputLabel>

      <Select
        {...selectProps}
        labelId="machine_type"
        value={radio === "9" ? "" : radio}
        onChange={(e) => handleRadio(e.target.value)}
        disabled={disableRadio}
      >
        {system_type === "lift" && (
          <MenuItem value={"0"}>Electric Traction Lift (Machine Room)</MenuItem>
        )}
        {system_type === "lift" && (
          <MenuItem value={"10"}>
            Electric Traction Lift (Machine Roomless)
          </MenuItem>
        )}
        {system_type === "lift" && (
          <MenuItem value={"8"}>Electric Inclined Lift</MenuItem>
        )}
        {system_type === "lift" && (
          <MenuItem value={"3"}>Service Lift</MenuItem>
        )}
        {system_type === "lift" && <MenuItem value={"2"}>Stairlift</MenuItem>}
        {system_type === "lift" && (
          <MenuItem value={"1"}>Hydraulic Lift</MenuItem>
        )}
        {system_type === "lift" && (
          <MenuItem value={"7"}>Vertical Lifting Platform</MenuItem>
        )}
        {system_type === "lift" && (
          <MenuItem value={"6"}>
            Mechanized Vehicle Parking System (MVPS)
          </MenuItem>
        )}
        {system_type === "escalator" && (
          <MenuItem value={"4"}>Escalator</MenuItem>
        )}
        {system_type === "escalator" && (
          <MenuItem value={"5"}>Passenger Conveyor</MenuItem>
        )}
        {system_type === "lift" && <MenuItem value={"11"}>Others</MenuItem>}
      </Select>
    </React.Fragment>
  );
};

const SelectApplicationType = (props) => {
  const { application_type, handleApplicationFlow, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="application">
        Choose type of application
      </InputLabel>
      <Select
        {...selectProps}
        labelId="application"
        id="application"
        value={application_type}
        onChange={(e) =>
          handleApplicationFlow(undefined, undefined, e.target.value)
        }
        disabled={disableRadio}
      >
        <MenuItem value={"add sc"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>Add Safety Component </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontStyle: "italic", ml: 5 }}
            >
              Add stand-alone safety component or safety component attached to
              lift/escalator model
            </Typography>
          </div>
        </MenuItem>
        <MenuItem value={"manu name change"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>Manufacturer Name Change </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontStyle: "italic", ml: 5 }}
            >
              Change the name of manufacturers of a lift/escalator model
            </Typography>
          </div>
        </MenuItem>
        <MenuItem value={"update cert"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>Update Certificate</Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontStyle: "italic", ml: 5 }}
            >
              Update the type test certificates of a lift/escalator model or
              safety component
            </Typography>
          </div>
        </MenuItem>
        <MenuItem value={"range ext"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>Range Extension</Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontStyle: "italic", ml: 5 }}
            >
              Range extension of a lift/escalator model or safety component
            </Typography>
          </div>
        </MenuItem>
      </Select>
    </React.Fragment>
  );
};

const SelectScType = (props) => {
  const { sc_type, handleApplicationFlow, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="modelOrStandAlone">
        Choose type of safety component
      </InputLabel>
      <Select
        {...selectProps}
        labelId="modelOrStandAlone"
        id="modelOrStandAlone"
        value={sc_type}
        onChange={(e) =>
          handleApplicationFlow(undefined, undefined, undefined, e.target.value)
        }
        disabled={disableRadio}
      >
        <MenuItem value={"standAloneSc"}>Stand-alone safety component</MenuItem>
        <MenuItem value={"leModelSc"}>
          Safety component attached to lift/escalator model
        </MenuItem>
      </Select>
    </React.Fragment>
  );
};

const SelectSystemType = (props) => {
  const { system_type, handleApplicationFlow, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="liftOrSc">
        Choose type of model
      </InputLabel>
      <Select
        {...selectProps}
        labelId="liftOrSc"
        id="liftOrSc"
        value={system_type}
        onChange={(e) => handleApplicationFlow(undefined, e.target.value)}
        disabled={disableRadio}
      >
        <MenuItem value={"lift"}>lift/escalator</MenuItem>
        <MenuItem value={"sc"}>Safety component</MenuItem>
      </Select>
    </React.Fragment>
  );
};

export {
  SelectRC,
  SelectAction,
  SelectLEtype,
  SelectMachine,
  SelectApplicationType,
  SelectScType,
  SelectSystemType,
};
