import React, { useState, useEffect } from "react";
import MetricsBox from "../components/dashboard/MetricsBox";
import Chart from "../components/dashboard/Chart";
import { Title } from "react-admin";
import axios from "axios";
import getToken from "../utils/getAuthToken";
import { useTranslation } from 'react-i18next';

import CompStatPublic from "../../_components/CompStatPublic/index"
// DateRangePicker
import DateRangeComponent from "../../_hoc/DateRangePicker/index";


// Dropdown
const Dropdown = ({ options, defaultOption, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <div>
      <select
        style={{
          padding: "10px",
          // "-webkit-box-shadow": "2px 4px 10px 1px rgba(0,0,0,0.47)",
          boxShadow: "2px 4px 10px 1px rgba(201,201,201,0.47)",
          borderRadius: "10px",
          minWidth: "200px",
        }}
        id="dropdown"
        value={selectedOption}
        onChange={(e) => handleOptionSelect(e.target.value)}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

const Divider = () => (
  <hr
    style={{
      margin: "20px 0",
      height: "1px",
      border: "none",
      background: "#ccc",
    }}
  />
);

const Dashboard = () => {
  const { t : itranslate } = useTranslation();
  // const options = ["Last Month", "Current Month", "YTD"];
  const options = [itranslate('dashboard.Last Month'), itranslate('dashboard.Current Month'), itranslate('dashboard.YTD')];

  const defaultOption = options[0];
  const [metrics, setMetrics] = useState([]);
  const [statsOverview, setStatsOverview] = useState([]);
  const [RCData, setRCData] = useState([]);
  const [RCs, setRCs] = useState([]);
  // const [RCnames, setRCNames] = useState(["All"]);
  const [RCnames, setRCNames] = useState([itranslate("dashboard.All")]);

  const [unit, setUnits] = useState("lastmonth");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRC, setSelectedRC] = useState();

  const handleOptionSelect = (selectedOption) => {
    if (selectedOption === options[0]) {
      setUnits("lastmonth");
    } else if (selectedOption === options[1]) {
      setUnits("currentmonth");
    } else {
      setUnits("ytd");
    }
  };

  //graph
  const [graphSelection, setGraphSelection] = useState("submitted");
  const [graphRange, setGraphRange] = useState("6");
  const [graphData, setGraphData] = useState([]);

  // const graphOptions = {
  //   "Applications Submitted": "submitted",
  //   "Applications Approved": "approved",
  //   "Lift Model Approved": "liftApproved",
  //   "Escalator Model Approved": "EscalatorApproved",
  //   "Safety Component Approved": "SCApproved",
  // };

  const graphOptions = {
    [itranslate('dashboard.Applications Submitted')]: "submitted",
    [itranslate('dashboard.Applications Approved')]: "approved",
    [itranslate('dashboard.Lift Model Approved')]: "liftApproved",
    [itranslate('dashboard.Escalator Model Approved')]: "EscalatorApproved", 
    [itranslate('dashboard.Safety Component Approved')]: "SCApproved",
  };

  const graphOptionsKeys = Object.keys(graphOptions);

  const handleGraphSelect = (selectedOption) => {
    setGraphSelection(graphOptions[selectedOption]);
  };

  const handleRangeChange = (range) => {
    setGraphRange(range);
  };

  const handleRCSelect = (selectedOption) => {
    const RC = RCs.find((rc) => rc.name === selectedOption);
    setSelectedRC(RC);
  };

  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    const params = {};
    if (startDate !== null) {
      params.startDate = startDate;
    }
    if (endDate !== null) {
      params.endDate = endDate;
    }

    const endpoint = selectedRC
      ? `${process.env.REACT_APP_ENV_ENDPOINT}/lift/metrics/rcdata/${selectedRC.id}`
      : `${process.env.REACT_APP_ENV_ENDPOINT}/lift/metrics/rcsdata`;

    axios
      .get(endpoint, {
        headers: {
          authorization: getToken(),
        },
        params: params,
      })
      .then((json) => {
        setRCData(json.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [selectedRC, startDate, endDate]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/metrics/graph`, {
        params: { graphSelection, graphRange },
        headers: {
          authorization: getToken(),
        },
      })
      .then((json) => {
        setGraphData(json.data);
      });
  }, [graphRange, graphSelection]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/metrics`, {
        params: { unit },
        headers: {
          authorization: getToken(),
        },
      })
      .then((json) => {
        setMetrics(json.data);
      });
  }, [unit]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/metrics/findrcs`, {
        headers: {
          authorization: getToken(),
        },
      })
      .then((json) => {
        const RCs = json.data;
        setRCs(RCs);

        const RCnames = RCs.map((rc) => rc.name);
        setRCNames((prevRCNames) => [...new Set([...prevRCNames, ...RCnames])]);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/metrics/stats`, {
        headers: {
          authorization: getToken(),
        },
      })
      .then((json) => {
        setStatsOverview(json.data);
      });

    axios
      .get(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/metrics/rcsdata`, {
        headers: {
          authorization: getToken(),
        },
      })
      .then((json) => {
        setRCData(json.data);
      });
  }, []);

  return (
    <>
      {/* <Title title="- Dashboard" /> */}
      <Title title={`- ${itranslate('appBar.Dashboard')}`} />

      {/* For Key Metrics */}
      <div style={{ textAlign: "center", marginTop: "30px", marginBottom: "20px" }}>
        <h1 style={{ marginBottom: "10px" }}>
          {/* Key Metrics */}
          {itranslate('dashboard.Key Metrics')}
        </h1>

        <Dropdown
          options={options}
          defaultOption={defaultOption}
          onSelect={handleOptionSelect}
        />
      </div>

      <div
        className="widgets"
        style={{
          maxWidth: "75vw",
          margin: "0 auto",
          display: "flex",
          padding: "20px",
          flexDirection: "row",
          flexWrap: "wrap", // Add this line to enable wrapping
          gap: "20px",
          justifyContent: "center",
        }}
      >
        {metrics.length > 0 &&
          metrics.map((metric, index) => (
            <MetricsBox key={index} metricsValue={metric} />
          ))}
      </div>

      <Divider />

      {/* For Statistics: Overview */}
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <h1> 
          {/* Statistics: Overview */}
          {itranslate('dashboard.Statistics Overview')}
        </h1>
      </div>
      <div
        className="widgets"
        style={{
          maxWidth: "75vw",
          margin: "0 auto",
          display: "flex",
          flexWrap: "wrap",
          padding: "20px",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        {statsOverview.length > 0 &&
          statsOverview.map((metric, index) => (
            <div key={index}>
              <MetricsBox metricsValue={metric} />
            </div>
          ))}
      </div>
      <Divider />

      {/* For Statistics: Individual RCs */}
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <h1>
          {/* Statistics: Individual RCs */}
          {itranslate('dashboard.Statistics Individual RCs')}
        </h1>
      </div>
      <div
        className="widgets"
        style={{
          display: "flex",
          padding: "20px",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dropdown
          options={RCnames}
          defaultOption={defaultOption}
          onSelect={handleRCSelect}
        />
        <DateRangeComponent onDateChange={handleDateChange} />
      </div>
      <div
        className="widgets"
        style={{
          maxWidth: "75vw",
          margin: "0 auto",
          display: "flex",
          flexWrap: "wrap",
          padding: "20px",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        {RCData.length > 0 &&
          RCData.map((metric, index) => (
            <div key={index}>
              <MetricsBox metricsValue={metric} />
            </div>
          ))}
      </div>

      <Divider />

   <div
     style={{
      maxWidth: "70vw",
      width: "100%",
      margin: "0 auto",
      padding: "20px",
      borderRadius: "5px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
     }}
   >

      {/* For Graph */}
      <div
        className="charts"
        style={{
          padding: "5px 20px",
          flexDirection: "row",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        <div
          className="widgets"
          style={{
            display: "flex",
            padding: "20px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Dropdown
            options={graphOptionsKeys}
            defaultOption={graphOptionsKeys[0]}
            onSelect={handleGraphSelect}
          />
        </div>

        {graphData && (
          <Chart data={graphData} rangeChange={handleRangeChange} />
        )}
      </div>

  </div>

      <Divider />

      {/* {process.env.REACT_APP_PHASE_TWO && <CompStatPublic /> } */}
      {<CompStatPublic /> }
    </>
  );
};

export default Dashboard;
