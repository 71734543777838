import { useState } from "react";
import { Modal, Box, IconButton, Paper } from "@mui/material";
import { Close } from "@mui/icons-material";
import { PDFViewer } from "@react-pdf/renderer";
// import DerivedAppendixForSChtml from "../DerivedAppendixForSChtml";
import DerivedAppendixForSC from "../DerivedAppendixForSC";
// import DerivedAppendixHtml from "../DerivedAppendixHtml";
import DerivedAppendixPDF from "../DerivedAppendixPDF";


const PdfModal = ({
  openModal,
  setOpenModal,
  application,
  data,
  setData,
  certificates,
  standalones,
  simpleDisplay,
}) => {
  const [showPDF, setShowPDF] = useState(true);
  const [appendix, setAppendix] = useState(
    data.appendix || {
      overviews: [],
      conditions: [],
      remarks: [],
      remarks2: [],
      approvalLetterSubject: [],
      approvalLetterBody: [],
      table: [],
      manufacturer_code: [],
      hideSCCerts: [],
      hideTableProps: [],
      hideCerts: [],
      hideComponentCodes: [],
      hideComponentCodes2: [],
      SCLineBreak: {},
    }
  );
  // handle err
  const [error, setError] = useState(null);


  // fix the height of the pdf problem when using csp
  const handlePDFLoad = () => {
    const iframe = document.getElementsByClassName("dappendix_pdf2_iframe");
    if (iframe.length === 0) return 
    const iframeDoc = iframe[0].contentWindow.document;
    const embedTag = iframeDoc.getElementsByTagName("embed")[0];
    if (embedTag) {
      embedTag.style.position = "absolute";
      embedTag.style.left = 0;
      embedTag.style.top = 0;
    }
  }


  if (error) return <div>DerivedAppendixModal Error</div>;

  if (!data) return null;
  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false);
        setData(null);
      }}
      sx={{ overflowY: "scroll", display: "flex", flexDirection: "row" }}
    >
      <Paper
        sx={{
          margin: "0",
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: 0,
            top: 50,
            zIndex: 9999,
            backgroundColor: "#ff234ab8",
          }}
          onClick={() => {
            setOpenModal(false);
            setData(null);
          }}
          aria-label="close modal"
        >
          <Close sx={{ fontSize: "40px" }} />
        </IconButton>

        <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            {application.app_type.type === "SafetyComponent"
              ? showPDF && (
                  <PDFViewer 
                    width="100%" 
                    height="100%"
                    className="dappendix_pdf2_iframe"
                    onLoad={handlePDFLoad}
                  >
                    <DerivedAppendixForSC
                      appendix={appendix}
                      application={application}
                      certificates={certificates}
                      da_url={data.url}
                    />
                  </PDFViewer>
                )
              : showPDF && (
                    <PDFViewer 
                      width="100%" 
                      height="100%"
                      className="dappendix_pdf2_iframe"
                      onLoad={handlePDFLoad}
                    >
                      <DerivedAppendixPDF
                        appendix={appendix}
                        application={application}
                        certificates={certificates}
                        standalones={standalones}
                        da_url={data.url}
                      />
                    </PDFViewer>
                )}
          </div>
        </Box>

      </Paper>
    </Modal>
  );
};

export default PdfModal;
