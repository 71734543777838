import "../../styles/appendix/Appendix.css";
import React, { useState } from "react";
import keyToField from "../../utils/keyToField";
import { alphabet, romanize } from "../../utils/romanize";
import parseAppendixJson from "../../utils/pdf/appendix_format_parser";
import moment from "moment";
import DateStringInput from "../../utils/certEditUtils/DateStringInput";
import { fixInvalidDates } from "../../utils/misc/fixInvalidDates";
import {
  renderContent,
  renderIndexedContent,
} from "../../utils/pdf/renderContent";
import renderChinese from "../../utils/pdf/renderChinese";
import PartD from "../pdf/PartD";
import { Checkbox, Box } from "@mui/material";


function AppendixHtml(props) {
  const {
    makeCorrections,
    setHash,
    cert,
    setCert,
    application,
    certificates,
    appendix,
    setAppendix,
    userInfo,
    userRoles
  } = props;
    // console.log("🚀 ~ AppendixHtml ~ appendix:", appendix)
    // console.log("🚀 ~ AppendixHtml ~ certificates:", certificates)

  const simpleDisplay = (cert_id, field) => {
    const matchCert = certificates.find((cert) => cert.id === cert_id);
    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field]
    );
  };

  const model_manufacturer = application?.model
    .flatMap((model) => model.manufacturer_files)
    .map((file) =>
      application.manufacturer.find((manu) => manu._id === file.manufacturer_id)
    );

  if (!application || !appendix || !certificates) return null;

  const rcDisplay =
    application.rc_id?.address?.company +
    " " +
    (application.model[0]?.LE_type === "E"
      ? `(REC no.: ${application.rc_id?.rc_number_escalator})`
      : `(RLC no.: ${application.rc_id?.rc_number_lift})`);

  function findAndSetHash(field) {
    const label = cert?.position_data?.find((result) => result.comment.text === field);
    if (label) setHash(label.id);
  }


  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))


  return (
    <div className="appendix">
      <div className="pt-12 pr-6 pb-12 pl-12 leading-relaxed">
        <div className="appendixHeader">
          <p>{`Application No.: ${application.application_number}`}</p>
          <p>Appendix</p>
        </div>
        <div className="appendixSection">
          <p>Technical Specifications for</p>
          <input
            disabled={isDisabled} 
            style={{
              width: "100%",
              fontFamily: "Frutiger_ultra_bold",
            }}
            value={
              appendix.title ||
              `${application.manufacturer[0].provided_name}, Model ${application.model[0].provided_model_name}`
            }
            onChange={(e) => {
              setAppendix({
                ...appendix,
                title: e.target.value,
              });
            }}
          />
        </div>

        {/* --------------------------------------------------------SECTION A------------------------------------------------------------ */}

        <p className="appendixHeader2">
          Registered{" "}
          {application.model[0]?.LE_type === "E" ? "Escalator" : "Lift"}{" "}
          Contractor: <span className="underline">{rcDisplay}</span>
        </p>

        <div>
          <p className="appendixHeader">Overview</p>
          {renderContent(
            appendix?.overviews || [],
            "plainHtml",
            application.model
          )}
        </div>

        <p style={{ fontFamily: "Frutiger_Ultra_bold", marginTop: 10 }}>
          A. Conditions:
        </p>
        {renderIndexedContent(
          appendix?.conditions || [],
          "plainHtml",
          application.model
        )}

        {/* --------------------------------------------------------SECTION B------------------------------------------------------------ */}

        <p className="appendixSection">
          B. General Operating Parameters and Characteristics
        </p>
        {application?.model.map((model, i) => {
          return <TableB appendix={appendix} model={model} setAppendix={setAppendix} key={i} />;
        })}

        {appendix?.remarks?.length > 0 && (
          <div>
            <p style={{ fontFamily: "Frutiger_light", fontWeight: "bold" }}>
              Remarks:
            </p>
            {renderIndexedContent(
              appendix?.remarks || [],
              "plainHtml",
              application.model
            )}
          </div>
        )}

        <p className="appendixHeader2">Manufacturer Code</p>
        {appendix.manufacturer_code.map((manufacturer, i) => {
          return (
            <div className="manufacturer_code" key={i}>
              <p>{String.fromCharCode(65 + i)}. </p>
              <p>{manufacturer.name}</p>
              <p>{manufacturer.address}</p>
            </div>
          );
        })}

        {application &&
          appendix.manufacturer_code.length === 0 &&
          certificates &&
          model_manufacturer.map((manufacturer, i) => {

            if (!appendix.hideCerts?.includes(manufacturer.iso_certificate_id)) {

              const iso_manufacturer_address = simpleDisplay(manufacturer.iso_certificate_id,"iso_manufacturer_address");
              let referredCert = certificates.filter((cert) => cert.id === manufacturer.iso_certificate_id)[0];  

              return (
                <div className="manufacturer_code" key={i}>
                  <p>{String.fromCharCode(65 + i)}. </p>
                  <div
                    className="manufacturer_name"
                    style={{
                      fontFamily: "Frutiger_bold",
                      fontSize: "11px",
                      ...renderChinese(manufacturer.provided_name),
                    }}
                  >
                     {manufacturer?.short_name || manufacturer?.provided_name}
                  </div>
                  
                  {!referredCert?.historical ? <input
                    className="manufacturer_address"
                    onChange={(e) =>
                      makeCorrections(
                        e.target.value,
                        manufacturer.iso_certificate_id,
                        "iso_manufacturer_address"
                      )
                    }
                    value={
                      simpleDisplay(
                        manufacturer.iso_certificate_id,
                        "iso_manufacturer_address"
                      )
                    }
                    onSelect={() =>
                      referredCert.id !== cert.id
                        ? setCert(referredCert)
                        : findAndSetHash("iso_manufacturer_address")
                    }
                  /> : <div
                    className="manufacturer_address"
                    style={renderChinese(iso_manufacturer_address)}
                  >
                    {iso_manufacturer_address}
                  </div>}
                </div>
              );
            }
          })}

        {/* --------------------------------------------------------SECTION C------------------------------------------------------------ */}

        <p className="appendixSection">
          C. List of Applicable Type Examination Certificates
        </p>
        {appendix?.remarks2?.length > 0 && (
          <div>
            <p style={{ fontFamily: "Frutiger_light", fontWeight: "bold" }}>
              Remarks:
            </p>
            {renderIndexedContent(
              appendix?.remarks2 || [],
              "plainHtml",
              application.model
            )}
          </div>
        )}

        {application?.model.map((model, i) => {
          return (
            <TableCModel
              appendix={appendix}
              application={application}
              model={model}
              model_manufacturer={model_manufacturer}
              index={i}
              key={i}
              simpleDisplay={simpleDisplay}
              makeCorrections={makeCorrections}
              cert={cert}
              setCert={setCert}
              certificates={certificates}
              findAndSetHash={findAndSetHash}
              userInfo={userInfo}
              userRoles={userRoles}
            />
          );
        })}

        {Object.keys(application?.app_type?.components)
          .filter((component_type) => {
            if (["TractionMachine", "Controller"].includes(component_type)) return false;
            return application.app_type?.components[component_type]?.length > 0;
          })
          .sort((a, b) => {
            const order = [
              "Buffer",
              "OverspeedGovernor",
              "LandingDoorLockingDevice",
              "CarDoorLockingDevice",
              "SafetyGear",
              "ACOP",
              "UCMP",
              "SafetyCircuitElectricalComponent",
            ];
            function customSort(item) {
              const index = order.indexOf(item);
              return index !== -1 ? index : order.length;
            }
            return customSort(a) - customSort(b);
          })
          .map((component_type, j) => {
            return (
              <TableCComp
                application={application}
                component_type={component_type}
                key={j}
                index={j}
                manufacturers={application?.manufacturer}
                components={application?.app_type?.components}
                simpleDisplay={simpleDisplay}
                makeCorrections={makeCorrections}
                cert={cert}
                setCert={setCert}
                certificates={certificates}
                appendix={appendix}
                findAndSetHash={findAndSetHash}
                userInfo={userInfo}
                userRoles={userRoles}
              />
            );
          })}
        <PartD
          application={application}
          appendix={appendix}
          format="plainHtml"
        />
      </div>
    </div>
  );
}

function TableB(props) {
  const { model, appendix, setAppendix } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);
  // console.log("🚀 ~ TableB ~ selectedOptions:", selectedOptions)


  const handleCheckboxChange = (e, field) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => {
        return [...prevSelectedOptions, field];
      });

      let section = structuredClone(appendix["tableB_Options"]);
      if (!section.includes(field)) section.push(field);
      setAppendix({ ...appendix, tableB_Options: section });

    } else {
      setSelectedOptions((prevSelectedOptions) => {
        return prevSelectedOptions.filter((fieldValue) => fieldValue !== field);
      });

      let section = structuredClone(appendix["tableB_Options"]);
      section = section.filter((f) => f !== field);
      setAppendix({ ...appendix, tableB_Options: section });
   };
  };


  const fieldShownForLift = [
    "provided_model_name",
    "type",
    "rated_load",
    "rated_speed",
    "maximum_travel_of_height",
    "rope_factor",
    "balancing_factor",
  ];
  const fieldShownForEscalator = [
    "provided_model_name",
    "type",
    "angle_of_inclination",
    "rated_speed",
    "maximum_rise",
  ];
  //manufacturer files is for displaying type test cert
  let modelFields =
    model.LE_type === "L" ? fieldShownForLift : fieldShownForEscalator;

  if (appendix.table?.length > 0) {
    const addedField = appendix.table.map((row) => row.field);
    modelFields.push(...addedField);
  }

  return (
    <table className="tableB">
      <tbody>
        {modelFields.map((field, i) => {
          const addedRow = appendix.table?.find((row) => row.field === field);
          
          if (
            (model[field] && !Array.isArray(model[field])) ||
            (Array.isArray(model[field]) && model[field].length > 0) ||
            addedRow
          )
            return (
              <tr key={i}>
                <td 
                  className="tableHeader"
                  style={{ minWidth : "150px",  maxWidth: "200px", }}
                >{keyToField[field] ?? field}</td>

                <td>
                  {addedRow?.value ? (
                    <Box
                       sx={{
                         display: "flex",
                         alignItems: "center",
                         justifyContent: "space-between",
                         fontFamily: (selectedOptions.includes(field) || appendix.tableB_Options.includes(field) ? "DejaVu Sans Bold Oblique" : undefined),
                         fontSize: (selectedOptions.includes(field) || appendix.tableB_Options.includes(field) ? "1.3em" : "1em"),
                         fontWeight: (selectedOptions.includes(field) || appendix.tableB_Options.includes(field) ? "bold" : undefined),
                         fontStyle: (selectedOptions.includes(field) || appendix.tableB_Options.includes(field) ? "italic" : undefined),
                         maxWidth: "300px",
                         overflowX: "auto",
                       }}
                     >
                      {parseAppendixJson(addedRow?.value, [], "plainhtml")}
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={
                          selectedOptions.includes(field) ||
                          appendix.tableB_Options.includes(field)
                        }
                        onChange={(e) => handleCheckboxChange(e, field)}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontFamily: (selectedOptions.includes(field) || appendix.tableB_Options.includes(field) ? "DejaVu Sans Bold Oblique" : undefined),
                        fontSize: (selectedOptions.includes(field) || appendix.tableB_Options.includes(field) ? "1.3em" : "1em"),
                        fontWeight: (selectedOptions.includes(field) || appendix.tableB_Options.includes(field) ? "bold" : undefined),
                        fontStyle: (selectedOptions.includes(field) || appendix.tableB_Options.includes(field) ? "italic" : undefined),
                        maxWidth: "300px",
                        overflowX: "auto",
                      }}
                    >
                      {keyToField[model[field]] || model[field]}

                      {field !== "provided_model_name" && field !== "type" ? (
                        <Checkbox
                          size="small"
                          color="primary"
                          checked={
                            selectedOptions.includes(field) ||
                            appendix.tableB_Options.includes(field)
                          }
                          onChange={(e) => handleCheckboxChange(e, field)}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  )}
                </td>
              </tr>
            );
        })}
      </tbody>
    </table>
  );
}

function TableCModel(props) {
  const {
    appendix,
    application,
    model,
    model_manufacturer,
    index,
    simpleDisplay,
    makeCorrections,
    certificates,
    cert,
    setCert,
    findAndSetHash,
    userInfo,
    userRoles
  } = props;

  if (!certificates || !application) return null;

  // Engineer will have "View Only" to appendix, if "application is Pending" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))

  const certs = certificates.filter(
    (tec) =>
      tec?.reference === model.model_code &&
      ["FullQualityCert", "ModelTypeCert"].includes(tec.type) &&
      !appendix.hideCerts?.includes(tec.id)
  );

  const checkLiftStandard = [
    "HydraulicLift",
    "ElectricTractionLiftMachineRoom",
    "ElectricTractionLiftRoomless",
  ].includes(application?.app_type?.type);

  const checkEscalatorStandard = ["Escalator", "PassengerConveyor"].includes(application?.app_type?.type);

  return (
    <React.Fragment>
      <p className="romanIndex">
        {romanize(index + 1)}. {model.LE_type === "E" ? "Escalator" : "Lift"}{" "}
        Model - {model.provided_model_name}
      </p>

      <table className="tableCmodel">
        <thead>
          <tr>
            <th>{appendix?.tecHeader || "Type Exam. Certificate"}</th>
            <th>Manufacturer Code</th>
            <th>EMSD Approval Ref./Issue Date</th>
          </tr>
        </thead>
        <tbody>

          {certs.map((tec, i) => {
            return (
              <tr key={i}>
                <td>
                  {!tec.historical ? (
                    <>
                      <input
                        disabled={isDisabled}
                        onChange={(e) =>
                          makeCorrections(
                            e.target.value,
                            tec.id,
                            "tec_certificate_number"
                          )
                        }
                        value={simpleDisplay(tec.id, "tec_certificate_number")}
                        onSelect={() =>
                          tec.id !== cert.id
                            ? setCert(tec)
                            : findAndSetHash("tec_certificate_number")
                        }
                      />{" "}
                      dated
                      <DateStringInput
                        isDisabled={isDisabled}
                        onChange={(e) =>
                          makeCorrections(
                            e.target.value,
                            tec.id,
                            "tec_issue_date"
                          )
                        }
                        value={simpleDisplay(tec.id, "tec_issue_date")}
                        onSelect={() =>
                          tec.id !== cert.id
                            ? setCert(tec)
                            : findAndSetHash("tec_issue_date")
                        }
                      />
                    </>
                  ) : (
                    <>
                      {simpleDisplay(tec.id, "tec_certificate_number")} dated{" "}
                      {fixInvalidDates(simpleDisplay(tec.id, "tec_issue_date"))}
                    </>
                  )}
                </td>
                <td>
                  {!tec.historical ? (
                    <input
                      disabled={isDisabled}
                      onChange={(e) =>
                        makeCorrections(
                          e.target.value,
                          tec.id,
                          "manufacturer_code"
                        )
                      }
                      value={simpleDisplay(tec.id, "manufacturer_code") || "A"}
                      onSelect={() =>
                        tec.id !== cert.id
                          ? setCert(tec)
                          : findAndSetHash("manufacturer_code")
                      }
                    />
                  ) : (
                    simpleDisplay(tec.id, "manufacturer_code") || "A"
                  )}
                </td>
                <td>
                  {!tec.historical ? (
                    <input
                      disabled={isDisabled}
                      onChange={(e) =>
                        makeCorrections(
                          e.target.value,
                          tec.id,
                          "Approval Reference"
                        )
                      }
                      value={simpleDisplay(tec.id, "Approval Reference")}
                      onSelect={() => {
                        if (tec.id !== cert.id) setCert(tec);
                      }}
                    />
                  ) : (
                    simpleDisplay(tec.id, "Approval Reference")
                  )}

                  {checkLiftStandard && tec.comply_standard?.lift && "[Ƨ]"}
                  {checkEscalatorStandard &&
                    tec.comply_standard?.escalator &&
                    "[#]"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
}

function TableCComp(props) {
  const {
    application,
    component_type,
    manufacturers,
    components,
    index,
    simpleDisplay,
    makeCorrections,
    certificates,
    cert,
    setCert,
    appendix,
    findAndSetHash,
    userInfo,
    userRoles
  } = props;
    // console.log("🚀 ~ component_type:", component_type)
    // console.log("🚀 ~ manufacturers:", manufacturers)
    // console.log("🚀 ~ TableCComp ~ components:", components)

  if (!certificates || !application) return null;

  
  // get unique manufacturers by manufacturer code
  let uniqueManufacturers = [];
  const manufacturerCodes = new Set();
  
  for (const manufacturer of manufacturers) {
    if (!manufacturerCodes.has(manufacturer.manufacturer_code)) {
      manufacturerCodes.add(manufacturer.manufacturer_code);
      uniqueManufacturers.push(manufacturer);
    }
  }  
  // sort the manufacturers alphabetically
  uniqueManufacturers.sort((a, b) => a.provided_name.localeCompare(b.provided_name));
  // console.log("🚀 ~ uniqueManufacturers:", uniqueManufacturers)



  const checkLiftStandard = [
    "HydraulicLift",
    "ElectricTractionLiftMachineRoom",
    "ElectricTractionLiftRoomless",
  ].includes(application?.app_type?.type);

  const checkEscalatorStandard = ["Escalator", "PassengerConveyor"].includes(application?.app_type?.type);

  // Engineer will have "View Only" to appendix, if "application is Pending" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))


  // used later to store the unique keys to remove duplicates
  const unique_Keys = new Set();

  return (
    <React.Fragment>
      <p className="romanIndex" style={{ marginTop: '20px' }}>
        {romanize(application?.model?.length + index + 1)}. Safety Components -{" "}
        {keyToField[component_type]}
      </p>

      <table className="tableCcomp">
        <thead>
          <tr>
            <th>Model</th>
            <th>Type Exam. Certificate</th>
            <th>Issue Date</th>
          </tr>
        </thead>
        <tbody>


          {/* group the components by manufacturer code */}
          {uniqueManufacturers?.map((manufacturer, i) => {
          // {manufacturers?.map((manufacturer, i) => {
            // const componentsOfManu = components[component_type].filter((compModel) => compModel.manufacturer_id === manufacturer._id);
            const componentsOfManu = components[component_type].filter((compModel) => compModel.manufacturer_code === manufacturer.manufacturer_code);
            // console.log("🚀 ~ {manufacturers?.map ~ componentsOfManu:", componentsOfManu)


            function getCertList(component) {
              const certs = certificates.filter((tec) =>
                tec?.reference === component.component_code &&
                tec.component_type === component_type &&
                tec.type === "ComponentTypeCert" &&
                !appendix.hideCerts?.includes(tec.id)
              );
              return certs;
            }; 



            /***************************  Certificates  ****************************/

            // combine all the certificates of the manufacturer
            let combinedCerts = componentsOfManu.reduce((acc, comp) => {
              const certs = getCertList(comp);
              return [...acc, ...certs];
            }, []);
            // console.log("🚀 ~ combinedCerts ~ combinedCerts:", combinedCerts)


            // remove any duplicates 
            const uniqueIds = new Set();
            let uniquefilteredCerts = combinedCerts.filter(cert => {
              
              if (uniqueIds.has(cert.id)) {
                return false; // Exclude duplicates
              }
              uniqueIds.add(cert.id); // Add to Set
              return true; // Keep this item
            });
            // console.log("🚀 ~ uniquefilteredCerts ~ uniquefilteredCerts:", uniquefilteredCerts)


            // remove further duplicates by component_type, reference (component code), description (component name), and tec_certificate_number
            const uniqueIdss = new Set();
            const uniquefilteredCertss = uniquefilteredCerts.filter(item => {
              // Call simpleDisplay to get the value for comparison
              const displayValue = simpleDisplay(item.id, "tec_certificate_number"); 
              
              // Construct a unique key based on multiple fields
              const uniqueKey = `${item.component_type}|${item.reference}|${item.description}|${displayValue}`;

              // console.log("🚀 ~ uniquefilteredCertss ~ uniqueKey:", uniqueKey)
              if (uniqueIdss.has(uniqueKey)) {
                return false; // Exclude duplicates
              }
              
              uniqueIdss.add(uniqueKey); // Add unique key to the Set
              return true; // Keep this item
            });
            // console.log("🚀 ~ uniquefilteredCerts ~ uniquefilteredCertss:", uniquefilteredCertss)

            /***************************  Certificates  ****************************/




            /***************************  Components  ****************************/

            // get the certs for each component
            const filteredComp = componentsOfManu.filter((component) => {
              const certs = getCertList(component);
              return certs.length > 0;
            });
            // console.log("🚀 ~ filteredComp ~ filteredComp:", filteredComp)


            // remove any duplicates first by component code
            // then combine the type_test_certificate_id arrays, and then remove duplicates
            let uniquefilteredComps = Array.from(filteredComp.reduce((map, comp) => {
              if (!map.has(comp.component_code)) {
                map.set(comp.component_code, { ...comp, type_test_certificate_id: [] });
              }
              // Combine and remove duplicates using a Set
              const existingIds = map.get(comp.component_code).type_test_certificate_id;
              const combinedIds = new Set([...existingIds, ...comp.type_test_certificate_id]);
              map.get(comp.component_code).type_test_certificate_id = [...combinedIds];
              
              return map;
            }, new Map()).values());
            
            // sort the components alphabetically
            uniquefilteredComps.sort((a, b) => a.provided_comp_name.localeCompare(b.provided_comp_name));
            // console.log("🚀 ~ uniquefilteredComps ~ uniquefilteredComps:", uniquefilteredComps)

            /***************************  Components  ****************************/

            

            // if (filteredComp.length > 0)
            // if (uniquefilteredComps.length > 0)
            //   return (
            //     <React.Fragment key={i}>
            //       <tr className="font-bold">
            //         <td>
            //           {manufacturer?.short_name || manufacturer?.provided_name}
            //         </td>
            //       </tr>


                  {/* {filteredComp.map((component) => {
                    const certs = getCertList(component);
                    console.log("🚀 ~ {filteredComp.map ~ certs:", certs)

                    return certs.map((tec, i) => {
                      return (
                        <tr key={i} className={!tec.historical && "italic font-bold"}>

                          <td>{component.provided_comp_name}</td>

                          <td>
                            {!tec.historical ? (
                              <input
                                disabled={isDisabled}
                                className="font-bold italic"
                                onChange={(e) =>
                                  makeCorrections(
                                    e.target.value,
                                    tec.id,
                                    "tec_certificate_number"
                                  )
                                }
                                value={simpleDisplay(
                                  tec.id,
                                  "tec_certificate_number"
                                )}
                                onSelect={() =>
                                  tec.id !== cert.id
                                    ? setCert(tec)
                                    : findAndSetHash("tec_certificate_number")
                                }
                              />
                            ) : (
                              simpleDisplay(tec.id, "tec_certificate_number")
                            )}
                          </td>

                          <td>
                            {!tec.historical ? (
                              <DateStringInput
                                isDisabled={isDisabled}
                                className="w-2/5 font-bold italic"
                                onChange={(e) =>
                                  makeCorrections(
                                    e.target.value,
                                    tec.id,
                                    "tec_issue_date"
                                  )
                                }
                                value={simpleDisplay(tec.id, "tec_issue_date")}
                                onSelect={() =>
                                  tec.id !== cert.id
                                    ? setCert(tec)
                                    : findAndSetHash("tec_issue_date")
                                }
                              />
                            ) : (
                              simpleDisplay(tec.id, "tec_issue_date")
                            )}
                            {checkLiftStandard &&
                              tec.comply_standard?.lift &&
                              "[Ƨ]"}
                            {checkEscalatorStandard &&
                              tec.comply_standard?.escalator &&
                              "[#]"}
                          </td>

                        </tr>
                      );

                    });
                  })} */}


                {/* {filteredComp.map((component) => { */}
                
                {/* {uniquefilteredComps.map((component) => {
                      return (
                        
                          uniquefilteredCerts.map((tec, i) => {
                            return (
                              <tr key={i} className={!tec.historical && "italic font-bold"}>

                              <td>{component.provided_comp_name}</td>

                              <td>
                                {!tec.historical ? (
                                  <input
                                    disabled={isDisabled}
                                    className="font-bold italic"
                                    onChange={(e) =>
                                      makeCorrections(
                                        e.target.value,
                                        tec.id,
                                        "tec_certificate_number"
                                      )
                                    }
                                    value={simpleDisplay(
                                      tec.id,
                                      "tec_certificate_number"
                                    )}
                                    onSelect={() =>
                                      tec.id !== cert.id
                                        ? setCert(tec)
                                        : findAndSetHash("tec_certificate_number")
                                    }
                                  />
                                ) : (
                                  simpleDisplay(tec.id, "tec_certificate_number")
                                )}
                              </td>
    
                              <td>
                                {!tec.historical ? (
                                  <DateStringInput
                                    isDisabled={isDisabled}
                                    className="w-2/5 font-bold italic"
                                    onChange={(e) =>
                                      makeCorrections(
                                        e.target.value,
                                        tec.id,
                                        "tec_issue_date"
                                      )
                                    }
                                    value={simpleDisplay(tec.id, "tec_issue_date")}
                                    onSelect={() =>
                                      tec.id !== cert.id
                                        ? setCert(tec)
                                        : findAndSetHash("tec_issue_date")
                                    }
                                  />
                                ) : (
                                  simpleDisplay(tec.id, "tec_issue_date")
                                )}
                                {checkLiftStandard &&
                                  tec.comply_standard?.lift &&
                                  "[Ƨ]"}
                                {checkEscalatorStandard &&
                                  tec.comply_standard?.escalator &&
                                  "[#]"}
                              </td>
    
                            </tr>
                          )
                            
                          }))
                        }
                )} */}

{/* 
                    {uniquefilteredComps.map((component) => {
                      return (
                        
                          uniquefilteredCerts.map((tec, i) => {
                            return (
                              <tr key={i} className={!tec.historical && "italic font-bold"}>

                              <td>{component.provided_comp_name}</td>

                              <td>
                                {!tec.historical ? (
                                  <input
                                    disabled={isDisabled}
                                    className="font-bold italic"
                                    onChange={(e) =>
                                      makeCorrections(
                                        e.target.value,
                                        tec.id,
                                        "tec_certificate_number"
                                      )
                                    }
                                    value={simpleDisplay(
                                      tec.id,
                                      "tec_certificate_number"
                                    )}
                                    onSelect={() =>
                                      tec.id !== cert.id
                                        ? setCert(tec)
                                        : findAndSetHash("tec_certificate_number")
                                    }
                                  />
                                ) : (
                                  simpleDisplay(tec.id, "tec_certificate_number")
                                )}
                              </td>
    
                              <td>
                                {!tec.historical ? (
                                  <DateStringInput
                                    isDisabled={isDisabled}
                                    className="w-2/5 font-bold italic"
                                    onChange={(e) =>
                                      makeCorrections(
                                        e.target.value,
                                        tec.id,
                                        "tec_issue_date"
                                      )
                                    }
                                    value={simpleDisplay(tec.id, "tec_issue_date")}
                                    onSelect={() =>
                                      tec.id !== cert.id
                                        ? setCert(tec)
                                        : findAndSetHash("tec_issue_date")
                                    }
                                  />
                                ) : (
                                  simpleDisplay(tec.id, "tec_issue_date")
                                )}
                                {checkLiftStandard &&
                                  tec.comply_standard?.lift &&
                                  "[Ƨ]"}
                                {checkEscalatorStandard &&
                                  tec.comply_standard?.escalator &&
                                  "[#]"}
                              </td>
    
                            </tr>
                          )
                            
                          }))
                        }
                  )} */}


              //   </React.Fragment>
              // );

              
              // const unique_Keys = new Set();
              
              if (uniquefilteredComps.length > 0) {
                return (
                  <React.Fragment key={i}>
                    <tr className="font-bold">
                      <td style={{ paddingTop: '12px'}}>
                        {manufacturer?.short_name || manufacturer?.provided_name}
                      </td>
                    </tr>

                    {uniquefilteredComps.map((component) => {

                      return component.type_test_certificate_id.map((certId) => {

                          // only render the cert belong to the component
                          const tec = uniquefilteredCerts.find((tec) => tec.id === certId);
                          if (!tec) return null; // Skip if no matching tec


                          const displayValue = simpleDisplay(tec.id, "tec_certificate_number")

                          // Create a unique key from the specified fields
                          const uniqueKey = `${component_type}|${component.manufacturer_code}|${component.provided_comp_name}|${displayValue}`;

                          // if (component.provided_comp_name === 'MX10 (extension)' && displayValue === '09522/10') {
                          //   console.log('uniqueKey: ', uniqueKey)
                          //   console.log('component: ', component)
                          // }

                          // Check for duplicates
                          if (unique_Keys.has(uniqueKey)) {
                            return null; // Skip rendering this duplicate
                          }

                          unique_Keys.add(uniqueKey); // Add the unique key to the Set
                          // console.log("🚀 ~ returncomponent.type_test_certificate_id.map ~ unique_Keys:", unique_Keys)


                          return (
                            <tr
                              key={tec.id}
                              className={!tec.historical && "italic font-bold"}
                            >
                              {/* <td>{component.provided_comp_name} - {component.component_code} - {tec.id} </td> */}
                              <td>{component.provided_comp_name} </td>

                              <td>
                                {!tec.historical ? (
                                  <input
                                    disabled={isDisabled}
                                    className="w-full font-bold italic"
                                    onChange={(e) =>
                                      makeCorrections(
                                        e.target.value,
                                        tec.id,
                                        "tec_certificate_number"
                                      )
                                    }
                                    value={simpleDisplay(
                                      tec.id,
                                      "tec_certificate_number"
                                    )}
                                    onSelect={() =>
                                      tec.id !== cert.id
                                        ? setCert(tec)
                                        : findAndSetHash(
                                            "tec_certificate_number"
                                          )
                                    }
                                  />
                                ) : (
                                  simpleDisplay(
                                    tec.id,
                                    "tec_certificate_number"
                                  )
                                )}
                              </td>
                              <td>
                                {!tec.historical ? (
                                  <DateStringInput
                                    isDisabled={isDisabled}
                                    className="w-3/5 font-bold italic"
                                    onChange={(e) =>
                                      makeCorrections(
                                        e.target.value,
                                        tec.id,
                                        "tec_issue_date"
                                      )
                                    }
                                    value={simpleDisplay(
                                      tec.id,
                                      "tec_issue_date"
                                    )}
                                    onSelect={() =>
                                      tec.id !== cert.id
                                        ? setCert(tec)
                                        : findAndSetHash("tec_issue_date")
                                    }
                                  />
                                ) : (
                                  // simpleDisplay(tec.id, "tec_issue_date")
                                  fixInvalidDates(simpleDisplay(tec.id, "tec_issue_date"))
                                )}
                                {checkLiftStandard &&
                                  tec.comply_standard?.lift &&
                                  "[Ƨ]"}
                                {checkEscalatorStandard &&
                                  tec.comply_standard?.escalator &&
                                  "[#]"}
                              </td>
                            </tr>
                        )
                        }                         
                      );
                    })}
                  </React.Fragment>
                );
              }

              
              return null;
          })}

        </tbody>
      </table>
    </React.Fragment>
  );
}

export default AppendixHtml;
