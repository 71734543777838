import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  Paper,
  Typography,
  Button,
  CircularProgress
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { pdfjs } from "react-pdf";
import PdfModal from "./PdfModal";
import { useTranslation } from "react-i18next";
const ShowPDF = ({ File, setFile, openModal, setOpenModal }) => {
  const [isValidPdf, setIsValidPdf] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const {t} = useTranslation("pages", { keyPrefix: "typeApprovalRecord" });

  // check if file is a valid pdf
  useEffect(() => {
    const checkPdfValidity = async () => {
      try {
        await pdfjs.getDocument({ url: File }).promise;
        setIsValidPdf(true);
      } catch (error) {
        console.error("Error loading PDF:", error);
        setIsValidPdf(false);
      }
      setIsLoading(false); 
    };
    checkPdfValidity();
  }, [File]);

  const Spinner = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '30px'}}>
      <CircularProgress />
    </Box>
  );

  if (isLoading) {
    // Show a loading state while the useEffect is running
    return (
      <Modal open={openModal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner />
        </Box>
      </Modal>
    );
  }

  return isValidPdf ? (
    <Modal
      open={openModal}
      sx={{
        overflowY: "scroll",
        display: "flex",
        flexDirection: "row",
        paddingLeft: "100px",
        paddingRight: "100px",
      }}
    >
      <Paper
        sx={{
          margin: "0",
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: 0,
            top: 50,
            zIndex: 9999,
            backgroundColor: "#ff234ab8",
          }}
          onClick={() => {
            setFile(null);
            setOpenModal(false);
          }}
        >
          <Close sx={{ fontSize: "40px" }} />
        </IconButton>

        <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
          <PdfModal pdfUrl={File} />
        </Box>
      </Paper>
    </Modal>
  ) : (
    <Modal
      open={openModal}
      sx={{
        overflowY: "scroll",
        display: "flex",
        flexDirection: "row",
        paddingLeft: "100px",
        paddingRight: "100px",
      }}
    >
      <Paper
        sx={{
          margin: "0",
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: 0,
            top: 50,
            zIndex: 9999,
            backgroundColor: "#ff234ab8",
          }}
          onClick={() => {
            setFile(null);
            setOpenModal(false);
          }}
        >
          <Close sx={{ fontSize: "40px" }} />
        </IconButton>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <>
            <Typography variant="h6" id="error-modal-title" sx={{ marginBottom: '5px'}}>
              {t("File Not Found")}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setFile(null);
                setOpenModal(false);
              }}
            >
              {t('Close')}
            </Button>
          </>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ShowPDF;
