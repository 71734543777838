/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { visuallyHidden } from '@mui/utils';
import FormControlLabel from '@mui/material/FormControlLabel';

// mui accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// mui tree view
import * as React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';


// import lodash
import { isEmpty } from 'lodash';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';

// components
import EnhancedTableHead from "../CompEnhancedTable/ModelManuListTable/EnhancedTableHead.jsx";
import TableTitle from '../CompEnhancedTable/ModelManuListTable/TableTitle'
import TablePagination from '@mui/material/TablePagination';
import BasicModalLarge from '../../../../_hoc/modals/BasicModalLarge.jsx';

// constants
import {headCells} from '../constants/headCells.js'

// redux
import { useSelector, useDispatch } from "react-redux";

// usehooks
import { useSessionStorage } from "@uidotdev/usehooks";
import { ShowBtn } from '../../../../_hoc/Buttons/ShowBtn.jsx';
import { ShowFileBtn } from '../../../../_hoc/Buttons/ShowFileBtn.jsx';

import { getModelList } from '../../../../admin/useHooks/useModels/index.jsx';
import BasicModal from '../../../../_hoc/modals/BasicModal.jsx';

import { useTranslate } from 'react-admin';

const BackBtn = styled(Button)(({ theme }) => ({
    color: "#434343",
    backgroundColor: "white",
    '&:hover': {
        color: "white",
        backgroundColor: "#434343",
    },
    variant:"outlined"
}));

export default function CreateStage2ModelManuList({stage, setStage, option, setOption, 
    // next btn
    setNextBtnDisabled, nextBtnDisabled,
    // data list
    setDataModel,
    // ru creation data
    setModel_manu_sc_id_arr, model_manu_sc_id_arr, setCertsChosen, certsChosen,
    handleGetPDFFile
}) {
    const translate = useTranslate();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [tableHeadCells, setTableHeadCells] = useState([])

    const [errMsg, setErrMsg] = useState("")

    const [selected, setSelected] = useSessionStorage("selected_CreateStage2ModelManuList", []);
    // for docs
    const [selectedTec, setSelectedTec] = useSessionStorage("selectedTec_CreateStage2ModelManuList", [])
    const [selectedIso, setSelectedIso] = useSessionStorage("selectedIso_CreateStage2ModelManuList", [])

    const [isLoading, setIsLoading] = useState(true);
    const [openLoadingModal, setOpenLoadingModal] = useState(true)
    const [openBackOptionsModal, setOpenBackOptionsModal] = useState(false)
    const [openLargeModalModelSec, setOpenLargeModalModelSec] = useState(false)

    const [data, setData] = useState([])
    const [paginatedData, setPaginatedData] = useState([])
    const [modelSec, setModelSec] = useState([])

    const [selectAllFoundItemsTrigger, setSelectAllFoundItemsTrigger] = useState(false)


    // for select all manu and certs per model
    const [manus, setManus] = useState([])
    const [tecs, setTecs] = useState([])
    const [isos, setIsos] = useState([])

    const dispatch = useDispatch();
    let previousStage = useSelector((state) => state.stage.previousValue)


    const handleBack = () => {
        setOpenBackOptionsModal(true)
    }
    
    // filter based on the selected models // init
    useEffect(() => {
        let mTemp = model_manu_sc_id_arr.flatMap(v => v.model)
        let selectedModels
        getModelList(`{}`, "[]", "[]", selectedModels={ dataType: "ids", data: mTemp}).then((res) => {
            selectedModels = res.data.sort((a,b) => b.model_code.split("RM")[1] - a.model_code.split("RM")[1])
            setData(selectedModels)
            setDataModel(selectedModels) // this is stored in session storage and modelComp step can access it
            setPaginatedData(selectedModels.slice(0, 5))
    
            let temp = selectedModels.flatMap(model => model.manufacturersPopulated.flatMap(manu => `${manu._id}-${model._id}`))
            setManus(temp)
    
            let tempTecs = selectedModels.flatMap(model => model.manufacturer_files.flatMap(v => v.type_test_certificate_id.map(tec_id => `${tec_id}-${model._id}`)))
            setTecs(tempTecs)
    
            let tempIsos = selectedModels.flatMap(model => model.manufacturersPopulated.flatMap(v => {
                return `${v.iso_certificate_id}-${model._id}`
            }))
    
            setIsos(tempIsos)
        })
        
    }, [])

    // set headCells based on the option and stage
    useEffect(() => {
        if(option === "models"){
            if(stage === "2_2"){
                setTableHeadCells(headCells.manufacturers)
            }
            if(previousStage === "2_1"){
                setSelected([])
                setSelectedTec([])
                setSelectedIso([])
            }
        }
        setSelectAllFoundItemsTrigger(false)
    }, [stage])

    // add when user selects and update certsChosen
    useEffect(() => {

        let amount = 0

        let item = model_manu_sc_id_arr
        let error = ""
        let nextBtnDisabledValue = null
        item.map(v => {
            let relatedManufacturersChosen = selected.filter(manuId_uniqueModelCode => manuId_uniqueModelCode.split('-')[1] === v.model).map(manuId_uniqueModelCode => manuId_uniqueModelCode.split('-')[0])
            if(relatedManufacturersChosen.length === 0){
                error = "Please select at least one manufacturer for each model"
                nextBtnDisabledValue = true
            } else {
                amount++
            }
            return {
                model: v.model,
                relatedManufacturersChosen: relatedManufacturersChosen
            }
        })
        
        if ( amount === item.length){
            setErrMsg("")
            setNextBtnDisabled(false)
        }
        if(error){
            setErrMsg(error)
        }
        if(nextBtnDisabledValue){
            setNextBtnDisabled(nextBtnDisabledValue)
        }

    }, [selected])

    // handle query value update
    useEffect(() => {
        if(data.length > 0){
            setIsLoading(false)
            setOpenLoadingModal(false)
        }
    }, [data])


    useEffect(() => {
        if(isLoading === false) {
            setOpenLoadingModal(false)
        } else {
            setOpenLoadingModal(true)
        }

    }, [isLoading])

    // seletAll rows
    const handleSelectAllClick = (event, modelId) => {
        if (event.target.checked) {
            const newSelected = manus.map((n) => {
                if(n.split("-")[1] === modelId){
                    return n
                } else {
                    if(isSelected(n)){
                        return n
                    } else {
                        return null
                    }
                }
            });
            setSelected(newSelected.filter(v => v !== null));
            return;
        }
        setSelected(selected.filter(n => {
			return n.split("-")[1] !== modelId
		}));
    };

    const handleSelectAllCertsClick = (event, modelId) => {
        if (event.target.checked) {
            const newSelectedTec = tecs.flatMap((n) => n.split("-")[1] === modelId ? n : isTecOptionSelectedFn(n) ? n : null)
            setSelectedTec(newSelectedTec.filter(v => v !== null));
            const newSelectedIso = isos.flatMap((n) => n.split("-")[1] === modelId ? n : isIsoOptionSelectedFn(n) ? n : null)
            setSelectedIso(newSelectedIso.filter(v => v !== null));
            return;
        }
		setSelectedTec(selectedTec.filter(n => {
			return n.split("-")[1] !== modelId
		}));
        setSelectedIso(selectedIso.filter(n => {
			return n.split("-")[1] !== modelId
		}));
    }

    // select one
    const handleClick = (event, _id) => {
        event.preventDefault()
        const selectedIndex = selected.indexOf(_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleDocOptionClick = (event, cert_type, cert_id) => {
        event.preventDefault()
        if(cert_type === "tec"){
            const selectedIndex = selectedTec.indexOf(cert_id);
            let newSelected = [];
    
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedTec, cert_id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedTec.slice(1));
            } else if (selectedIndex === selectedTec?.length - 1) {
                newSelected = newSelected.concat(selectedTec.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedTec.slice(0, selectedIndex),
                    selectedTec.slice(selectedIndex + 1),
                );
            }
            setSelectedTec(newSelected);
        } else if(cert_type === "iso"){
            const selectedIndex = selectedIso.indexOf(cert_id);
            let newSelected = [];
    
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedIso, cert_id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedIso.slice(1));
            } else if (selectedIndex === selectedIso?.length - 1) {
                newSelected = newSelected.concat(selectedIso.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedIso.slice(0, selectedIndex),
                    selectedIso.slice(selectedIndex + 1),
                );
            }
            setSelectedIso(newSelected);
        }
    };


    const isSelected = (id) => selected.indexOf(id) !== -1;
    const isTecOptionSelectedFn = (id) => selectedTec.indexOf(id) !== -1;
    const isIsoOptionSelectedFn = (id) => selectedIso.indexOf(id) !== -1;

    // row btn handlers
    const handleView = (rowInfo) => {
        window.open(`lift_rc#/manufacturers/${rowInfo._id}/show`, '_blank');
    }

    function handleSelectAllFoundItemsInAllPages(){
        if(selected.length > 0 || selectedTec.length > 0 || selectedIso.length > 0){
            setSelected([])
            setSelectedTec([])
            setSelectedIso([])
        } else {
            setSelected(data.flatMap(model => model.manufacturersPopulated.flatMap(manu => `${manu._id}-${model._id}`)))
            setSelectedTec(data.flatMap(model => model.manufacturer_files.flatMap(v => v.type_test_certificate_id.map(v => `${v}-${model._id}`))))
            setSelectedIso(data.flatMap(model => model.manufacturersPopulated.flatMap(v => `${v.iso_certificate_id}-${model._id}`)))
        }
        setSelectAllFoundItemsTrigger(!selectAllFoundItemsTrigger)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPaginatedData(data.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage));
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setPaginatedData(data.slice(0, parseInt(event.target.value, 10)));
      };

      const handleNext = (obj) => {
        obj.e.preventDefault()
        if(option === 'models'){
            if(stage === '2_2'){
                setCertsChosen({
                    tec: selectedTec.length > 0 ? selectedTec.map(v => v?.split("-")[0]) : [],
                    iso: selectedIso.length > 0 ? selectedIso.map(v => v?.split("-")[0]) : []
                })
                setModel_manu_sc_id_arr(model_manu_sc_id_arr.map(v => {
                    let relatedManufacturersChosen = selected.filter(manuId_uniqueModelCode => manuId_uniqueModelCode.split('-')[1] === v.model).map(manuId_uniqueModelCode => manuId_uniqueModelCode.split('-')[0])
                    return {
                        model: v.model,
                        relatedManufacturersChosen: relatedManufacturersChosen
                    }
                }))
                dispatch(setStage("2_3"))
            }
        }
    }

    return (
        <Container component="main" maxWidth="xl" sx={{ mb:4 }}>
            {/* Backdrop */}
            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoadingModal}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }

            <Box
                component={"div"}
                sx={{
                    height: "5em",
                    display: "flex",
                    justifyContent: "space-between",
                }}
                aria-label="menu for the table"
            >
                {/* BackBtn */}
                <Box
                    component={"div"}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                    aria-label="Back button and Help Icon"
                >
                    <BackBtn 
                        onClick={handleBack}
                        size="small"
                        sx={{
                            mb: 6,
                            border: "1px solid #434343",
                        }}
                    >
                        {translate("button.back")}
                    </BackBtn>
                </Box>

                {/* handleSelectAllFoundItemsInAllPages */}
                {
                    selected.length <= 0 && (
                        <Button
                            onClick={handleSelectAllFoundItemsInAllPages}
                            aria-label='select all found items'
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{
                                float: "right",
                                mb: 6
                            }}
                        >
                            {translate("phase2.createRu.selectAllFoundItemsInAllPages")}
                        </Button>
                    )
                }

                {
                    selected.length > 0 && (
                        <Button
                            onClick={handleSelectAllFoundItemsInAllPages}
                            aria-label='select all found items'
                            color="primary"
                            variant="outlined"
                            size="small"
                            sx={{
                                float: "right",
                                mb: 6
                            }}
                        >
                            {translate("phase2.createRu.deselect")}

                        </Button>
                    )
                }

            </Box>



            {
                paginatedData && paginatedData.map((m, i) => {
                    return (
                        <Box key={`${m._id} ${i}`} 
                            sx={{ 
                                width: '100%',
                                boxShadow: "0 0 0 2rem rgba(246,248,249)",
                            }}
                        >
                                
                            <Paper 
                                sx={{ width: '100%', mb: 2 }}
                            >
                                <Accordion 
                                    expanded={m === modelSec ? true : false} 
                                    sx={
                                        m === modelSec ? { bgcolor: "#D1D8C5" } : {}
                                    }
                                    onChange={(event, expanded) => {
                                        if(expanded){
                                            setModelSec(m)
                                            setOpenLargeModalModelSec(true)
                                        }
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className='flex'
                                    >
                                        <TableTitle data={m} />
                                        {
                                            !selected.map(v => v.split("-")[1] ).includes(m?._id) && (
                                                <div className="text-red-500 font-bold ml-8">
                                                        {`(${translate("phase2.createRu.selectAtLeastOne")} ${translate("phase2.createRu.manufacturer")})`}
                                                </div>  
                                            )
                                        }
                                    </AccordionSummary>
                                </Accordion>
                            </Paper>
                        </Box>
                    )
                })
            }

            {
                selected.length > 0 && (
                    <>
                        {
                            errMsg && (
                                <div>
                                    {errMsg}
                                </div>
                            )
                        }

                    </>

                )
            }

            <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Button
                type="submit"
                sx={{
                    // center the button
                    display: 'flex',
                    m: "auto",
                    width: "95%",
                }}
                variant="contained"
                color="primary"
                onClick={(e) => handleNext({ e })}
                aria-label="submit"
                disabled={nextBtnDisabled}
            >
                {translate("phase2.createRu.next")}
            </Button>
			{/* model's components */}
			<BasicModalLarge open={openLargeModalModelSec} setOpen={setOpenLargeModalModelSec} setOpen2={setModelSec}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='medium'
                    >
                        {
                            !isLoading && modelSec?.manufacturersPopulated?.length > 0 &&
                            (
                                <>
                                    <EnhancedTableHead
                                        onSelectAllClick={handleSelectAllClick}
                                        numSelected={selected.filter(v => v.split("-")[1] === modelSec._id).length}
                                        rowCount={modelSec?.manufacturersPopulated.length}
                                        tableHeadCells={tableHeadCells}
                                        modelSec={modelSec}
                                        certsCountPerRow={tecs.filter(v => v.split("-")[1] === modelSec._id).length + isos.filter(v => v.split("-")[1] === modelSec._id).length}
                                        numCertsSelectedPerRow={selectedTec.filter(v => v.split("-")[1] === modelSec._id).length + selectedIso.filter(v => v.split("-")[1] === modelSec._id).length}
                                        onSelectAllCertsClick={handleSelectAllCertsClick}
                                    />
                                    <TableBody>
                                            {modelSec?.manufacturersPopulated?.length > 0 && modelSec?.manufacturersPopulated?.map((row, index) => {
                                                const isItemSelected = isSelected(`${row?._id}-${modelSec?._id}`);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                let tecIds = modelSec.manufacturer_files.filter(v => v.manufacturer_id === row._id)[0].type_test_certificate_id
                                                let isoId = row?.iso_certificate_id
                                                const isIsoOptionSelected = isIsoOptionSelectedFn(`${isoId}-${modelSec._id}`);

                                                return (
                                                    <TableRow
                                                        key={`${row?._id}-${modelSec?._id}`}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                                onClick={(event) => handleClick(event, `${row?._id}-${modelSec?._id}`)}
                                                                sx={{ cursor: 'pointer' }}
                                                                aria-checked={isItemSelected}
                                                            />
                                                            <Box component="span" id={labelId} sx={visuallyHidden}>
                                                                {isItemSelected ? "selected" : "not yet selected"}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            onClick={(event) => handleClick(event, `${row?._id}-${modelSec?._id}`)}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            {row?.manufacturer_code}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ 
                                                                cursor: 'pointer', 
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                maxWidth: "8em"
                                                            }}
                                                            onClick={(event) => handleClick(event, `${row?._id}-${modelSec?._id}`)}
                                                        >{row?.provided_name}</TableCell>

                                                        <TableCell>
                                                            <TreeView
                                                                sx={{
                                                                    flexGrow: 1,
                                                                    overflowY: 'auto',
                                                                }}
                                                                aria-label="file system navigator"
                                                                defaultCollapseIcon={<ExpandMoreIcon />}
                                                                defaultExpandIcon={<ChevronRightIcon />}
                                                            >
                                                                <TreeItem nodeId="1" label="TEC">
                                                                    <ol>
                                                                        {
                                                                            !isEmpty(tecIds) && tecIds.length > 0 && tecIds.map((tecId, i) => {
                                                                                const isTecOptionSelected = isTecOptionSelectedFn(`${tecId}-${modelSec?._id}`);
                                                                                return (
                                                                                    <li
                                                                                        key={`${i}-${tecId}`}
                                                                                    >
                                                                                        <FormControlLabel
                                                                                            label="tec"
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    color="primary"
                                                                                                    checked={isTecOptionSelected}
                                                                                                    inputProps={{
                                                                                                        'aria-labelledby': labelId,
                                                                                                    }}
                                                                                                    onClick={(event) => handleDocOptionClick(event, 'tec', `${tecId}-${modelSec?._id}`)}
                                                                                                    sx={{ cursor: 'pointer' }}
                                                                                                    aria-checked={isTecOptionSelected}
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            <Box component="span" id={labelId} sx={visuallyHidden}>
                                                                                                {isTecOptionSelected ? "selected" : "not yet selected"}
                                                                                            </Box>
                                                                                            
                                                                                        </FormControlLabel>
                                                                                        <Box component="span" id={labelId}>
                                                                                            {
                                                                                                modelSec?.certsPopulated?.filter(v => v._id === tecId)[0]?.cert_type_metadata?.tec_certificate_number || 'Not Available'
                                                                                            }
                                                                                        </Box>
                                                                                        {/* view cert file, using api /lift/files/:fileId */}
                                                                                        <ShowFileBtn
                                                                                            handleGetPDFFile={handleGetPDFFile}
                                                                                            id={tecId}
                                                                                        />
                                                                                    </li>
                                                                                ) 
                                                                            })
                                                                        }
                                                                    </ol>
                                                                </TreeItem>
                                                                
                                                                <TreeItem nodeId="2" label="ISO">
                                                                    <FormControlLabel
                                                                        label="iso"
                                                                        control={
                                                                            <Checkbox
                                                                                color="primary"
                                                                                checked={isIsoOptionSelected}
                                                                                inputProps={{
                                                                                    'aria-labelledby': labelId,
                                                                                }}
                                                                                onClick={(event) => handleDocOptionClick(event, 'iso', `${isoId}-${modelSec?._id}`)}
                                                                                sx={{ cursor: 'pointer' }}
                                                                                aria-checked={isIsoOptionSelected}
                                                                            />
                                                                        }
                                                                    >
                                                                        <Box component="span" id={labelId} sx={visuallyHidden}>
                                                                            {isIsoOptionSelected ? `selected iso ${isoId}` : `did not select iso ${isoId}`}
                                                                        </Box>
                                                                    </FormControlLabel>
                                                                    <Box component="span" id={labelId}>
                                                                        {
                                                                            modelSec.manu_iso.filter(v => v.iso_certificate_id?._id.toString() === isoId)[0]?.iso_certificate_id?.cert_type_metadata?.iso_cert_number || 'Not Available'
                                                                        }
                                                                    </Box>
                                                                    <ShowFileBtn 
                                                                        handleGetPDFFile={handleGetPDFFile}
                                                                        id={isoId}
                                                                    />
                                                                </TreeItem>
                                                            </TreeView>
                                                                            
                                                        </TableCell>

                                                        <TableCell
                                                            size="small"
                                                        >
                                                            <ShowBtn 
                                                                handleView={handleView}
                                                                row={row}
                                                                code={row?.manufacturer_code}
                                                                size='small'
                                                            />

                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </>
                            )
                        }
                    </Table>
                </TableContainer>
			</BasicModalLarge>

            {/* Back option modal */}
            <BasicModal open={openBackOptionsModal} setOpen={setOpenBackOptionsModal}>
                <Box className='flex flex-col gap-3'
                    component={"div"}
                >
                    {translate("phase2.createRu.goBack")}
                    <Button
                        onClick={() => {
                            if(!(selected.length <= 0 || selected.every(v => v === undefined ||  v === null || v === ""))){
                                setSelected([])
                                setSelectedTec([])
                                setSelectedIso([])
                            }
                            dispatch(setOption("models"))
                            dispatch(setStage("2_1"))
                        }}
                    >
                        Confirm
                    </Button>
                </Box>
            </BasicModal>


        </Container>

    );
}