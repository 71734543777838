import { useState } from "react";
import { Confirm, useNotify, useRefresh } from "react-admin";
import axios from "axios";
import getToken from "../../../utils/getAuthToken";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import palette from "../../../styles/palette";


export const TransferButton = ({
  id,
  type,
  teams,
  // applicationRole,
  userInfo,
  record,
  userRoles
}) => {
  console.log("🚀 ~ type:", type)
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const [isApproving, setIsApproving] = useState(false);
  const handleClick = () => setOpen(true);
  const [selectedRole, setSelectedRole] = useState({ _id: "", role_name: "" });
  // console.log("🚀 ~ selectedRole:", selectedRole)

  const handleDialogClose = () => setOpen(false);


  const setRole = (e) => {
    let selectedEngRole;

    if (type === "Pending E") {
      selectedEngRole = engRoles.find(
        (engRole) => engRole._id === e.target.value
      );
    } else if (type === "Pending SE") {
      selectedEngRole = seRoles.find((seRole) => seRole._id === e.target.value);
    }

    if (selectedEngRole) {
      setSelectedRole({
        _id: selectedEngRole._id,
        role_name: selectedEngRole.role_name,
      });
    }
  };

  const Spinner = () => (
    <Backdrop
      sx={{ color: "#fff", zIndex: 9999, top: "-350px" }}
      open={isApproving}
    >
      <CircularProgress color="inherit" size={40} />
    </Backdrop>
  );


  const handleTransfer = async () => {
    const data = {
      id,
      type,
      selectedRole: selectedRole,
    };
    // console.log("🚀 ~ handleTransfer ~ data:", data)

    try {
      if (selectedRole._id === "" || selectedRole.role_name === "") {
        notify(`Please select a valid role`);
        return;
      };

      setIsApproving(true);
      
      const response = await axios.put(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/revoke/transferOne/${id}`,
        { data },
        {
          headers: {
            authorization: getToken(),
          },
        }
      );
      // console.log("🚀 ~ handleTransfer ~ response:", response);
      if (response.data) notify("Success")
      setSelectedRole({ _id: "", role_name: "" })
      refresh();
    } catch (error) {
      console.error("Error updating status:", error);
      if (error.response && error.response.status === 409) {
        notify(`Application already assigned to this Engineer`);
      } else {
        notify(`Error while transferring application`);
      } 
    } finally {
      setIsApproving(false);
      setOpen(false);
    }
  };



  let engRoles = [];
  let seRoles = [];
  teams &&
    teams.map((team) => {
      engRoles.push(...team.engineers);
      seRoles.push(...team.seniorEngineers);

      return null;
    });


  const handleText = () => {
    return {
      label: `Transfer to another ${type === "Pending E" ? "Engineer" : "Senior Engineer"}`,
      title: "Confirm Transfer",
      content:
        `Are you sure you want to transfer this application to another ${type === "Pending E" ? "Engineer" : "Senior Engineer"}?`,
    };
  };



  // useEffect(() => {
  //   applicationRole && setSelectedRole(applicationRole._id || "");
  // }, [applicationRole]);

 
  const isButtonDisabled =
  ((record?.status ==="Pending E" || record?.status === "Pending SE") &&
  !userRoles.includes(record?.current_role_id) && userInfo?.role !== "EMSDGR") ||
  userInfo?.role === "Inspector" ||
  userInfo?.role === "TapasAdmin" || 
  userInfo?.role === "EMSDCE" 


  return (
    <Box>
      {!isButtonDisabled && ( 
        <>
          <FormControl
            fullWidth
            sx={{
              width: "150px",
              height: "100%",
            }}
          >
            {
              <>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    width: "150px",
                    fontSize: ".8em",
                  }}
                >
                  {handleText().label}
                </InputLabel>
    
                <Select
                  sx={{ mb: 1, fontSize: ".9rem" }}
                  value={selectedRole._id}
                  onChange={setRole}
                  disabled={isButtonDisabled}
                >
                  {type === "Pending E" &&
                    engRoles &&
                    engRoles.map((engRole, index) => (
                      <MenuItem 
                        value={engRole._id} 
                        key={index}
                        sx={{ fontSize: "1rem" }}
                      >
                        {engRole.role_name}
                      </MenuItem>
                    ))}
                  {type === "Pending SE" &&
                    seRoles &&
                    seRoles.map((seRole, index) => (
                      <MenuItem 
                        value={seRole._id} 
                        key={index}
                        sx={{ fontSize: "1rem" }}
                      >
                        {seRole.role_name}
                      </MenuItem>
                    ))}
                </Select>
              </>
            }
    
            <Button
              disabled={isButtonDisabled}
              sx={{
                backgroundColor: palette.shadow,
                color: "black",
                height: "100%",
                border: "1px solid black",
                width: "150px",
                fontSize: ".8em",
              }}
              onClick={handleClick}
            >
              {handleText().label}
            </Button>
          </FormControl>
          <Confirm
            isOpen={open}
            loading={isApproving}
            title={handleText().title}
            content={
              <Box>
                <Typography>{handleText().content}</Typography>
              </Box>
            }
            onConfirm={handleTransfer}
            onClose={handleDialogClose}
          />
        </>
      )}

      <Spinner />
    </Box>
  );
};
