import { useEffect, useState } from "react";
import { Confirm, useNotify, useUpdate } from "react-admin";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import getToken from "../utils/getAuthToken";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Typography,
  TextField,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getdaAppendice, clearAappendice } from "../redux/pdfSlice";
import useDeAppendix from "../useHooks/useDeAppendix"; // hooks
import palette from "../styles/palette";
import Appendix from "../components/appendix/Appendix";
import getBase64 from "../components/appendix/getBase64";
import AppendixForSC from "../components/appendix/AppendixForSC";
import ApprovalLetter from "../components/appendix/ApprovalLetter";

export const ApproveButton = (props) => {
  const dispatch = useDispatch();
  const {
    latestApp,
    id,
    type,
    teams,
    applicationRole,
    previousStatus,
    upload,
    userInfo,
  } = props;
  const [open, setOpen] = useState(false);
  const pdfProps = useSelector((state) => state.pdf.value);
  const navigate = useNavigate();
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate();
  const [isApproving, setIsApproving] = useState(false);
  const handleClick = () => setOpen(true);
  const [selectedRole, setSelectedRole] = useState("");
  const [remark, setRemark] = useState("");
  const handleDialogClose = () => setOpen(false);
  const { saveDeAppendix } = useDeAppendix(id);
  const daAppendice = useSelector(getdaAppendice);

  const setRole = (e) => {
    setSelectedRole(e);
  };

  const Spinner = () => (
    <Backdrop
      sx={{ color: "#fff", zIndex: 9999, top: "-350px" }}
      open={isApproving}
    >
      <CircularProgress color="inherit" size={40} />
    </Backdrop>
  );

  const handleConfirm = () => {
    update(
      "applications",
      {
        id: id,
        data: {
          status: type,
          current_role_id: selectedRole,
          previousStatus: previousStatus,
          remark: remark,
        },
      },
      {
        onSuccess: () => navigate(`/applications`),
        onError: () =>
          notify(
            `Error creating record, ensure the criteria check has all been passed!`
          ),
      }
    );
    setOpen(false);
  };

  const handleApprove = async () => {
    try {
      setIsApproving(true);
      let newCertificates = [];

      const appendixBase64 = await getBase64(
        pdfProps.appendixInfo.isSC ? (
          <AppendixForSC {...pdfProps.appendixInfo} onResults={(data) => (newCertificates = data)}/>
        ) : (
          <Appendix {...pdfProps.appendixInfo} />
        )
      );
      const approvalLetterBase64 = await getBase64(
        <ApprovalLetter {...pdfProps.approvalLetterInfo} />
      );
      if (!appendixBase64 || !approvalLetterBase64) throw new Error();
      const pdf = {
        appendixBase64,
        approvalLetterBase64,
      };

      console.log("🚀 ~ handleApprove ~ newCertificates:", newCertificates)

      const res = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/apps/approve/${id}`,
        { PDFFiles: pdf, remark: remark, newCertificates: newCertificates },
        {
          headers: {
            authorization: getToken(),
          },
        }
      );

      // if (process.env.REACT_APP_PHASE_TWO) {
        // create a derived appendix record to be used for verifying qrcode
        const {
          appendix,
          _id,
          application_number,
          rc_id,
          app_type: { type },
        } = res.data.application;
        const originalAppendix = true;
        await saveDeAppendix(
          appendix,
          _id,
          application_number,
          rc_id,
          type,
          originalAppendix,
          daAppendice.url,
          daAppendice.encoded
        );
        dispatch(clearAappendice());
      // }
      notify(`Application approved`);
      navigate(`/applications`);
    } catch (err) {
      console.log(err);
      notify(`Error approving application, please try again later`);
    } finally {
      setIsApproving(false);
      setOpen(false);
    }
  };

  let engRoles = [];
  let seRoles = [];
  let ceRoles = [];
  teams &&
    teams.map((team) => {
      engRoles.push(...team.engineers);
      seRoles.push(...team.seniorEngineers);
      ceRoles.push(...team.chiefEngineer);

      return null;
    });

  const handleText = () => {
    switch (type) {
      case "Pending": {
        return {
          label: "Issues Found (Return to GR)",
          title: "Confirm Issues Found (Pass to GR)",
          content:
            "Are you sure you want to set the status of this application to Pending? The GR will receive notice",
        };
      }
      case "Clarifying": {
        return {
          label: "Set Clarifying (Return to RC)",
          title: "Confirm Set Clarifying",
          content:
            "Are you sure you want to set the status of this application to Clarifying? The RC will receive notice and be able to make changes based on your comments",
        };
      }
      case "Pending E": {
        return {
          label: "Pass to Engineer",
          title: "Confirm Pass to Engineer",
          content:
            "Are you sure you want to set the status of this application to Pending E? The Engineer will receive notice",
        };
      }
      case "Pending SE": {
        return {
          label: "Pass to SE",
          title: "Confirm Pass to SE",
          content:
            "Are you sure you want to set the status of this application to Pending SE? The SE will receive notice",
        };
      }
      case "Pending CE": {
        return {
          label: "Approve (Pass to CE)",
          title: "Confirm Approval (Pass to CE)",
          content:
            "Are you sure you want to set the status of this application to Pending CE? The CE will receive notice and provide final approval",
        };
      }
      case "Approved": {
        return {
          label: "Approve Application",
          title: "Confirm Set Approved",
          content:
            "Are you sure you want to set the status of this application to Approved?",
        };
      }
      case "Rejected": {
        return {
          label: "Deny (Return to RC)",
          title: "Confirm Set Rejected",
          content:
            "Are you sure you want to set the status of this application to Rejected? Rejected applications cannot be amended and the applicant will have to make a new application",
        };
      }
      case "Withdrawn": {
        return {
          label:
            window.location.pathname === "/lift_emsd"
              ? "Withdraw (Return to RC)"
              : "Withdraw Application",
          title: "Confirm Set Withdrawn",
          content:
            "Are you sure you want to set the status of this application to Withdrawn? Withdrawn applications cannot be amended and the applicant will have to make a new application",
        };
      }
      default: {
        return {
          label: "Default Label",
          title: "Default Title",
          content: "Default Content",
        };
      }
    }
  };

  const handleRemark = (e) => {
    setRemark(e.target.value);
  };

  useEffect(() => {
    applicationRole && setSelectedRole(applicationRole._id || "");
  }, [applicationRole]);
  const isButtonDisabled =
    (userInfo?.role === "EMSDGR" && //GR and current role name is not GR and not pending
      (upload?.status.includes("Pending E") ||
        upload?.status.includes("Pending SE") ||
        upload?.status.includes("Pending CE")) &&
      upload?.current_role_name !== "GR") ||
    userInfo?.role === "Inspector"; //Inspector
  // || (userInfo?.role !== "EMSDGR" && //Check roleId except GR
  //    !userRoles.includes(upload?.current_role_id));

  return (
    <Box>
      <FormControl
        fullWidth
        sx={{
          width: "250px",
          height: "100%",
        }}
      >
        {type !== "Pending" &&
          type !== "Rejected" &&
          type !== "Withdrawn" &&
          type !== "Clarifying" &&
          type !== "Approved" && (
            <>
              <InputLabel id="demo-simple-select-label">
                {handleText().label}
              </InputLabel>

              <Select
                sx={{ mb: 1 }}
                value={selectedRole}
                onChange={(event) => setRole(event.target.value)}
              >
                {type === "Pending E" &&
                  engRoles &&
                  engRoles.map((engRole, index) => (
                    <MenuItem value={engRole._id} key={index}>
                      {engRole.role_name}
                    </MenuItem>
                  ))}
                {type === "Pending SE" &&
                  seRoles &&
                  seRoles.map((seRole, index) => (
                    <MenuItem value={seRole._id} key={index}>
                      {seRole.role_name}
                    </MenuItem>
                  ))}
                {type === "Pending CE" &&
                  ceRoles &&
                  ceRoles.map((ceRole, index) => (
                    <MenuItem value={ceRole._id} key={index}>
                      {ceRole.role_name}
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}

        <Button
          disabled={isButtonDisabled}
          sx={{
            backgroundColor: palette.shadow,
            color: "black",
            height: "100%",
            border: "1px solid black",
          }}
          onClick={handleClick}
        >
          {handleText().label}
        </Button>
      </FormControl>
      <Confirm
        isOpen={open}
        loading={type === "Approved" ? isApproving : isLoading}
        title={handleText().title}
        content={
          <Box>
            <Typography>{handleText().content} </Typography>
            {latestApp?.application_number &&
              upload?.update_upon &&
              latestApp.application_number !== upload.update_upon && (
                <Typography sx={{ color: "red" }}>
                  This application does not contain the latest approved
                  application data, check application info for refreshing
                </Typography>
              )}
            {/* CE approval no need this remarks. Just confirm button is ok for CE approval. Still need when convert back to SE */}
            <TextField
              value={remark}
              label="remarks"
              variant="standard"
              onChange={handleRemark}
              inputProps={{ maxLength: 500 }}
              fullWidth
            />
          </Box>
        }
        onConfirm={type === "Approved" ? handleApprove : handleConfirm}
        onClose={handleDialogClose}
      />

      <Spinner />
    </Box>
  );
};
