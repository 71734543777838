import {
  Card,
  CardContent,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  CardHeader,
  Button,
  Autocomplete,
  TextField,
  ListItem,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetList, useRefresh, useDelete, useNotify } from "react-admin";
import { confirmAlert } from "react-confirm-alert";
import RecordSelect from "./RecordSelect";

import {
  SelectApplicationType,
  SelectScType,
  SelectSystemType,
  SelectLEtype,
} from "../../components/create/SelectInputs";

import { useDispatch } from "react-redux";
import { setComponents } from "../../redux/componentsSlice";
import _ from "lodash";
import RecordLogs from "./RecordLogs";
import DescriptionInput from "./DescriptionInput";

// admin assign user that can use tis function
function RecordManagement(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notify = useNotify();
  const [deleteOne, { isLoading }] = useDelete();
  const refresh = useRefresh();

  const [applicationFlow, setApplicationFlow] = useState({
    action: "update",
    system_type: "lift",
    application_type: "add sc",
    sc_type: "standAloneSc",
  });

  const [upload, setUpload] = useState({});
  const [value, setValue] = useState("new");
  const [rc_id, setRc] = useState("");
  const [manufacturer, setManufacturer] = useState("");

  const [app, setApp] = useState({
    id: "",
    deleteRequestId: "",
    label: "",
    manufacturers: [],
  });

  const [chosenComponents, setChosenComponents] = useState([]); // For safety component, storing the applicationId of chosen component
  const [chosenComponentsPopulated, setChosenComponentsPopulated] = useState([]);

  const { data: rcs } = useGetList("rcs", {
    pagination: { page: 1, perPage: 0 },
  });

  const selectProps = {
    sx: { mt: 2 },
    fullWidth: true,
    label: "operation",
  };

  const reset = () => {
    setApp({ id: "", deleteRequestId: "", label: "" });
    setChosenComponents([]);
    setChosenComponentsPopulated([]);
  };

  const handleTarget = (value) => {
    setApp(value);
    setManufacturer("");
  };

  const handleMultipleTarget = (value, componentsLoad) => {
    const uniqueRc = _.uniqBy(value, "rc_id");
    if (uniqueRc.length > 1)
      return notify("You cannot choose more than one RC");

    const ids = value.map((comp) => comp.id);
    const populated = componentsLoad.filter((comp) => ids.includes(comp.id));

    setChosenComponents(value);
    setChosenComponentsPopulated(populated);
  };

  const handleAction = (e) => {
    setValue(e.target.value);
    reset();
  };

  const handleRc = (e) => {
    setRc(e.target.value);
    reset();
  };

  const handleManufacturer = (e) => {
    setManufacturer(e.target.value);
  };

  const handleApplicationFlow = (
    action,
    system_type,
    application_type,
    sc_type
  ) => {
    // Check applicationCreate.js for details comment
    let newFlow;
    if (system_type) {
      newFlow = { ...applicationFlow, system_type: system_type };
    } else if (application_type) {
      newFlow = {
        ...applicationFlow,
        application_type: application_type,
        system_type: "lift",
      };
    } else if (sc_type) {
      newFlow = { ...applicationFlow, sc_type: sc_type };
    }
    reset();
    setApplicationFlow(newFlow);
  };

  const getMode = (applicationFlow) => {
    // a: Manufacturer Name Change
    // b: Model Cert Update (Range Extension)
    // c: LE model range extension
    // d: Update SC Certificate
    // e: SC range extension
    // f: New SC from New Manufacturer
    const type = applicationFlow.application_type;
    const system = applicationFlow.system_type;

    let mode = "";
    if (type === "add sc") {
      mode = "f";
    } else if (type === "manu name change") {
      mode = "a";
    } else if (type === "update cert") {
      mode = system === "lift" ? "b" : system === "sc" && "d";
    } else if (type === "range ext") {
      mode = system === "lift" ? "c" : system === "sc" && "e";
    }

    return mode;
  };

  const isMultipleStandalone =
    ["d", "e"].includes(getMode(applicationFlow)) &&
    applicationFlow.sc_type === "standAloneSc";

  const handleContinue = () => {
    const mode = getMode(applicationFlow);

    let query = mode === "a" ? `?manufacturer=${manufacturer}` : "";
    if (value !== "new" && !isMultipleStandalone && !app.id)
      return notify("Please select a record");
    if (mode === "a" && !manufacturer)
      return notify("Please select a manufacturer");

    if (isMultipleStandalone) {
      if (chosenComponents.length === 0)
        return notify("Please select components");
      dispatch(setComponents(chosenComponentsPopulated));
      navigate(
        `/record_management/applications_${mode}/${chosenComponents[0].app_id}`
      );
    } else
      navigate(
        `/record_management/${
          value === "new"
            ? "new"
            : value === "component_update" //Update standalone safety component (current record)
            ? "component"
            : value === "model_update" //Update lift/escalator model (current record)
            ? "applications_record"
            : `applications_${mode}`
        }/${value !== "new" ? app.id : ""}${query}`
      );
  };

  const handleDelete = () => {
    if (!upload?.description) return notify("Please fill in the remark");
    confirmAlert({
      title: "Confirm to delete record",
      message: `Are you sure to do this.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteOne(
              `records/${value}`, //either model_delete or component_delete
              {
                id: app.deleteRequestId,
                meta: { description: upload.description },
              },
              {
                onSuccess: () => {
                  setApp({ id: "", deleteRequestId: "", label: "" });
                  notify("Record deleted");
                  refresh();
                },
                onError: (e) => notify(e.message),
              }
            );
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <Card sx={{ mt: 2.5 }}>
      <CardHeader title="Record Management" />
      <CardContent>
        <>
          <InputLabel sx={{ mt: 4 }} id="action">
            Choose type of action
          </InputLabel>
          <Select
            {...selectProps}
            value={value}
            onChange={handleAction}
            labelId="action"
            id="action"
          >
            <MenuItem value={"new"}>
              Brand new application / Add standalone Safety Component
            </MenuItem>
            <MenuItem value={"update"}>Update application</MenuItem>
            <MenuItem value={"model_update"}>
              Update lift/escalator model (current record)
            </MenuItem>
            <MenuItem value={"component_update"}>
              Update standalone safety component (current record)
            </MenuItem>
            <MenuItem value={"model_delete"}>
              Delete lift/escalator model record
            </MenuItem>
            <MenuItem value={"component_delete"}>
              Delete standalone safety component record
            </MenuItem>
          </Select>
        </>

        {/*  
        a: "Manufacturer Name Change"
        b: "Update lift/escalator model certificate"
        c: "Lift/escalator model range extension"
        d: "Update safety component certificate"
        e: "Safety component range extension"
        f: "Add new safety component"
        */}
        {value === "update" && (
          <>
            <SelectApplicationType
              application_type={applicationFlow.application_type}
              handleApplicationFlow={handleApplicationFlow}
            />
            {(applicationFlow.application_type === "update cert" ||
              applicationFlow.application_type === "range ext") && (
              // Cert update/Range extension
              <>
                <SelectSystemType
                  system_type={applicationFlow.system_type}
                  handleApplicationFlow={handleApplicationFlow}
                />
                {applicationFlow.system_type === "sc" && (
                  <SelectScType
                    sc_type={applicationFlow.sc_type}
                    handleApplicationFlow={handleApplicationFlow}
                  />
                )}
              </>
            )}
          </>
        )}

        {value !== "new" && (
          <>
            <InputLabel sx={{ mt: 4 }} id="rcs">
              Choose RC
            </InputLabel>
            {rcs && (
              <Select
                {...selectProps}
                value={rc_id}
                onChange={handleRc}
                labelId="rcs"
                id="rcs"
              >
                {rcs.map((rc) => {
                  let rcDisplay = "";
                  if (rc.address?.company_chinese)
                    rcDisplay += rc.address?.company_chinese + " ";
                  if (rc?.rc_number_lift) rcDisplay += rc?.rc_number_lift;
                  if (rc?.rc_number_escalator)
                    rcDisplay += " / " + rc?.rc_number_escalator;
                  return (
                    <MenuItem value={rc.id} key={rc.id}>
                      {rcDisplay || rc.name}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
            <RecordSelect
              app={app}
              type={
                value === "update"
                  ? applicationFlow.system_type === "lift" ||
                    applicationFlow.system_type === "escalator" ||
                    (applicationFlow.system_type === "sc" &&
                      applicationFlow.sc_type === "leModelSc")
                    ? "model"
                    : "component"
                  : value.split("_")[0]
              } //either model or component
              handleTarget={handleTarget}
              handleMultipleTarget={handleMultipleTarget}
              rc_id={rc_id}
              multiple={isMultipleStandalone}
              chosenComponents={chosenComponents}
            />
            {value === "update" &&
              applicationFlow.application_type === "manu name change" && (
                <>
                  <InputLabel sx={{ mt: 4 }} id="manufacturerSelectLabel">
                    Choose a manufacturer
                  </InputLabel>{" "}
                  <Select
                    sx={{ mt: 2 }}
                    fullWidth
                    labelId="manufacturerSelectLabel"
                    id="manufacturerSelect"
                    defaultValue=""
                    label="operation"
                    onChange={handleManufacturer}
                  >
                    {app.manufacturers?.map((manu) => (
                      <MenuItem key={manu._id} value={manu._id}>
                        {manu.provided_name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
          </>
        )}
        {value.includes("delete") ? (
          <>
            <DescriptionInput upload={upload} setUpload={setUpload} />
            <Button onClick={handleDelete}>Delete</Button>
          </>
        ) : (
          <Button onClick={handleContinue}>Continue</Button>
        )}
      </CardContent>
      <CardHeader title="Logs" />
      <CardContent>
        <RecordLogs />
      </CardContent>
    </Card>
  );
}

export default RecordManagement;
