import { SelectInput, TextInput, ReferenceInput, Filter } from "react-admin";
import { BooleanInput } from "react-admin";

const ModelFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        source="q"
        label="Search model code / model name / manufacturer / type / RC / cert data"
        alwaysOn
        sx={{ width: "600px" }}
      />
      <TextInput label="Model Code" source="model_code" defaultValue="RM" />
      <TextInput label="Model Name" source="provided_model_name" />
      <TextInput label="Component Code" source="component_code" defaultValue="RC"/>
      <TextInput label="Component Name" source="provided_comp_name" />

      <SelectInput
        label="Type of Machine"
        source="type"
        choices={[
          {
            id: "ElectricTractionLiftMachineRoom",
            name: "Electric Traction Lift (Machine Room)",
          },
          {
            id: "ElectricTractionLiftRoomless",
            name: "Electric Traction Lift (Machine Roomless)",
          },
          { id: "InclinedLift", name: "Electric Inclined Lift" },
          { id: "ServiceLift", name: "Service Lift" },
          { id: "StairLift", name: "Stairlift" },
          { id: "HydraulicLift", name: "Hydraulic Lift" },
          {
            id: "ElectricalVerticalLiftingPlatform",
            name: "Vertical Lifting Platform",
          },
          { id: "MVPS", name: "Mechanized Vehicle Parking System (MVPS)" },
          { id: "Escalator", name: "Escalator" },
          { id: "PassengerConveyor", name: "Passenger Conveyor" },
          { id: "SafetyComponent", name: "Safety Component" },
          { id: "Others", name: "Others" },
        ]}
      />
      <TextInput
        label="Manufacturers"
        source="manufacturersPopulated.provided_name"
      />
      {window.location.pathname === "/lift_emsd" && (
        <ReferenceInput source="rc_id" label="RC" reference="rcs" perPage={100}>
          {/* <SelectInput optionText="name" /> */}
          <SelectInput optionText={choice => {
            let displayText = "";
            displayText = choice?.rc_number_lift;
            if (choice?.rc_number_escalator) {
              displayText += ` / ${choice.rc_number_escalator}`;
            }
            if (choice?.address?.company) {
              displayText += ` / ${choice.address.company}`;  
            }
            return displayText;
          }} />  
        </ReferenceInput>
      )}
      <TextInput label="Rated Speed" source="rated_speed" />
      <TextInput label="Rated Load" source="rated_load" />
      <BooleanInput label="Comply standard" source="comply_standard" />
    </Filter>
  );
};

export default ModelFilter;
