import {
  Modal,
  Button,
  Box,
  ButtonGroup,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  Tabs,
  Tab,
} from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import { useNotify } from "react-admin";
import Appendix from "./Appendix";
import AppendixForSChtml from "./AppendixForSChtml";
import AppendixForSC from "./AppendixForSC";
import AppendixHtml from "./AppendixHtml";
import ApprovalLetter from "./ApprovalLetter";
import { Close, FormatBold, FormatUnderlined, FormatItalic } from "@mui/icons-material";
import AppendixEditInput from "./editAppendix/AppendixEditInput";
import CertValuesPopper from "./CertValuesPopper";
import StyleTagPopper from "./styleTagPopper";
import getToken from "../../utils/getAuthToken";
import CertEditArea from "./CertEditArea";
import CertPdfDisplay from "./CertPdfDisplay";
import { PDFViewer } from "@react-pdf/renderer";
import { useDispatch } from "react-redux";
import { useGetOne, Confirm } from "react-admin";
import {
  setAppendixInfo,
  setApprovalLetterInfo,
  setAappendice,
  clear,
} from "../../redux/pdfSlice";
import _ from "lodash";
import DocumentList from "./DocumentList";
import { useGetList } from "react-admin";


import useMediaQuery from '@mui/material/useMediaQuery';
import { borderRadius } from "@mui/system";



const buttonStyle = {
  fontSize: "0.62em",
  padding: "3px 2px",
};

const buttonFontSize = {
  fontSize: "1.2em"
};


const AppendixModal = (props) => {
  const notify = useNotify();
  const matches = useMediaQuery('(max-width: 600px)');

  const dispatch = useDispatch();
  const {
    openAppendix,
    setOpenAppendix,
    application,
    setUpload,
    certificates,
    setCertificates,
    originalCertificates,
    savedData,
    setSavedData,
    data,
    cert,
    setCert,
    makeCorrections,
    sendCorrections,
    deleteField,
    simpleDisplay,
    userInfo,
    userRoles,
    allowManageRecords
  } = props;
  //get extra standalones component for rendering appendixSC, enabled only when application type is SC
  const { data: standalones } = useGetOne(
    "components/standalones",
    {
      id: application?.rc_id?._id,
    },
    { enabled: application?.app_type.type === "SafetyComponent" }
  );
  const { data: signatureResponse } = useGetList("options/signature");
  const currentCert = certificates?.find((c) => c.id === cert?.id); //after the changes to makeCorrections, it no longer mutate cert varaible, hence we need to find the cert which reflect the latest change
  const [appendix, setAppendix] = useState(data);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [showCoverLetter, setShowCoverLetter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl_styleDisplay, setAnchorElStyle] = useState(null);
  const [section, setSection] = useState("conditions");
  const [text, setText] = useState({
    text: "",
    range: [],
    index: null,
    subIndex: null,
    section: null,
  });
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClick_styleDisplay = (event) => {
    setAnchorElStyle(anchorEl_styleDisplay ? null : event.currentTarget);
  };
  const openPopper = Boolean(anchorEl);
  const id = openPopper ? "simple-popper" : undefined;

  const handleSave = async () => {
    try {
      await storeBase64(certificates, appendix);
      await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/apps/appendix/${application.id}`,
        { appendix },
        {
          headers: { authorization: getToken() },
        }
      );
      await sendCorrections();
      setSavedData({ certificates, appendix });
      notify("Successfully saved");
      return true;
    } catch (err) {
      notify(err.message);
      return false;
    }
  };

  const handleDialogCloseWithSave = async (save) => {
    try {
      if (save) {
        const isSaved = await handleSave();
        if (!isSaved) throw new Error("Error saving");
      } else {
        setCertificates(savedData.certificates || originalCertificates); //reset to user saved data / default cert data
        setAppendix(savedData.appendix || data);
      }
      setOpenAppendix(false);
    } catch (err) {
      notify(err.message);
    } finally {
      setConfirmOpen(false);
    }
  };

  // this will populate the "manufacturer_code" array in the appendix, if we have LEOSdata, and if the "manufacturer_code" array in the appendix is empty
  useEffect(() => {
    if(confirmOpen === false) {
      // console.log(application?.model?.[0]?.LEOSdata?.[0]?.manufacturer_code)
      let tempArray = [];
      let tempObject = {};
      application?.model?.[0]?.LEOSdata?.[0]?.manufacturer_code?.map((m) => {
        tempObject.name = m.short_name;
        tempObject.address = m.address
        tempArray.push(tempObject)
        tempObject = {}
      });

      if (appendix?.manufacturer_code && appendix?.manufacturer_code?.length === 0) {
        setAppendix({
          ...appendix,
          manufacturer_code: tempArray
        })
      }
    }
  },[application, confirmOpen])

  useEffect(() => {
    //Don't remove it, sometimes react admin will return the old cache data and this is to ensure we get the latest data
    if (data) {
      setAppendix(data);
    }
  }, [data]);

  const storeBase64 = async (certificates, appendix) => {
    //certs can be either originalCertificates or certificates, certificates can be updated by user and is used on handleSave
    if (
      application &&
      certificates &&
      (application.app_type.type === "SafetyComponent" ? standalones : true) &&
      appendix
    ) {
      let res;
      try {
        res = await axios.get(
          `${process.env.REACT_APP_ENV_ENDPOINT}/api2/apps/createURL/${application.application_number}`,
          { headers: { authorization: getToken() } }
        );
      } catch (err) {
        console.log(err);
      }
      dispatch(
        setAppendixInfo({
          isSC: application.app_type.type === "SafetyComponent",
          appendix,
          application,
          certificates,
          standalones,
          qrcode_url: res?.data?.url,
        })
      );
      dispatch(
        setApprovalLetterInfo({
          appendix,
          application,
          format: "pdf",
          signatureResponse,
        })
      );
      if (res) dispatch(setAappendice(res?.data)); // storing url and encooded data in redux - to be used to generate qrcode
    }
  };

  useEffect(() => {
    //Saving base64 of appendix and approval letter on applicationShow for ApproveButton API call
    storeBase64(originalCertificates, data);
    return () => {
      dispatch(clear());
    };
  }, [application, originalCertificates, standalones, signatureResponse, data]);

  const formatText = (mode) => {
    try {
      // text is a state set in AppendixEditInput.js when user highlight text
        let section = structuredClone(appendix[text.section]);
        let content = text.section === "table" ? section[text.index]["value"] : section[text.index][text.subIndex];
        const before = content.substring(0, text.range[0]);
        const after = content.substring(text.range[1]);
        function getStyleTag() {
          let result = "";
          switch (mode) {
            case "ultraBold":
              result = "**";
              break;
            case "bold":
              result = "**~";
              break;
            case "underlineBold":
              result = "**__";
              break;
            case "underline":
              result = "__";
              break;
            case "italic":
              result = "**_";
              break;
            case "chineseLight":
              result = "##";
              break;
            case "chineseBold":
              result = "###";
              break;
            default:
              result = "";
              break;
          }
          return result;
        }
        const newContent =
          before +
          getStyleTag() +
          text.text +
          getStyleTag().split("").reverse().join("") +
          after;

        if (text.section === "table") {
          section[text.index]["value"] = newContent;
        } else {
          section[text.index][text.subIndex] = newContent;
        }

        setAppendix({ ...appendix, [text.section]: section });
        /* const text = { text: "", range: [], index: null, subIndex: null, section: null };
      setText(text); */
    } catch (err) {
      console.log(err);
    } finally {
      setText({
        text: "",
        range: [],
        index: null,
        subIndex: null,
        section: null,
      });
    }
  };

  // --------------------------   Method From PDF Highlighter Below ----------------------------- //

  const [hash, setHash] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const ref = useRef();
  const parseIdFromHash = () => {
    return hash;
  };
  const resetHash = () => {
    setHash("");
  };
  const getNextId = () => String(Math.random()).slice(2);
  const scrollToHighlightFromHash = () => {
    const highlight = getHighlightById(parseIdFromHash());
    if (highlight && ref.current) {
      ref.current.scrollTo(highlight);
    }
  };
  useEffect(() => {
    if (hash) {
      scrollToHighlightFromHash();
    }
  }, [hash]);

  const getHighlightById = (id) => {
    return currentCert.position_data.find((highlight) => highlight.id === id);
  };


  // fix the height of the pdf problem when using csp
  const handlePDFLoad = () => {
    const iframe = document.getElementsByClassName("appendix_pdf_iframe");
    if (iframe.length === 0) return 
    const iframeDoc = iframe[0].contentWindow.document;
    const embedTag = iframeDoc.getElementsByTagName("embed")[0];
    if (embedTag) {
      embedTag.style.position = "absolute";
      embedTag.style.left = 0;
      embedTag.style.top = 0;
    }
  }


  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled = 
    (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") || 
    (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
    (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))


  // const handleConfirm = () => {
  //   if (!isDisabled) {
  //     handleDialogCloseWithSave(false);
  //     setConfirmOpen(false);
  //   }
  // };


  return (
    <Modal
      open={openAppendix}
      onClose={() => setOpenAppendix(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", flexDirection: "row", width: "100vw", height: "100vh" }}
    >
      {!application || !certificates || !appendix ? (
        <CircularProgress
          size="3rem"
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          color="inherit"
        />
      ) : (
        <Paper
          sx={{
            margin: "0",
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: 0,
              zIndex: 9999,
              backgroundColor: "#ff234ab8",
            }}
            onClick={() => {
              setConfirmOpen(true);
            }}
          >

            {/* close icon and confirm dialog */}
            <Close sx={{ fontSize: "40px" }} />
            <Confirm
              isOpen={confirmOpen}
              title="Save changes?"
              content="Click confirm to save, changes will be lost if cancelled."
              onConfirm={() => isDisabled ? handleDialogCloseWithSave(false) : handleDialogCloseWithSave(true)}
              onClose={() => handleDialogCloseWithSave(false)}
              // confirmDisabled={isDisabled}
            />
          </IconButton>

{/* top bar */}
          <ButtonGroup
            variant="contained"
            color="inherit"
            sx={{ position: "absolute", left: 0, zIndex: 9999 }}
            // size="small"
          >
            <Button disabled={isDisabled} sx={buttonStyle} onClick={() => handleSave()}>Save</Button>
            <Button disabled={isDisabled} sx={buttonStyle} onClick={() => formatText("ultraBold")}>
              Ultra
              <FormatBold sx={buttonFontSize} />
            </Button>
            <Button disabled={isDisabled} sx={buttonStyle} onClick={() => formatText("bold")}>
              <FormatBold sx={buttonFontSize} />
            </Button>
            <Button disabled={isDisabled} sx={buttonStyle} onClick={() => formatText("underlineBold")}>
              <FormatBold sx={buttonFontSize}/> +
              <FormatUnderlined sx={buttonFontSize}/>
            </Button>
            <Button disabled={isDisabled} sx={buttonStyle} onClick={() => formatText("underline")}>
              <FormatUnderlined sx={buttonFontSize}/>
            </Button>
            <Button disabled={isDisabled} sx={buttonStyle} onClick={() => formatText("italic")}>
              <FormatItalic sx={buttonFontSize}/>
            </Button>
            <Button disabled={isDisabled} sx={buttonStyle} onClick={() => formatText("chineseLight")}>
             Chinese
            </Button>
            <Button disabled={isDisabled} sx={buttonStyle} onClick={() => formatText("chineseBold")}>
              Chinese
              <FormatBold sx={buttonFontSize}/>
            </Button>
            <Button sx={buttonStyle} aria-describedby={id} onClick={handleClick}>
              Certificate Value
            </Button>
            <Button disabled={isDisabled} sx={buttonStyle} aria-describedby={id} onClick={handleClick_styleDisplay}>
              Format Codes
            </Button>
            <Button sx={buttonStyle} onClick={() => setShowPDF((prevState) => !prevState)}>
              {!showPDF ? "Review  PDF" : "Preview"}
            </Button>
            <Button
              sx={buttonStyle}
              onClick={() => {
                setShowCoverLetter((prevState) => !prevState);
                setSection(
                  showCoverLetter ? "conditions" : "approvalLetterBody"
                );
              }}
            >
              {showCoverLetter ? "Appendix" : "Approval letter"}
            </Button>
          </ButtonGroup>

          {/* popper */}
          <CertValuesPopper anchorEl={anchorEl} certificates={certificates} />
          <StyleTagPopper anchorEl_styleDisplay={anchorEl_styleDisplay} />

{/* left side column, top-left body */}
          <Box className="w-2/6">
            <div 
              className="overflow-y-scroll h-3/5"
            >
              <Tabs
                variant="scrollable"
                scrollButtons
                value={section}
                onChange={(e, value) => setSection(value)}
                textColor="primary"
                sx={{ mt: 5 }}
              >
                {showCoverLetter && [
                  <Tab label="References" value="approvalLetterMisc" key="approvalLetterMisc" />,
                  <Tab
                    label="Subject"
                    value="approvalLetterSubject"
                    key="Subject"
                  />,
                  <Tab label="Body" value="approvalLetterBody" key="Body" />,
                ]}

                {application.app_type.type !== "SafetyComponent" &&
                  !showCoverLetter && [
                    <Tab label="Overviews" value="overviews" key="overviews" />,
                    <Tab
                      label="Conditions"
                      value="conditions"
                      key="conditions"
                    />,
                    <Tab
                      label="Manufacturers"
                      value="manufacturer_code"
                      key="manufacturers"
                    />,
                    <Tab label="Remarks" value="remarks" key="remarks" />,
                    <Tab
                      label="Remarks (part C)"
                      value="remarks2"
                      key="remarks2"
                    />,
                    <Tab label="Table B" value="table" key="table B" />,
                    <Tab label="Table C" value="hideCerts" key="table C" />,
                    <Tab label="Part D" value="partD" key="partD" />,
                    <Tab
                      label="Remarks (part D)"
                      value="partDremarks"
                      key="partDremarks"
                    />,
                  ]}

                {application.app_type.type === "SafetyComponent" &&
                  !showCoverLetter && [
                    <Tab
                      label="conditions"
                      value="conditions"
                      key="conditions"
                    />,
                    <Tab label="remarks" value="remarks" key="remarks" />,
                    <Tab
                      label="line break"
                      value="SCLineBreak"
                      key="line_break"
                    />,
                    <Tab label="Columns" value="SCHideColumns" key="SCHideColumns" />,
                  ]}
              </Tabs>
              <AppendixEditInput
                application={application}
                standalones={standalones}
                setUpload={setUpload}
                appendix={appendix}
                certificates={certificates}
                setCertificates={setCertificates}
                setAppendix={setAppendix}
                section={section}
                setText={setText}
                applicationType={application.app_type.type}
                userInfo={userInfo}
                userRoles={userRoles}
                allowManageRecords={allowManageRecords}
              />
            </div>
            {/* bottom-left part certificate */}
            {currentCert && (
              <CertEditArea
                application={application}
                cert={currentCert}
                certificates={certificates}
                setCertificates={setCertificates}
                setHash={setHash}
                makeCorrections={makeCorrections}
                simpleDisplay={simpleDisplay}
                deleteField={deleteField}
                userInfo={userInfo}
                userRoles={userRoles}
              />
            )}
          </Box>

{/* middle column */}
          <Box sx={{ display: "flex", width: "67%", flexDirection: "column" }}>
            <div 
              className="flex h-[88%] w-full m-auto"
            >
              {showCoverLetter ? (
                showPDF ? (
                    <PDFViewer 
                      width="55%" 
                      height="100%"
                      className="appendix_pdf_iframe"
                      onLoad={handlePDFLoad}
                    >
                      <ApprovalLetter
                        appendix={appendix}
                        application={application}
                        signatureResponse={signatureResponse}
                        format="pdf"
                      />
                    </PDFViewer>
                ) : (
                  <ApprovalLetter
                    appendix={appendix}
                    application={application}
                    signatureResponse={signatureResponse}
                    format="plainHtml"
                  />
                )
              ) : application.app_type.type === "SafetyComponent" ? (
                showPDF ? (
                    <PDFViewer 
                      width="55%" 
                      height="100%"
                      className="appendix_pdf_iframe"
                      onLoad={handlePDFLoad}
                    >
                      <AppendixForSC
                        appendix={appendix}
                        application={application}
                        certificates={certificates}
                        standalones={standalones}
                      />
                    </PDFViewer>
                ) : (
                  // eslint-disable-next-line react/jsx-pascal-case
                  <AppendixForSChtml
                    appendix={appendix}
                    makeCorrections={makeCorrections}
                    setHash={setHash}
                    cert={currentCert}
                    setCert={setCert}
                    application={application}
                    certificates={certificates}
                    userInfo={userInfo}
                    userRoles={userRoles}
                  />
                )
              ) : showPDF ? (
                  <PDFViewer 
                    width="55%" 
                    height="100%"
                    className="appendix_pdf_iframe"
                    onLoad={handlePDFLoad}
                  >
                    <Appendix
                      appendix={appendix}
                      application={application}
                      certificates={certificates}
                    />
                  </PDFViewer>
              ) : (
                // eslint-disable-next-line react/jsx-pascal-case
                <AppendixHtml
                  appendix={appendix}
                  setAppendix={setAppendix}
                  makeCorrections={makeCorrections}
                  setHash={setHash}
                  cert={currentCert}
                  setCert={setCert}
                  application={application}
                  certificates={certificates}
                  userInfo={userInfo}
                  userRoles={userRoles}
                />
              )}
              <CertPdfDisplay
                ref={ref}
                cert={currentCert}
                openBackdrop={openBackdrop}
                resetHash={resetHash}
                scrollToHighlightFromHash={scrollToHighlightFromHash}
              />
            </div>
            <Box
              sx={{
                height: "12%",
                overflow: "auto",
              }}
            >
              <DocumentList
                certificates={certificates}
                cert={currentCert}
                setCert={setCert}
              />
            </Box>
          </Box>
        </Paper>
      )}
    </Modal>
  );
};

export default AppendixModal;
