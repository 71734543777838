import "./logs.css";
import { useState, useEffect } from "react";
import axios from "axios";
import getToken from "../../utils/getAuthToken";
import { MenuItem, Select, InputLabel, Button, CircularProgress, Box, Autocomplete, TextField  } from "@mui/material";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import { useTranslation } from 'react-i18next';


const labelProps = {
  sx: {
    fontWeight: "bold",
  },
};

const dateStyles = {
  padding: "5px",
  boxShadow: "2px 4px 10px 1px rgba(201,201,201,0.47)",
  borderRadius: "10px",
  minWidth: "200px",
  fontSize: "16px",
}

const Spinner = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '30px'}}>
    <CircularProgress />
  </Box>
)

// Date for Applications
const DateRangeComponent = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    onDateChange(newStartDate, endDate);
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    onDateChange(startDate, newEndDate);
  };

  return (
    <div>
      <input
        type="date"
        value={startDate || ""}
        onChange={handleStartDateChange}
        style={dateStyles}
      />

      <input
        type="date"
        value={endDate || ""}
        onChange={handleEndDateChange}
        style={dateStyles}
      />
    </div>
  );
};

// Date for Users
const DateRangeComponent1 = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    onDateChange(newStartDate, endDate);
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    onDateChange(startDate, newEndDate);
  };

  return (
    <div>
      <input
        type="date"
        value={startDate || ""}
        onChange={handleStartDateChange}
        style={dateStyles}
      />

      <input
        type="date"
        value={endDate || ""}
        onChange={handleEndDateChange}
        style={dateStyles}
      />
    </div>
  );
};

// Date for sysem logs
const DateRangeComponent2 = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    onDateChange(newStartDate, endDate);
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    onDateChange(startDate, newEndDate);
  };

  return (
    <div>
      <input
        type="date"
        value={startDate || ""}
        onChange={handleStartDateChange}
        style={dateStyles}
      />

      <input
        type="date"
        value={endDate || ""}
        onChange={handleEndDateChange}
        style={dateStyles}
      />
    </div>
  );
};

const Divider = () => (
  <hr
    style={{
      margin: "20px 0",
      height: "1px",
      border: "none",
      background: "#ccc",
    }}
  />
);


const AllLogs = () => {
  const { t : itranslate } = useTranslation();

  // applications
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [applicationsNumbers, setApplicationsNumbers] = useState([]);
  const [applicationsData, setApplicationsData] = useState([]);
  const [applicationDataAltered, setApplicationDataAltered] = useState([]);
  const [options, setOptions] = useState(undefined);

  // users
  const [startDate1, setStartDate1] = useState(null);
  const [endDate1, setEndDate1] = useState(null);
  const [userNames, setUserNames] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);

  // syslogs
  const [startDate2, setStartDate2] = useState(null);
  const [endDate2, setEndDate2] = useState(null);
  const [syslogsData, setSyslogsData] = useState([]);

  // applications
  const fileName = "applications-detail";
  const [loading, setLoading] = useState(false);
  const headers = [
    { label: "application_number", key: "meta.application_number" },
    { label: "app_id", key: "meta.app_id" },
    { label: "app_type", key: "meta.app_type" },
    { label: "description", key: "message" },
    { label: "app_status", key: "meta.app_status" },
    { label: "request_username", key: "meta.request_username" },
    { label: "request_userId", key: "meta.request_userId" },
    { label: "request_role", key: "meta.request_role" },
    { label: "message_id", key: "meta.message_id" },
    { label: "message_subject", key: "meta.message_subject" },
    { label: "message_content", key: "meta.message_content" },
    { label: "timestamp", key: "timestamp" },
  ];

  // users
  const usersFileName = "users-details";
  const [loading1, setLoading1] = useState(false)
  const usersheaders = [
    { label: "request_username", key: "meta.request_username" },
    { label: "request_userId", key: "meta.request_userId" },
    { label: "request_role", key: "meta.request_role" },
    { label: "description", key: "message" },
    { label: "timestamp", key: "timestamp" },
    { label: "request_email", key: "meta.request_email" },
    { label: "request_active", key: "meta.request_active" },
    { label: "username", key: "meta.username" },
    { label: "userId", key: "meta.userId" },
    { label: "user_email", key: "meta.user_email" },
    { label: "user_role", key: "meta.user_role" },
    { label: "user_active", key: "meta.user_active" },
    { label: "draftId", key: "meta.draftId" },
    { label: "manufacturer_code", key: "meta.manufacturer_code" },
    {
      label: "manufacturer_provided_name",
      key: "meta.manufacturer_provided_name",
    },
    { label: "manufacturer_alias", key: "meta.manufacturer_alias" },
    { label: "revoke_id", key: "meta.revoke_id" },
    { label: "revoke_status", key: "meta.revoke_status" },
    { label: "components", key: "meta.components" },
    { label: "manufacturer", key: "meta.manufacturer" },
    { label: "model", key: "meta.model" },
    {
      label: "signed_revocation_letter_id",
      key: "meta.signed_revocation_letter_id",
    },
    { label: "team_description", key: "meta.team_description" },
    { label: "team_id", key: "meta.team_id" },
    { label: "team_chiefEngineer_id", key: "meta.team_chiefEngineer_id" },
    {
      label: "team_chiefEngineer_role_type",
      key: "meta.team_chiefEngineer[0].role_type",
    },
    {
      label: "team_chiefEngineer_role_level",
      key: "meta.team_chiefEngineer[0].ce_role_level",
    },
    {
      label: "team_chiefEngineer_role_name",
      key: "meta.team_chiefEngineer[0].role_name",
    },
    {
      label: "team_chiefEngineer_email",
      key: "meta.team_chiefEngineer[0].default_ce_email",
    },
    { label: "team_seniorEngineers_id", key: "meta.team_seniorEngineers_id" },
    {
      label: "team_seniorEngineers_role_type",
      key: "meta.team_seniorEngineers[0].role_type",
    },
    {
      label: "team_seniorEngineers_role_level",
      key: "meta.team_seniorEngineers[0].se_role_level",
    },
    {
      label: "team_seniorEngineers_role_name",
      key: "meta.team_seniorEngineers[0].role_name",
    },
    {
      label: "team_seniorEngineers_email",
      key: "meta.team_seniorEngineers[0].default_se_email",
    },
    { label: "team_engineers_id", key: "meta.team_engineers_id" },
    {
      label: "team_engineers_role_type",
      key: "meta.team_engineers[0].role_type",
    },
    {
      label: "team_engineers_role_level",
      key: "meta.team_engineers[0].en_role_level",
    },
    {
      label: "team_engineers_role_name",
      key: "meta.team_engineers[0].role_name",
    },
    {
      label: "team_engineers_email",
      key: "meta.team_engineers[0].default_en_email",
    },
    {
      label: "team_engineers_special_application_type",
      key: "meta.team_engineers[0].special_application_type",
    },
    { label: "notification_id", key: "meta.notification_id" },
    { label: "notification_content", key: "meta.notification_content" },
  ];

  // syslogs
  const syslogsFileName = "syslogs-details";
  const [loading2, setLoading2] = useState(false);
  const syslogsheaders = [
    { label: "type", key: "level" },
    { label: "timestamp", key: "timestamp" },
    { label: "user", key: "meta.loggedInUser" },
    { label: "information", key: "message" },
    { label: "additional_information1", key: "meta.stack" },
    { label: "additional_information2", key: "meta.info" },
    { label: "sender_email", key: "meta.sender_email"},
    { label: "recipient_email", key: "meta.recipient_email"},
    { label: "email_subject", key: "meta.subject"},
    { label: "email_content", key: "meta.text"},
  ];

  // applications
  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleChange = (value) => {
    setApplicationsNumbers(value);
  };

  const handleSearch = () => {
    setApplicationsData([]);
    setLoading(true);
    const body = {};
    if (startDate !== null) {
      body.startDate = startDate;
    }
    if (endDate !== null) {
      body.endDate = endDate;
    }
    if (applicationsNumbers !== null) {
      body.id = applicationsNumbers;
    }

    // let convertedTime;
    async function fetchData() {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/logs/applicationLogs`,
        {body},
        {headers: { authorization: getToken() }}
      );
      // console.log(data);
       let convertedData = data.map(log => {
        return {
          ...log,
          timestamp: moment(log.meta.timestamp).tz('Asia/Hong_Kong').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        }
       }) 
      setApplicationsData(convertedData);
      setLoading(false);
    }
    fetchData();
  };

  
  // removing semicolons and replacing double quotes with single quotes from message_content, as these are causing the contents splitting into separate columns
  useEffect(() => {
    if(applicationsData.length !== 0) {
      const csvData = applicationsData.map((item) => ({
        ...item,
        meta: {
          ...item.meta,
          message_content: item.meta.message_content ? item.meta.message_content.replace(/;/g, '').replace(/"/g, "'") : item.meta.message_content
        },
      }));
      setApplicationDataAltered(csvData);
    }
  }, [applicationsData]);


  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/logs/applicationLogs`,
        {body: {id: "All"},},
        {headers: {authorization: getToken()},}
      );

      const results = [];
      data.forEach((value) => {
        results.push({
          key: value.meta.application_number,
          value: value.meta.application_number,
        });
      });

      let jsonObject = results.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      setOptions([
        // { key: "All Application Numbers", value: "All" },
        { key: itranslate("logs.All Application Numbers") , value: "All" },
        ...uniqueArray,
      ]);
    }
    fetchData();
  }, []);

  // users
  const handleUsersDateChange = (startDate1, endDate1) => {
    setStartDate1(startDate1);
    setEndDate1(endDate1);
  };

  const handleUsersChange = (value) => {
    setUserNames(value);
  };

  const handleUsersSearch = () => {
    setUsersData([]);
    setLoading1(true);
    const body = {};
    if (startDate1 !== null) {
      body.startDate = startDate1;
    }
    if (endDate1 !== null) {
      body.endDate = endDate1;
    }
    if (userNames !== null) {
      body.user = userNames;
    }
    // console.log(body)

    async function fetchData() {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/logs/userLogs`,
        {body},
        {headers: {authorization: getToken()}}
      );
      // console.log(data);
      let convertedData = data.map(log => {
        return {
          ...log,
          timestamp: moment(log.meta.timestamp).tz('Asia/Hong_Kong').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        }
      }) 
      setUsersData(convertedData);
      setLoading1(false);
    }
    fetchData();
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/logs/userLogs`,
        {body: {user: "All"}},
        {headers: {authorization: getToken()}}
      );
      // console.log(data)

      const results = [];
      data.forEach((value) => {
        results.push({
          key: value.meta.request_username,
          value: value.meta.request_username,
        });
      });

      let jsonObject = results.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      // setUsersOptions([{ key: "All Users", value: "All" }, ...uniqueArray]);
      setUsersOptions([{ key: itranslate("logs.All Users") , value: "All" }, ...uniqueArray]);

    }
    fetchData();
  }, []);

  // syslogs
  const handleSyslogsDateChange = (startDate2, endDate2) => {
    setStartDate2(startDate2);
    setEndDate2(endDate2);
  };

  const handleSyslogsSearch = () => {
    setSyslogsData([])
    setLoading2(true);
    const body = {};
    if (startDate2 !== null) {
      body.startDate = startDate2;
    }
    if (endDate2 !== null) {
      body.endDate = endDate2;
    }

    async function fetchData() {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/logs/sysLogs`,
        {body,},
        {headers: { authorization: getToken() }}
      );
      // console.log(data);
      let convertedData = data.map(log => {
        return {
          ...log,
          timestamp: moment(log.meta.timestamp).tz('Asia/Hong_Kong').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        }
        }) 
      setSyslogsData(convertedData);
      setLoading2(false);
    }
    fetchData();
  };

  return (
    <>
      <div className="logs__container">
        <div className="logs__date">
          <InputLabel {...labelProps}>
          {/* Select Applications */}
          {itranslate("logs.Select Applications")}
          </InputLabel>
          {/* <Select {...selectProps || ""}>
            {options?.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  value={option.value}
                  onClick={() => handleChange(option.value)}
                >
                  {option.key}
                </MenuItem>
              );
            })}
          </Select> */}

          <Autocomplete
              options={options}
              getOptionLabel={(option) => option.key}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleChange(newValue.value);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label={labelProps.children} variant="outlined" />
              )}
            />

          <DateRangeComponent onDateChange={handleDateChange} />

          <div className="logs__button">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSearch()}
            >
              {/* Search */}
              {itranslate("logs.Search")}
            </Button>
          </div>

          {applicationsData.length !== 0 ? (
            <div className="logs__export">
              <>
                <div className="logs__message">
                  {/* Click to Download */}
                  {itranslate("logs.Click to Download")}
                </div>
                <Button variant="contained" color="primary">
                  <CSVLink
                    headers={headers}
                    // data={applicationsData}
                    data={applicationDataAltered}
                    filename={fileName}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {/* {"Export Data"} */}
                    {itranslate("logs.Export Data")}
                  </CSVLink>
                </Button>
              </>
            </div>
          ) : (
            <div className="logs__export">
              {/* {loading ?  <Spinner /> : "No Data"} */}
              {loading ?  <Spinner /> : itranslate("logs.No Data") }
            </div>
          )}
        </div>
      </div>

      <Divider />

      <div className="logs__container">
        <div className="logs__date">
          <InputLabel {...labelProps}>
          {/* Select Users */}
          {itranslate("logs.Select Users")}
          </InputLabel>
          {/* <Select {...selectProps || ""}>
            {usersOptions?.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  value={option.value}
                  onClick={() => handleUsersChange(option.value)}
                >
                  {option.key}
                </MenuItem>
              );
            })}
          </Select> */}

            <Autocomplete
              options={usersOptions}
              getOptionLabel={(option) => option.key}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleUsersChange(newValue.value);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label={labelProps.children} variant="outlined" />
              )}
            />

          <DateRangeComponent1 onDateChange={handleUsersDateChange} />

          <div className="logs__button">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUsersSearch()}
            >
              {/* Search */}
              {itranslate("logs.Search")} 
            </Button>
          </div>

          {usersData.length !== 0 ? (
            <div className="logs__export">
              <>
                <div className="logs__message">
                  {/* Click to Download */}
                  {itranslate("logs.Click to Download")}
                </div>
                <Button variant="contained" color="primary">
                  <CSVLink
                    headers={usersheaders}
                    data={usersData}
                    filename={usersFileName}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {/* {"Export Data"} */}
                    {itranslate("logs.Export Data")}
                  </CSVLink>
                </Button>
              </>
            </div>
          ) : (
            <div className="logs__export">
              {/* {loading1 ?  <Spinner /> : "No Data"} */}
              {loading1 ?  <Spinner /> : itranslate("logs.No Data") }
            </div>
          )}
        </div>
      </div>

      <Divider />

      <div className="logs__container">
        <div className="logs__date">
          <InputLabel {...labelProps}>
          {/* System Logs */}
          {itranslate("logs.System Logs")}
          </InputLabel>

          <DateRangeComponent2 onDateChange={handleSyslogsDateChange} />

          <div className="logs__button">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSyslogsSearch()}
            >
              {/* Search */}
              {itranslate("logs.Search")}
            </Button>
          </div>

          {syslogsData.length !== 0 ? (
            <div className="logs__export">
              <>
                <div className="logs__message">
                  {/* Click to Download */}
                  {itranslate("logs.Click to Download")}
                </div>
                <Button variant="contained" color="primary">
                  <CSVLink
                    headers={syslogsheaders}
                    data={syslogsData}
                    filename={syslogsFileName}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {/* {"Export Data"} */}
                    {itranslate("logs.Export Data")}
                  </CSVLink>
                </Button>
              </>
            </div>
          ) : (
            <div className="logs__export">
              {/* {loading2 ?  <Spinner /> : "No Data"} */}
              {loading2 ?  <Spinner /> : itranslate("logs.No Data") }
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AllLogs;
