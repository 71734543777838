import * as React from 'react';

// mui
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// redux
import { useSelector, useDispatch } from "react-redux";
import { setStage } from "../../../admin/redux/stageSlice.js";
import { setOption } from '../../../admin/redux/optionSlice.js';


import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";

// hooks
import { generateRestrictedURL } from '../../../admin/useHooks/restrictedURL/useRestrictedURL'
import { useSessionStorage } from "@uidotdev/usehooks";
import { usePrevious } from "@uidotdev/usehooks";

import ErrModal from "../../../_hoc/modals/BasicModal"

// redux 
import { setRuPageData } from '../../../admin/redux/ruCreate/ruPageDataSlice.js';

import { useTranslate } from 'react-admin';

const BackBtn = styled(Button)(({ theme }) => ({
    backgroundColor: "#616161",
    '&:hover': {
        backgroundColor: "#434343",
    },
}));


export default function RemarkForm() {
    const translate = useTranslate();
    const navigate = useNavigate()
    const [clientUser, setClientUser] = useState("")
    const [token, setToken] = useState("")
    const [remark, setRemark] = useState("")

    const [label, setLabel] = useState("")
    const [msg, setMsg] = useState("")
    const [open, setOpen] = useState(false)

    // redux store
    const dispatch = useDispatch();
    const stageStore = useSelector((state) => state.stage.value);
    const stage = structuredClone(stageStore);
    const ruPageDataStore = useSelector((state) => state.ruPageData.value);
    const ruPageData = structuredClone(ruPageDataStore);
    
    useEffect(() => {
        setClientUser("")
        setToken("")
        setRemark("")
        setLabel("")
        setMsg("")
        setOpen(false)
    }, [stage])

    const handleBack = () => {
        dispatch(setStage("more options?"));
    }

    const handleRemarkSubmit = () => {
        generateRestrictedURL(clientUser, token, remark, ruPageData).then((res) => {
            
            if (res?.ruResult) {
                navigate(`/ruInfoShow/${res?.ruResult._id}`)
            }

            setClientUser("")
            setToken("")
            setRemark("")
            setLabel("")
            setMsg("")
            setOpen(false)
            
            dispatch(setOption(""));
            dispatch(setStage(""));
        }).catch(err => {
            console.log(err.response.data);
            setLabel(err?.response?.data?.label)
            setMsg(err?.response?.data?.message)
            setOpen(true)
        })
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-center",
                    }}
                >
                    <TextField
                        margin="normal"
                        id="clientUser"
                        label="Client User"
                        color={clientUser ? "success" : "warning"}
                        variant="outlined"
                        type="text"
                        name="clientUser"
                        value={clientUser}
                        onChange={(e) => setClientUser(e.target.value)}
                        helperText={
                            !clientUser && translate("phase2.createRu.clientUserIsRequired")
                        }
                    />

                    <TextField
                        margin="normal"
                        id="token"
                        color={token.length > 0 ? "success" : "warning"}
                        label="Token"
                        variant="outlined"
                        type="text"
                        name="token"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        helperText={
                            !(token.length > 0) && translate("phase2.createRu.tokenIsRequired")
                        }
                    />

                    <TextField
                        margin="normal"
                        id="remark"
                        color={remark ? "success" : "warning"}
                        label="remark"
                        variant="outlined"
                        type="text"
                        name="remark"
                        value={remark}
                        multiline
                        maxRows={3}
                        onChange={(e) => setRemark(e.target.value)}
                        helperText={
                            !remark && translate("phase2.createRu.remarkIsRequired")
                        }
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ my: 2, mx: 2 }}
                        onClick={handleRemarkSubmit}
                        aria-label='Enter'
                    >
                        {translate("button.enter")}
                    </Button>
                    <BackBtn
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mx: 2 }}
                        onClick={(e) => handleBack({e})}
                    >
                        {translate("button.back")}
                    </BackBtn>
                </Box>
            </Box>

            {
                label && msg && <ErrModal open={open} setOpen={setOpen} title="Error">
                    {`${label}: ${msg}`}
                </ErrModal>
            }
        </Container>
    )

}