import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  MenuItem,
  TextField,
  InputLabel,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DeleteIcon from "@mui/icons-material/Delete";
import keyToField from "../keyToField";
import { fixInvalidDates } from "../misc/fixInvalidDates";
import moment from "moment";
import palette from "../../styles/palette";
import HighlightInput from "../../components/appendix/editAppendix/HighlightInput";
//BM00006680
const EditableSelect = (props) => {
  const {
    options,
    value,
    field,
    handleDelete,
    handleSelect,
    handleInput,
    scrollToPosition,
    isMandatory,
    checkIfEdited,
    application,
    userInfo,
    userRoles,
    cert,
    certificates,
    setCertificates,
  } = props;
  const [editable, setEditable] = useState(false);
  const inputRef = useRef(null);

  const markMandatory = (
    <Typography
      component="span"
      sx={{
        color: "red",
        fontStyle: "italic",
        fontSize: "0.75rem",
        verticalAlign: "top",
      }}
    >
      *Required
    </Typography>
  );

  const handleSelectChange = (event) => {
    handleSelect(field, event.target.value);
    setEditable(false);
  };

  const handleBlur = () => {
    if (inputRef.current && inputRef.current.clickedDone) {
      setEditable(false);
    } else if (inputRef.current) {
      setEditable(false);
      handleInput(inputRef.current.value);
    }
  };

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role""
  const isDisabled =
    (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
    (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
    (userInfo?.role === "EMSDEngineer" &&
      application?.status === "Pending E" &&
      !userRoles.includes(application?.current_role_id));

  return (
    <>
      <InputLabel sx={{ color: "black" }}>
        {field.includes("date") &&
          (!fixInvalidDates(value, "checking") || !value) && (
            <Typography
              component="span"
              sx={{
                color: "red",
                fontSize: "0.75rem",
              }}
            >
              (Invalid date format)
            </Typography>
          )}
        {/* EMSD requirement: do not translate the field if field is not mandatory */}
        {!isMandatory ? field : keyToField[field] || field}{" "}
        {isMandatory && markMandatory}
      </InputLabel>
      {editable ? (
        <TextField
          disabled={isDisabled}
          sx={{ width: "100%" }}
          value={value}
          onChange={handleInput}
          onBlur={handleBlur}
          autoFocus
          variant="outlined"
          placeholder={field.includes("date") ? `DD-MM-YYYY` : ""}
        />
      ) : (
        <Select
          disabled={isDisabled}
          sx={{
            width: "100%",
            backgroundColor: checkIfEdited ? palette.shadow : "",
          }}
          value={value}
          onChange={handleSelectChange}
        >
          {options.map((option, index) => (
            <MenuItem key={option + index} value={option}>
              {field.includes("date") ? fixInvalidDates(option) : option}
            </MenuItem>
          ))}
          {!options.includes(value) && (
            <MenuItem key={value} value={value}>
              {field.includes("date") ? fixInvalidDates(value) : value}
            </MenuItem>
          )}
        </Select>
      )}
      <IconButton
        disabled={isDisabled}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.clickedDone = true;
          }
          setEditable(!editable);
        }}
        size="small"
      >
        <EditIcon />
        <Typography>{editable ? "Done" : "Edit"}</Typography>
      </IconButton>
      <IconButton disabled={isDisabled} onClick={scrollToPosition} size="small">
        <LocationOnIcon />
        <Typography>Show Position</Typography>
      </IconButton>
      {!isMandatory && (
        <IconButton
          disabled={isDisabled}
          onClick={() => handleDelete(field)}
          size="small"
          sx={{ color: palette.delete }}
        >
          <DeleteIcon />
          <Typography>Delete</Typography>
        </IconButton>
      )}
      <HighlightInput
        certificates={certificates}
        setCertificates={setCertificates}
        cert={cert}
        field={field}
        application={application}
        userInfo={userInfo}
        userRoles={userRoles}
      />
    </>
  );
};

export default EditableSelect;
