import { useState, Suspense, useMemo, createRef } from "react";
import { Card, CardHeader, CardContent, Box, Tooltip, Button, Backdrop, CircularProgress } from "@mui/material";
import AppendixStore from "./editAppendix/AppendixStore";
import useDeAppendix from "../../useHooks/useDeAppendix";
import { toPng } from 'html-to-image';
import { QRCodeSVG } from 'qrcode.react';
import { RecordGenAppendix } from "../../pages/recordManagement/RecordGenAppendix";
import ApprovalHistory from "../approval/ApprovalHistory";
// import getToken from "../../utils/getAuthToken";

export const ShowAppendix = (props) => {
  const { upload, openAppendix, setOpenAppendix, allowManageRecords } = props;

  const { data: deAppendices, isLoading: isLoadingDeAppendices } = useDeAppendix(upload.id)
  // console.log("🚀 ~ file: ShowAppendix.js:9 ~ ShowAppendix ~ deAppendices:", deAppendices)

  const [openQrPreDownloadModal, setOpenQrPreDownloadModal] = useState(false);

  const Spinner = () => (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openQrPreDownloadModal}
    >
      <CircularProgress color="inherit" size={40} />
    </Backdrop>
  )

  // refs for different das
  const refsByIndex = useMemo(() => {
    const refs = {};
    if (deAppendices && deAppendices.length > 0) {
      deAppendices.forEach((da) => {
        if (da && typeof da === 'object' && da.da_no !== undefined) {
          refs[da.da_no] = createRef(null);
        }
      });
      return refs;
    } else {
      return null;
    }
  }, [deAppendices]);

  const handleQrCodeDownload = (da_no) => {
    if (Object.keys(refsByIndex).length === 0) return

    toPng(refsByIndex[da_no].current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = `qr_code_${da_no}.png`
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      }).finally(() => {
        setOpenQrPreDownloadModal(false)
      })
  }


  // const downloadFile = async (id) => {
  //   window.open(
  //     `${
  //       process.env.REACT_APP_ENV_ENDPOINT
  //     }/lift/files/${id}?token=${getToken()}`
  //   );
  // };


  if (!upload) return null;
  return (
    <Card>
      <CardHeader title="Appendix / Approval letter" />
      {allowManageRecords && <RecordGenAppendix id={upload.id} openAppendix={openAppendix} setOpenAppendix={setOpenAppendix} />}
      <CardContent sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: '2rem' }}>
        <>
          <AppendixStore
            appendix={upload.appendix}
            application={upload}
            type="show"
          />          

        {window.location.pathname === "/lift_rc" && !isLoadingDeAppendices && deAppendices && deAppendices.length > 0 && (
         <>
          {deAppendices.map((v, i) => 
            // on TAPAS RC - we only want to show the link to "verify appendix page" after 08th March 2024 (bug fixed) as previous qrcode could be using wrong domain name. 
            typeof v === 'object' && v.da_no !== undefined && v.originalAppendix && new Date(v.createdAt) > new Date('2024-03-08') ? (
              <Box key={v._id}>
                <Button
                  sx={{ marginLeft: "1em" }}
                  variant="outlined"
                  onClick={() => window.open(v.url, '_blank')}
                >
                  Open Link
                </Button>

                <Tooltip placement="top" title={`click and download qr code(${v.da_no})`}>
                  <Button
                    ref={refsByIndex[v.da_no]}
                    aria-label={`click and download qr code(${v.da_no})`}
                    disableFocusRipple={true}
                    disableRipple={true}
                    className="Mui-focusVisible"
                    onClick={() => setOpenQrPreDownloadModal(true)}
                  >
                    <Suspense>
                      <QRCodeSVG
                        value={v.url}
                        size={120}
                        includeMargin={true}
                        onClick={() => handleQrCodeDownload(v.da_no)}
                        alt={`qr code(${v.da_no})`}
                      />
                    </Suspense>
                  </Button>
                </Tooltip>

              </Box>
            ) : null
          )}
          
          {/* 
           {
            <Button onClick={() => downloadFile(upload.appendix.pdfFile)}>
              Test Download
            </Button>
           } */}
         </>
        )}
        </>
      </CardContent>

      {openQrPreDownloadModal && (
        <Spinner />
      )}
      
      {window.location.pathname === "/lift_emsd" && <ApprovalHistory upload={upload} sx={{px: 2}}/>}
    </Card>
  );
};
