import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import axios from "axios";
import jsonServerProvider from "ra-data-json-server";

const codeDeterminant = (type) => {
  switch (type) {
    case "ACOP": {
      return "AC";
    }
    case "OverspeedGovernor": {
      return "OG";
    }
    case "SafetyGear": {
      return "SG";
    }
    case "UCMP": {
      return "UC";
    }
    case "CarDoorLockingDevice": {
      return "CD";
    }
    case "LandingDoorLockingDevice": {
      return "LD";
    }
    case "SafetyCircuitElectricalComponent": {
      return "SC";
    }
    case "Pallet": {
      return "PL";
    }
    case "Step": {
      return "SP";
    }
    case "OneWayValve": {
      return "OV";
    }
    case "RaptureValve": {
      return "RV";
    }
    case "Buffer": {
      return "BU";
    }
    default: {
      return "C";
    }
  }
};

//need a mapper esp for _id from mongodb to id in react-admin
// const Mapping = {
//   createdAt: {
//     newName: "created_at",
//     setValue: (object) => object["createdAt"],
//   },
//   _id: {
//     newName: "id",
//     setValue: (object) => object["_id"],
//   },
//   app_type: {
//     newName: "app_type",
//     setValue: (object) => {
//       let temp = object["app_type"]["app_type_data"];
//       if (object["app_type"]["app_type_data"]) {
//         Object.keys(object["app_type"]["app_type_data"])?.map((key) =>
//           temp[key].map((y, i) => {
//             if (y.component_code)
//               temp[key][i].component_code = String(temp[key][i].component_code);
//           })
//         );
//         delete object["app_type"]["app_type_data"];
//       }
//       return { ...object["app_type"], components: temp ? temp : {} };
//     },
//   },
  // model: {
  //   newName: "model",
  //   setValue: (object) => {
  //     let temp = object["model"];
  //     return !(typeof temp[0] === "string")
  //       ? temp.map((el) => {
  //           return { ...el, model_code: el["model_code"] };
  //         })
  //       : temp;
  //   },
  // },
//   manufacturer_id: {
//     newName: "manufacturer",
//     setValue: (object) => {
//       let temp = object["manufacturer_id"];
//       return !(typeof temp[0] === "string")
//         ? temp.map((el) => {
//             return { ...el, manufacturer_code: el["manufacturer_code"] };
//           })
//         : temp;
//     },
//   },
// };





const Mapping = {
  createdAt: {
    newName: "created_at",
    setValue: (object) => object["createdAt"],
  },
  _id: {
    newName: "id",
    setValue: (object) => object["_id"],
  },
  app_type: {
    newName: "app_type",
    setValue: (object) => {
      const appTypeData = object["app_type"]?.["app_type_data"];
      let temp = appTypeData || {};

      if (appTypeData) {
        Object.keys(appTypeData).forEach((key) => {
          if (Array.isArray(temp[key])) {
            temp[key].forEach((y, i) => {
              if (y && y.component_code) {
                temp[key][i].component_code = String(y.component_code);
              }
            });
          }
        });
        delete object["app_type"]["app_type_data"];
      }
      return { ...object["app_type"], components: temp };
    },
  },
  model: {
    newName: "model",
    setValue: (object) => {
      const temp = object["model"];
  
      // Check if temp is an array and has elements
      if (Array.isArray(temp) && temp.length > 0) {
        return temp.map((el) => {
          // Ensure el is an object before accessing its properties
          if (el && typeof el === 'object') {
            return {
              ...el,
              model_code: el["model_code"] || null, // Default to null if not present
            };
          }
          return { model_code: null }; // Fallback for invalid elements
        });
      } else {
        // Return an empty array if temp is null, undefined, or not an array
        return temp || [];
      }
    },
  },
  // model: {
  //   newName: "model",
  //   setValue: (object) => {
  //     const temp = object["model"];
  
  //     // Check if temp is null or undefined
  //     if (!temp) {
  //       return []; // Return an empty array if temp is null or undefined
  //     }
  
  //     // Check if temp is an array
  //     if (Array.isArray(temp)) {
  //       // Check if the first element is a string
  //       if (typeof temp[0] === 'string') {
  //         return temp.map((el) => ({
  //           model_code: el // Create an object with the string as model_code
  //         }));
  //       } else {
  //         return temp.map((el) => {
  //           // Ensure el is an object before accessing its properties
  //           if (el && typeof el === 'object') {
  //             return {
  //               ...el,
  //               model_code: el["model_code"] || null, // Default to null if not present
  //             };
  //           }
  //           return { model_code: null }; // Fallback for invalid elements
  //         });
  //       }
  //     } else {
  //       // If temp is not an array, wrap it in an array
  //       return [temp];
  //     }
  //   },
  // },
  // model: {
  //   newName: "model",
  //   setValue: (object) => {
  //     const temp = object["model"];
  
  //     // Check if temp is null or undefined
  //     if (!temp) {
  //       return []; // Return an empty array if temp is null or undefined
  //     }
  
  //     // Check if temp is an array
  //     if (Array.isArray(temp)) {
  //       // Return the array of strings directly
  //       return temp.filter(el => typeof el === 'string'); // Filter to include only strings
  //     } else {
  //       // If temp is not an array, wrap it in an array if it's a string
  //       return typeof temp === 'string' ? [temp] : [];
  //     }
  //   },
  // },
  manufacturer_id: {
    newName: "manufacturer",
    setValue: (object) => {
      const temp = object["manufacturer_id"];
  
      // Check if temp is an array and has elements
      if (Array.isArray(temp) && temp.length > 0) {
        return temp.map((el) => {
          // Ensure el is an object before accessing its properties
          if (el && typeof el === 'object') {
            return {
              ...el,
              manufacturer_code: el["manufacturer_code"] || null, // Default to null if not present
            };
          }
          return { manufacturer_code: null }; // Fallback for invalid elements
        });
      } else {
        // Return an empty array if temp is null, undefined, or not an array
        return temp || [];
      }
    },
  },
};




function mappingFunction(object, mapping) {
  const result = {};

  Object.keys(object).map((key) => {
    if (Mapping[key]) {
      let newKey = mapping[key].newName;
      result[newKey] = mapping[key].setValue(object);
    } else {
      result[key] = object[key];
    }
  });

  return result;
};


// function mappingFunction(object, mapping) {
//   const result = {};

//   Object.keys(object).forEach((key) => {
//     if (Mapping[key]) {
//       let newKey = Mapping[key].newName;
//       result[newKey] = Mapping[key].setValue(object);
//     } else {
//       result[key] = object[key];
//     }
//   });

//   return result;
// }



function reverseMapSort(object, mapping) {
  if (!object) {
    return {};
  }
  let result = JSON.parse(JSON.stringify(object));
  for (const [key, value] of Object.entries(mapping)) {
    if (object.field === value.newName) {
      result.field = key;
    }
  }
  return result;
};


const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");

const apiUrl = `${process.env.REACT_APP_ENV_ENDPOINT}/lift`;
const httpClient = fetchUtils.fetchJson;

function getToken() {
  const path = window.location.pathname;
  const portal =
    path === "/lift_rc" ? "rc" : path === "/lift_emsd" ? "emsd" : "";
  const authToken = JSON.parse(
    localStorage.getItem(`tapas_lift_${portal}_token`)
  );
  return authToken;
}

const myDataProvider = {
  ...dataProvider,
  updateMessageDialog: (id, body) => {
    return axios
      .post(`${apiUrl}/messages/${id}`, body, {
        headers: { authorization: getToken() },
      })
      .then((result) => {
        return result;
      });
  },

  deleteMessageDialog: (id, applicationId) => {
    return axios
      .delete(`${apiUrl}/messages/${id}?applicationId=${applicationId}`, {
        headers: { authorization: getToken() },
      })
      .then((result) => {
        return result;
      });
  },

  getList: (resource, params) => {
    if (resource === "applications" || resource === "v2/applications") {
      const { page, perPage } = params.pagination;
      const { field, order } = reverseMapSort(params.sort, Mapping);
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
        pathname: window.location.pathname, // Add pathname
        // 3 types of status:
        // 1. awaitingApproval: for emsd portal, only show applications that are awaiting approval
        // 2. approved: for emsd portal, only show applications that are approved
        // 3. simplifiedStatus: for rc portal, when rc filter status, no need to be specific, such as "Pending E", "Pending SE" all counted as pending
        type:
          window.location.pathname === "/lift_emsd"
            ? resource === "applications"
              ? "awaitingApproval"
              : "approved"
            : "simplifiedStatus",
      };

      const url = `${apiUrl}/apps?${stringify(query)}`;

      return axios
        .get(url, { 
          headers: { authorization: getToken() }
        })
        .then((result) => {
          let premap = result;
          let map = {
            ...premap,
            data: premap.data.map((record) => {
              return mappingFunction(record, Mapping);
            }),
            total: Number.parseInt(result.headers["x-total-count"]),
          };
          return map;
        });
    } else {
      const { page, perPage } = params.pagination;
      const { field, order } = reverseMapSort(params.sort, Mapping);
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };

      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return axios
        .get(url, { headers: { authorization: getToken() } })
        .then((result) => {
          let premap = result;
          let map = {
            ...premap,
            // data: premap.data.map((record) => {
            //   return mappingFunction(record, Mapping);
            // }),
            data: Array.isArray(premap.data) 
            ? premap.data.map((record) => mappingFunction(record, Mapping)) 
            : [],
            total: Number.parseInt(result.headers["x-total-count"]),
          };
          return map;
        });
    }
  },

  getOne: (resource, params) => {
    if (resource === "applications" || resource === "v2/applications") {
      const url = `${apiUrl}/apps/${params.id}`;

      return axios
        .get(url, { headers: { authorization: getToken() } })
        .then((result) => {
          console.log('API Response:', result.data); // Log the response
          let premap = result;
          let map = { ...premap, data: mappingFunction(premap.data, Mapping) };
          // console.log('Before mapping:', premap.data);
          // console.log('Keys before mapping:', Object.keys(premap.data));
          // console.log('After mapping:', map.data);
          // console.log('Keys after mapping:', Object.keys(map.data));
          
          return map;
        });
    } else {
      const url = `${apiUrl}/${resource}/${params.id}`;

      return axios
        .get(url, { headers: { authorization: getToken() } })
        .then((result) => {
          let premap = result;
          let map = { ...premap, data: mappingFunction(premap.data, Mapping) };
          return map;
        });
    }
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return axios
      .get(url, { headers: { authorization: getToken() } })
      .then((res) => {
        let premap = res;
        let map = {
          ...premap,
          data: premap.data.map((record) => {
            return { ...mappingFunction(record, Mapping) };
          }),
        };
        // console.log('Before mapping2:', premap.data);
        // console.log('Keys before mapping2:', Object.keys(premap.data));
        // console.log('After mapping2:', map.data);
        // console.log('Keys after mapping2:', Object.keys(map.data));

        return map;
      });
  },

  getManyReference: (resource, params) => {
    if (!(resource === "applications")) {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return axios
        .get(url, { headers: { authorization: getToken() } })
        .then(({ json }) => {
          let premap = json;
          let map = {
            ...premap,
            data: premap.data.map((record) => {
              return mappingFunction(record, Mapping);
            }),
          };
          return map;
        });
    } else
      return [
        { id: 667, title: "I agree", post_id: 123 },
        { id: 895, title: "I don't agree", post_id: 123 },
      ];
  },

  create: (resource, params) => {
    if (
      [
        "records",
        "team",
        "rcs",
        "users",
        "notifications",
        "revoke",
        "app_preSubmit/validate",
      ].includes(resource)
    ) {
      const { data, meta } = params;
      const query = {
        ...(meta && { type: meta?.type }),
      };
      const queryString =
        Object.keys(query).length > 0 ? `?${stringify(query)}` : "";
      return axios
        .post(
          `${process.env.REACT_APP_ENV_ENDPOINT}/lift/${resource}${queryString}`,
          data,
          {
            headers: {
              authorization: getToken(),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let premap = response;
          let map = { ...premap, data: mappingFunction(premap.data, Mapping) };
          return map;
        })
        .catch((error) => {
          if (resource !== "team") throw new Error(error.response.data);
        });
    } else if (resource === "files") {
      var formData = new FormData();

      if (Array.isArray(params.data)) {
        params.data.map((file, i) => {
          formData.append(`file${i}`, file);
        });
      } else {
        formData.append(
          params.meta.description ? params.meta.description : "file",
          params.data
        );
      }

      formData.append("file_code", params.meta.file_code);
      if (params.meta.type) formData.append("type", params.meta.type);

      return axios
        .post(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/`, formData, {
          headers: {
            authorization: getToken(),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          let premap = res.data;
          let map = premap.map((record) => {
            return mappingFunction(record, Mapping);
          });
          return { data: { id: "cheeky", data: map } };
        });
    } else {
      return axios
        .post(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/apps`, params.data, {
          headers: {
            authorization: getToken(),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let premap = response;
          let map = { ...premap, data: mappingFunction(premap.data, Mapping) };
          return map;
        });
    }
  },

  update: (resource, params) => {
    if (resource === "application" || resource === "applications") {
      const { id, data, meta } = params;
      return axios
        .put(
          `${process.env.REACT_APP_ENV_ENDPOINT}/lift/apps/${id}`,
          { data, ...(meta && { meta }) },
          {
            headers: {
              authorization: getToken(),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let premap = response;
          let map = { ...premap, data: mappingFunction(premap.data, Mapping) };
          return map;
        });
    } else {
      const { id, data, meta } = params;
      return axios
        .put(
          `${process.env.REACT_APP_ENV_ENDPOINT}/lift/${resource}/${id}`,
          {
            data,
            ...(meta && { meta }),
          },
          {
            headers: {
              authorization: getToken(),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let premap = response;
          let map = { ...premap, data: mappingFunction(premap.data, Mapping) };
          return map;
        });
    }
  },

  delete: (resource, params) => {
    const { id, meta } = params;
    return axios
      .delete(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/${resource}/${id}`, {
        headers: { authorization: getToken() },
        data: { ...(meta && { meta }) },
      })
      .then((res) => {
        let premap = res;
        let map = { ...premap, data: mappingFunction(premap.data, Mapping) };
        return map;
      });
  },

  updateMany: (resource, params) => {
    const { ids, data } = params;
    if (!(resource === "applications")) {
      const query = {
        filter: JSON.stringify({ _id: ids }),
      };
      return axios
        .put(
          `${apiUrl}/${resource}?${stringify(query)}`,
          { data },
          {
            headers: {
              authorization: getToken(),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => ({ data: [response.data] }))
        .catch((error) => {
          console.log(error);
        });
    } else return [123, 124, 125];
  },

  deleteMany: (resource, params) => {
    if (!(resource === "applications")) {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        headers: {
          authorization: getToken(),
        },
        method: "DELETE",
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json }));
    } else return [123, 124, 125];
  },
};

export default myDataProvider;
