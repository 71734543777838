function formatDate(dateString, includeTime) {
  try {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    if (includeTime) {
      // Convert to Hong Kong Time (UTC+8)
      date.setUTCHours(date.getUTCHours() + 8);
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const ampm = hours >= 12 ? "pm" : "am";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedTime = `${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")}${ampm}`;
      const formattedDate = `${day} ${month}, ${year} ${formattedTime}`;
      return formattedDate;
    } else {
      const formattedDate = `${day} ${month}, ${year}`;
      return formattedDate;
    }
  } catch {
    return dateString;
  }
}

export default formatDate;
