import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

// mui
import { Box, Button, TextField, Divider, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import handleDateConversion from "../../utils/misc/noTimeZoneFormatDate";

// css
import "../../styles/deAppendicesPage/main.css"

// hooks
import { getRuInfo } from "../../useHooks/restrictedURL/useRestrictedURL"

// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useTranslate } from "react-admin";
const CopyBtn = styled(Button)(({ theme }) => ({
    backgroundColor: "#121212",
    color: "white",
    '&:hover': {
        backgroundColor: "#121212",
    },
}));

const GotoBtn = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#4383cc",
    '&:hover': {
        backgroundColor: "#1565c0",
    },
}));

export default function PageRuInfoShow() {
    const { ru_id } = useParams()
    const [ruInfo, setRuInfo] = useState({})

    // showPwd
    const [showPwd, setShowPwd] = useState(false)
    const translate = useTranslate();

    useEffect(() => {
        getRuInfo(ru_id).then(res => {
            setRuInfo(res?.result)
        }).catch(err => {
            console.log(err)
        })
    }, [ru_id])

    const handleCopy = (url) => {
        navigator.clipboard.writeText(url);
    }



    return (

        <Box sx={{ display: "flex", margin: "auto", width: "100%", justifyContent: "space-around" }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <Typography
                    variant="h6"
                    component="h6"
                >
                    {translate("phase2.ruShow.title")}
                </Typography>
                <TextField
                    margin="normal"
                    type="text"
                    id="outlined-disabled"
                    label={translate("phase2.ru.enhancedTableHead.Client User")}
                    name="clientUser"
                    value={`${ruInfo?.client_user}`}
                    disabled
                    variant="outlined"
                    sx={{
                        width: "20em",
                    }}
                />

                <TextField
                    margin="normal"
                    type="text"
                    id="outlined-disabled"
                    label={translate("phase2.ru.enhancedTableHead.Remark")}
                    name="remark"
                    disabled
                    multiline
                    value={`${ruInfo?.remark}`} // ${ruInfo.remark}
                    variant="outlined"
                    sx={{
                        width: "20em",
                    }}
                />

                {
                    ruInfo?.user_id && (
                        <TextField
                            margin="normal"
                            type="text"
                            id="outlined-disabled"
                            label={translate("phase2.ruShow.Creator")}
                            name="creator"
                            disabled
                            value={ruInfo?.user_id?.username}
                            variant="outlined"
                            sx={{
                                width: "20em",
                            }}
                        />
                    )
                }
                {
                    ruInfo?.createdAt && (
                        <TextField
                            margin="normal"
                            type="text"
                            id="outlined-disabled"
                            label={translate("phase2.ru.enhancedTableHead.Creation Date")}
                            name="creation_date"
                            disabled
                            value={handleDateConversion(new Date(ruInfo?.createdAt))}
                            variant="outlined"
                            sx={{
                                width: "20em",
                            }}
                        />
                    )
                }
                {
                    ruInfo?.expiry_date && (
                        <TextField
                            margin="normal"
                            type="text"
                            id="outlined-disabled"
                            label={translate("phase2.ru.enhancedTableHead.Expiry Date")}
                            name="expiry_date"
                            disabled
                            value={handleDateConversion(new Date(ruInfo?.expiry_date))}
                            variant="outlined"
                            sx={{
                                width: "20em",
                            }}
                        />
                    )
                }

                {/* docs option */}
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                    {ruInfo?.docs?.map((doc, i) => (
                        <FormControlLabel
                            key={doc.name + String(i)}
                            label={doc.name}
                            control={<Checkbox checked={doc.value} />}
                        />
                    ))}
                </Box>

                <Typography
                    component="div"
                    variant="body2"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <TextField
                        margin="normal"
                        type="text"
                        id="outlined-disabled"
                        label={translate("phase2.ruShow.token")}
                        name="token"
                        multiline
                        disabled
                        sx={{
                            width: "30em",
                        }}
                        variant="outlined"
                        value={showPwd ? `${ruInfo?.token}` : "********"} // ${ruInfo.token}
                    />
                    <Tooltip title={showPwd ? "Hide" : "Show"}>
                        <IconButton
                            onClick={() => setShowPwd(!showPwd)}
                            aria-label={showPwd ? "Hide" : "Show"}
                        >
                            {showPwd ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </Tooltip>
                </Typography>


                <Typography
                    component="div"
                    variant="body2"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <TextField
                        multiline
                        margin="normal"
                        id="outlined-disabled"
                        label={translate("phase2.ruShow.url")}
                        variant="outlined"
                        type="text"
                        name="url"
                        disabled
                        sx={{
                            width: "40em",
                        }}
                        value={`${window.location.origin}/verifyRecord/record/ru/${ruInfo?.encrypted_ruNo}`}
                    />
                    <CopyBtn
                        sx={{ mt: "0.5em", ml: "0.5em" }}
                        onClick={() => handleCopy(`${window.location.origin}/verifyRecord/record/ru/${ruInfo?.encrypted_ruNo}`)}
                    >
                        {translate("phase2.ruShow.copy")}
                    </CopyBtn>
                </Typography>


                <Link to="/restrictedUrls">
                    <GotoBtn
                        sx={{ mt: "0.5em" }}
                    >
                        {translate("phase2.ruShow.gotoManagement")}
                    </GotoBtn>
                </Link>

            </Box>
        </Box>

    )
}