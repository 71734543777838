import { DateInput, SelectInput, TextInput, Filter } from "react-admin";

const ManufacturerFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        source="q"
        label="Search manufacturer code / manufacturer name / scope "
        alwaysOn
        sx={{ width: "500px" }}
      />
      <TextInput
        label="Manufacturer Code"
        source="manufacturer_code"
        defaultValue="M"
      />
      <TextInput label="Manufacturer Name" source="provided_name" />
      <TextInput
        label="Manufacturer Address"
        source="iso_manufacturer_address"
      />
      <SelectInput
        label="Scope"
        source="type"
        choices={[
          {
            id: "SafetyComponent",
            name: "Safety Component",
          },
          {
            id: "Model",
            name: "Lift/Escalator System Model",
          },
          {
            id: "Both",
            name: "Both Lift/Escalator System Model and Safety Components",
          },
        ]}
      />
      <DateInput label="All Approval Date" source="approval_date" />
    </Filter>
  );
};

export default ManufacturerFilter;
