import React, { useState } from "react";
import { Confirm } from "react-admin";
import { Button } from "@mui/material";

const DeleteWithConfirmButton = (props) => {
  const { selectedIds, customDeleteLogic, label, sx, userInfo, upload, userRoles } = props;
  const [showConfirm, setShowConfirm] = useState(false);

  const handleDelete = () => {
    setShowConfirm(true);
  };

  const handleConfirm = async () => {
    setShowConfirm(false);
    if (selectedIds) await customDeleteLogic(selectedIds);
    else await customDeleteLogic();
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  // disable the button if the user is not EMSDGR (GR) or Inspector, but the application's current role name is not GR.
  const isButtonDisabled =
    (userInfo?.role === "EMSDGR" && //GR and current role name is not GR and not pending
      (upload?.status.includes("Pending E") ||
        upload?.status.includes("Pending SE") ||
        upload?.status.includes("Pending CE")) &&
      upload?.current_role_name !== "GR") ||
    userInfo?.role === "Inspector" || //Inspector
    (userInfo?.role !== "EMSDGR" && //Check roleId except GR
      !(userRoles || []).includes(upload?.current_role_id));
      
  return (
    <>
      <Button 
        sx={sx} 
        onClick={handleDelete}
        disabled={isButtonDisabled}
      >
        {label || "Delete"}
      </Button>
      <Confirm
        title="Are you sure?"
        content="This action is irreversible. Do you want to delete this record?"
        isOpen={showConfirm}
        onConfirm={handleConfirm}
        onClose={handleCancel}
      />
    </>
  );
};

export default DeleteWithConfirmButton;
