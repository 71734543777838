import { Card, CardContent, CardHeader } from "@mui/material";
import { ApproveButton } from "../../buttons/ApproveButton";
import { TransferButton } from "../../buttons/TransferButton";
import ApprovalHistory from "./ApprovalHistory";

export const ApprovalControl = (props) => {
  const { latestApp, upload, userRole, teams, userInfo, userRoles } = props;

  const applicationRoleId = upload.current_role_id;
;
  // to check if current EMSD Logged in user can make changes
  // only GR and users with the same role can make changes
  const applicationAmendable = userRoles?.includes(upload?.current_role_id) || userRole.role === "EMSDGR";


  const applicationEngineerId = upload.engineer_role_id;
  const applicationSeniorId = upload.senior_role_id;
  const applicationChiefId = upload.chief_role_id;

  const findDefaultRole = (applicationRoleId) => {
    let currentRole = null;

    for (const team of teams) {
      const { chiefEngineer, seniorEngineers, engineers } = team;

      const extractFromRoleArray = (roleArray) => {
        return roleArray.find((role) => role._id === applicationRoleId);
      };

      const extractedFromChiefEngineer = extractFromRoleArray(chiefEngineer);
      const extractedFromSeniorEngineers =
        extractFromRoleArray(seniorEngineers);
      const extractedFromEngineers = extractFromRoleArray(engineers);

      if (extractedFromChiefEngineer) {
        currentRole = extractedFromChiefEngineer;
        break;
      } else if (extractedFromSeniorEngineers) {
        currentRole = extractedFromSeniorEngineers;
        break;
      } else if (extractedFromEngineers) {
        currentRole = extractedFromEngineers;
        break;
      }
    }

    return currentRole;
  };

  if (!teams) return null;
  const currentRole = findDefaultRole(applicationRoleId);
  const ApplicationEngineerRole = findDefaultRole(applicationEngineerId);
  const ApplicationSeniorRole = findDefaultRole(applicationSeniorId);
  const ApplicationChiefRole = findDefaultRole(applicationChiefId);

  return (
    <Card>
      <CardHeader title="Action" />
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          {/* <ApproveButton id={upload.id} type={"Approved"} /> */}
          {window.location.pathname === "/lift_rc" &&
            upload.status !== "Approved" &&
            upload.status !== "Clarifying" &&
            upload.status !== "Withdrawn" && (
              <ApproveButton id={upload.id} type={"Withdrawn"} />
            )}

          {window.location.pathname === "/lift_emsd" &&
            applicationAmendable && (
            <>
                {(upload.status === "Pending" ||
                upload.status === "ReturningClarifying") && userRole?.role !== "Inspector" && (
                <>
                  {<ApproveButton 
                    id={upload.id} 
                    type={"Rejected"} 
                    userInfo={userInfo}
                    upload={upload}
                  />}
                  {upload.status !== "Withdrawn" && (
                    <ApproveButton 
                      id={upload.id} 
                      type={"Withdrawn"} 
                      userInfo={userInfo}
                      upload={upload}
                    />
                  )}
                  {<ApproveButton 
                    id={upload.id} 
                    type={"Clarifying"} 
                    userInfo={userInfo}
                    upload={upload}
                  />}
                  {["Update lift/escalator model certificate", "Update safety component certificate", "Update safety component certificate (synchronised)"].includes(upload.update_type) ? (
                    <ApproveButton
                      latestApp={latestApp}
                      upload={upload}
                      id={upload.id}
                      type={"Approved"}
                      teams={teams}
                      applicationRole={currentRole}
                    />
                  ) : (
                    <ApproveButton
                      latestApp={latestApp}
                      upload={upload}
                      id={upload.id}
                      type={"Pending E"}
                      teams={teams}
                      applicationRole={currentRole}
                    />
                  )}
                </>
              )}
                {upload.status === "Pending E" && (
                <>
                  <ApproveButton 
                    id={upload.id} 
                    type={"Rejected"} 
                    userInfo={userInfo}
                    upload={upload}
                  />
                  <ApproveButton 
                    id={upload.id} 
                    type={"Withdrawn"}
                    userInfo={userInfo}
                    upload={upload}
                  />
                  {<ApproveButton 
                    id={upload.id} 
                    type={"Clarifying"} 
                    userInfo={userInfo}
                    upload={upload}
                  />}
                  {(userRole?.role !== "EMSDGR" || userRole?.role !== "Inspector") && (
                    <ApproveButton
                      latestApp={latestApp}
                      id={upload.id}
                      type={"Pending"}
                      previousStatus={upload.status}
                      teams={teams}
                      applicationRole={currentRole}
                      upload={upload}
                      userInfo={userInfo}
                    />
                  )}
                  <TransferButton
                    latestApp={latestApp}
                    upload={upload}
                    id={upload.id}
                    type={"Pending E"}
                    teams={teams}
                    applicationRole={currentRole}
                  />
                  {(userRole?.role !== "EMSDGR" || userRole?.role !== "Inspector") && (
                    <ApproveButton
                      latestApp={latestApp}
                      id={upload.id}
                      type={"Pending SE"}
                      teams={teams}
                      applicationRole={ApplicationSeniorRole}
                      userInfo={userInfo}
                      upload={upload}
                    />
                  )}
                </>
              )}
              {upload.status === "Pending SE" && (
                <>
                  <TransferButton
                    latestApp={latestApp}
                    upload={upload}
                    id={upload.id}
                    type={"Pending SE"}
                    teams={teams}
                    applicationRole={ApplicationSeniorRole}
                  />
                  {(userRole?.role !== "EMSDGR" || userRole?.role !== "Inspector") && (
                    <>
                      <ApproveButton
                        latestApp={latestApp}
                        id={upload.id}
                        type={"Pending E"}
                        teams={teams}
                        applicationRole={ApplicationEngineerRole}
                        userInfo={userInfo}
                        upload={upload}
                      />
                      <ApproveButton
                        latestApp={latestApp}
                        id={upload.id}
                        type={"Pending CE"}
                        teams={teams}
                        applicationRole={ApplicationChiefRole}
                        userInfo={userInfo}
                        upload={upload}
                      />
                    </>
                  )}
                </>
              )}
              {upload.status === "Pending CE" && (
                <>
                  <TransferButton
                    latestApp={latestApp}
                    upload={upload}
                    id={upload.id}
                    type={"Pending CE"}
                    teams={teams}
                    applicationRole={ApplicationChiefRole}
                  />
                  {(userRole?.role !== "EMSDGR" || userRole?.role !== "Inspector") && (
                    <>
                      <ApproveButton
                        latestApp={latestApp}
                        id={upload.id}
                        type={"Pending SE"}
                        teams={teams}
                        applicationRole={ApplicationSeniorRole}
                        userInfo={userInfo}
                        upload={upload}
                      />
                      <ApproveButton 
                        latestApp={latestApp}
                        id={upload.id} 
                        type={"Approved"} 
                        userInfo={userInfo}
                        upload={upload}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {window.location.pathname === "/lift_emsd" && <ApprovalHistory upload={upload} sx={{mt: 5}} />}
      </CardContent>
    </Card>
  );
};
