import { Button, Box, Stack, ButtonGroup } from "@mui/material";
import React, { useState } from "react";
import appendix_templates from "../../../utils/pdf/appendix_templates.json";
import approval_letter_templates from "../../../utils/pdf/approval_letter_templates.json";
import TableInput from "./TableInput";
import TextInput from "./TextInput";
import HideCerts from "./HideCerts";
import LineBreakInput from "./LineBreakInput";
import ManufacturerCode from "./ManufacturerCode";
import _ from "lodash";
import PartDinput from "./PartDinput";
import ApprovalLetterInput from "./ApprovalLetterInput";
import HideColumns from "./HideColumns";

const EditAppendixInput = (props) => {
  const {
    appendix,
    application,
    standalones,
    setUpload,
    setAppendix,
    certificates,
    setCertificates,
    section,
    setText,
    applicationType,
    userInfo,
    userRoles,
    allowManageRecords
  } = props;

  const [models, setModels] = useState(
    (application?.model || []).map((model) => ({ ...model }))
  ); //deep clone the models for ModelInput as we don't want to mutate application

  const handleLoadTemplate = () => {
    const approvalLetterType =
      application.app_type.type === "SafetyComponent"
        ? "updateSC"
        : application.app_type.type === "Escalator" ||
          application.app_type.type === "PassengerConveyor"
        ? "escalator"
        : "lift";

    let updatedAppendix = {
      ...appendix,
    };

    const content = (type) => {
      switch (type) {
        case "approvalLetterSubject":
          return approval_letter_templates[approvalLetterType][
            "approvalLetterSubject"
          ];
        case "approvalLetterBody":
          return approval_letter_templates[approvalLetterType][
            "approvalLetterBody"
          ];
        case "table": {
          const sectionBdata = application.model?.[0]?.LEOSdata?.[0];
          const tableTemplate = Object.keys(sectionBdata || {})
            .filter(
              (key) =>
                ![
                  "exam_cert_records",
                  "latest_cert",
                  "lift_cert",
                  "manufacturer_code",
                  "rc_type",
                  "rc_code",
                  "Lift Model :",
                  "LE_create_by",
                  "LE_approve_by",
                  "LE_CODE",
                  "app_type",
                  "Type",
                ].includes(key) &&
                (!_.isObject(sectionBdata[key]) ||
                  Array.isArray(sectionBdata[key]))
            )
            .map((key) => {
              return {
                field: key,
                value: Array.isArray(sectionBdata[key])
                  ? sectionBdata[key].join(", ")
                  : sectionBdata[key],
              };
            });
          return tableTemplate;
        }
        default:
          return appendix_templates[application.app_type.type][type];
      }
    };

    [
      "overviews",
      "conditions",
      "remarks",
      "remarks2",
      "table",
      "approvalLetterSubject",
      "approvalLetterBody",
    ].forEach((key) => {
      if (appendix[key]?.length === 0) {
        updatedAppendix = {
          ...updatedAppendix,
          [key]: content(key),
        };
      }
    });

    setAppendix(updatedAppendix);
  };

  const addSection = () => {
    if (["hideCerts", "SCLineBreak"].includes(section)) return;
    setAppendix({
      ...appendix,
      [section]: [
        ...appendix[section],
        section === "table"
          ? { field: "", value: "" }
          : section === "manufacturer_code"
          ? { name: "", address: "" }
          : [""],
      ],
    });
  };

  const deleteSection = (i, type) => {
    let section = structuredClone(appendix[type]);
    section = section.filter((section, index) => index !== i);
    setAppendix({ ...appendix, [type]: section });
  };


  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))


  return (
    <Box sx={{ px: 1 }}>
      <Box sx={{ textAlign: "right" }}>
        <ButtonGroup
          sx={{ mt: 1 }}
          variant="contained"
          color="primary"
          aria-label="outlined primary button group"
        >
          <Button disabled={isDisabled} onClick={addSection}>Add New Section</Button>
          <Button disabled={isDisabled} onClick={() => handleLoadTemplate()}>Load Template</Button>
        </ButtonGroup>
      </Box>
      {section === "manufacturer_code" ? (
        <ManufacturerCode
          appendix={appendix}
          setAppendix={setAppendix}
          deleteSection={deleteSection}
          application={application}
          userInfo={userInfo}
          userRoles={userRoles}
        />
      ) : section === "table" ? (
        <TableInput
          application={application}
          setUpload={setUpload}
          appendix={appendix}
          deleteSection={deleteSection}
          setAppendix={setAppendix}
          models={models}
          setModels={setModels}
          userInfo={userInfo}
          userRoles={userRoles}
          setText={setText}
        />
      ) : section === "hideCerts" ? (
        //Table C
        <HideCerts
          appendix={appendix}
          setAppendix={setAppendix}
          certificates={certificates}
          setCertificates={setCertificates}
          application={application}
          userInfo={userInfo}
          userRoles={userRoles}
          allowManageRecords={allowManageRecords}
        />
      ) : section === "SCLineBreak" ? (
        <LineBreakInput
          appendix={appendix}
          standalones={standalones}
          setAppendix={setAppendix}
          application={application}
          userInfo={userInfo}
          userRoles={userRoles}
        />
      ) : section === "SCHideColumns" ? (
        <HideColumns
          appendix={appendix}
          standalones={standalones}
          setAppendix={setAppendix}
          application={application}
          userInfo={userInfo}
          userRoles={userRoles}
        />
      ): section === "partD" ? (
        <PartDinput 
          appendix={appendix} 
          setAppendix={setAppendix}
          application={application}
          userInfo={userInfo}
          userRoles={userRoles}
        />
      ) : section === "approvalLetterMisc" ? (
        <ApprovalLetterInput 
          appendix={appendix} 
          setAppendix={setAppendix}
          application={application}
          userInfo={userInfo}
          userRoles={userRoles}
        />
      ) : (
        <Stack
          spacing={2}
          direction="column"
          sx={{
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextInput
            applicationType={applicationType}
            appendix={appendix}
            setAppendix={setAppendix}
            paragraph={section}
            deleteSection={deleteSection}
            setText={setText}
            application={application}
            userInfo={userInfo}
            userRoles={userRoles}
          />
        </Stack>
      )}
    </Box>
  );
};

export default EditAppendixInput;
