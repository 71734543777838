import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
export function ShowBtn({ handleView, row, code, children, ...props }) {
    const { t } = useTranslation("pages", { keyPrefix: "typeApprovalRecord" });
    
    return (
        <span
            {...props}
            className="cursor-pointer"
            onClick={() => handleView(row)}
            aria-label={`view ${code}`}
        >
            <IconButton
                onClick={() => handleView(row)}
                aria-label={`view ${code}`}
            >
                <VisibilityIcon 
                    sx={{
                        color: "#2a598f"
                    }}
                />
            </IconButton>
            <span
                className='text-[#2a598f] font-bold'
                aria-label={`view ${code}`}
            >
                {children ? children : t("show")}
            </span>
        </span>
    )
}