import React, { useEffect, useState, useRef} from 'react';
import {
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Button,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Pagination
} from "@mui/material"; 
import { useSelector, useDispatch } from 'react-redux';
import { changeComponentsPage, changeComponentsItemsPerPage } from '../../redux/revoke/revokeSlice';
import keyToField from "../../utils/keyToField";


const selectProps = {
  sx: { mt: 2 },
  fullWidth: true,
  label: "operation",
};


const fields = {
  "Buffer": "Buffer",
  "Overspeed Governor": "OverspeedGovernor",
  "Landing door locking device": "LandingDoorLockingDevice",
  "Car door locking device": "CarDoorLockingDevice",
  "Safety Gear": "SafetyGear",
  "Ascending car overspeed protection means": "ACOP",
  "Unintended car movement protection means": "UCMP",
  "Safety circuit containing electrical components": "SafetyCircuitElectricalComponent",
  "Rapture Valve": "RaptureValve",
  "One Way Valve": " OneWayValve",
  "Step": "Step",
  "Pallet": "Pallet",
  "Traction Machine": "TractionMachine",
  "Controller": "Controller",
  "Others": "Others",
};



const SelectSafetyComponent = ({ setUpload, upload }) => {
  const dispatch = useDispatch();
  const { comps, currentPage, itemsPerPage, totalItems } = useSelector((state) => state.revoke.components);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [searchQueryResult, setSearchQueryResult] = useState('');

  const totalCompsRef = useRef(totalItems);
  const [totalPages, setTotalPages] = useState(Math.ceil(totalCompsRef.current / itemsPerPage));

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  let allComps = searchQueryResult !== "" ? searchQueryResult : comps;
  let sortedComps = allComps && [...allComps].sort((a, b) => a.component_code.localeCompare(b.component_code));  // sort by component_code
  const paginatedComps = sortedComps?.slice(startIndex, endIndex);


  useEffect(() => {
    setSelectedOptions(upload.components);
    setSelectAll(upload.components?.length === sortedComps?.length);
  }, [upload.components, sortedComps]);

  useEffect(() => {
    dispatch(changeComponentsPage(1));

    if (searchQueryResult.length === 0) {
      totalCompsRef.current = totalItems;
    } else if (searchQueryResult.length > 0 && searchQueryResult !== "") {
      totalCompsRef.current = searchQueryResult.length;
    } else {
      totalCompsRef.current = 0;
    };

    setTotalPages(Math.ceil(totalCompsRef.current / itemsPerPage)); // Update totalPages
  }, [searchQueryResult, totalItems, itemsPerPage]);



  const handlePageChange = (e, newPage) => { 
    dispatch(changeComponentsPage(newPage));
  };


  const handleItemsPerPageChange = (newItemsPerPage) => dispatch(changeComponentsItemsPerPage(newItemsPerPage));


  const handleSearchChange = () => {
    sortedComps = comps; // reset to all comps when user press "enter" to search

    if (searchValue.trim() === '') {
      setSearchQueryResult(''); // Set the search query result to an empty string
    } else {
      let filteredComps = sortedComps.filter((comp) => {
        const { component_code, provided_comp_name, type, manufacturers_provided_name, manufacturersPopulated, manufacturer_name } = comp;
        const manufacturerName = manufacturers_provided_name?.[0]?.provided_name || manufacturersPopulated?.[0]?.provided_name || manufacturer_name || "";

        // filter the matched fields based on the search value. 
        // then using map() to extract the corresponding values into matchedValues
        const matchedValues = Object.entries(fields)
          .filter(([key, value]) => {
            return key.toLowerCase().includes(searchValue.toLowerCase().trim());
          })
          .map(([key, value]) => value);

        return (
          (component_code && component_code.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (provided_comp_name && provided_comp_name.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (type && type.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (manufacturerName && manufacturerName.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          // matchedValues.some() to check if any of the matched values are included in the "type" field.
          (matchedValues && matchedValues.some((matchedValue) => type.toLowerCase().includes(matchedValue.toLowerCase().trim())))
        );
      });
      setSearchQueryResult(filteredComps);
    }
  };


  const handleClearSearch = () => setSearchValue('');  // Clear the search field value
  

  const handleCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;

    setUpload((prevState) => {
      let updatedSC = [...prevState.components];

      if (isChecked) {
        updatedSC.push(id);
      } else {
        updatedSC = updatedSC.filter((compId) => compId !== id);
      }

      return {
        ...prevState,
        components: updatedSC,
      };
    });

    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => {
        return [...prevSelectedOptions, id];
      });
    } else {
      setSelectedOptions((prevSelectedOptions) => {
        return prevSelectedOptions.filter((optionId) => optionId !== id);
      });
    }
  };


  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    setSelectedOptions(isChecked ? sortedComps.map((comp) => comp.id) : []);

    setUpload((prevState) => ({
      ...prevState,
      components: isChecked ? sortedComps.map((comp) => comp.id) : [],
    }));
  };


  return (
    <>
      <TextField
        sx={{ flex: 1, width: "40%" }}
        variant="filled"
        className="searchBox"
        label="Search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                sx={{
                  cursor: "pointer",
                  color: "#051B44",
                  fontSize: ".7em",
                  textTransform: "none",
                }}
                onClick={handleClearSearch}
              >
                Clear
              </Button>
              <Button
                onClick={handleSearchChange}
                aria-label='search'
                variant="contained"
                sx={{
                  backgroundColor: "#2A598F",
                  textTransform: "none",
                  '&:hover': {
                    backgroundColor: "#203C5B",
                  },
                  fontSize: ".7em",
                }}
              >
                Search
              </Button>
            </InputAdornment>
          ),
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearchChange()
          }
        }}
      />

      {paginatedComps?.length > 0 && (
         <FormControl {...selectProps}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{width: "15%"}}>Component Code</TableCell>
                  <TableCell sx={{width: "20%"}}>Component Name</TableCell>
                  <TableCell sx={{width: "25%"}}>Type</TableCell>
                  <TableCell sx={{width: "30%"}}>Manufacturer Name</TableCell>
                  <TableCell sx={{width: "20%"}}>RC</TableCell>
                  <TableCell sx={{width: "10%"}} align="right">
                    {" "}
                    Select All
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                      inputProps={{ "aria-label": "Select All" }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedComps.map((comp) => (
                  <TableRow key={comp.id}>
                    <TableCell>{comp.component_code}</TableCell>
                    <TableCell>{comp.provided_comp_name}</TableCell>
                    <TableCell>{keyToField[comp.type]}</TableCell>
                    {/* <TableCell>
                      {comp?.manufacturers_provided_name?.[0]?.provided_name || comp?.manufacturersPopulated?.[0]?.provided_name}
                    </TableCell> */}
                    <TableCell>{comp.manufacturer_name}</TableCell>
                    <TableCell>{comp?.rcsPopulated[0]?.name + " / " + comp?.rcsPopulated[0]?.address?.company }</TableCell>
                    <TableCell align="right">
                      <Checkbox
                        checked={selectedOptions.includes(comp.id)}
                        onChange={(e) => handleCheckboxChange(e, comp.id)}
                        inputProps={{
                          "aria-label": comp.provided_comp_name,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FormControl>
      )}

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <p style={{ marginRight: '10px' }}>Rows per page:</p>
          <Select
            variant='outlined'
            id="select-items-per-page"
            value={itemsPerPage}
            onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
            sx={{ minWidth: '40px', height: '30px'}}
          >
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="20">20</MenuItem>
          </Select>
        </div>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />

      </div>
    </>
  );
};

export default SelectSafetyComponent;