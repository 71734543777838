import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Container,
  Typography,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";

function FooterNotices(props) {
  const { route } = useParams();
  const title = () => {
    switch (route) {
      case "important_notices":
        return "Important Notices";
      case "privacy_policy":
        return "Privacy Policy";
      case "contact_us":
        return "Contact Us";
      case "about_us":
        return "About Us";
      default:
        return "Footer Notices";
    }
  };
  return (
    <Card sx={{ mt: 2.5, minHeight: "89.8vh" }}>
      <Container maxWidth="lg">
        <CardHeader title={title()} />
        <CardContent>
          <Link to="/" style={{ fontFamily: "Frutiger_light" }}>
            Back
          </Link>
          {route === "important_notices" && (
            <Box>
              <Typography variant="body2" sx={{ fontFamily: "Frutiger_bold" }}>
                Copyright Notice
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                The content available on this website ("the web content"),
                including but not limited to all text, graphics, drawings,
                diagrams, photographs and compilation of data or other materials
                are subject to copyright owned by the Government of the Hong
                Kong Special Administrative Region or other entities. Except as
                expressly permitted herein or where prior written authorization
                is obtained from the Electrical and Mechanical Services
                Department, any reproduction, adaptation, distribution,
                dissemination or making available of such copyright works to the
                public is strictly prohibited.
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Permission is granted for users to (i) use the character-based
                translation software provided in this website to convert the web
                content from traditional Chinese version to simplified Chinese
                version; and (ii) download the materials herein to store them in
                local computers, provided that this is solely for personal or
                non-commercial internal use, and provided further that this
                copyright notice is downloaded at the same time. Users should
                note that the above permission only applies to Government
                copyright materials. Where third party copyrights are involved,
                an appropriate notice will appear in this website.
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: "Frutiger_bold" }}>
                Disclaimer
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                The information contained in this website is compiled by the
                Electrical and Mechanical Services Department of the Government
                of the Hong Kong Special Administrative Region ("the
                Government") for general information only. Whilst the Government
                endeavours to ensure the accuracy of this general information,
                no statement, representation, warranty or guarantee, express or
                implied, is given as to its accuracy or appropriateness for use
                in any particular circumstances.
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                This website can also contain information contributed by others
                over whom, and in respect of which, the Government may have no
                influence. Users may link from this website to sites provided by
                others. The Government expressly states that it has not approved
                or endorsed the information provided by others on this website
                or any other sites linked to this website and the Government
                accepts no responsibility or liability (howsoever caused) for
                such information.
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                This website contains a character-based translation software
                which enables users to convert the web content from traditional
                Chinese version to simplified Chinese version. The Government
                expressly states that it has not approved or endorsed the
                simplified Chinese version of the web content and the Government
                accepts no responsibility or liability (however caused) for such
                simplified Chinese version. Under no circumstances should users
                treat the simplified Chinese version of the web content as an
                equivalent of the traditional Chinese version thereof. Users
                should verify the simplified Chinese version of the web content
                by making reference to the published traditional Chinese version
                thereof, and should seek independent legal advice on the legal
                status, validity and effect of the simplified Chinese version of
                the web content before relying or acting upon it.
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                The Government is not responsible for any loss or damage
                whatsoever arising out of or in connection with any information
                including data or programmes on this website. The Government
                reserves the right to omit, suspend or edit all information
                compiled by the Government in this website at any time in its
                absolute discretion without giving any reason or prior notice.
                Users are responsible for making their own assessment of all
                information contained in this website and are advised to verify
                such information by making reference, for example, to original
                publications and obtaining independent advice before acting
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: "Frutiger_bold" }}>
                Information Contained in This Site
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                EMSD updates the information contained in this website
                regularly. However, if users are looking for the most up-to-date
                information, please contact us by email to verify the
                information before acting on it.
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: "Frutiger_bold" }}>
                Bribery
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Any attempt to offer advantage (as defined in the Prevention of
                Bribery Ordinance ("POBO")(Cap.201)) to any government officer
                with a view to influencing the outcome of this application
                constitutes an offence under the POBO and renders the
                application invalid. The case will be reported to the
                Independent Commission Against Corruptoin. You should report to
                the ICAC (Telephone No.: 2526 6366) if any government officer or
                their agent solicits an advantage from you in relation to this
                application.
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: "Frutiger_bold" }}>
                About this site
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                This website is produced and maintained by the Electrical and
                Mechanical Services Department (EMSD), the Government of the
                Hong Kong Special Administrative Region ("the Government"). The
                site is best viewed in 1024 x 768 resolution with Internet
                Explorer 9.0 or Firefox 22.0 or above. Overseas Internet users
                should note that the telephone numbers and facsimile lines of
                Hong Kong are preceded by the area code "852"
              </Typography>
            </Box>
          )}
          {route === "privacy_policy" && (
            <Box>
              <Typography variant="body2" sx={{ fontFamily: "Frutiger_bold" }}>
                Website
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                The Government of the Hong Kong Special Administrative Region
                ("the Government") is concerned to ensure that all personal data
                submitted through emsd.gov.hk are handled in accordance with the
                relevant provisions of the Personal Data (Privacy) Ordinance
                (www.pcpd.org.hk).
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                The Government will record visits to emsd.gov.hk without
                collecting any personal identifiable information of users. Such
                general statistics are collected for the compilation of
                statistical reports and the diagnosis of problems with or
                concerning computer systems to help improve emsd.gov.hk.
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                For different purposes and at different times you may be invited
                to provide personal data to the Government through emsd.gov.hk
                on a voluntary basis. Personal information may include name,
                telephone number, mailing address or e-mail address. The
                Government will specify the collection purpose and intended
                usage of your data when it invites you to provide such
                information and advise you how you may request access to or
                correction of the personal data provided.
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Unless permitted or required by law, the Government will not
                disclose your personal data to any third parties without your
                prior consent.
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                emsd.gov.hk uses SSL protocol to encrypt data during network
                transmission to protect your personal data. All personal data
                you provide to the Government via emsd.gov.hk are secured, and
                access to them is restricted to authorised personnel only.
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Search service on emsd.gov.hk is provided by an independent
                contractor of the Government. We are advised by the independent
                contractor that it does not collect personal identifiable
                information while serving search results through emsd.gov.hk.
                The independent contractor will share the anonymous data it
                collects through the search service with the Government for
                compiling traffic analysis on government websites. The
                Government will not match the data obtained from any such search
                activity with any personal data possibly held by the Government.
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                This Privacy Policy has been translated into Chinese. If there
                is any inconsistency or ambiguity between the English version
                and the Chinese version, the English version shall prevail.
              </Typography>
            </Box>
          )}
          {route === "about_us" && (
            <Box>
              <Typography variant="body2">
                The General Legislation Division (GLD) is the Regulatory
                Services branch enforcing the Lifts and Escalators Ordinance
                (the Ordinance), Cap. 618. Under the Ordinance , registered
                lift/escalator contractors (RCs) are required to seek type
                approval for a particular brand and model of lifts /escalators
                and safety components from the Director prior to their
                installation or major alteration of any lift/escalator or safety
                component of such brand and model (see section 16(1)(e) and (f),
                and section 47(1)(e) and (f) of the Ordinance.
              </Typography>
            </Box>
          )}
          {route === "contact_us" && (
            <TableContainer>
              <TableBody>
                <TableRow>
                  <TableCell variant="head">Public Enquiry Hotline</TableCell>
                  <TableCell>2808 3549</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Email</TableCell>
                  <TableCell>lesd@emsd.gov.hk</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Address</TableCell>
                  <TableCell>
                    Electrical and Mechanical Services Department Headquarters,
                    3 Kai Shing Street, Kowloon, Hong Kong.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Office Hours</TableCell>
                  <TableCell>
                    Monday - Friday 9:00am - 5:15pm (Closed on Saturdays,
                    Sundays and Public Holidays)
                  </TableCell>
                </TableRow>
              </TableBody>
            </TableContainer>
          )}
        </CardContent>
      </Container>
    </Card>
  );
}

export default FooterNotices;
