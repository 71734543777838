import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

// react hooks
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from 'react-i18next';
const fields = [
  {
    value: 'model_code',
    label: 'Model Code',
  },
  {
    value: 'provided_model_name',
    label: 'Model Name',
  },
  {
    value: 'type',
    label: 'Type',
  },
  {
    value: 'manufacturersPopulated.provided_name',
    label: 'Manufacturer Name',
  }
];


function InputSearchBar({ setFilter }) {
  const { t } = useTranslation("pages", { keyPrefix: "typeApprovalRecord" });
  const [filterField, setFilterField] = useState("provided_model_name");
  const [filterValue, setFilterValue] = useState("");
  const matches = useMediaQuery('(max-width: 600px)');
  
  const handleTextSearch = (e) => {
    e.preventDefault()
    setFilter(`{"${filterField}":"${filterValue}"}`)
  }

  return (
    <Toolbar
      sx={{
        mt: 3,
        mx: 2      
      }}
    >
        <Typography
          sx={{ flex: '1 1 100%' }}
          id="tableTitle"
          component="div"
          style={{
            m: 0,
          }}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
              flexGrow: 1,
              m: 0
            }}
            noValidate
            autoComplete="off"
          >

            <Grid 
              container spacing={{xs: 1, sm: 1, md: 2}} columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{
                // center
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row",
                m: 0
              }}
            >
              <Grid item xs={12}>
                <TextField
                  id="standard-basic" label="Text Search" variant="outlined"
                  value={filterValue}
                  sx={{
                    boxShadow: "0 0 0 1rem rgba(42,89,143,.25)",
                    borderRadius: "2px",
                    mb:2
                  }}
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start">
                        <TextField
                          id="filterField"
                          select
                          label="Filter"
                          variant="filled"
                          onChange={(e) => setFilterField(e.target.value)}
                          value={filterField}
                        >
                          {fields.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {t(option.label)}
                            </MenuItem>
                          ))}
                        </TextField>
                      </InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          sx={!matches ? {
                            cursor: "pointer",
                            color: "#051B44",
                            textTransform: "none",
                          } : {
                            cursor: "pointer",
                            color: "#051B44",
                            fontSize: "8px",
                            textTransform: "none",
                          }}
                          onClick={() => setFilterValue("")}
                        >
                          {t("Clear")}
                        </Button>
                        <Button
                          onClick={handleTextSearch}
                          aria-label='search'
                          variant="contained"
                          sx={!matches ? {
                            backgroundColor: "#2A598F",
                            textTransform: "none",
                            '&:hover': {
                              backgroundColor: "#203C5B",
                            },
                          } : {
                            backgroundColor: "#2A598F",
                            textTransform: "none",
                            '&:hover': {
                              backgroundColor: "#203C5B",
                            },
                            fontSize: "8px",
                          }}
                          
                        >
                          {t("Search")}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setFilterValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleTextSearch(e)
                    }
                  }}
                />
              </Grid>

            </Grid>

          </Box>
        </Typography>

    </Toolbar>
  );
}

export default InputSearchBar