import {
  SelectInput,
  TextInput,
  ReferenceInput,
  Filter,
  BooleanInput,
} from "react-admin";

const ComponentFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        source="q"
        label="Search component code / component name / model name / manufacturer / type / RC / cert data"
        alwaysOn
        sx={{ width: "800px" }}
      />
      <TextInput
        label="Component Code"
        source="component_code"
        defaultValue="RC"
      />
      <TextInput label="Component Name" source="provided_comp_name" />
      <SelectInput
        label="Type of component"
        source="type"
        choices={[
          {
            id: "ACOP",
            name: "Ascending car overspeed protection means",
          },
          {
            id: "OverspeedGovernor",
            name: "Overspeed Governor",
          },
          { id: "UCMP", name: "Unintended car movement protection means" },
          { id: "CarDoorLockingDevice", name: "Car door locking device" },
          {
            id: "LandingDoorLockingDevice",
            name: "Landing door locking device",
          },
          {
            id: "SafetyCircuitElectricalComponent",
            name: "Safety circuit containing electrical components",
          },
          { id: "Pallet", name: "Pallet" },
          { id: "Step", name: "Step" },
          {
            id: "OneWayValve",
            name: "One Way Valve",
          },
          { id: "RaptureValve", name: "Rapture Valve" },
          { id: "SafetyGear", name: "Safety Gear" },
          { id: "Buffer", name: "Buffer" },
          { id: "TractionMachine", name: "Traction Machine" },
          { id: "Controller", name: "Controller" },
        ]}
      />
      <SelectInput
        label="Lift/escalator Model Type"
        source="modelsPopulated.type"
        choices={[
          {
            id: "ElectricTractionLiftMachineRoom",
            name: "Electric Traction Lift (Machine Room)",
          },
          {
            id: "ElectricTractionLiftRoomless",
            name: "Electric Traction Lift (Machine Roomless)",
          },
          { id: "InclinedLift", name: "Electric Inclined Lift" },
          { id: "ServiceLift", name: "Service Lift" },
          { id: "StairLift", name: "Stairlift" },
          { id: "HydraulicLift", name: "Hydraulic Lift" },
          {
            id: "ElectricalVerticalLiftingPlatform",
            name: "Vertical Lifting Platform",
          },
          { id: "MVPS", name: "Mechanized Vehicle Parking System (MVPS)" },
          { id: "Escalator", name: "Escalator" },
          { id: "PassengerConveyor", name: "Passenger Conveyor" },
          { id: "SafetyComponent", name: "Safety Component" },
          { id: "Others", name: "Others" },
        ]}
      />
      <TextInput
        label="Lift/escalator models"
        source="modelsPopulated.provided_model_name"
      />
      <TextInput
        label="Manufacturers"
        source="manufacturersPopulated.provided_name"
      />
      {window.location.pathname === "/lift_emsd" && (
        <ReferenceInput source="rc_id" label="RC" reference="rcs" perPage={100}>
          {/* <SelectInput optionText="name" /> */}
          <SelectInput
            optionText={(choice) => {
              let displayText = "";
              displayText = choice?.rc_number_lift;
              if (choice?.rc_number_escalator) {
                displayText += ` / ${choice.rc_number_escalator}`;
              }
              if (choice?.address?.company) {
                displayText += ` / ${choice.address.company}`;
              }
              return displayText;
            }}
          />
        </ReferenceInput>
      )}
      <BooleanInput label="Comply standard" source="comply_standard" />
      <BooleanInput label="Is standalone" source="standalone" />
    </Filter>
  );
};

export default ComponentFilter;
