import React, { useState, useRef } from "react";
import { Button, Popper, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

function NameEditor(props) {
  const { nameInput, onClick } = props;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleAddClick = (e) => {
    onClick();
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    //This button will be inside AccordionSummary, have the e.stopPropagation is stop trigger the collaspe
    <div style={{ padding: "0px 10px" }} onClick={(e) => e.stopPropagation()}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "add-form-popper" : undefined}
        aria-haspopup="true"
        sx={{ p: 0 }}
        onClick={handleAddClick}
      >
        Edit name
      </Button>
      <Popper
        id="add-form-popper"
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        sx={{
          backgroundColor: "white",
          zIndex: 999,
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          boxShadow: "1px 1px 5px black",
        }}
        disablePortal
      >
        <IconButton
          sx={{
            backgroundColor: "#ff234ab8",
            p: 0.5,
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close sx={{ fontSize: "15px" }} />
        </IconButton>
        {nameInput}
      </Popper>
    </div>
  );
}

export default NameEditor;
