import {
  PDFDownloadLink,
  Page,
  Document,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import keyToField from "../../utils/keyToField";
import fontRegister from "../../utils/pdf/fontRegister";
import summaryLogo from "../../styles/logo/summaryLogo.png";
import formatDate from "../../utils/misc/formatDate";
import checkbox from "../../styles/logo/checkbox.png";
import renderChinese from "../../utils/pdf/renderChinese";

function ApplicationSummary(props) {
  fontRegister();
  const { upload, userInfo } = props;

  const modelManufacturerList = upload.manufacturer.filter(
    (manu) => manu.type !== "SafetyComponent"
  );

  const scManufacturerList = upload.manufacturer.filter(
    (manu) => manu.type !== "Model"
  );

  function fileList(data, type) {
    return data.map((item, index) => {
      const title =
        type === "manufacturer"
          ? "Manufacturer Name"
          : type === "model"
          ? "Model(s)"
          : "Safety Component(s)";
      const name =
        type === "manufacturer"
          ? item.provided_name
          : type === "model"
          ? item.provided_model_name
          : `${keyToField[item.type]} | ${item.provided_comp_name}`;
      function getFilenamesFromField(field) {
        return field?.filename ||
          (Array.isArray(field) && field.some((item) => item.filename))
          ? field
          : [];
      }
      const files =
        type === "model"
          ? item.manufacturer_files.flatMap((file) => {
              return Object.values(file).flatMap(getFilenamesFromField);
            })
          : Object.values(item).flatMap(getFilenamesFromField);
      return (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            ...(index !== 0 && { marginTop: "10px" }),
          }}
          key={index}
        >
          <View style={{ width: "43.5%" }}>
            {index === 0 && (
              <Text style={{ textDecoration: "underline" }}>{title}</Text>
            )}
            <Text style={{ fontSize: "10px", ...renderChinese(name) }}>
              {name}
            </Text>
          </View>
          <View style={{ width: "56.5%" }}>
            {index === 0 && (
              <Text style={{ textDecoration: "underline" }}>File Name</Text>
            )}
            <View>
              {files.map((field, fieldIndex) => {
                return (
                  <Text
                    key={fieldIndex}
                    style={{
                      fontSize: "9px",
                      ...renderChinese(field.filename),
                    }}
                  >
                    {fieldIndex + 1}. {field.filename}
                  </Text>
                );
              })}
            </View>
          </View>
        </View>
      );
    });
  }

  const modelDetails = (model) => {
    return (
      <View key={model.provided_model_name}>
        <Text
          style={{ textDecoration: "underline", fontFamily: "Frutiger_bold" }}
        >
          {model.provided_model_name}
        </Text>
        {Object.keys(model)
          .filter(
            (keys) =>
              keys !== "manufacturer_files" &&
              keys !== "LE_type" &&
              keys !== "provided_model_name" &&
              keys !== "model_code"
          )
          .map((field, i) => {
            const translated = keyToField[field] ?? field;
            const translatedValue = keyToField[model[field]] ?? model[field];
            return (
              <Text key={i}>
                <Text>{translated}: </Text>
                <Text
                  style={{
                    fontFamily: "Frutiger_bold",
                  }}
                >
                  {Array.isArray(translatedValue)
                    ? translatedValue.join(", ")
                    : translatedValue}
                </Text>
              </Text>
            );
          })}
      </View>
    );
  };

  return (
    <Document>
      <Page
        wrap
        style={{
          padding: "48px 61.64px 3px 12px",
          fontSize: "11px",
          fontFamily: "Frutiger_light",
          lineHeight: 1.6,
        }}
      >
        <View>
          {/* ------------------------------------------------------Top Banner ----------------------------------------------------*/}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid black",
              marginRight: "-48px",
            }}
          >
            <Image
              src={summaryLogo}
              style={{ width: "200px", marginBottom: "10px" }}
            />
            <Text
              style={{ fontSize: 14, marginTop: "10px", marginLeft: "10px" }}
            >
              Smart Lift and Escalator {"\n"}
              Type Approval Processing and Authenticaion System
            </Text>
          </View>
          {/* --------------------------------------------------Application Summary ------------------------------------------------*/}
          <Text
            style={{
              fontSize: 14,
              fontFamily: "Frutiger_bold",
              marginTop: "15px",
            }}
          >
            Application Summary
          </Text>
          <View style={{ marginTop: "18px" }}>
            {upload.temp_application_number && (
              <Text>
                Temporary application number: {upload.temp_application_number}
              </Text>
            )}
            <Text>Date and Time: {formatDate(new Date(), true)}</Text>
            <Text>
              Created By: {userInfo.username} ({userInfo.rc})
            </Text>
          </View>
          {/* --------------------------------------------------  Type of system  ------------------------------------------------*/}
          <View>
            <Text style={{ fontFamily: "Frutiger_bold", marginTop: "16px" }}>
              Type of System:
            </Text>
            <Text>
              {keyToField[upload.app_type.type] ?? upload.app_type.type}
            </Text>
          </View>
          {/* -------------------------------------------------- Model Manufacturers ------------------------------------------------*/}
          <View style={{ marginTop: "16px" }}>
            <Text style={{ fontFamily: "Frutiger_bold" }}>
              Model Manufacturers:
            </Text>
            <View>{fileList(modelManufacturerList, "manufacturer")}</View>
          </View>
          <View style={{ marginTop: "16px" }}>
            <Text style={{ fontFamily: "Frutiger_bold" }}>
              Safety Component Manufacturers:
            </Text>
            <View>{fileList(scManufacturerList, "manufacturer")}</View>
          </View>
          <View style={{ marginTop: "16px" }}>
            <Text style={{ fontFamily: "Frutiger_bold" }}>
              Models and Components:
            </Text>
            <View>{fileList(upload.model, "model")}</View>
            <View style={{ marginTop: "10px" }}>
              {fileList(
                Object.values(upload.app_type.components).flat(),
                "component"
              )}
            </View>
          </View>
          <View style={{ marginTop: "16px" }}>
            <Text style={{ fontFamily: "Frutiger_bold" }}>Model Details:</Text>
            <View>{upload.model.map((model) => modelDetails(model))}</View>
          </View>
          <View style={{ marginTop: "16px" }}>
            <Text style={{ fontFamily: "Frutiger_bold" }}>Declaration</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <Image src={checkbox} style={{ width: 15, marginRight: 5 }} />
              <Text>
                I declare that all information in this application is correct,
                complete and true. I also understand that a person who knowingly
                and wilfully makes a statement or gives information which he
                knows to be false or does not believe to be true shall be guilty
                of an offence under law.{" "}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const SummaryDownload = (props) => {
  const { upload, userInfo } = props;
  return (
    <div>
      <PDFDownloadLink
        document={<ApplicationSummary upload={upload} userInfo={userInfo} />}
        fileName="application_summary.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download summary"
        }
      </PDFDownloadLink>
      {/*       <PDFViewer width={550} height={800}>
        <ApplicationSummary upload={upload} userInfo={userInfo} />
      </PDFViewer> */}
    </div>
  );
};

export default SummaryDownload;
