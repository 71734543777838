import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import Dropzone from "react-dropzone";
import { useCreate, useNotify, useRefresh } from "react-admin";
import getToken from "../../utils/getAuthToken";
import DocumentEmsdUpload from "./DocumentEmsdUpload";
import axios from 'axios'
import DeleteHasIconConfirmButton from "../../buttons/DeleteHasIconConfirmButton";
import palette from "../../styles/palette";
import LeosDocuments from "./LeosDocuments";

export const DocumentStack = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { show, edit, add, upload, setUpload, update, title, userInfo_Role, userInfo, userRoles } =
  props;
  const [expanded, setExpanded] = useState(!userInfo_Role);
  const [create] = useCreate();

  const fileDownload = async (id) => {
    window.open(
      `${
        process.env.REACT_APP_ENV_ENDPOINT
      }/lift/files/${id}?token=${getToken()}`
    );
  };

  const dropzoneId = "dropzone";

  useEffect(() => {
    const dragEventHandler = (e) => {
      if (e.target.className !== dropzoneId) {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.dropEffect = "none";
      }
    };

    ["dragenter", "dragover", "drop"].forEach((ev) =>
      window.addEventListener(ev, dragEventHandler, false)
    );

    return () => {
      ["dragenter", "dragover", "drop"].forEach((ev) =>
        window.removeEventListener(ev, dragEventHandler, false)
      );
    };
  }, []);

  const handleToggle = () => {
    setExpanded(!expanded);
  };


  const handleChange = (action, index, description, file) => {
    
    if (action === "addDocument") {
      setUpload({
        ...upload,
        other_documents: [...upload?.other_documents, {}],
      });
    };

    if (action === "editDescription") {
      let change_arr = upload?.other_documents;
      let test;

      // status = Clarifying, means the applcation is in clarifying state and returned to RC
      if (upload.status === 'Clarifying') {
        change_arr[index].doc_description = file;  // temporarily storing the document filename (description)
        test = { ...upload, other_documents: change_arr };
      } else {
        // This is when a new application is being created at Upload Additional Documents
        change_arr[index].description = file;
        test = { ...upload, other_documents: change_arr };
      }
      setUpload(test);  // updating the application state (upload)
    };

    if (action === "addFile") {
      if (file.size <= 104857600) {
        create(
          "files",
          {
            data: file,
            meta: {
              type: "other_documents",
              description: description,
              file_code: upload.file_code ? upload.file_code : update.file_code,
            },
          },
          {
            onSuccess: ({ id, data }) => {
              let change_arr = upload?.other_documents;
              let test;

              // status = Clarifying, means the applcation is in clarifying state and returned to RC
              if (upload.status === 'Clarifying') {
                const updatedData = { ...data[0], _id: data[0].id }; 
                delete updatedData.id;

                // push new data to the end of the array
                if (change_arr.length > 0) change_arr[change_arr.length - 1] = updatedData;
                test = { ...upload, other_documents: change_arr };
              } else {
                // This is when a new application is being created at Upload Additional Documents
                change_arr[index] = { ...change_arr[index], file: data[0] };
                test = { ...upload, other_documents: change_arr };
              }

              setUpload(test);

            },
            onError: (e) => notify(e.response.data),
          }
        );
      } else {
        notify("Error: File size should be less than 100MB");
      }
    };

    if (action === "deleteFile") {
      const test = {
        ...upload,
        other_documents: [
          ...upload?.other_documents.filter((x, i) => i !== index),
        ],
      };
      console.log(test);
      setUpload(test);
    };
  };


  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/${id}`,
        { headers: { authorization: getToken() } }
      );
      if (res.status === 200) refresh();
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <>
            {(add || edit) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  aria-label="settings"
                  onClick={() => {
                    handleChange("addDocument");
                  }}
                >
                  Add Document
                </Button>
              </div>
            )}
            <Accordion sx={{ mt: 3 }} expanded={expanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: "lightgray" }}
                onClick={handleToggle}
              >
                <Typography>Documents</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  spacing={2}
                  direction="column"
                  divider={<Divider orientation="horizontal" flexItem />}
                  sx={{ "&:first-of-type": { mt: 1 } }}
                >
                  {upload?.other_documents?.length > 0 &&
                    upload?.other_documents.map((el, i) => {
                      if (el !== null) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            key={`ad${i}`}
                          >
                            {(edit || add) && (
                              <TextField
                                disabled={ upload?.other_documents[i]?.file !== undefined || upload?.other_documents[i]?._id !== undefined }
                                onChange={(e) => {
                                  handleChange(
                                    "editDescription",
                                    i,
                                    "",
                                    e.target.value
                                  );
                                }}
                                sx={{ minWidth: "500px" }}
                                id="outlined-basic"
                                label={"Description of Document"}
                                value={
                                  upload?.status === "Clarifying"
                                    ? upload.other_documents[i]?.metadata?.description
                                    : upload.other_documents[i]?.description 
                                }
                                variant="outlined"
                              />
                            )}
  
                            {/* on EMSD - Awaiting Approval */}
                            { upload?.other_documents[i] && show && (
                                <>
                                  <Typography sx={{}}>
                                    {
                                      upload?.other_documents[i]?.metadata
                                        ?.description
                                    }
                                  </Typography>
                                  <div>
                                    <Button
                                      variant="contained"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        fileDownload(upload.other_documents[i]._id);
                                      }}
                                    >
                                      Download
                                    </Button>
  
                                    <DeleteHasIconConfirmButton
                                      sx={{ fontSize: "1.2rem", padding: "0", width: ".2rem", color: palette.secondary }}
                                      customDeleteLogic={() => handleDelete(upload.other_documents[i]._id)}
                                      userInfo={userInfo}
                                      upload={upload}
                                      userRoles={userRoles}
                                    />
                                  </div>
                                </>
                            )}
                            

                            {(edit || add) && (
                              <>
                                {upload?.other_documents[i]?.file ? (
                                  <div
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {add && (
                                      <Typography
                                        sx={{
                                          pl: 2,
                                          wordBreak: "break-all",
                                        }}
                                      >
                                        {
                                          upload?.other_documents[i]?.file
                                            ?.filename
                                        }
                                      </Typography>
                                    )}

                                    <IconButton
                                      onClick={() => {
                                        handleChange("deleteFile", i);
                                      }}
                                      size="small"
                                      sx={{ p: 0, ml: 1 }}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </div>
                                ) : upload?.other_documents[i]?._id ? (
                                  <div
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {edit && (
                                      <Typography
                                        sx={{
                                          pl: 2,
                                          wordBreak: "break-all",
                                        }}
                                      >
                                        {
                                          upload?.other_documents[i]?.filename 
                                        }
                                      </Typography>
                                    )}

                                    <IconButton
                                      onClick={() => {
                                        handleChange("deleteFile", i);
                                      }}
                                      size="small"
                                      sx={{ p: 0, ml: 1 }}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </div>
                                ) : upload?.other_documents[i]?.description ? (
                                  <div
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Dropzone
                                      onDrop={(acceptedFiles) =>
                                        handleChange(
                                          "addFile",
                                          i,
                                          upload?.other_documents[i]?.description,
                                          acceptedFiles[0]
                                        )
                                      }
                                    >
                                      {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                      }) => (
                                        <section
                                          style={{
                                            border: "3px dashed lightgray",
                                            height: "75px",
                                            width: "80%",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <div
                                            {...getRootProps()}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <input {...getInputProps()} />
                                            {isDragActive ? (
                                              <p className="dropzone">
                                                Drop the files here ...
                                              </p>
                                            ) : edit || add ? (
                                              <p
                                                style={{
                                                  color: "#585858",
                                                  fontSize: "max(0.7rem, 0.8vw)",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Drag and drop a file here, or
                                                click to select files
                                              </p>
                                            ) : (
                                              <p>No file found</p>
                                            )}
                                          </div>
                                        </section>
                                      )}
                                    </Dropzone>
                                    <IconButton
                                      onClick={() => {
                                        handleChange("deleteFile", i);
                                      }}
                                      size="small"
                                      sx={{ p: 0, ml: 1 }}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </div>
                                ) : upload?.other_documents[i]?.metadata?.description || upload?.other_documents[i]?.doc_description ? (
                                  <div
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Dropzone
                                      onDrop={(acceptedFiles) =>
                                        handleChange(
                                          "addFile",
                                          i,
                                          upload?.other_documents[i]?.metadata?.description || upload?.other_documents[i]?.doc_description,
                                          acceptedFiles[0]
                                        )
                                      }
                                    >
                                      {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                      }) => (
                                        <section
                                          style={{
                                            border: "3px dashed lightgray",
                                            height: "75px",
                                            width: "80%",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <div
                                            {...getRootProps()}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <input {...getInputProps()} />
                                            {isDragActive ? (
                                              <p className="dropzone">
                                                Drop the files here ...
                                              </p>
                                            ) : edit || add ? (
                                              <p
                                                style={{
                                                  color: "#585858",
                                                  fontSize: "max(0.7rem, 0.8vw)",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Drag and drop a file here, or
                                                click to select files
                                              </p>
                                            ) : (
                                              <p>No file found</p>
                                            )}
                                          </div>
                                        </section>
                                      )}
                                    </Dropzone>
                                    <IconButton
                                      onClick={() => {
                                        handleChange("deleteFile", i);
                                      }}
                                      size="small"
                                      sx={{ p: 0, ml: 1 }}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </div>                                
                                ) : (
                                  <div
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography>
                                      Please type a description first
                                    </Typography>
                                    <IconButton
                                      onClick={() => {
                                        handleChange("deleteFile", i);
                                      }}
                                      size="small"
                                      sx={{ p: 0, ml: 1 }}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </div>
                                )}
                              </>
                            )}

                          </div>
                        );
                      }
                      
                     
                    })}
                  {show &&
                    upload.status !== "Approved" &&
                    window.location.pathname === "/lift_emsd" && (
                      <DocumentEmsdUpload
                        upload={upload}
                        setUpload={setUpload}
                        userInfo={userInfo}
                        userRoles={userRoles}
                      />
                    )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </>
          {show &&
            upload.status === "Approved" &&
            window.location.pathname === "/lift_emsd" && (
              <LeosDocuments upload={upload} />
          )}
        </CardContent>
      </Card>
    </>
  );
};
