import { useEffect, useState } from "react";
import { Card, CardHeader, CardContent } from "@mui/material";
import { useGetList } from "react-admin";
import CertificateList from "./CertificateLists";
import TapasRcCheckingResult from "./TapasRcCheckingResult";
import TapasEmsdCheckingResult from "./TapasEmsdCheckingResult";
import AliasCheck from "./AliasCheck";
import AppendixModal from "../appendix/AppendixModal";
import axios from "axios";
import getToken from "../../utils/getAuthToken";
import _ from "lodash";

export const RulesCheck = (props) => {
  const {
    upload,
    setUpload,
    userInfo_Role,
    openAppendix,
    setOpenAppendix,
    userInfo,
    userRoles,
    allowManageRecords,
  } = props;
  const { data: originalCertificates, isLoading: isLoading } = useGetList(
    "certificates",
    {
      filter: {
        application: upload.id,
      },
      pagination: { page: null, perPage: null },
    },
    {
      cacheTime: 0,
      //Set cache to 0 so that the useEffect storeBase64 call in appendixModal wouldn't called multiple times when the result is cached
    }
  );

  const [savedData, setSavedData] = useState({
    certificates: null,
    appendix: null,
  }); //This is the certificates that user saved
  const [certificates, setCertificates] = useState(null);
  const [cert, setCert] = useState({});

  const imageView = async (targetCert) => {
    setCert(targetCert);
    setOpenAppendix(true);
  };

  useEffect(() => {
    //Deep clone so we don't mutate the original data, which is needed when user close the modal without saving
    if (originalCertificates) {
      setCertificates(originalCertificates);
      if (Object.keys(cert || {}).length === 0)
        setCert(originalCertificates[0]);
    }
  }, [isLoading, originalCertificates]);

  const simpleDisplay = (certificateId, field) => {
    if (originalCertificates && certificates && certificates.length > 0) {
      const correction = certificates.filter((correction) => {
        return correction.id === certificateId;
      })[0];

      const certificate = originalCertificates.filter((certificate) => {
        return certificate.id === certificateId;
      })[0];

      if (
        correction?.cert_corrections?.[field] ||
        correction?.cert_corrections?.[field] === ""
      )
        return correction.cert_corrections[field];
      else if (correction?.cert_type_metadata?.[field])
        return correction.cert_type_metadata[field];
      else return certificate?.cert_type_metadata?.[field];
    }
  };

  const deleteField = (certificateId, field) => {
    if (!certificates) return;
    setCertificates(
      certificates.map((c) => {
        if (c.id === certificateId) {
          const { [field]: deletedField, ...rest } = c.cert_type_metadata || {};
          const { [field]: deletedCorrectionsField, ...corrections } =
            c.cert_corrections || {};
          // Create a *new* object with changes
          return {
            ...c,
            ...(c?.cert_type_metadata?.[field] && {
              cert_type_metadata: rest,
            }),
            ...(c?.cert_corrections?.[field] && {
              cert_corrections: corrections,
            }),
          };
        } else {
          // No changes
          return c;
        }
      })
    );
  };

  const makeCorrections = (value, certificateId, field, newPosition) => {
    if (!certificates) return;
    setCertificates(
      certificates.map((c) => {
        if (c.id === certificateId) {
          // Create a *new* object with changes
          if (field === "comply_standard")
            return { ...c, comply_standard: value };
          else
            return {
              ...c,
              ...(newPosition && {
                position_data: cert.position_data.map((highlight) => {
                  const newHighlight = { ...highlight };
                  if (newHighlight.comment.text === field) {
                    newHighlight.position = newPosition;
                  }
                  return newHighlight;
                }),
                unUsedAiResult: cert.unUsedAiResult.map((highlight) => {
                  const newHighlight = { ...highlight };
                  const current = cert.position_data.find(
                    (item) => item.comment.text === field
                  );

                  if (
                    newHighlight.text === value &&
                    newHighlight.label === field
                  ) {
                    newHighlight.position = current.position; //exchange object with the one in cert.position_data
                    newHighlight.text =
                      cert?.cert_corrections?.[field] || //value before changes
                      cert?.cert_type_metadata?.[field];
                  }
                  return newHighlight;
                }),
              }),
              ...(value && {
                cert_corrections: {
                  ...c.cert_corrections,
                  [field]: value,
                },
              }),
            };
        } else {
          // No changes
          return c;
        }
      })
    );
  };

  const sendCorrections = async () => {
    await axios.put(
      `${process.env.REACT_APP_ENV_ENDPOINT}/lift/certificates`,
      certificates,
      { headers: { authorization: getToken() } }
    );
  };

  return (
    <Card sx={{}}>
      <CardHeader title="Rules Check" />
      <CardContent sx={{ mt: 2 }}>
        <AppendixModal
          openAppendix={openAppendix}
          setOpenAppendix={setOpenAppendix}
          data={upload?.appendix}
          application={upload}
          setUpload={setUpload}
          isLoadingCerts={isLoading}
          originalCertificates={originalCertificates}
          certificates={certificates}
          setCertificates={setCertificates}
          savedData={savedData}
          setSavedData={setSavedData}
          cert={cert}
          setCert={setCert}
          deleteField={deleteField}
          makeCorrections={makeCorrections}
          sendCorrections={sendCorrections}
          simpleDisplay={simpleDisplay}
          userInfo={userInfo}
          userRoles={userRoles}
          allowManageRecords={allowManageRecords}
        />
        {/* By Default, only show if role not equal to "EMSDCE","EMSDSE" */}
        {!userInfo_Role && upload?.manufacturer?.length > 0 && (
          <AliasCheck
            upload={upload}
            setUpload={setUpload}
            userInfo={userInfo}
            userRoles={userRoles}
          />
        )}

        {upload?.aiFeedBack?.length > 0 && (
          <TapasRcCheckingResult
            certificates={certificates}
            aiFeedBack={upload.aiFeedBack}
            userInfo_Role={userInfo_Role}
          />
        )}
        <TapasEmsdCheckingResult
          certificates={certificates}
          aiChecking={upload?.aiChecking}
          userInfo_Role={userInfo_Role}
        />
        <CertificateList
          upload={upload}
          certificates={certificates}
          imageView={imageView}
          userInfo_Role={userInfo_Role}
          userInfo={userInfo}
          userRoles={userRoles}
        />
      </CardContent>
    </Card>
  );
};
