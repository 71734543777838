import { useState } from 'react'
import {
  Box,
  Button,
  Typography,
  Input,
  FormHelperText,
  TextField
} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import axios from "axios";
import getToken from "../../utils/getAuthToken";
import { useNotify, useGetList } from 'react-admin'
import DownloadPublicHolidays from './DownloadPublicHolidays';
import { useTranslation } from 'react-i18next';


const UpdatePublicHolidays = () => {
  const { t : itranslate } = useTranslation();
  
  const [file, setFile] = useState(null);
  const [signatureFile, setSignatureFile] = useState(null);
  const { data: signatureResponse } = useGetList("options/signature", { pagination: { page: 1, perPage: 0 } }, { cacheTime: 0 });
  const [signature, setSignature] = useState(null);
  const notify = useNotify();
  const types = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  const handleFileChange = (e) => {
   let selected = e.target.files[0]
    if (selected && types.includes(selected.type)) {
      setFile(selected);
    } else {
      setFile(null);
      notify("Please select an excel file (xlsx / xls formats)");
      return;
    }
  };

  const handleSignatureFileChange = (e) => {
    let selected = e.target.files[0];
    setSignatureFile(selected);
  };

  const handleText = (e) => {
    setSignature(e.target.value);
  }

  const handleFileUpload = async (type) => {
    const formData = new FormData();
    const uploadedFile = type === "uploadSignature" ? signatureFile : file;
    formData.append(type === "uploadSignature" ? 'jpgFile' : 'xlsxFile', uploadedFile);
    const requestUrl = type === "uploadSignature" ? `${process.env.REACT_APP_ENV_ENDPOINT}/lift/options/signature_image/` : `${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/uploadPublicHolidays/`;
    try {
      const res = await axios.post(
          requestUrl,
          formData,
        {headers: { 
          authorization: getToken(),
          'Content-Type': 'multipart/form-data'
        }}
      );
      // console.log(res)
      if (res.status === 201) {
        notify("File Uploaded Successfully");
        if (type === "uploadSignature") setSignatureFile(null);
        else setFile(null);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      console.log(err)
      setFile(null)
    }
  };

  const handleNameUpload = async () => {
    try {
      const res = await axios.post(
          `${process.env.REACT_APP_ENV_ENDPOINT}/lift/options/signature/`,
        {data: signature},
        {headers: { 
          authorization: getToken(),
        }}
      );
      notify("Signature Name Updated Successfully");
      setSignature(null);
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>

        {/* <Typography sx={{ textAlign: "center" }} variant="h5" marginBottom="20px">
          {itranslate('publicHolidays.Update Public Holidays')}
        </Typography>
        <FormControl fullWidth>
          <input
            // accept=".xls,.xlsx, application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            accept="application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,"
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }} // Hide the default file input
          />
          <label htmlFor="file-upload">
            <Button variant="contained" component="span">
              {itranslate('publicHolidays.Upload Your File')}
            </Button>
          </label>
          <FormHelperText>
            {itranslate('publicHolidays.Please choose excel file (xlsx or xls formats) only')}
          </FormHelperText>
          <Box sx={{ mt: 2 }}>
            {file && <Typography>{file.name}</Typography>} 
          </Box>
        </FormControl> */}

      <Typography sx={{ textAlign: "center" }} variant="h5" marginBottom="20px" >
        {/* Update Public Holidays */}
        {itranslate('publicHolidays.Update Public Holidays')}
        </Typography>
      <FormControl>
        <Input id="my-input" type="file" onChange={handleFileChange} />
        <FormHelperText id="my-helper-text">
          {/* Please choose excel file (xlsx or xls formats) only */}
          {itranslate('publicHolidays.Please choose excel file (xlsx or xls formats) only')}
          </FormHelperText>
        <Box sx={{ display: "flex", justifyContent: "center"}}>
          <Button sx={{ mt: 1, width: "25%"}} variant='contained' onClick={()=>handleFileUpload("uploadPublicHolidays")}>
            {/* Upload */}
            {itranslate('publicHolidays.Upload')}
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "100px"}}>
          <DownloadPublicHolidays />
        </Box>
      </FormControl>

      <Typography sx={{ textAlign: "center", marginTop: "100px" }} variant="h5" marginBottom="20px" >
      {/* Update Signature */}
        {itranslate('signature.Update Signature')}
      </Typography>
      <FormControl>
        <Input id="my-input" type="file" onChange={handleSignatureFileChange} />
        <FormHelperText id="my-helper-text">
          {/* Please choose jpg file only */}
          {itranslate('signature.Please choose jpg file only')}
        </FormHelperText>
        <Box sx={{ display: "flex", justifyContent: "center"}}>
          <Button sx={{ mt: 1, width: "25%"}} variant='contained' onClick={()=>handleFileUpload("uploadSignature")}>
            {/* Upload */}
            {itranslate('signature.Upload')}
        </Button>
        </Box>
      </FormControl>
      <Box>
        <Typography sx={{ textAlign: "center", marginTop: "100px" }} variant="h5" marginBottom="20px" >
          {/* Change Signature Name */}
          {itranslate('signature.Change Signature Name')}
        </Typography>
        <TextField 
          label="Signature name"
          key={`signature:${signatureResponse?.[0]?.signature}`} //key to force rerender when signatureResponse changes
          defaultValue={signatureResponse?.[0]?.signature} 
          value={signature}
          variant="standard" 
          onChange={handleText}
         />
        <Button sx={{ mt: 1, width: "25%"}} variant='contained' onClick={handleNameUpload}>
          {/* Update */}
          {itranslate('signature.Update')}
        </Button>
      </Box>

    </Box>
  )
}

export default UpdatePublicHolidays