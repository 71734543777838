import React from "react";
import { useRecordContext } from "react-admin";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const EngineerComponent = ({ label, source }) => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <Box>
      {record[source] &&
        record[source].map((value, index) => (
          <Box
            key={index}
            sx={{
              border: "1px solid",
              padding: "10px",
              margin: "10px",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
            }}
          >
            {value?.role_name && (
              <Typography>{`Role Name: ${value?.role_name} `}</Typography>
            )}
            <Typography>
              {`Account owner: ${
                value?.default_en_username ||
                value?.default_ce_username ||
                value?.default_se_username ||
                value?.default_en_email ||
                value?.default_ce_email ||
                value?.default_se_email
              }`}
            </Typography>
            {value?.special_application_type?.length > 0 && (
              <Typography>
                {`Special Application: ${value?.special_application_type}`}
              </Typography>
            )}
          </Box>
        ))}
    </Box>
  );
};

export default EngineerComponent;
