import { useState } from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionActions,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Box,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ManufacturerFields } from "./ManufacturerFields";
import { useCreate, useNotify } from "react-admin";
import { useLocation } from "react-router-dom";
import keyToField from "../../utils/keyToField";
import ManufacturerExtraDetail from "./ManufacturerExtraDetail";
import ShortNameEditor from "../approval/ShortNameEditor";
import ManufacturerNameInput from "./ManufacturerNameInput.js";
import NameEditor from "../../utils/applications/NameEditor";
import { confirmAlert } from "react-confirm-alert";
import autoSave from "../../utils/applications/autoSave";

export const ManufacturerDisplay = (props) => {
  const {
    edit,
    add,
    isValidated,
    title,
    upload,
    setUpload,
    setuploadCopy,
    template,
    show,
    showOne,
    appShow,
    existing,
    filenames,
    userInfo_Role,
    userInfo,
    userRoles
  } = props;

  const location = useLocation();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [create, { isLoading: uploadingFile }] = useCreate();
  const notify = useNotify();

  const handleChange = (action, index, key, file) => {
    if (action === "addManu") {
      let newUpload = {
        ...upload,
        manufacturer: [
          ...upload.manufacturer,
          upload?.app_type?.type === "SafetyComponent"
            ? {
                ...structuredClone(template.manufacturer),
                type: "SafetyComponent",
              }
            : { ...structuredClone(template.manufacturer) },
        ],
      };

      setUpload(newUpload);

       // This checks if setuploadCopy is defined and is a function before using, to get rid of warning
      if (typeof setuploadCopy === "function") {
        setuploadCopy(newUpload);
      };

      let newUpdate = {
        ...props.update,
        manufacturer: [
          ...upload.manufacturer,
          props.update?.app_type?.type === "SafetyComponent"
            ? {
                ...structuredClone(template.manufacturer),
                type: "SafetyComponent",
              }
            : { ...structuredClone(template.manufacturer) },
        ],
      };

      edit && props.setUpdate(newUpdate);

      autoSave(newUpload, newUpdate, edit.mode);
    };


    if (action === "addManuLoad") {
      const address =
        value?.certsPopulated?.cert_corrections?.iso_manufacturer_address ||
        value?.certsPopulated?.cert_type_metadata?.iso_manufacturer_address;
      let newUpload = {
        ...upload,
        manufacturer: [
          ...upload.manufacturer,
          {
            ...structuredClone(template.manufacturer),
            provided_name: value.provided_name,
            ...(value.type
              ? { type: value.type }
              : upload?.app_type?.type === "SafetyComponent"
              ? { type: "SafetyComponent" }
              : {}),
            ...(value.manufacturer_code && {
              manufacturer_code: value.manufacturer_code,
            }),
            ...(value.alias_name?.length > 0 && {
              alias_name: value.alias_name,
            }),
            ...(value.short_name && {
              short_name: value.short_name,
            }),
            ...(address && { address }),
          },
        ],
      };

      setUpload(newUpload);

      if (typeof setuploadCopy === "function") {
        setuploadCopy(newUpload);
      };

      autoSave(newUpload, props.update, edit.mode);
    };


    if (action === "editName") {
      let newUpload = { ...upload };
      let changedManu = newUpload.manufacturer.find(
        (manu) => manu._id === index || manu.provided_name === index
      );
      const documentOmitted = [
        "history",
        "organization_man",
        "size_location_cap",
        "product_range_yearly_production",
      ];
      const address =
        value?.certsPopulated?.cert_corrections?.iso_manufacturer_address ||
        value?.certsPopulated?.cert_type_metadata?.iso_manufacturer_address;
      changedManu.provided_name = value.provided_name;
      changedManu.manufacturer_code = value.manufacturer_code || "";
      changedManu.alias_name = value.alias_name || [];
      changedManu.short_name = value.short_name || "";
      changedManu.address = address;
      changedManu.type = value.type || "";
      if (changedManu.manufacturer_code) {
        documentOmitted.forEach((doc) => {
          changedManu[doc] = "";
        });
      }
      //Change manufacturer name for models
      for (let model of newUpload.model) {
        for (let file of model.manufacturer_files) {
          if (file.manufacturer_id === index) {
            file.manufacturer_id = value.provided_name;
            file.provided_name = value.provided_name;
          }
        }
      }
      //Change manufacturer name for components
      for (let componentType in newUpload.app_type.components) {
        let componentsList = newUpload.app_type.components[componentType];
        for (let comp of componentsList) {
          if (comp.manufacturer_id === index) {
            comp.manufacturer_id = value.provided_name;
          }
        }
      }
      setUpload(newUpload);

      if (typeof setuploadCopy === "function") {
        setuploadCopy(newUpload);
      };

      autoSave(newUpload, props.update, edit.mode);
    }


    if (action === "deleteManu") {
      let confirmDelete = true;

      let newUpload = {
        ...upload,
        manufacturer: [...upload.manufacturer.filter((x, i) => i !== index)],
      };

      // step 3 - check if manufacturer is added to model
      newUpload?.model?.map((model, x) => {
        return model.manufacturer_files?.map((mmf, y) => {
          if (mmf.manufacturer_id === key.provided_name) {
            notify(
              `You cannot delete this manufacturer, it is added to model ${model?.provided_model_name}. Please first delete the related manufacturer.`
            );
            confirmDelete = false;
          }
          return "";
        });
      });

      // step 4 - check if manufacturer is added to component
      Object.values(newUpload?.app_type?.components).map(
        (componentArray, a) => {
          return componentArray.map((componentObj, b) => {
            if (componentObj.manufacturer_id === key.provided_name) {
              notify(
                `You cannot delete this manufacturer, it is added to component ${componentObj?.provided_comp_name}. Please first delete the related manufacturer.`
              );
              confirmDelete = false;
            }
            return "";
          });
        }
      );

      if (confirmDelete) {
        confirmAlert({
          title: "Confirm to delete Manufacturer",
          message: "Are you sure to do this.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                setUpload(newUpload);

                if (typeof setuploadCopy === "function") {
                  setuploadCopy(newUpload);
                };

                let newUpdate = {
                  ...props.update,
                  manufacturer: [
                    ...upload.manufacturer.filter((x, i) => i !== index),
                  ],
                };
                edit && props.setUpdate(newUpdate);
                autoSave(newUpload, edit ? newUpdate : props.update, edit.mode);
              },
            },
            {
              label: "No",
            },
          ],
        });
      }
    }

    if (action === "editText") {
      let arr = upload.manufacturer;
      arr[index][key] = file;

      let newUpload = { ...upload, manufacturer: [...arr] };
      setUpload(newUpload);

      if (typeof setuploadCopy === "function") {
        setuploadCopy(newUpload);
      };

      let newUpdate = { ...props.update, manufacturer: [...arr] };
      edit && props.setUpdate(newUpdate);
      autoSave(newUpload, edit ? newUpdate : props.update, edit.mode);
    }

    if (action === "addFile") {
      const fileSize = file.reduce((acc, curr) => acc + curr.size, 0);
      // 104857600 = 100mb size limit
      if (fileSize > 104857600) {
        notify("Error: File size should be less than 100MB");
        return;
      }
      create(
        "files",
        {
          data: file,
          meta: {
            file_code: upload.file_code ?? props.update.file_code,
          },
        },
        {
          onSuccess: ({ id, data }) => {
            let change_arr = upload.manufacturer;
            //Only save the recently uploaded file, filter all old one
            //only for update application (edit.mode)
            if (Array.isArray(change_arr[index][key]))
              change_arr[index][key] = change_arr[index][key]
                .filter((file) => (edit.mode ? file.id : file))
                .concat(data);
            else change_arr[index][key] = data[0];

            const newUpload = { ...upload, manufacturer: change_arr };
            setUpload(newUpload);

            if (typeof setuploadCopy === "function") {
              setuploadCopy(newUpload);
            };

            const newUpdate = { ...props.update, manufacturer: change_arr };
            edit && props.setUpdate(newUpdate);
            autoSave(newUpload, edit ? newUpdate : props.update, edit.mode);
          },
          onError: (e) => notify(e.response.data),
        }
      );
    }

    if (action === "deleteFile") {
      let change_arr = upload.manufacturer;

      if (Array.isArray(change_arr[index][key]))
        change_arr[index][key] = change_arr[index][key].filter(
          (item, fileIndex) => fileIndex !== file
        );
      else change_arr[index][key] = "";

      let newUpload = { ...upload, manufacturer: change_arr };
      setUpload(newUpload);

      if (typeof setuploadCopy === "function") {
        setuploadCopy(newUpload);
      };

      let newUpdate = { ...props.update, manufacturer: change_arr };
      edit && props.setUpdate(newUpdate);
      autoSave(newUpload, edit ? newUpdate : props.update, edit.mode);
    }
  };

  return (
    <Card>
      {title && <CardHeader title={title} />}
      <CardContent>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {((edit &&
              ((edit.mode && edit.mode !== "a") ||
                (edit.clarifying && edit.clarifying !== "a"))) ||
              add) && (
              <ManufacturerNameInput
                mode="add"
                edit={edit}
                value={value}
                setValue={setValue}
                inputValue={inputValue}
                setInputValue={setInputValue}
                upload={upload}
                existing={existing}
                handleChange={handleChange}
              />
            )}
          </div>

          {upload?.manufacturer
            // map 2 times to keep index consistent for delete, note that it is ({manufacturer, i}) not (manufacturer, i)
            .map((manufacturer, i) => ({
              manufacturer,
              i,
            }))
            .filter(({ manufacturer, i }) => {
              const changedManufacturers = Object.keys(
                upload.changes?.manufacturer || {}
              );

              if (show && upload.update_upon) {
                return changedManufacturers?.includes(manufacturer._id);
              } else if (edit.mode === "a") {
                // If it is manufacturer name change, hide all manufacturers except the one user picked in updateModeSelector.js
                const query = new URLSearchParams(location.search);
                const manufacturerQuery = query.get("manufacturer");
                if (!manufacturerQuery) return true; //it should not happen but just in case
                if (manufacturerQuery === manufacturer._id) return true;
                return false;
              } else if (edit.mode === "f") {
                // If it is adding safety component to exisitng LE model, hide all existing manufacturers
                return !manufacturer._id;
              } else if (edit.clarifying === "f" || edit.clarifying === "a") {
                return (
                  !manufacturer._id ||
                  changedManufacturers?.includes(manufacturer._id)
                );
              } else return true;
            })
            .map(({ manufacturer, i }) => {
              const manu = manufacturer.provided_name
                ? manufacturer.provided_name
                : `Manufacturer 1`;
              const manuFailed =
                add &&
                upload?.aiFeedBack?.length > 0 &&
                upload?.aiFeedBack?.some(
                  (item) =>
                    item.name === manu && item.field === "manufacturer_id"
                );
              return (
                <Accordion
                  sx={{ mt: 3 }}
                  defaultExpanded={!appShow}
                  key={`manu${i}`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      backgroundColor: "lightgray",
                      ...(add && manuFailed && { backgroundColor: "pink" }),
                    }}
                  >
                    <Box
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        display: "flex",
                        gap: 2,
                      }}
                    >
                      <Typography>
                        {manufacturer.provided_name}{" "}
                        {show && !manufacturer?.active && (
                          <span style={{ color: "red" }}> (Revoked)</span>
                        )}
                      </Typography>
                      {(add || edit.clarifying === "new") && (
                        <NameEditor
                          onClick={() =>
                            setInputValue(manufacturer.provided_name)
                          }
                          nameInput={
                            <ManufacturerNameInput
                              mode="edit"
                              manufacturer_id={
                                manufacturer._id || manufacturer.provided_name
                              }
                              value={value}
                              setValue={setValue}
                              inputValue={inputValue}
                              setInputValue={setInputValue}
                              upload={upload}
                              existing={existing}
                              handleChange={handleChange}
                            />
                          }
                        />
                      )}
                    </Box>
                    <Typography sx={{ color: "text.secondary" }}>
                      {keyToField[manufacturer.type]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ManufacturerFields
                      show={show}
                      showOne={showOne}
                      add={add}
                      isValidated={isValidated}
                      edit={edit}
                      template={template.manufacturer}
                      manufacturerObject={manufacturer}
                      handleChange={handleChange}
                      uploadingFile={uploadingFile}
                      manufacturerIndex={i}
                      upload={upload}
                      setUpload={setUpload}
                      filenames={filenames}
                    />

                    {showOne && (
                      <ManufacturerExtraDetail manufacturer={manufacturer} />
                    )}
                  </AccordionDetails>
                  <Divider orientation="horizontal" flexItem />
                  {((edit &&
                    ((edit.mode && edit.mode !== "a") ||
                      (edit.clarifying && edit.clarifying !== "a") ||
                      edit.clarifying === "new")) ||
                    add) && (
                    <AccordionActions>
                      <Delete
                        onClick={() =>
                          handleChange("deleteManu", i, manufacturer)
                        }
                      />
                    </AccordionActions>
                  )}
                </Accordion>
              );
            })}
        </>
        {window.location.pathname === "/lift_emsd" &&
          show &&
          !showOne &&
          !userInfo_Role && // By Default, only show if role not equal to "EMSDCE","EMSDSE"
          upload.status !== "Approved" &&
          upload?.manufacturer && (
            <ShortNameEditor 
              manufacturers={upload?.manufacturer}
              upload={upload}
              userInfo={userInfo}
              userRoles={userRoles}
            />
          )}
      </CardContent>
    </Card>
  );
};
