import {
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import keyToField from "../../../utils/keyToField";
import { romanize } from "../../../utils/romanize";
import { useDelete, useNotify } from "react-admin";
import { confirmAlert } from "react-confirm-alert";

const CertItem = (props) => {
  const {
    cert,
    key,
    handleHide,
    handleUnhide,
    handleDelete,
    appendix,
    name,
    application,
    userInfo,
    userRoles,
    allowManageRecords,
  } = props;
  const isHidden = appendix["hideCerts"].includes(cert.id);

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role""
  const isDisabled =
    (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
    (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
    (userInfo?.role === "EMSDEngineer" &&
      application?.status === "Pending E" &&
      !userRoles.includes(application?.current_role_id));

  return (
    <React.Fragment key={key}>
      <ListItem
        secondaryAction={
          <>
            <Button
              onClick={() => isHidden ? handleUnhide(cert.id) : handleHide(cert.id)}
              aria-label={isHidden ? "Unhide" : "Hide"}
              disabled={isDisabled}
            >
              {isHidden ? "Unhide" : "Hide"}
            </Button>
            {allowManageRecords && (
              <Button
                disabled={isDisabled}
                onClick={() => handleDelete(cert.id)}
              >
                Delete
              </Button>
            )}
          </>
        }
      >
        <ListItemText
          primary={
            cert.cert_corrections?.tec_certificate_number ||
            cert.cert_type_metadata?.tec_certificate_number
          }
          secondary={`${
            cert.cert_corrections?.tec_issue_date ||
            cert.cert_type_metadata?.tec_issue_date
          } | ${name} `}
        />
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};

function HideCerts(props) {
  const {
    appendix,
    setAppendix,
    certificates,
    setCertificates,
    application,
    userInfo,
    userRoles,
    allowManageRecords,
  } = props;
  const notify = useNotify();
  const [deleteOne, { isLoading: deleting }] = useDelete();

  const handleHide = (id) => {
    let section = structuredClone(appendix["hideCerts"]);
    if (!section.includes(id)) section.push(id);
    setAppendix({ ...appendix, hideCerts: section });
  };
  const handleUnhide = (id) => {
    let section = structuredClone(appendix["hideCerts"]);
    section = section.filter((cert) => cert !== id);
    setAppendix({ ...appendix, hideCerts: section });
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Delete Certificates",
      message:
        "This will also delete the certificate in database. Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            deleteOne(
              "certificates",
              {
                id: id,
                meta: { app_id: application.id },
              },
              {
                onSuccess: ({ data }) => {
                  setCertificates(
                    certificates.filter((cert) => cert.id !== id)
                  );
                },
                onError: (e) => {
                  notify(e.response.data);
                },
              }
            ),
        },
        {
          label: "No",
        },
      ],
      overlayClassName: "react-confirm-alert-body-overlay", //To make it has higher z-index (check )
    });
  };

  const handleText = (e, type) => {
    setAppendix({ ...appendix, [type]: e.target.value });
  };

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
    (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
    (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
    (userInfo?.role === "EMSDEngineer" &&
      application?.status === "Pending E" &&
      !userRoles.includes(application?.current_role_id));

  if (!certificates || !appendix) return null;
  return (
    <Box>
      <Typography>Refer</Typography>
      <TextField
        fullWidth
        variant="standard"
        onChange={(e) => handleText(e, "refer")}
        value={appendix["refer"]}
        disabled={isDisabled}
      />
      <Typography>Type test certificate header</Typography>
      <TextField
        fullWidth
        variant="standard"
        onChange={(e) => handleText(e, "tecHeader")}
        value={appendix["tecHeader"]}
        disabled={isDisabled}
      />
      <Typography>
        Previous Certificates{" "}
        <Typography variant="caption">
          (Hided certificates will be approved but not show in appendix only)
        </Typography>
      </Typography>
      <List>
        {/* Model certs - code copied from appendixHtml.js (need same format) */}
        {application?.model.map((model, index) => {
          const certs = certificates.filter(
            (tec) =>
              tec?.reference === model.model_code &&
              tec.type === "ModelTypeCert"
          );

          return (
            <Box>
              <Typography sx={{ textAlign: "center", mt: 2 }}>
                {romanize(index + 1)}. Lift Model - {model.provided_model_name}
              </Typography>
              {certs.map((cert, i) => {
                return (
                  <CertItem
                    cert={cert}
                    key={cert.id}
                    handleHide={handleHide}
                    handleUnhide={handleUnhide}
                    handleDelete={handleDelete}
                    appendix={appendix}
                    name={model.provided_model_name}
                    application={application}
                    userInfo={userInfo}
                    userRoles={userRoles}
                    allowManageRecords={allowManageRecords}
                  />
                );
              })}
            </Box>
          );
        })}
        {/* Component certs - code copied from appendixHtml.js (need same format) */}
        {Object.keys(application?.app_type?.components)
          .filter((component_type) => {
            if (["TractionMachine", "Controller"].includes(component_type))
              return false;
            return application.app_type?.components[component_type]?.length > 0;
          })
          .sort((a, b) => {
            const order = [
              "Buffer",
              "OverspeedGovernor",
              "LandingDoorLockingDevice",
              "CarDoorLockingDevice",
              "SafetyGear",
              "ACOP",
              "UCMP",
              "SafetyCircuitElectricalComponent",
            ];
            function customSort(item) {
              const index = order.indexOf(item);
              return index !== -1 ? index : order.length;
            }
            return customSort(a) - customSort(b);
          })
          .map((component_type, j) => {
            return (
              <Box>
                <Typography sx={{ textAlign: "center", mt: 2 }}>
                  {romanize(application?.model?.length + j + 1)}. Safety
                  Components - {keyToField[component_type]}
                </Typography>
                {application?.manufacturer?.map((manufacturer, i) => {
                  return application?.app_type?.components[component_type]
                    .filter(
                      (compModel) =>
                        compModel.manufacturer_id === manufacturer._id
                    )
                    .map((component) => {
                      const certs = certificates.filter(
                        (tec) =>
                          tec?.reference === component.component_code &&
                          tec.component_type === component_type &&
                          tec.type === "ComponentTypeCert"
                      );

                      return certs.map((cert, i) => {
                        return (
                          <CertItem
                            cert={cert}
                            key={cert.id}
                            handleHide={handleHide}
                            handleUnhide={handleUnhide}
                            handleDelete={handleDelete}
                            appendix={appendix}
                            name={component.provided_comp_name}
                            application={application}
                            userInfo={userInfo}
                            userRoles={userRoles}
                            allowManageRecords={allowManageRecords}
                          />
                        );
                      });
                    });
                })}
              </Box>
            );
          })}
      </List>
    </Box>
  );
}

export default HideCerts;
