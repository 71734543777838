import React, { useEffect, useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Popper,
  IconButton,

} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { fixInvalidDates } from "../../../utils/misc/fixInvalidDates";
import renderChinese from "../../../utils/pdf/renderChinese";
import keyToField from "../../../utils/keyToField";




function DerivedHideCerts({
  application,
  appendix,
  setAppendix,
  certificates,
  simpleDisplay,
  manufacturers,
  components,
  component_type,
  setCompLevel,
  setCertLevel,
}) {




  // const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedComponent, setSelectedComponent] = useState(null);

  // const open = Boolean(anchorEl);
  // const handleClick = (e, component) => {
  //   setSelectedComponent(component);
  //   setAnchorEl(anchorEl ? null : e.currentTarget);
  // };
  // const handleClose = () => {
  //   setSelectedComponent(null);
  //   setAnchorEl(null);
  // };




  // const handleHide = (code) => {
  //   let section = structuredClone(appendix["hideComponentCodes"]);
  //   if (!section.includes(code)) section.push(code);
  //   setAppendix({ ...appendix, hideComponentCodes: section });
  // };

  // const handleUnhide = (code) => {
  //   let section = structuredClone(appendix["hideComponentCodes"]);
  //   section = section.filter((cert) => cert !== code);
  //   setAppendix({ ...appendix, hideComponentCodes: section });
  // };



  const handleHide2 = ({ component_type, component_code }) => {
    let section = structuredClone(appendix["hideComponentCodes2"]);
    if (!section.some(item => item.component_type === component_type && item.component_code === component_code)) {
      section.push({
        component_type: component_type,
        component_code: component_code
      });
    }
    setAppendix({ ...appendix, hideComponentCodes2: section });
  };


  const handleUnhide2 = ({ component_type, component_code }) => {
    let section = structuredClone(appendix["hideComponentCodes2"]);
    section = section.filter((item) => item.component_code !== component_code || item.component_type !== component_type);
    setAppendix({ ...appendix, hideComponentCodes2: section });
  };




  // const handleIndividualCertHide2 = ({ component_type, cert_id }) => {
  //   let section = structuredClone(appendix["hideIndividualCerts2"]);
  //   // if (!section.includes(id)) section.push(id);
  //   if (!section.some(item => item.component_type === component_type && item.cert_id === cert_id)) {
  //     section.push({
  //       component_type: component_type,
  //       cert_id: cert_id
  //     });
  //   }
  //   setAppendix({ ...appendix, hideIndividualCerts2: section });
  // };

  // const handleIndividualCertUnhide2 = ({ component_type, cert_id }) => {
  //   let section = structuredClone(appendix["hideIndividualCerts2"]);
  //   section = section.filter((item) => item.cert_id !== cert_id || item.component_type !== component_type);
  //   setAppendix({ ...appendix, hideIndividualCerts2: section });
  // };



  if (!certificates || !appendix) return null;



  return (
    <>
      <Typography
        style={{
          width: "100%",
          fontFamily: "Frutiger_bold",
          fontSize: "18px",
          marginTop: "15px",
          marginBottom: "10px",
          textDecoration: "underline",
        }}
      >
        {keyToField[component_type]}
      </Typography>

      {manufacturers.map((manu, i) => {
        const componentsOfManu = components[component_type].filter(
          (compModel) => compModel.manufacturer_id === manu._id
        );

        if (componentsOfManu.length > 0) {
          // console.log("🚀 ~ Testing...")
          return (
            <List
              key={i}
              style={{
                width: "100%",
                fontFamily: "Frutiger_bold",
                fontSize: "16px",
                ...renderChinese(manu.provided_name),
              }}
            >
              {manu?.short_name || manu?.provided_name}

              {componentsOfManu.map((component) => {
                const certs = certificates.filter(
                  (tec) =>
                    tec?.reference === component.component_code &&
                    tec?.type === "ComponentTypeCert"
                );

                let temp = [];
                if (certs.length >= 1) temp.push(certs[0]); // if there are more than 1 cert which are the same, we only want to show 1 for user to select
                

                
                
                return temp.map((cert, i) => {
                  const isHidden2 = appendix["hideComponentCodes2"].find(
                    (item) => item.component_code === cert.reference && item.component_type === component_type
                  )  
                
                  return (
                    <React.Fragment key={i}>
                      <ListItem
                        onMouseEnter={() => setCompLevel({ component_type, component_code: cert.reference })}
                        onMouseLeave={() => setCompLevel(null)}
                        secondaryAction={
                          <Button
                            onClick={() =>
                              isHidden2 
                                ? handleUnhide2({ component_type, component_code: cert.reference }) 
                                : handleHide2({ component_type, component_code: cert.reference })
                            }
                          >
                            {isHidden2 ? "Unhide" : "Hide"}
                          </Button>
                        }
                      >
                        <ListItemText
                          primary={component.provided_comp_name}
                        ></ListItemText>
                      </ListItem>

                      {/* <Box sx={{ marginBottom: "20px" }}>
                        <IconButton
                          aria-describedby="hideCert_popper"
                          type="button"
                          onClick={(e) => handleClick(e, component)}
                        >
                          {open && selectedComponent === component ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        {open && selectedComponent === component && (
                          <Popper
                            sx={{ zIndex: 100000 }}
                            id="hideCert_popper"
                            open={open}
                            anchorEl={anchorEl}
                          >
                            <PopperContent
                              key={i}
                              appendix={appendix}
                              component={component}
                              certs={certs}
                              setCertLevel={setCertLevel}
                              handleHide={handleIndividualCertHide2}
                              handleUnhide={handleIndividualCertUnhide2}
                              simpleDisplay={simpleDisplay}
                              handleClose={handleClose}
                              component_type={component_type}
                            />
                          </Popper>
                        )}
                      </Box> */}

                    </React.Fragment>
                  );
                });
              })}
            </List>
          );
        }
      })}
      

    </>
  );
}

// need to use a separate function to get the correct value
function PopperContent({
  component,
  certs,
  setCertLevel,
  handleHide,
  handleUnhide,
  appendix,
  simpleDisplay,
  handleClose,
  component_type
}) {
  const filteredCerts = certs.filter(
    (cert) => cert?.reference === component.component_code
  );
  // console.log("🚀 ~ PopperContent ~ filteredCerts:", filteredCerts)
  return (
    <Box sx={{ border: 1, p: 1, width: 730, bgcolor: "background.paper" }}>
      {filteredCerts?.map((cert, i) => {

        const isHiddenCert2 = appendix["hideIndividualCerts2"].find(
          (item) => item.cert_id === cert.id && item.component_type === component_type
        )  

        return (
          <React.Fragment key={cert.id}>
            <ListItem
              onMouseEnter={() => setCertLevel({ component_type, cert_id: cert.id })}
              onMouseLeave={() => setCertLevel(null)}
              secondaryAction={
                <Button
                  onClick={() =>
                    isHiddenCert2 ? handleUnhide({ component_type, cert_id: cert.id }) : handleHide({ component_type, cert_id: cert.id })
                  }
                >
                  {isHiddenCert2 ? "Unhide Certificate" : "Hide Certificate"}
                </Button>
              }
            >
              <ListItemText
                sx={{ maxWidth: "500px" }}
                primary={component.provided_comp_name}
                secondary={
                  simpleDisplay(cert.id, "tec_type_exam") ||
                  simpleDisplay(cert.id, "tec_certificate_number")
                }
                // secondary={cert.id}
              ></ListItemText>
            </ListItem>
          </React.Fragment>
        );
      })}
      <Button variant="contained" onClick={handleClose}>Close</Button>
    </Box>
  );
}

export default DerivedHideCerts;
