import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionActions,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  Grid,
  TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Close, Delete, ExpandLess, ExpandMore } from "@mui/icons-material";
import { useCreate, useDelete, useNotify } from "react-admin";
import NameEditor from "../../utils/applications/NameEditor";
import ComponentNameInput from "./ComponentNameInput";
import keyToField from "../../utils/keyToField";
import FileUploadZoneAndShowFileImage from "../../utils/fileUploadZone";
import _ from "lodash";
import areEqual from "../../utils/misc/arraysAreEqual";
import getToken from "../../utils/getAuthToken";
import { confirmAlert } from "react-confirm-alert";
import autoSave from "../../utils/applications/autoSave";
import mandatoryFields from "../update/mandatoryFields.json";

export const ComponentDisplay = (props) => {
  const {
    applicationFlow,
    show,
    edit,
    add,
    isValidated,
    title,
    upload,
    setUpload,
    template,
    options,
    setOptions,
    variable,
    nav,
    optional,
    setOptional,
    filenames,
    userInfo_Role,
    isRecordManagementStandalone,
  } = props;

  const [compTemplate, setCompTemplate] = useState(variable);

  const [value, setValue] = useState(
    upload?.model?.length > 0 || upload?.app_type?.type === "SafetyComponent"
      ? 0
      : -1
  );
  const [clickedModel, setClickedModel] = useState({});

  const [open, setOpen] = useState(
    upload?.model?.reduce((p, c, i) => {
      // return { ...p, [i]: true };
      return { ...p, [i]: !userInfo_Role }; // By Default, only expand if role not equal to "EMSDCE","EMSDSE"
    }, {})
  );

  const [create, { isLoading: uploadingFile }] = useCreate();
  const [deleteOne, { isLoading: deleting }] = useDelete();
  const [temp, setTemp] = useState(""); //select menu for optional types
  const notify = useNotify();


  useEffect(() => {
    //convert buffer to optional safety  component if the lift model is rated speed is <= 1.0 m/s and it is electric lift
    if (
      typeof upload.model[0] === "object" &&
      [
        "ElectricTractionLiftMachineRoom",
        "ElectricTractionLiftRoomless",
      ].includes(upload?.app_type.type)
    ) {
      //assume only one model)
      if (upload.model[0].rated_speed <= 1) {
        setCompTemplate({
          ...variable,
          optional: {
            ...variable.optional,
            [upload?.app_type.type]: {
              ...variable.optional[upload?.app_type.type],
              Buffer: [],
            },
          },
        });
      } else {
        //In case user increase the value of rated speed after deleting buffer
        if (
          Object.keys(optional).some(
            (model) => !optional[model].includes("Buffer")
          )
        ) {
          const clone = { ...optional };
          for (const model in clone) {
            if (!clone[model].includes("Buffer")) clone[model].push("Buffer");
          }
          setOptional(clone);
        }
        setCompTemplate(variable);
      }
    }
  }, [upload]);


  useEffect(() => {
    // "optional" is an array that contains all types of components that belongs to the type of model.
    // If the mode is edit, the old form data might NOT contain the optional component types, so we need to render it based on app_type.components
    // instead of the fixed, static template from "variable"
    if (
      typeof upload.model[0] === "object" &&
      (Object.keys(optional).length === 0 ||
        //In case user change the name of model
        !areEqual(
          upload.model.map((m) => m.provided_model_name),
          Object.keys(optional)
        ))
    ) {
      //When show is true, sometimes there are more components type in the data than in the template (example: BM00006652)
      const shownComponent = Object.keys(
        show
          ? upload?.app_type?.components
          : compTemplate.app_type[upload?.app_type.type]
      )?.sort((a, b) => {
        const order = Object.keys(compTemplate.app_type[upload?.app_type.type]);
        function customSort(item) {
          const index = order.indexOf(item);
          return index !== -1 ? index : order.length;
        }
        return customSort(a) - customSort(b);
      });

      setOptional(
        upload?.model?.reduce((a, c, i) => {
          return { ...a, [c.provided_model_name]: [...shownComponent] };
        }, {})
      );
    }
  }, [optional, upload, setOptional, compTemplate.app_type]);


  useEffect(() => {
    setClickedModel(upload?.model[0]);
  }, []);


  useEffect(() => {
    setTemp("");
  }, [clickedModel]);


  useEffect(() => {
    setValue(-1);
  }, [optional]);


  const handleChange = (
    action,
    component,
    index,
    key,
    file,
    model_id,
    fileIndex
  ) => {
    if (action === "addOptionalComponentCategoryStandAlone") {
      const newUpload = {
        ...upload,
        app_type: {
          ...upload.app_type,
          components: {
            ...upload.app_type.components,
            [component]: [
              // comp_obj
            ],
          },
        },
      };

      setUpload(newUpload);

      let newUpdate = props.update;

      if (edit) {
        newUpdate = {
          ...props.update,
          app_type: {
            ...props.update.app_type,
            components: {
              ...props.update.app_type.components,
              [component]: [],
            },
          },
        };
        props.setUpdate(newUpdate);
      }

      autoSave(newUpload, newUpdate, edit?.mode);
    };


    if (action === "addOptionalComponentCategory") {
      setOptional({
        ...optional,
        [model_id.provided_model_name]: [
          ...optional[model_id.provided_model_name],
          component,
        ],
      });

      const newUpload = {
        ...upload,
        app_type: {
          ...upload.app_type,
          components: {
            ...upload.app_type.components,
            [component]: upload.app_type.components[component]
              ? [...upload.app_type.components[component]]
              : [],
          },
        },
      };

      setUpload(newUpload);

      let newUpdate = props.update;

      if (edit) {
        newUpdate = {
          ...props.update,
          app_type: {
            ...props.update.app_type,
            components: {
              ...props.update.app_type.components,
              [component]: upload.app_type.components[component]
                ? [...upload.app_type.components[component]]
                : [],
            },
          },
        };

        props.setUpdate(newUpdate);
      }

      autoSave(newUpload, newUpdate, edit?.mode);
    };


    if (action === "deleteOptionalComponentCategory") {
      if (Object.keys(optional).length > 0) {
        setOptional({
          ...optional,
          [model_id.provided_model_name]: optional[
            model_id.provided_model_name
          ].filter((x) => x !== component),
        });

        let temp = structuredClone(optional);

        temp[model_id.provided_model_name] = temp[model_id.provided_model_name].filter((x) => x !== component);

        let comp_obj = upload.app_type.components;

        if (model_id && comp_obj[component]) {
          comp_obj[component] = comp_obj[component].filter((component) => component.model_id !== model_id.provided_model_name);
        };
         
        if (
          edit?.clarifying === "record" ||
          !model_id ||
          (comp_obj[component] &&
            Object.values(temp).every((x) => !x.includes(component)) &&
            comp_obj[component].length === 0)
        ) {
          delete comp_obj[component];
        };

        const newUpload = {
          ...upload,
          app_type: { ...upload.app_type, components: comp_obj },
        };

        setUpload(newUpload);

        let newUpdate = props.update;

        if (edit) {
          newUpdate = {
            ...props.update,
            app_type: { ...props.update.app_type, components: comp_obj },
          };
          props.setUpdate(newUpdate);
        }

        autoSave(newUpload, newUpdate, edit?.mode);

      } else {
        //This block happens when adding/editing stand alone component (no LE model exists)
        let comp_obj = upload.app_type.components;

        delete comp_obj[component];

        const newUpload = {
          ...upload,
          app_type: { ...upload.app_type, components: comp_obj },
        };

        setUpload(newUpload);

        let newUpdate = props.update;

        if (edit) {
          newUpdate = {
            ...props.update,
            app_type: { ...props.update.app_type, components: comp_obj },
          };
          props.setUpdate(newUpdate);
        }

        autoSave(newUpload, newUpdate, edit?.mode);
      }
    };


    if (action === "setSafetyComponent") {
      let change_obj = {};

      let comp_obj = {
        ...structuredClone(template.component),
        type: component,
      };

      delete comp_obj.model_id;

      change_obj[component] = [comp_obj];

      const newUpload = {
        ...upload,
        app_type: { ...upload.app_type, components: change_obj },
      };

      setUpload(newUpload);

      let newUpdate = props.update;

      if (edit) {
        newUpdate = {
          ...props.update,
          app_type: { ...props.update.app_type, components: change_obj },
        };
        props.setUpdate(newUpdate);
      }

      autoSave(newUpload, newUpdate, edit?.mode);
    };


    if (action === "addComponent") {
      const newUpload = {
        ...upload,
        app_type: {
          ...upload.app_type,
          components: {
            ...upload.app_type.components,
            [component]: [
              ...upload.app_type.components[component],
              {
                ...structuredClone(template.component),
                type: component,
                model_id: model_id,
              },
            ],
          },
        },
      };

      setUpload(newUpload);
      let newUpdate = props.update;
      if (edit) {
        newUpdate = {
          ...props.update,
          app_type: {
            ...props.update.app_type,
            components: {
              ...props.update.app_type.components,
              [component]: [
                ...props.update.app_type.components[component],
                {
                  ...structuredClone(template.component),
                  type: component,
                  model_id: model_id,
                },
              ],
            },
          },
        };
        props.setUpdate(newUpdate);
      }

      autoSave(newUpload, newUpdate, edit?.mode);
    };


    if (action === "addComponentLoad") {
      let temp = structuredClone(template.component);

      if (!model_id) {
        delete temp.model_id;
      }

      const newUpload = {
        ...upload,
        app_type: {
          ...upload.app_type,
          components: {
            ...upload.app_type.components,
            [component]: [
              ...upload.app_type.components[component],
              {
                ...temp,
                provided_comp_name: key.provided_comp_name,
                type: component,
                ...(model_id && { model_id: model_id }),
              },
            ],
          },
        },
      };

      setUpload(newUpload);
      autoSave(newUpload, props.update, edit?.mode);
    };


    if (action === "editName") {
      let newUpload = structuredClone(upload);

      let changedComp = newUpload.app_type.components[component].find((comp) => comp._id === index || comp.provided_comp_name === index);

      changedComp.provided_comp_name = key.provided_comp_name;
      setUpload(newUpload);
      autoSave(newUpload, props.update, edit?.mode);
    };


    if (action === "confirmComponentAddition") {
      // Increase the amount displayed when confirmed in Add mode
      let change_obj = upload?.app_type.components;

      let chosenComponent = change_obj[component].filter((comp) => model_id ? (comp.model_id === model_id || comp.temp_id === model_id) : comp)[index];

      let clonedchosenComponent = structuredClone(chosenComponent);

      const mandatoryFile = isRecordManagementStandalone
        ? mandatoryFields.recordManagementStandalone.components
        : mandatoryFields.f.components;

      if (
        mandatoryFile.every((file) =>
          Array.isArray(clonedchosenComponent[file])
            ? clonedchosenComponent[file].length > 0
            : clonedchosenComponent[file]
        )
      ) {
        chosenComponent.confirmed = true;
        const newUpload = {
          ...upload,
          app_type: { ...upload.app_type, components: change_obj },
        };
        setUpload(newUpload);
        let newUpdate = props.update;
        if (edit) {
          newUpdate = {
            ...props.update,
            app_type: { ...props.update.app_type, components: change_obj },
          };
          props.setUpdate(newUpdate);
        }
        autoSave(newUpload, newUpdate, edit?.mode);
      } else {
        notify("Missing component mandatory document");
      }
    };


    if (action === "deleteComponent") {
      confirmAlert({
        title: "Confirm to delete component",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const componentsOfSelectedModel = upload.app_type.components[
                component
              ].filter((comp) =>
                model_id ? (comp.model_id === model_id || comp.temp_id === model_id) : comp
              );
              const chosenComponent = componentsOfSelectedModel[index];
              const newUpload = {
                ...upload,
                app_type: {
                  ...upload.app_type,
                  components: {
                    ...upload.app_type.components,
                    [component]: [
                      ...upload.app_type.components[component].filter(
                        (comp) => !_.isEqual(comp, chosenComponent)
                      ),
                    ],
                  },
                },
              };
              setUpload(newUpload);
              let newUpdate = props.update;
              if (edit) {
                newUpdate = {
                  ...props.update,
                  app_type: {
                    ...props.update.app_type,
                    components: {
                      ...props.update.app_type.components,
                      [component]: [
                        ...props.update.app_type.components[component]?.filter(
                          (x, i) => i !== index
                        ),
                      ],
                    },
                  },
                };

                props.setUpdate(newUpdate);
              }
              //Let EMSD delete component
              if (show)
                deleteOne("components", {
                  id: chosenComponent._id,
                  meta: { app_id: upload.id },
                });
              else autoSave(newUpload, newUpdate, edit?.mode);
            },
          },
          {
            label: "No",
          },
        ],
      });
    };
    

    if (action === "setComponentManufacturer") {
      let change_obj = upload?.app_type.components;

      change_obj[component].filter((comp) =>
        model_id ? (comp.model_id === model_id || comp.temp_id === model_id) : comp)[index]["manufacturer_id"] = file;

      const newUpload = {
        ...upload,
        app_type: { ...upload.app_type, components: change_obj },
      };

      setUpload(newUpload);

      let newUpdate = props.update;

      if (edit) {
        newUpdate = {
          ...props.update,
          app_type: { ...props.update.app_type, components: change_obj },
        };
        props.setUpdate(newUpdate);
      }

      autoSave(newUpload, newUpdate, edit?.mode);
    };


    if (action === "setComponentName") {
      let change_obj = upload?.app_type.components;

      change_obj[component].filter((comp) =>model_id ? (comp.model_id === model_id || comp.temp_id === model_id) : comp)[index]["provided_comp_name"] = file;

      const newUpload = {
        ...upload,
        app_type: { ...upload.app_type, components: change_obj },
      };

      setUpload(newUpload);

      let newUpdate = props.update;

      if (edit) {
        newUpdate = {
          ...props.update,
          app_type: { ...props.update.app_type, components: change_obj },
        };
        props.setUpdate(newUpdate);
      }

      autoSave(newUpload, newUpdate, edit?.mode);
    };


    if (action === "addComponentFile") {
      const fileSize = file.reduce((acc, curr) => acc + curr.size, 0);
      if (fileSize > 104857600) {
        notify("Error: File size should be less than 100MB");
        return;
      }
      create(
        "files",
        {
          data: file,
          meta: {
            file_code: upload.file_code ?? props.update.file_code,
          },
        },
        {
          onSuccess: ({ id, data }) => {
            let change_obj = upload?.app_type.components;
            let filtered_components = change_obj[component].filter((comp) => model_id ? (comp.model_id === model_id || comp.temp_id === model_id) : comp);
            let change_field = filtered_components[index][key];
            //Only save the recently uploaded file, filter all old one
            //only for update application (edit.mode)
            if (Array.isArray(change_field))
              change_field = change_field
                .filter((file) => (edit.mode ? file.id : file))
                .concat(data);
            else change_field = data[0];

            filtered_components[index][key] = change_field;

            let newUpload = {
              ...upload,
              app_type: { ...upload.app_type, components: change_obj },
            };

            setUpload(newUpload);

            let newUpdate = props.update;

            if (edit) {
              newUpdate = {
                ...props.update,
                app_type: {
                  ...props.update.app_type,
                  components: change_obj,
                },
              };
              props.setUpdate(newUpdate);
            }
            autoSave(newUpload, newUpdate, edit?.mode);
          },
          onError: (e) => notify(e.response.data),
        }
      );
    };


    if (action === "deleteComponentFile") {
      let change_obj = upload?.app_type.components;

      const affectedComponent = change_obj[component].filter((comp) =>
        model_id ? (comp.model_id === model_id || comp.temp_id === model_id) : comp)[index][key];

      change_obj[component].filter((comp) =>
        model_id ? (comp.model_id === model_id || comp.temp_id === model_id) : comp
      )[index][key] = Array.isArray(affectedComponent)
        ? affectedComponent.filter((file, index) => index !== fileIndex)
        : "";

      delete change_obj[component].filter((comp) =>
        model_id ? (comp.model_id === model_id || comp.temp_id === model_id) : comp
      )[index].confirmed;

      const newUpload = {
        ...upload,
        app_type: { ...upload.app_type, components: change_obj },
      };

      setUpload(newUpload);

      let newUpdate = props.update;

      if (edit) {
        newUpdate = {
          ...props.update,
          app_type: { ...props.update.app_type, components: change_obj },
        };
        props.setUpdate(newUpdate);
      }

      autoSave(newUpload, newUpdate, edit?.mode);
    }
  };


  const getCompList = (compType) => {
    return (upload?.app_type?.components?.[compType] || [])
      .map((component, componentIndex) => ({ component, componentIndex }))
      .filter(
        ({ component, componentIndex }) =>
          upload.app_type.type === "SafetyComponent" ||
          ((component?.model_id === clickedModel.provided_model_name ||
            component?.model_id === clickedModel._id) &&
            component?.type === compType)
      )
      .filter(({ component, componentIndex }) => {
        // If it is adding safety component to exisitng LE model, hide all existing components
        // map 2 times to keep index consistent for delete, note that it is ({component, i}) not (component, i)
        const changedComps = Object.keys(upload.changes?.components?.[compType] || {});

        //Only shows Changed Components in Update application
        if (show && upload.update_upon) {
          return changedComps.includes(component._id);
        } else if (edit.mode === "f") {
          return !component._id;
        } else if (edit.clarifying === "f") {
          return !component._id || changedComps.includes(component._id);
        } else if (["d", "e"].includes(edit.clarifying)) {
          //We display all the component in edit.mode to let user choose which one to update
          //but when clarifying, only show the changed component
          return changedComps.includes(component._id);
        } else return component;
      });
  };

  return (
    <>
      <Card sx={{}}>
        <CardHeader title={title} />
        <CardContent
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <>
            {upload?.app_type.type !== "SafetyComponent" ? (
              upload?.model?.length > 0 ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {upload?.model?.map((model, i) => {
                    return (
                      <Box
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                        key={`model${i}-holder`}
                      >
                        <List
                          sx={{ width: "250px", bgcolor: "background.paper" }}
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                        >
                          <ListItemButton
                            onClick={(e) => {
                              let temp = open;
                              delete temp.i;
                              setOpen({ ...temp, [i]: !open[i] });
                            }}
                            sx={{ backgroundColor: "#A7C7E7" }}
                          >
                            <ListItemText
                              primary={
                                model.provided_model_name ||
                                "Missing Model Name"
                              }
                            />
                            {open[i] ? <ExpandLess /> : <ExpandMore />}
                            {/* {shouldExpandListItem ? <ExpandMore /> : <ExpandLess /> } */}
                          </ListItemButton>
                          <Collapse in={open[i]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              {optional[model.provided_model_name]
                                ?.filter((comp_type) => {
                                  if (show) {
                                    if (upload.update_upon) {
                                      const changedComps = Object.keys(
                                        upload.changes?.components || {}
                                      );
                                      return changedComps.some(
                                        (type) => type === comp_type
                                      );
                                    }
                                    return Object.keys(
                                      upload?.app_type.components
                                    ).includes(comp_type);
                                  } else if (
                                    edit.mode !== "f" &&
                                    edit.clarifying !== "f" &&
                                    edit.clarifying !== "f2" &&
                                    edit.clarifying !== "new"
                                  ) {
                                    //Stop showing irrelevant SC in range ext/update cert
                                    return Object.keys(
                                      upload?.app_type.components
                                    ).includes(comp_type);
                                  } else return comp_type;
                                })
                                .map((el, componentIndex) => {
                                  return (
                                    <ListItemButton
                                      sx={{
                                        pl: 1,
                                        borderBottom:
                                          "0.5px solid rgba(0, 0, 0, 0.12)",
                                        ...(value !== -1 &&
                                          value ===
                                            Object.keys(
                                              upload?.app_type.components
                                            ).indexOf(el) && {
                                            backgroundColor: "#ecf9ff",
                                          }),
                                      }}
                                      key={`model${i}-tab-component${el}`}
                                      onClick={(e) => {
                                        setValue(
                                          Object.keys(
                                            upload?.app_type.components
                                          ).indexOf(el)
                                        );
                                        setClickedModel(model);
                                      }}
                                    >
                                      {(add ||
                                        ["new", "record"].includes(
                                          edit.clarifying
                                        )) &&
                                      Object.keys(
                                        compTemplate.optional[
                                          upload?.app_type.type
                                        ]
                                      ).includes(el) ? (
                                        <ListItemIcon
                                          sx={{ minWidth: 0, mr: 1 }}
                                        >
                                          <Close
                                            onClick={() => {
                                              handleChange(
                                                "deleteOptionalComponentCategory",
                                                el,
                                                i,
                                                "",
                                                "",
                                                model
                                              );
                                            }}
                                          />
                                        </ListItemIcon>
                                      ) : (
                                        <></>
                                      )}
                                      {/* Safety Components sidebar */}
                                      <ListItemText
                                        primary={keyToField[el] ?? el}
                                        // If mode is add, only consider the amount is increased if the user hit the confirm button
                                        secondary={`Amount: ${
                                          getCompList(el).filter((c) =>
                                            add ||
                                            edit.clarifying === "new" ||
                                            (edit.mode || edit.clarifying) ===
                                              "f"
                                              ? c?.component?.confirmed
                                              : c
                                          ).length
                                        }`}
                                      />
                                    </ListItemButton>
                                  );
                                })}
                            </List>

                            {/* //add category */}
                            {!show &&
                              Object.keys(compTemplate.component).filter(
                                (comp_type) =>
                                  Object.keys(
                                    compTemplate.optional[upload?.app_type.type]
                                  ).includes(comp_type) &&
                                  optional[model.provided_model_name] &&
                                  !optional[
                                    model.provided_model_name
                                  ]?.includes(comp_type)
                              ).length > 0 && (
                                <FormControl fullWidth sx={{ mt: 4 }}>
                                  <InputLabel id="loader">
                                    Add Optional Types
                                  </InputLabel>
                                  <Select
                                    fullWidth
                                    labelId="loader"
                                    id="load"
                                    defaultValue=""
                                    value={temp}
                                    label="Component Types"
                                    onChange={(e) => {
                                      handleChange(
                                        "addOptionalComponentCategory",
                                        e.target.value,
                                        i,
                                        "",
                                        "",
                                        model
                                      );
                                      setTemp("");
                                    }}
                                  >
                                    {Object.keys(compTemplate.component)
                                      .filter(
                                        (x) =>
                                          !optional[
                                            model.provided_model_name
                                          ].includes(x) &&
                                          Object.keys(
                                            compTemplate.optional[
                                              upload?.app_type.type
                                            ]
                                          ).includes(x)
                                      )
                                      .map((el, i) => {
                                        return (
                                          <MenuItem
                                            key={`model${clickedModel}-potential-component${el}`}
                                            value={el}
                                          >
                                            {keyToField[el] ?? el}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </FormControl>
                              )}
                          </Collapse>
                        </List>
                      </Box>
                    );
                  })}
                </div>
              ) : (
                <Typography>No Models Found, Please Add A Model</Typography>
              )
            ) : (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <List
                  sx={{ width: "250px", bgcolor: "background.paper" }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton
                    onClick={(e) => {
                      let temp = open;
                      delete temp["0"];
                      setOpen({ ...temp, 0: !open["0"] });
                    }}
                    sx={{ backgroundColor: "#A7C7E7" }}
                  >
                    <ListItemText primary={"Safety Components"} />
                    {open["0"] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open["0"]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {Object.keys(upload?.app_type.components).map(
                        (el, componentTypeIndex) => {
                          return (
                            <ListItemButton
                              sx={{
                                pl: 1,
                                borderBottom: "0.5px solid rgba(0, 0, 0, 0.12)",
                              }}
                              key={`component${el}`}
                              onClick={(e) => {
                                setValue(componentTypeIndex);
                              }}
                            >
                              {(add || ["new", "record"].includes(edit.clarifying)) &&
                              Object.keys(compTemplate.optional[upload?.app_type.type]).includes(el) ? (
                                <ListItemIcon sx={{ minWidth: 0, mr: "5px" }}>
                                  <Close
                                    onClick={() => {
                                      handleChange(
                                        "deleteOptionalComponentCategory",
                                        el,
                                        0
                                      );
                                    }}
                                  />
                                </ListItemIcon>
                              ) : (
                                <></>
                              )}
                              <ListItemText
                                primary={keyToField[el] ?? el}
                                secondary={`Amount: ${
                                  getCompList(el).filter((c) =>
                                    add ||
                                    edit.clarifying === "new" ||
                                    (edit.mode || edit.clarifying) === "f"
                                      ? c?.component?.confirmed
                                      : c
                                  ).length
                                }`}
                              />
                            </ListItemButton>
                          );
                        }
                      )}
                    </List>
                    {(add || ["new", "record"].includes(edit.clarifying)) && (
                      // the concept: when user picking lift as system_type, show lift components only , vice versa
                      // but if it is not brand new application, for example, clarifying, applicationFlow is undefined
                      // so we looks for whether the model contains escalator components (step/pallet)
                      // since escalator comp and lift comp never mix
                      <>
                        {(applicationFlow?.system_type === "lift" ||
                          (edit &&
                            !Object.keys(upload?.app_type.components).includes("Step") &&
                            !Object.keys(upload?.app_type.components).includes("Pallet"))) && (
                          <FormControl fullWidth sx={{ mt: 4 }}>
                            <InputLabel id="loader">
                              Add Lift Components
                            </InputLabel>
                            <Select
                              fullWidth
                              labelId="loader"
                              id="load"
                              onChange={(e) => {
                                handleChange(
                                  "addOptionalComponentCategoryStandAlone",
                                  e.target.value,
                                  0
                                );
                                setTemp("");
                              }}
                            >
                              {Object.keys(compTemplate.component)
                                .filter(
                                  (comp) =>
                                    Object.keys(compTemplate.optional[upload?.app_type.type]).includes(comp) &&
                                    !Object.keys(upload?.app_type.components).includes(comp) &&
                                    !["Step", "Pallet"].includes(comp)
                                )
                                .map((el, i) => {
                                  return (
                                    <MenuItem
                                      key={`potential-component${el}`}
                                      value={el}
                                    >
                                      {keyToField[el] ?? el}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        )}
                        {(applicationFlow?.system_type === "escalator" ||
                          (edit &&
                            (Object.keys(upload?.app_type.components).includes("Steps") ||
                              Object.keys(upload?.app_type.components).includes("Pallets")))) && (
                          <FormControl fullWidth>

                            <InputLabel id="loaderEscalator">
                              Add Escalator Components
                            </InputLabel>

                            <Select
                              fullWidth
                              labelId="loaderEscalator"
                              id="loadEscalator"
                              onChange={(e) => {
                                handleChange(
                                  "addOptionalComponentCategoryStandAlone",
                                  e.target.value,
                                  0
                                );
                                setTemp("");
                              }}
                            >
                              {["Step", "Pallet"]
                                .filter((comp) => !Object.keys(upload?.app_type.components)
                                .includes(comp))
                                .map((el) => {
                                  return (
                                    <MenuItem
                                      key={`potential-component${el}`}
                                      value={el}
                                    >
                                      {keyToField[el] ?? el}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        )}
                      </>
                    )}
                  </Collapse>
                </List>
              </Box>
            )}

            {Object.keys(upload?.app_type?.components).map(
              (el, componentTypeIndex) => {
                return (
                  <div
                    style={{ display: "contents" }}
                    key={`model${clickedModel?.provided_model_name}-tabfields-component${el}`}
                  >
                    <ComponentFields
                      getCompList={getCompList}
                      show={show}
                      add={add}
                      isValidated={isValidated}
                      edit={edit}
                      template={template}
                      variable={compTemplate}
                      upload={upload}
                      uploadingFile={uploadingFile}
                      options={options}
                      setOptions={setOptions}
                      value={value}
                      nav={nav}
                      clickedModel={clickedModel}
                      componentTypeIndex={componentTypeIndex}
                      name={el}
                      handleChange={handleChange}
                      filenames={filenames}
                      isRecordManagementStandalone={
                        isRecordManagementStandalone
                      }
                    />
                  </div>
                );
              }
            )}
          </>
        </CardContent>
      </Card>
    </>
  );
};

function ComponentFields(props) {
  const {
    getCompList,
    value,
    componentTypeIndex,
    name,
    clickedModel,
    add,
    isValidated,
    edit,
    upload,
    template,
    variable,
    handleChange,
    show,
    nav,
    uploadingFile,
    options,
    setOptions,
    filenames,
    isRecordManagementStandalone,
    ...other
  } = props;

  const [select, setSelect] = useState(null); //autocomplete
  const [inputValue, setInputValue] = useState(""); //autocomplete

  const clickedModelId =
    clickedModel &&
    (clickedModel._id ? clickedModel._id : clickedModel.provided_model_name);
  // When edit is boolean, it is when the user edit the application such as those with status clarifying.
  // But if edit is an object {mode: ...}, user chose a different type of application under new application

  const allowDeleteComp =
    (show &&
      window.location.pathname === "/lift_emsd" &&
      !["Approved", "Clarifying", "Withdrawn"].includes(upload.status)) ||
    add ||
    edit.mode === "f" ||
    ["new", "record", "f", "f2"].includes(edit.clarifying);

  const dropzoneId = "dropzone";

  useEffect(() => {
    const dragEventHandler = (e) => {
      if (e.target.className !== dropzoneId) {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.dropEffect = "none";
      }
    };

    ["dragenter", "dragover", "drop"].forEach((ev) => window.addEventListener(ev, dragEventHandler, false));

    return () => {
      ["dragenter", "dragover", "drop"].forEach((ev) =>
        window.removeEventListener(ev, dragEventHandler, false)
      );
    };
  }, []);

  const fileDownload = async (id) => {
    window.open(
      `${
        process.env.REACT_APP_ENV_ENDPOINT
      }/lift/files/${id}?token=${getToken()}`
    );
  };

  const components = getCompList(name);

  //hidden when 1. clicked value isn't a component type. 2. clicked value has no model, UNHIDDEN when application type is a safety component
  if (
    value !== componentTypeIndex ||
    (!upload?.app_type.type === "SafetyComponent" &&
      !(upload?.model?.length > 0)) ||
    (clickedModel && Object.keys(clickedModel)?.length < 1)
  )
    return null;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${componentTypeIndex}`}
      aria-labelledby={`simple-tab-${componentTypeIndex}`}
      style={{ flex: 1, marginLeft: "16px" }}
      {...other}
    >
      <>
        {components.map(({ component, componentIndex }) => {
          const displayedField = (mode) => {
            const fields = [
              "component_code",
              "provided_comp_name",
              "manufacturer_id",
              "type_test_certificate_id",
              "compliance_confirmation",
              "install_operation_maint",
              "maintenance_schedule",
              "arr_training_tech_support",
              "other_comp_documents",
            ];
            const defaultFields =
              show && upload.update_upon
                ? upload.changes?.components?.[name][component._id].concat([
                    "component_code",
                    "manufacturer_id",
                    "other_comp_documents",
                  ])
                : show ||
                  ((add || edit.clarifying === "f2") &&
                    upload?.app_type.type === "SafetyComponent")
                ? fields.concat("compatibility_cert_id") //add new standalone SC can upload compatibility cert
                : fields;

            switch (mode) {
              case "a":
                return ["type_test_certificate_id"];
              case "c":
                return clickedModel.LE_type === "L"
                  ? [
                      "type_test_certificate_id",
                      "compliance_confirmation",
                      "install_operation_maint",
                      "maintenance_schedule",
                      "arr_training_tech_support",
                      "other_comp_documents",
                    ]
                  : ["type_test_certificate_id"];
              case "d":
                return ["type_test_certificate_id", "manufacturer_id"];
              case "e":
                return ["type_test_certificate_id", "manufacturer_id"];
              default:
                return defaultFields;
            }
          };
          return (
            <Accordion
              defaultExpanded={!(edit.clarifying === "record")}
              sx={{ mt: 3 }}
              key={`${componentIndex}-${clickedModel?.provided_model_name}`}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: "lightgray" }}
              >
                <Typography>
                  {component["provided_comp_name"]}{" "}
                  {show && !component?.active && (<span style={{ color: "red" }}> (Revoked)</span>)}
                </Typography>

                {(add || edit.clarifying === "new") && (
                  <NameEditor
                    onClick={() => setInputValue(component["provided_comp_name"])}
                    nameInput={
                      <ComponentNameInput
                        edit={edit}
                        mode="edit"
                        name={name}
                        componentTypeIndex={componentTypeIndex}
                        component_id={
                          component._id || component.provided_comp_name
                        }
                        clickedModel={clickedModel}
                        select={select}
                        setSelect={setSelect}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        upload={upload}
                        options={options}
                        setOptions={setOptions}
                        handleChange={handleChange}
                      />
                    }
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} sx={{ width: "90%" }}>
                  {Object.keys(template.component)
                    ?.filter((field) => displayedField(edit.mode || edit.clarifying).includes(field))
                    .map((field) => {
                      const manu = component?.manufacturer_id;
                      const manuFailed =
                        add &&
                        upload?.aiFeedBack?.length > 0 &&
                        upload?.aiFeedBack?.some(
                          (item) =>
                            item.name === manu &&
                            item.field === "manufacturer_id"
                        );

                      const markMandatory = (isRecordManagementStandalone
                        ? mandatoryFields?.recordManagementStandalone?.components?.includes(field)
                        : add ||
                          edit?.clarifying === "new" ||
                          mandatoryFields[
                            upload?.mandatoryFileSet ||
                              edit?.mode ||
                              edit?.clarifying
                          ]?.components?.includes(field)) &&
                        !["other_comp_documents","compatibility_cert_id"].includes(field) && (
                          <Typography
                            component="span"
                            sx={{
                              color: "red",
                              fontStyle: "italic",
                              fontSize: "0.75rem",
                              verticalAlign: "top",
                            }}
                          >
                            *Required
                          </Typography>
                        );

                      return (
                        <Grid
                          item
                          xs={6}
                          key={`${component}-${clickedModel?.provided_model_name}-${field}`}
                        >
                          {field === "component_code" ||
                          field === "provided_comp_name" ? (
                            <Typography>
                              {keyToField[field] ?? field}:{" "}
                              {component[field] ?? "Not assigned"}
                            </Typography>
                          ) : field === "manufacturer_id" ? (
                            show ||
                            ["d", "e", "record_comp"].includes(
                              edit.mode || edit.clarifying
                            ) ? (
                              <Typography>
                                Manufacturer Reference:{" "}
                                {upload.manufacturer.find((manu) => manu._id === component["manufacturer_id"])?.provided_name}
                              </Typography>
                            ) : (
                              <div key={`${component}-${clickedModel?.provided_model_name}-${field}`}>
                                <Typography
                                  sx={{
                                    ...(manuFailed && {
                                      backgroundColor: "pink",
                                    }),
                                  }}
                                >
                                  {keyToField[field] ?? field} {markMandatory}
                                </Typography>
                                <Autocomplete
                                  disablePortal
                                  value={
                                    edit &&
                                    upload?.manufacturer.filter(
                                      (x) =>
                                        x._id === component["manufacturer_id"]
                                    ).length > 0
                                      ? upload?.manufacturer.filter(
                                          (x) =>
                                            x._id ===
                                            component["manufacturer_id"]
                                        )[0].provided_name
                                      : component["manufacturer_id"] || null
                                  }
                                  sx={{ width: "80%", maxWidth: "500px" }}
                                  id="comp_manuf_select"
                                  options={upload?.manufacturer
                                    ?.filter((manu) => {
                                      const changedManufacturers = Object.keys(
                                        upload.changes?.manufacturer || {}
                                      );
                                      const usedManufacturers = components
                                        .map((item) => ({ ...item.component }))
                                        .filter(
                                          (c) =>
                                            c.provided_comp_name ===
                                            component.provided_comp_name
                                        )
                                        .map((c) => c.manufacturer_id)
                                        .filter(Boolean);
                                      if (
                                        usedManufacturers.includes(
                                          manu.provided_name
                                        )
                                      )
                                        return false; //Prevent user input 2 comps with same name + same manufacturer
                                      //check manufacturerDisplay line 333, same filter
                                      return edit.mode === "f"
                                        ? !manu._id && manu.type !== "Model"
                                        : edit.clarifying === "f"
                                        ? (!manu._id ||
                                            changedManufacturers?.includes(
                                              manu._id
                                            )) &&
                                          manu.type !== "Model"
                                        : manu.type !== "Model";
                                    })
                                    .sort((a, b) =>
                                      a.provided_name.localeCompare(
                                        b.provided_name
                                      )
                                    )
                                    .map(
                                      (manufacturer) =>
                                        manufacturer.provided_name
                                    )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Manufacturers"
                                    />
                                  )}
                                  onChange={(e, newValue) =>
                                    clickedModel
                                      ? handleChange(
                                          "setComponentManufacturer",
                                          name,
                                          componentIndex,
                                          field,
                                          newValue,
                                          clickedModel._id
                                            ? clickedModel._id
                                            : clickedModel.provided_model_name
                                        )
                                      : handleChange(
                                          "setComponentManufacturer",
                                          name,
                                          componentIndex,
                                          field,
                                          newValue
                                        )
                                  }
                                />
                              </div>
                            )
                          ) : !show || component[field]?.length > 0 ? (
                            <div
                              // need to fix later
                              /* ...(upload?.changes && upload?.changes.length !== 0 && upload?.changes?.components[name]?.hasOwnProperty(component._id) && upload?.changes?.components[name][component._id]?.includes(field) && {backgroundColor:"pink"})  */
                              key={`${component}-${clickedModel?.provided_model_name}-${field}`}
                            >
                              <FileUploadZoneAndShowFileImage
                                AIfeedback={upload?.aiFeedBack}
                                edit={edit}
                                add={add}
                                isValidated={isValidated}
                                show={show}
                                data={component}
                                fieldname={field}
                                filenames={filenames}
                                markMandatory={markMandatory}
                                uploadingFile={uploadingFile}
                                deleteFile={(additionalFileIndex) => {
                                  clickedModel
                                    ? handleChange(
                                        "deleteComponentFile",
                                        name,
                                        componentIndex,
                                        field,
                                        "",
                                        clickedModel._id
                                          ? clickedModel._id
                                          : clickedModel.provided_model_name,
                                        additionalFileIndex
                                      )
                                    : handleChange(
                                        "deleteComponentFile",
                                        name,
                                        componentIndex,
                                        field,
                                        "",
                                        undefined,
                                        additionalFileIndex
                                      );
                                }}
                                downloadFile={fileDownload}
                                acceptFile={(acceptedFiles) =>
                                  clickedModel
                                    ? handleChange(
                                        "addComponentFile",
                                        name,
                                        componentIndex,
                                        field,
                                        acceptedFiles,
                                        clickedModel._id
                                          ? clickedModel._id
                                          : clickedModel.provided_model_name
                                      )
                                    : handleChange(
                                        "addComponentFile",
                                        name,
                                        componentIndex,
                                        field,
                                        acceptedFiles
                                      )
                                }
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      );
                    })}
                </Grid>
              </AccordionDetails>
              <Divider orientation="horizontal" flexItem />
              <AccordionActions
                sx={{
                  display: "flex",
                  justifyContent:
                    add ||
                    edit.clarifying === "new" ||
                    (edit.mode || edit.clarifying) === "f"
                      ? "space-between"
                      : "end",
                }}
              >
                {(add ||
                  edit.clarifying === "new" ||
                  (edit.mode || edit.clarifying) === "f") && (
                  <Button
                    disabled={
                      clickedModel
                        ? upload.app_type.components[name].filter((comp) =>
                            clickedModelId
                              ? (comp.model_id === clickedModelId || comp.temp_id === clickedModelId)
                              : comp
                          )[componentIndex].confirmed
                        : upload.app_type.components[name][componentIndex]
                            .confirmed
                    }
                    
                    variant="contained"
                    color="primary"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      clickedModel
                        ? handleChange(
                            "confirmComponentAddition",
                            name,
                            componentIndex,
                            "",
                            "",
                            clickedModel._id
                              ? clickedModel._id
                              : clickedModel.provided_model_name
                          )
                        : handleChange(
                            "confirmComponentAddition",
                            name,
                            componentIndex
                          );
                    }}
                  >
                    {(
                      clickedModel
                        ? upload.app_type.components[name].filter((comp) =>
                            clickedModelId
                              ? (comp.model_id === clickedModelId || comp.temp_id === clickedModelId)
                              : comp
                          )[componentIndex].confirmed
                        : upload.app_type.components[name][componentIndex]
                            .confirmed
                    )
                      ? "Confirmed"
                      : "Confirm"}
                  </Button>
                )}
                {allowDeleteComp && (
                  <Delete
                    onClick={() =>
                      handleChange("deleteComponent", name, componentIndex)
                    }
                  />
                )}
              </AccordionActions>
            </Accordion>
          );
        })}
        {(["new", "record", "f", "f2"].includes(edit.clarifying) ||
          edit.mode === "f" ||
          add) && (
          <ComponentNameInput
            edit={edit}
            mode="add"
            name={name}
            componentTypeIndex={componentTypeIndex}
            clickedModel={clickedModel}
            select={select}
            setSelect={setSelect}
            inputValue={inputValue}
            setInputValue={setInputValue}
            upload={upload}
            options={options}
            setOptions={setOptions}
            handleChange={handleChange}
          />
        )}
      </>
    </div>
  );
}
