import React from "react";
import { Button, TextField, Popper, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const ModelNameInput = (props) => {
  const {
    mode,
    value,
    setValue,
    inputValue,
    setInputValue,
    upload,
    existing,
    handleChange,
    templateDeterminant,
  } = props;
  const filter = createFilterOptions({
    stringify: (option) => option.provided_model_name + option.model_code,
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "10px",
        alignItems: "center",
      }}
    >
      <Autocomplete
        value={value}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionDisabled={(option) => option.inputValue === ""}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue({
              provided_model_name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              provided_model_name: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
            ?.filter(
              (model) =>
                templateDeterminant(upload?.app_type?.type).LE_type ===
                model.LE_type
            )
            ?.filter((x) =>
              upload.model.every((y) => y.model_code !== x.model_code)
            );

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.provided_model_name
          );
          if (!isExisting) {
            filtered.push({
              inputValue,
              provided_model_name:
                inputValue === ""
                  ? "Add model by typing here"
                  : `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={existing}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.provided_model_name;
        }}
        renderOption={(props, option) => (
          <li
            {...props}
            key={`${option.model_code ? option.model_code : option.id}: ${
              option.provided_model_name
            }`}
          >
            {option.model_code
              ? `${option.model_code}: ${option.provided_model_name}`
              : option.provided_model_name}
          </li>
        )}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Input Model Name Here" />
        )}
      />
      <Button
        disabled={value === null}
        size="small"
        sx={{ ml: 1, height: "48px", mt: "6px" }}
        variant="outlined"
        onClick={() => {
          if (mode === "edit") handleChange("editName");
          else handleChange("addModelLoad");
          setValue(null);
        }}
      >
        {mode === "edit" ? "Edit name" : "Add Model"}
      </Button>
    </div>
  );
};

export default ModelNameInput;
