import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import IconButton from '@mui/material/IconButton';

import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

// react hooks
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from 'react-i18next';

const fields = [
  {
    value: 'component_code',
    label: 'Component Code',
  },
  {
    value: 'provided_comp_name',
    label: 'Component Name',
  },
  {
    value: 'type',
    label: 'Type',
  },
  {
    value: "manufacturersPopulated.provided_name",
    label: "Manufacturer Name",
  },
  {
    value: "modelsPopulated.provided_model_name",
    label: "Model Name",
  }
];


function InputSearchBar({ setFilter }) {
  const { t } = useTranslation("pages", { keyPrefix: "typeApprovalRecord" });
  const [filterField, setFilterField] = useState("provided_comp_name");
  const [filterValue, setFilterValue] = useState("");
  const matches = useMediaQuery('(max-width: 600px)');

  const handleTextSearch = (e) => {
    e.preventDefault()
    if(filterValue === "" || filterField === "") {
      setFilter(`{"":""}`)
      return
    }
    setFilter(`{"${filterField}":"${filterValue}"}`)
  }

  return (
    <Toolbar
      sx={{
        mt: 3,
        mx: 2,
      }}
    >
        <Typography
          sx={{ flex: '1 1 100%' }}
          id="tableTitle"
          component="div"
          style={{
            m: 0,
          }}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
              flexGrow: 1,
              m: 0
            }}
            noValidate
            autoComplete="off"
          >

              <Grid 
                container spacing={{xs: 1, sm: 1, md: 2}} columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  // center
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  flexDirection: "row",
                  m: 0
                }}
              >
                <Grid item xs={12}>
                  {
                    filterField !== 'createdAt' && (
                      <TextField
                        id="standard-basic" label={t("Text Search")} variant="outlined" fullWidth
                        value={filterValue}
                        sx={{
                          boxShadow: "0 0 0 1rem rgba(42,89,143,.25)",
                          borderRadius: "2px",
                          mb:2
                        }}
                        InputProps={{
                          startAdornment:
                            <InputAdornment position="start">
                              <TextField
                                id="filterField"
                                select
                                label={t("Filter By")}
                                defaultValue="client_user"
                                variant="filled"
                                sx={{
                                  display: "flex",
                                  float: "right",
                                }}
                                onChange={(e) => setFilterField(e.target.value)}
                                value={filterField}
                              >
                                {fields.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {t(option.label)}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </InputAdornment>,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                sx={!matches ? {
                                  cursor: "pointer",
                                  color: "#051B44",
                                  textTransform: "none",
                                } : {
                                  cursor: "pointer",
                                  color: "#051B44",
                                  fontSize: "8px",
                                  textTransform: "none",
                                }}
                                onClick={() => setFilterValue("")}
                              >
                                {t("Clear")}
                              </Button>
                              <Button
                                onClick={handleTextSearch}
                                aria-label='search'
                                variant="contained"
                                sx={!matches ? {
                                  backgroundColor: "#2A598F",
                                  textTransform: "none",
                                  '&:hover': {
                                    backgroundColor: "#203C5B",
                                  },
                                } : {
                                  textTransform: "none",
                                  backgroundColor: "#2A598F",
                                  '&:hover': {
                                    backgroundColor: "#203C5B",
                                  },
                                  fontSize: "8px",
                                }}
                              >
                                {t("Search")}
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setFilterValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleTextSearch(e)
                          }
                        }}
                      />
                    )
                  }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>

                </Grid>
              </Grid>



          </Box>
        </Typography>
    </Toolbar>
  );
}

InputSearchBar.propTypes = {
  setFilter: PropTypes.func.isRequired
};

export default InputSearchBar