import {
  List,
  Typography,
  Box,
  ListItem,
  IconButton,
  ListItemText,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import getToken from "../../utils/getAuthToken";

function LeosDocuments(props) {
  const { upload } = props;
  const downloadFile = async (filename) => {
    window.open(
      `${
        process.env.REACT_APP_ENV_ENDPOINT
      }/lift/files/leosDocs/${encodeURIComponent(filename)}?token=${getToken()}`
    );
  };
  if (upload?.other_leos_documents?.length === 0) return null;
  return (
    <Box>
      <Typography variant="h6">LEOS Documents</Typography>
      <List>
        {upload?.other_leos_documents?.map((filename) => {
          return (
            <ListItem
              sx={{
                py: 0,
                px: 1,
                backgroundColor: "#efffe5",
                border: "1px solid rgba(118, 118, 118, 0.3)",
                maxWidth: "700px",
                width: "80%",
              }}
              secondaryAction={
                <IconButton>
                  <DownloadIcon onClick={() => downloadFile(filename)} />
                </IconButton>
              }
            >
              <ListItemText
                primary={filename}
                primaryTypographyProps={{
                  paddingRight: 6,
                }}
                style={{ wordBreak: "break-all" }}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

export default LeosDocuments;
