import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useCreate, useNotify } from "react-admin";
import axios from "axios";
import getToken from "../../utils/getAuthToken";
import Dropzone from "react-dropzone";
import { display } from "@mui/system";

function DocumentEmsdUpload(props) {
  const [create, { isLoading: uploadingFile }] = useCreate();
  const notify = useNotify();
  const { upload, setUpload, userInfo, userRoles } = props;
  const [description, setDescription] = useState("");
  const [isUploading, setIsUploading] = useState(false); // adding upload state


  // disable the button if the user is not EMSDGR (GR) or Inspector, but the application's current role name is not GR.
  const isButtonDisabled =
    (userInfo?.role === "EMSDGR" && //GR and current role name is not GR and not pending
      (upload?.status.includes("Pending E") ||
        upload?.status.includes("Pending SE") ||
        upload?.status.includes("Pending CE")) &&
      upload?.current_role_name !== "GR") ||
    userInfo?.role === "Inspector" || //Inspector
    (userInfo?.role !== "EMSDGR" && //Check roleId except GR
      !userRoles?.includes(upload?.current_role_id));

  const onDrop = (file) => {
    const file_code = uuidv4();
    setIsUploading(true); // Set the uploading state to true

    create(
      "files",
      {
        data: file,
        meta: {
          type: "other_documents",
          description: description,
          file_code,
        },
      },
      {
        onSuccess: ({ id, data }) => {
          const otherDocsClone = [...upload.other_documents].map(
            (item) => item?._id
          );
          otherDocsClone.push(data[0].id);
          axios
            .post(
              `${process.env.REACT_APP_ENV_ENDPOINT}/lift/apps/appendix/${upload.id}`,
              { other_documents: otherDocsClone },
              {
                headers: { authorization: getToken() },
              }
            )
            .then((res) => {
              notify("File uploaded");
              setUpload({
                ...upload,
                other_documents: res.data.other_documents,
              });
              setDescription("");
            })
            .catch((err) => {
              notify(`Error saving file`);
            })
            .finally(() => { 
              setIsUploading(false);  // Set the uploading state to false after the upload is complete
            })
        },
        onError: (e) => {
          notify(e.response.data);
          setIsUploading(false);
        }
      }
    );
  };
  if (upload?.status === "Withdrawn") return null;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <TextField
        onChange={(e) => setDescription(e.target.value)}
        disabled={isButtonDisabled}
        sx={{ minWidth: "500px" }}
        id="description"
        label={"Description of Document"}
        value={description}
        variant="outlined"
      />
      {description ? (
        <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles[0])}>
          {({ getRootProps, getInputProps }) => (
            <Box
              sx={{
                py: 0,
                px: 1,
                ml: 2,
                mb: 2,
                border: "3px dashed lightgray",
                maxWidth: "300px",
                cursor: "pointer",
                boxSizing: "border-box",
              }}
            >
              {/* disabling the upload button when the upload is in progress */}
              <div {...getRootProps()} style={{ display: isUploading ? 'none' : 'block'  }}>  
                <input {...getInputProps()}/>
                  <Typography>Upload File</Typography>
              </div>
              <div>
                {/* when upload is in progess, show "Uploading" instead of the button */}
                <Typography>{isUploading && "Uploading..."}</Typography> 
              </div>
            </Box>
          )}
        </Dropzone>
      ) : (
        <Typography>Please type a description first</Typography>
      )}
    </Box>
  );
}

export default DocumentEmsdUpload;
