import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Typography,
  ListItemSecondaryAction,
  Box,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";

const ManufacturerCode = (props) => {
  const { appendix, setAppendix, deleteSection, application, userInfo, userRoles } = props;

  const handleInputChange = (index, field, value) => {
    let section = structuredClone(appendix["manufacturer_code"]);
    section[index][field] = value;
    setAppendix({ ...appendix, manufacturer_code: section });
  };

  const handleMoveItem = (index, direction) => {
    let section = structuredClone(appendix["manufacturer_code"]);
    const currentItem = section[index];
    section[index] = section[index + direction];
    section[index + direction] = currentItem;
    setAppendix({ ...appendix, manufacturer_code: section });
  };

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))

  if (!appendix["manufacturer_code"]) return null;

  return (
    <List>
      {appendix["manufacturer_code"].map((item, index) => (
        <ListItem key={index}>
          <ListItemText>
            <Box display="flex" alignItems="center">
              <Typography component="span">
                {String.fromCharCode(65 + index)}.{" "}
              </Typography>
              <TextField
                variant="outlined"
                label="Name"
                value={item.name}
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
                disabled={isDisabled} 
              />
              <TextField
                variant="outlined"
                label="Address"
                value={item.address}
                onChange={(e) =>
                  handleInputChange(index, "address", e.target.value)
                }
                disabled={isDisabled} 
              />
            </Box>
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => handleMoveItem(index, -1)}
              disabled={index === 0}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              onClick={() => handleMoveItem(index, 1)}
              disabled={index === appendix["manufacturer_code"].length - 1}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton disabled={isDisabled} onClick={() => deleteSection(index, "manufacturer_code")}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default ManufacturerCode;
