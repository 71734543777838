import Typography from "@mui/material/Typography";
import { MenuItem, Select, TextField, Grid } from "@mui/material";
import React, { useEffect } from "react";
import keyToField from "../../utils/keyToField";
import FileUploadZoneAndShowFileImage from "../../utils/fileUploadZone";
import getToken from "../../utils/getAuthToken";

export const ManufacturerFields = (props) => {
  const {
    add,
    isValidated,
    edit,
    template,
    manufacturerObject,
    handleChange,
    manufacturerIndex,
    upload,
    show,
    showOne,
    uploadingFile,
    filenames,
  } = props;

  const filterDocumentInputIfExist = (inputField) => {
    const documentOmitted = [
      "history",
      "organization_man",
      "size_location_cap",
      "product_range_yearly_production",
    ];

    if (manufacturerObject.manufacturer_code && (edit || add)) {
      //If the manufacturer has manufacturer code, it means the manu already exist and registered in DB, therefore no need to submit some document in add or edit mode
      return !documentOmitted.includes(inputField);
    } else if (show && upload.update_upon) {
      const changedFields =
        upload.changes?.manufacturer?.[manufacturerObject._id];
      return [
        ...changedFields,
        "manufacturer_code",
        "other_man_documents",
        "short_name",
        "alias_name",
      ].includes(inputField);
    } else {
      return inputField;
    }
  };

  const fileDownload = async (id) => {
    window.open(
      `${
        process.env.REACT_APP_ENV_ENDPOINT
      }/lift/files/${id}?token=${getToken()}`
    );
  };

  const dropzoneId = "dropzone";

  useEffect(() => {
    const dragEventHandler = (e) => {
      if (e.target.className !== dropzoneId) {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.dropEffect = "none";
      }
    };

    ["dragenter", "dragover", "drop"].forEach((ev) =>
      window.addEventListener(ev, dragEventHandler, false)
    );

    return () => {
      ["dragenter", "dragover", "drop"].forEach((ev) =>
        window.removeEventListener(ev, dragEventHandler, false)
      );
    };
  }, []);

  return (
    <Grid container spacing={3} sx={{ width: "90%" }}>
      {Object.keys(template)
        .filter((field) => {
          let filter = [];
          if (upload?.app_type?.type === "SafetyComponent") filter.push("type");
          if (edit.mode !== "a" && edit.clarifying !== "a")
            filter.push("provided_name");
          if (!add || !manufacturerObject.address) filter.push("address");
          if (!manufacturerObject.short_name) filter.push("short_name");
          if (manufacturerObject.alias_name?.length === 0)
            filter.push("alias_name");
          if (manufacturerObject.manufacturer_code) filter.push("type");
          return !filter.includes(field);
        })
        .filter((field) => filterDocumentInputIfExist(field))
        .map((fieldname, i) => {
          const markMandatory = (add ||
            ["a", "f"].includes(edit.mode || edit.clarifying)) &&
            fieldname !== "other_man_documents" && (
              <Typography
                component="span"
                sx={{
                  color: "red",
                  fontStyle: "italic",
                  fontSize: "0.75rem",
                  verticalAlign: "top",
                }}
              >
                *Required
              </Typography>
            );
          if (edit.mode === "a" || edit.clarifying === "a") {
            //Manufacturer name change, only manufactuer name and ISO cert displayed
            return (
              <Grid item xs={12} key={`manu${manufacturerIndex}${fieldname}`}>
                {fieldname === "provided_name" ? (
                  <React.Fragment>
                    <Typography>
                      {keyToField[fieldname] ?? fieldname}
                    </Typography>
                    <TextField
                      disabled={!edit && !add}
                      onChange={(e) => {
                        handleChange(
                          "editText",
                          manufacturerIndex,
                          fieldname,
                          e.target.value
                        );
                      }}
                      id="outlined-basic"
                      value={
                        manufacturerObject.provided_name
                          ? manufacturerObject.provided_name
                          : ""
                      }
                      variant="outlined"
                    />
                  </React.Fragment>
                ) : (
                  fieldname === "iso_certificate_id" && (
                    <FileUploadZoneAndShowFileImage
                      edit={edit}
                      add={add}
                      isValidated={isValidated}
                      show={show}
                      showOne={showOne}
                      data={manufacturerObject}
                      fieldname={fieldname}
                      filenames={filenames}
                      markMandatory={markMandatory}
                      uploadingFile={uploadingFile}
                      deleteFile={(additionalFileIndex) => {
                        handleChange(
                          "deleteFile",
                          manufacturerIndex,
                          fieldname,
                          additionalFileIndex
                        );
                      }}
                      downloadFile={fileDownload}
                      acceptFile={(acceptedFiles) =>
                        handleChange(
                          "addFile",
                          manufacturerIndex,
                          fieldname,
                          acceptedFiles
                        )
                      }
                    />
                  )
                )}
              </Grid>
            );
          } else if (
            //!show for empty input field for new application, manufacturerObject[fieldname]?.length > 0 to check if the field exist for show
            !show ||
            manufacturerObject[fieldname]?.length > 0
          )
            return (
              <Grid item xs={6} key={`manu${manufacturerIndex}${fieldname}`}>
                {fieldname === "manufacturer_code" ? (
                  <Typography>
                    Manufacturer Code:{" "}
                    {manufacturerObject.manufacturer_code
                      ? manufacturerObject.manufacturer_code
                      : "Not assigned"}
                  </Typography>
                ) : fieldname === "address" ||
                  fieldname === "short_name" ||
                  fieldname === "alias_name" ? (
                  <Typography>
                    {keyToField[fieldname]}:{" "}
                    {Array.isArray(manufacturerObject[fieldname])
                      ? manufacturerObject[fieldname].join(", ")
                      : manufacturerObject[fieldname]}
                  </Typography>
                ) : fieldname === "type" ? (
                  //Only when the manufacturer has no manufacturer code (when user create new)
                  <React.Fragment>
                    <Typography>
                      Scope of manufacturer {markMandatory}
                    </Typography>
                    <Select
                      sx={{ width: "80%" }}
                      disabled={
                        (!add &&
                          !["f", "new", "record"].includes(edit.clarifying) &&
                          !(edit.mode === "f")) ||
                        manufacturerObject.manufacturer_code
                      }
                      labelId="loader"
                      id="load"
                      value={
                        manufacturerObject["type"]
                          ? manufacturerObject["type"]
                          : ""
                      }
                      label="operation"
                      onChange={(e) =>
                        props.handleChange(
                          "editText",
                          manufacturerIndex,
                          fieldname,
                          e.target.value
                        )
                      }
                    >
                      {/* Disable when the model / SC is already assigned to this manufacturer to prevent incorrect scope */}
                      <MenuItem
                        value={"SafetyComponent"}
                        disabled={upload?.model?.some((m) =>
                          m.manufacturer_files.some(
                            (f) =>
                              f.manufacturer_id ===
                              manufacturerObject["provided_name"]
                          )
                        )}
                      >
                        Safety Component
                      </MenuItem>
                      <MenuItem
                        value={"Model"}
                        disabled={Object.values(
                          upload?.app_type?.components || {}
                        )
                          .flat()
                          .some(
                            (c) =>
                              c.manufacturer_id ===
                              manufacturerObject["provided_name"]
                          )}
                      >
                        Lift/Escalator System Model
                      </MenuItem>
                      <MenuItem value={"Both"}>
                        Both Lift/Escalator System Model and Safety Components
                      </MenuItem>
                    </Select>
                  </React.Fragment>
                ) : (
                  <FileUploadZoneAndShowFileImage
                    edit={edit}
                    add={add}
                    isValidated={isValidated}
                    show={show}
                    showOne={showOne}
                    data={manufacturerObject}
                    fieldname={fieldname}
                    filenames={filenames}
                    markMandatory={markMandatory}
                    uploadingFile={uploadingFile}
                    deleteFile={(additionalFileIndex) => {
                      handleChange(
                        "deleteFile",
                        manufacturerIndex,
                        fieldname,
                        additionalFileIndex
                      );
                    }}
                    downloadFile={fileDownload}
                    acceptFile={(acceptedFiles) =>
                      handleChange(
                        "addFile",
                        manufacturerIndex,
                        fieldname,
                        acceptedFiles
                      )
                    }
                  />
                )}
              </Grid>
            );
        })}
    </Grid>
  );
};
