import { useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import keyToField from "../../utils/keyToField";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function TapasEmsdCheckingResult(props) {
  const { aiChecking, certificates, userInfo_Role } = props;
  const [expanded, setExpanded] = useState(!userInfo_Role);

  if (!aiChecking || !certificates) return null;
  const checkedFile = aiChecking.checkList?.map((item) => item.fileId);

  const handleToggle = () => {setExpanded(!expanded);};

  return (
    <Box sx={{ marginBottom: "30px", width: '60vw' }}>
      <Typography variant="h6" sx={{ fontFamily: "Frutiger_bold" }}>
        AI Checking
      </Typography>
      <Accordion expanded={expanded}>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: "lightgray" }}
          onClick={handleToggle}>
          <Typography >Toggle Content</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer sx={{ my: 2, overflowX: 'auto' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "13.5%", fontWeight: "bold" }}>
                    Certificate Number
                  </TableCell>
                  <TableCell sx={{ width: "13.5%", fontWeight: "bold" }}>
                    Field
                  </TableCell>
                  <TableCell sx={{ width: "15%", fontWeight: "bold" }}>
                    Industry range
                  </TableCell>
                  <TableCell sx={{ width: "30%", fontWeight: "bold" }}>
                    AI result
                  </TableCell>
                  <TableCell sx={{ width: "22.5%", fontWeight: "bold" }}>
                    User input
                  </TableCell>
                  <TableCell sx={{ width: "10%", fontWeight: "bold" }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checkedFile &&
                  certificates
                    .filter((cert) => checkedFile.includes(cert.file))
                    .map((cert) => {
                      const checkingItem = aiChecking.checkList?.find(
                        (item) => item.fileId === cert.file
                      );
                      return Object.keys(checkingItem.checking || {}).map(
                        (field, index, array) => {
                          const item = checkingItem.checking[field];
                          return (
                            <TableRow key={field}>
                              {index === 0 && (
                                <TableCell rowSpan={array.length}>
                                  {cert?.cert_corrections?.tec_certificate_number ||
                                    cert?.cert_corrections?.iso_cert_number ||
                                    cert?.cert_type_metadata
                                      ?.tec_certificate_number ||
                                    cert?.cert_type_metadata?.iso_cert_number ||
                                    "Cert number not found"}
                                </TableCell>
                              )}
                              <TableCell>{keyToField[field] ?? field}</TableCell>
                              <TableCell>
                                {/*  Don't display range if the field is about maximum/minimum */}
                                {field.includes("_min")
                                  ? item.industry?.min
                                  : field.includes("_max")
                                  ? item.industry?.max
                                  : (item.industry?.min || "") +
                                    "-" +
                                    (item.industry?.max || "")}
                              </TableCell>
 
                              <TableCell sx={{ maxWidth: "10px", padding: 0 }}>
                                <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                  {item.aiResult}
                                </div>
                              </TableCell>

                              <TableCell>{item.userInput}</TableCell>
                              <TableCell
                                sx={
                                  item.failed
                                    ? { color: "red" }
                                    : { color: "green" }
                                }
                              >
                                {item.failed ? "Failed" : "Passed"}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default TapasEmsdCheckingResult;
