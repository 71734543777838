import React, { forwardRef } from "react";
import { Button, Box, Stack, ButtonGroup} from "@mui/material";
import TextInputRevoke from "./TextInputRevoke";
import revocation_letter_templates from "./pdf/revocation_letter_templates.json";


export const RevokeEditInput = forwardRef((props, ref) => {
  const {
    appendix: currentContentsItem,
    setAppendix,
    section,
    setText,
  } = props;

  const targetRCRd = currentContentsItem?.rc_id;


  const handleText = (value, i, type, textIndex, subSectionIndex) => {
    const updatedItem = structuredClone(currentContentsItem?.revocation_letter[type]);

    updatedItem[subSectionIndex] = value;

    setAppendix((prevAppendix) => {
      const updatedAppendix = [...prevAppendix];
      updatedAppendix.map((key) => {
        if (key?.rc_id === targetRCRd) {
          return (key.revocation_letter[type] = updatedItem);
        }
      });

      return updatedAppendix;
    });

    ref.current = { index: i, subSectionIndex, type, textIndex };
  };



  const deleteSection = (i, type) => {
    setAppendix(prevAppendix => {
      return prevAppendix.map(item => {
        if (item.rc_id === targetRCRd) {
          // Deep clone to avoid reference issues
          // updatedItem can be used to manipulate or modify the copied object without affecting the original item object.
          const updatedItem = JSON.parse(JSON.stringify(item));
          // Check if section exists before filtering
          if (updatedItem.revocation_letter && updatedItem.revocation_letter[type]) {
            // Filter out deleted section
            updatedItem.revocation_letter[type] = updatedItem.revocation_letter[type].filter((_, index) => index !== i);
          } else {
            // Reset deleted section to empty array
            updatedItem.revocation_letter[type] = [];
          }
          return updatedItem;
        }
        return item;
      })
    });
  }


  const handleFocus = (event) => {
    event.preventDefault();
    const { target } = event;
    target.setSelectionRange(ref.current.textIndex, ref.current.textIndex);
  };


  const handleLoadTemplate1 = () => {
    const template = "template1";
    const template_content = revocation_letter_templates[template];
 
    let updatedContents = { ...currentContentsItem };

    ["heading", "body"].forEach((key) => {
      // only add the template if there's no content. Else, it will override the data
      if (updatedContents.revocation_letter[key]?.length === 0) {
        updatedContents.revocation_letter[key] = template_content[key];
      }
    });
    setAppendix((prevContents) => {
      const updatedAppendix = [...prevContents];
      updatedAppendix.map((key) => {
        if (key?.rc_id === targetRCRd) {
          return (key.revocation_letter = updatedContents.revocation_letter);
        }
      });
      return updatedAppendix;
    });
  };


  const handleLoadTemplate2 = () => {
    const template = "template2";
    const template_content = revocation_letter_templates[template];

    let updatedContents = { ...currentContentsItem };

    ["heading", "body"].forEach((key) => {
      // only add the template if there's no content. Else, it will override the data
      if (updatedContents.revocation_letter[key]?.length === 0) {
        updatedContents.revocation_letter[key] = template_content[key];
      }
    });
    setAppendix((prevContents) => {
      const updatedAppendix = [...prevContents];
      updatedAppendix.map((key) => {
        if (key?.rc_id === targetRCRd) {
          return (key.revocation_letter = updatedContents.revocation_letter);
        }
      });
      return updatedAppendix;
    });
  };


  return (
    <Box sx={{ px: 1 }}>
      <Box sx={{ textAlign: "right" }}>
        <ButtonGroup
          sx={{ mt: 1 }}
          variant="contained"
          color="primary"
          aria-label="outlined primary button group"
        >
          <Button
            onClick={() => {
              // const newItem = section === "table" ? { field: "", value: "" } : "" ;
              const newItem = "" ;

              setAppendix((prevAppendix) => {
                const updatedAppendix = prevAppendix.map((item) => {
                  if (item?.rc_id === targetRCRd) {
                    const updatedSection = [
                      ...(item?.revocation_letter[section] || []),
                      newItem,
                    ];
                    const updatedRevocationLetter = {
                      ...item?.revocation_letter,
                      [section]: updatedSection,
                    };
                    const updatedItem = {
                      ...item,
                      revocation_letter: updatedRevocationLetter,
                    };

                    return updatedItem;
                  }
                  return item;
                });
                return updatedAppendix;
              });
            }}
          >
            Add New Section
          </Button>

          <Button onClick={() => handleLoadTemplate1()}>Load Manufacturer Template</Button>
          <Button onClick={() => handleLoadTemplate2()}>Load Models/SC Template</Button>
        </ButtonGroup>
      </Box>

      {<Stack
        spacing={2}
        direction="column"
        sx={{
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
        >
          <TextInputRevoke
            ref={ref}
            appendix={currentContentsItem}
            paragraph={section}
            handleText={handleText}
            deleteSection={deleteSection}
            handleFocus={handleFocus}
            setText={setText}
          />
        </Stack>
      }
    </Box>
  );
});
