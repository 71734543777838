// in src/users.js
import * as React from "react";
import {
  Datagrid,
  List,
  BooleanInput,
  ReferenceField,
  EmailField,
  TextField,
  Create,
  useRecordContext,
  FormDataConsumer,
  Show,
  SimpleShowLayout,
  SaveButton,
  ShowButton,
  Edit,
  EditButton,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useRefresh,
  useUnselectAll,
  WithRecord,
  Toolbar,
  FunctionField,
} from "react-admin";
import DeleteUserWithConfirmButton from "../buttons/DeleteUserWithConfirmButton";
import usersExporter from "./exporters/usersExporter";
import { useTranslation } from 'react-i18next';


export const UserList = (props) => {
  const { t : itranslate } = useTranslation();

  const { userInfo } = props;
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("users");
  const UserBulkActionButtons = (props) => (
    <DeleteUserWithConfirmButton
      {...props}
      userInfo={userInfo}
      customDeleteLogic={async (selectedIds) => {
        try {
          await dataProvider.updateMany("users", {
            ids: selectedIds,
            data: { active: false },
          });
          unselectAll();
          refresh();
        } catch (error) {
          console.log(error);
        }
      }}
    />
  );

  return (
    <List 
      // title={`- Users`} 
      title={`- ${itranslate('appBar.Users')}`}
      exporter={usersExporter}
    >
      <Datagrid bulkActionButtons={<UserBulkActionButtons />}>
        <TextField 
          // label="Username"
          label={itranslate('users.Username')}
          source="username" 
        />

        <EmailField 
          // label="Email"
          label={itranslate('users.Email')}
          source="email" 
        />

        <ReferenceField
          // label="RC"
          label={itranslate('users.RC')}
          source="permissions_data.rc_id"
          reference="rcs"
          link={false}
        >
          <FunctionField
            render={(record) => {
              let rcDisplay = "";
              rcDisplay = record?.rc_number_lift;
              if (record?.rc_number_escalator)
                rcDisplay += " / " + record?.rc_number_escalator;
              return rcDisplay;
            }}
          />
        </ReferenceField>

        {/* <TextField 
          // label="Rank" 
          label={itranslate('users.Rank')}
          source="role"
        /> */}

        <FunctionField
            label={itranslate('users.Rank')}
            render={(record) =>{
              return (
                <>
                  {/* <button onClick={() => console.log(record)}>click</button> */}
                  {/* {record.role} */}
                  {itranslate(`users.${record.role}`)}
                </>
            )}}
          />

        {((window.location.pathname === "/lift_rc" &&
          userInfo?.role === "RCAdmin") ||
          userInfo?.role === "TapasAdmin") && <EditButton sx={{ padding: '0' }} />
        }

        <ShowButton sx={{ padding: '0' }}/>

      </Datagrid>
    </List>
  );
};


export const UserShow = () => {
  const { t : itranslate } = useTranslation();
  return (
    <Show title={<UserTitle />}>
    <SimpleShowLayout>
      <TextField 
        // label="Username" 
        label={itranslate('users.Username')}
        source="username" 
      />
      <TextField 
        // label="Email" 
        label={itranslate('users.Email')}
        source="email" 
      />
      <TextField 
        // label="Rank" 
        label={itranslate('users.Rank')}
        source="role" 
      />
    </SimpleShowLayout>
  </Show>
  )
}


const UserTitle = () => {
  const { t : itranslate } = useTranslation();
  const record = useRecordContext();
  return <span> {itranslate('users.User')} {record ? `"${record.email}"` : ""}</span>;
};


export const UserEdit = () => {
  const { t : itranslate } = useTranslation();
  // omit the delete button by using custom toolbar
  const EditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit title={<UserTitle />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <WithRecord
          render={(record) => {
            return (
              record.role === "RCUser" && (
                <BooleanInput
                  source="permissions_data.editDraft"
                  label="Allow edit other user's drafts"
                />
              )
            );
          }}
        />
        <WithRecord
          render={(record) => {
            return (
              ["EMSDCE", "EMSDSE", "EMSDEngineer", "EMSDGR"].includes(
                record.role
              ) && (
                <BooleanInput
                  source="permissions_data.allowManageRecords"
                  // label="Allow record management"
                  label={itranslate('users.Allow record management')}
                />
              )
            );
          }}
        />
      </SimpleForm>
    </Edit>
  );
};


export const UserCreate = (props) => {
  const { t : itranslate } = useTranslation();
  return (
    <Create {...props} resource="users" title={`${itranslate('users.Create user')}` }>
    <SimpleForm>
      <TextInput 
        // label="Username" 
        label={itranslate('users.Username')}
        source="username" 
      />
      <TextInput 
        // label="Email" 
        label={itranslate('users.Email')}
        source="email" 
      />
      <SelectInput
        source="role"
        // label="Rank"
        label={itranslate('users.Rank')}
        defaultValue={
          window.location.pathname === "/lift_rc" ? "RCUser" : "EMSDEngineer"
        }
        choices={
          window.location.pathname !== "/lift_rc"
            ? [
                { id: "EMSDEngineer", name: itranslate('users.Engineer') },
                { id: "EMSDGR", name: itranslate('users.GR') },
                { id: "EMSDSE", name: itranslate('users.Senior Engineer') },
                { id: "EMSDCE", name: itranslate('users.Chief Engineer') },
                { id: "RCAdmin", name: itranslate('users.RC admin') },
                { id: "Inspector", name: itranslate('users.Inspector') },
              ]
            : [{ id: "RCUser", name: itranslate('users.RC') }]
        }
      />
      {window.location.pathname !== "/lift_rc" && (
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.role === "RCAdmin" || formData.role === "RCUser") && (
              <ReferenceInput
                // label="RCs"
                label={itranslate('users.RCs')}
                source="rc_id"
                reference="rcs"
                perPage={100}
              >
                <SelectInput {...rest} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
      )}
    </SimpleForm>
  </Create>
  )
}
