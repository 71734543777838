import {
  Edit,
  Show,
  SimpleShowLayout,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  Create,
  useRecordContext,
  ShowButton,
  useShowController,
  ArrayField,
  SimpleForm,
  SingleFieldList,
  SelectInput,
  FunctionField
} from "react-admin";
import React, { useEffect, useState } from "react";
import { ModelDisplay } from "../components/model/ModelDisplay";
import ModelFilter from "./filters/ModelFilter";
import modelsExporter from "./exporters/modelsExporter";
import keyToField from "../utils/keyToField.js";
import isGetOneData from "../utils/applications/isGetOneData";
import * as static_fields from "../utils/static_fields.json";
import PreviewSc from "../components/model/PreviewSc";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { useTranslation } from 'react-i18next';


export const ModelShow = () => {
  const { record } = useShowController();

  const [update, setUpdate] = useState(null);

  useEffect(() => {
    if (record && isGetOneData(record, "models"))
      setUpdate({ model: [record] });
  }, [record]);

  return (
    <Show title={<ModelTitle />}>
      <SimpleShowLayout>
        {update && (
          <ModelDisplay
            show={true}
            showOne={true}
            edit={false}
            add={false}
            title={""}
            existing={[]}
            upload={update}
            setUpload={setUpdate}
            template={static_fields}
          />
        )}
      </SimpleShowLayout>
    </Show>
  );
};


export const ModelList = () => {
  const { t : itranslate } = useTranslation();
  return (
    <List
    filters=<ModelFilter />
    exporter={modelsExporter}
    // title={`- Models`}
    title={`- ${itranslate('appBar.Models')}`}
    sort={{ field: "approval_date", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false} isRowSelectable={(x) => false}>
      <TextField label="Model Code" source="model_code" />
      <TextField label="Model Name" source="provided_model_name" />

      {/* <ArrayField
        label="Manufacturer"
        source="manufacturer_files"
        sortBy="manufacturersPopulated.provided_name"
      >
        <SingleFieldList linkType={false}>
          <ReferenceField
            source="manufacturer_id"
            reference="manufacturers"
            link="show"
          >
            <TextField source="provided_name" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField> */}

      <FunctionField
        label="Manufacturer"
        render={(record) => (
          <div>
            {record && record?.manu_array?.map((manu) => {
              if (manu.active === true) {
                return (
                  <Box key={manu._id}>
                    <Link className="no-underline text-[#2a598f]" to={`/manufacturers/${manu._id}/show`}>
                      {manu.provided_name}
                    </Link>
                  </Box>
                )
              }
            })}
          </div>
        )}
      />

      <FunctionField
        label="Type"
        source="type"
        render={(record) => keyToField[record.type]}
      />
      {window.location.pathname === `/lift_emsd` && (
        <ReferenceField label="RC" source="rc_id" reference="rcs" link="show">
          <FunctionField
            render={(record) => {
              let rcDisplay = "";
              rcDisplay = record?.rc_number_lift;
              if (record?.rc_number_escalator)
                rcDisplay += " / " + record?.rc_number_escalator;
              if (record?.address?.company)
                rcDisplay += " / " + record?.address?.company;
              return rcDisplay || record.name;
            }}
          />
        </ReferenceField>
      )}
      {window.location.pathname === `/lift_emsd` && (
        <TextField label="Rated speed" source="rated_speed" />
      )}
      {window.location.pathname === `/lift_emsd` && (
        <TextField label="Rated load" source="rated_load" />
      )}
      <FunctionField
        render={(record) => <PreviewSc id={record.id} />}
        label="Preview"
      />
      <ShowButton sx={{ padding: '0' }} />
    </Datagrid>
  </List>
  )
}

const ModelTitle = () => {
  const record = useRecordContext();
  return <span>Model {record?.model_code}</span>;
};

export const ModelEdit = () => (
  <Edit title={<ModelTitle />}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>

      <TextInput source="title" />
      <TextInput multiline source="body" />
    </SimpleForm>
  </Edit>
);

export const ModelCreate = (props) => (
  <Create {...props} resource="models">
    <SimpleForm>
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="title" />
      <TextInput multiline source="body" />
    </SimpleForm>
  </Create>
);
