import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ResourceContextProvider,
  ReferenceField,
  DateField,
  EditButton,
} from "react-admin";
import { useTranslation } from 'react-i18next';

const RecordLogs = () => {
  const { t : itranslate } = useTranslation();
  return (
    <ResourceContextProvider value="records/list/logs">
    <List 
      // title="Record" 
      title={`- ${itranslate('appBar.Record')}`}
      empty={false} exporter={false} 
      disableSyncWithLocation>
      <Datagrid bulkActionButtons={false}>
        <TextField source="action" />
        <ReferenceField source="user_id" reference="users">
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField
          source="application_id"
          reference="apps"
          link={(record, reference) =>
            `/v2/applications/${record.application_id}/show`
          }
        >
          <TextField source="application_number" />
        </ReferenceField>

        <ReferenceField
          source="application_id_before_change"
          reference="records"
          label="Record before changes"
          link={(record, reference) =>
            `/v2/applications/${record.application_id_before_change}/show`
          }
        >
          <TextField source="application_number" />
        </ReferenceField>
        <TextField source="description" label="Description" />
        <DateField source="created_at" />
        <EditButton />
      </Datagrid>
    </List>
  </ResourceContextProvider>
  )
}



export default RecordLogs;
