import { useEffect, useState } from "react";
import {
  SelectInput,
  TextInput,
  ReferenceInput,
  Filter,
  DateInput,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import getToken from "../../utils/getAuthToken";

const ApplicationFilter = (props) => {
  const location = useLocation();
  let userInfo = useSelector((state) => state.userInfo.value); //team_role_id

  const [doubling_roles, setDoubling_roles] = useState([]);
  const [current_role_name, setCurrent_role_name] = useState("");
  const [concat_doubling_roles, setconcat_doubling_roles] = useState("");


  useEffect(() => {
    if (userInfo?.doubling_roles && userInfo?.doubling_roles.length > 0) {
      const getDoublingRoleNames = async () => {
        const res = await axios.post(
          `${process.env.REACT_APP_ENV_ENDPOINT}/lift/team/getDoublingRoleNames`,
          { doubling_roles: userInfo?.doubling_roles },
          { headers: { authorization: getToken() } }
        );
          setDoubling_roles(res.data.doubling_roles_with_role_names);
          setconcat_doubling_roles(res.data.concat_doubling_roles)
      };
      getDoublingRoleNames();
    }
  }, [userInfo]);

 
useEffect(() => {
    if (userInfo?.role === "EMSDGR") {
      setCurrent_role_name("GR");
    } else {
      const getCurrentRoleName = async () => {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_ENV_ENDPOINT}/lift/team/getCurrentRoleName`,
            { team: userInfo?.team, team_role_id: userInfo?.team_role_id },
            { headers: { authorization: getToken() } }
          );
          setCurrent_role_name(res.data.team_role_with_role_name);
        } catch (error) {
          // Handle the error here
          // console.error(
          //   "Error occurred while fetching current role name:",
          //   error.message
          // );

          // If no team role found, then set the appropriate current_role_name value
          if (
            userInfo?.doubling_roles?.length > 0 &&
            userInfo?.role === "EMSDEngineer"
          ) {
            setCurrent_role_name("Engineer");
          } else if (
            userInfo?.doubling_roles?.length > 0 &&
            userInfo?.role === "EMSDSE"
          ) {
            setCurrent_role_name("Senior Engineer");
          } else if (
            userInfo?.doubling_roles?.length > 0 &&
            userInfo?.role === "EMSDCE"
          ) {
            setCurrent_role_name("Chief Engineer");
          } 
        }
      };
      getCurrentRoleName();
    }
  }, [userInfo]);

  const emsdStatusFilterChoices = [
    {
      id: "Pending",
      name: "Pending",
    },
    {
      id: "Pending E",
      name: "Pending Engineer",
    },
    { id: "Pending SE", name: "Pending Senior Engineer" },
    { id: "Pending CE", name: "Pending Chief Engineer" },
    { id: "Clarifying", name: "Clarifying" },
    { id: "ReturningClarifying", name: "Returning Clarifying" },
    { id: "Withdrawn", name: "Withdrawn" },
  ];

  const rcStatusFilterChoices = [
    {
      id: "Pending",
      name: "Pending",
    },
    { id: "Approved", name: "Approved" },
    { id: "Clarifying", name: "Clarifying" },
    { id: "Withdrawn", name: "Withdrawn" },
  ];

  return (
    <Filter {...props}>
      <TextInput
        source="q"
        label="Search application no. / type of machine / models / status / RC"
        alwaysOn
        sx={{ width: "500px" }}
      />

      {/* adding "z" at the end of the 1st choice is to distinguish between "Show All Assigned" and other choices. Else React admin will treat them the same */}
      {window.location.pathname === "/lift_emsd" &&
        location.pathname === "/applications" && (
          <SelectInput
            alwaysOn
            label="Require Action"
            source="current_role_id"
            emptyText={"Show All"}
            choices={[
              {
                id: "#" + (userInfo.team_role_id || concat_doubling_roles ? `${userInfo.team_role_id + concat_doubling_roles}` : userInfo.user_id),
                name: "Show All Assigned"
              },
              {
                id: `${userInfo.team_role_id || userInfo.user_id}`,
                name: `${current_role_name}`
              },
              ...(doubling_roles.length > 0
                ? doubling_roles.map((role) => ({
                    id: role.team_role_id,
                    name: role.roleName
                  }))
                : [])
            ]}
          />
        )}


      <TextInput label="Application number" source="application_number" />
      <SelectInput
        label="Type of Application"
        source="update_type"
        // these choices comes from ApplicationEdit.js typeDeterminant()
        choices={[
          {
            id: "Brand New Application",
            name: "Brand New Application",
          },
          {
            id: "Manufacturer Name Change",
            name: "Manufacturer Name Change",
          },
          {
            id: "Update lift/escalator model certificate",
            name: "Update lift/escalator model certificate",
          },
          {
            id: "Lift/escalator model range extension",
            name: "Lift/escalator model range extension",
          },
          {
            id: "Update safety component certificate",
            name: "Update safety component certificate",
          },
          {
            id: "Safety component range extension",
            name: "Safety component range extension",
          },
          { id: "Add new safety component", name: "Add new safety component" },
          {
            id: "Add new standalone safety component",
            name: "Add new standalone safety component",
          },
        ]}
      />
      <SelectInput
        label="Type of Machine"
        source="app_type.type"
        choices={[
          {
            id: "ElectricTractionLiftMachineRoom",
            name: "Electric Traction Lift (Machine Room)",
          },
          {
            id: "ElectricTractionLiftRoomless",
            name: "Electric Traction Lift (Machine Roomless)",
          },
          { id: "InclinedLift", name: "Electric Inclined Lift" },
          { id: "ServiceLift", name: "Service Lift" },
          { id: "StairLift", name: "Stairlift" },
          { id: "HydraulicLift", name: "Hydraulic Lift" },
          {
            id: "ElectricalVerticalLiftingPlatform",
            name: "Vertical Lifting Platform",
          },
          { id: "MVPS", name: "Mechanized Vehicle Parking System (MVPS)" },
          { id: "Escalator", name: "Escalator" },
          { id: "PassengerConveyor", name: "Passenger Conveyor" },
          { id: "SafetyComponent", name: "Safety Component" },
          { id: "Others", name: "Others" },
        ]}
      />
      {/* If the location is /v2/applications, it only shows approved applications, so there is no point adding the filter */}
      {location.pathname === "/applications" && (
        <SelectInput
          label="Status"
          source="status"
          choices={
            window.location.pathname === "/lift_emsd"
              ? emsdStatusFilterChoices
              : rcStatusFilterChoices
          }
        />
      )}
      <TextInput label="Model" source="modelsPopulated.provided_model_name" />
      {window.location.pathname === "/lift_emsd" && (
        <ReferenceInput source="rc_id" label="RC" reference="rcs" perPage={100}>
          {/* <SelectInput optionText="name" /> */}
          <SelectInput
            optionText={(choice) => {
              let displayText = "";
              displayText = choice?.rc_number_lift;
              if (choice?.rc_number_escalator) {
                displayText += ` / ${choice.rc_number_escalator}`;
              }
              if (choice?.address?.company) {
                displayText += ` / ${choice.address.company}`;
              }
              return displayText;
            }}
          />
        </ReferenceInput>
      )}
      <DateInput label="Application Date" source="createdAt" />
    </Filter>
  );
};

export default ApplicationFilter;
