/* eslint-disable react/jsx-pascal-case */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { visuallyHidden } from '@mui/utils';
import FormControlLabel from '@mui/material/FormControlLabel';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import TablePagination from '@mui/material/TablePagination';


// mui accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// mui tree view
import * as React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';


// components
import EnhancedTableHead from "../CompEnhancedTable/ModelCompListTable/EnhancedTableHead.jsx";
import TableTitle from "../CompEnhancedTable/ModelCompListTable/TableTitle"
import BasicModalLarge from '../../../../_hoc/modals/BasicModalLarge.jsx';
import {headCells} from '../constants/headCells.js'

// useHooks
import { useSessionStorage } from "@uidotdev/usehooks";


// redux
import { useSelector, useDispatch } from "react-redux";
import { setStage } from "../../../../admin/redux/stageSlice.js";
import { ShowBtn } from '../../../../_hoc/Buttons/ShowBtn.jsx';
import { ShowFileBtn } from '../../../../_hoc/Buttons/ShowFileBtn.jsx';
import BasicModal from '../../../../_hoc/modals/BasicModal.jsx';

import { useTranslate } from 'react-admin';
const BackBtn = styled(Button)(({ theme }) => ({
    color: "#434343",
    backgroundColor: "white",
    '&:hover': {
        color: "white",
        backgroundColor: "#434343",
    },
    variant:"outlined"
}));


export default function CreateStage2ModelCompList({
	setNextBtnDisabled, nextBtnDisabled,
	dataModel, setModel_manu_sc_id_arr, model_manu_sc_id_arr, setCertsChosen, certsChosen,
	handleGetPDFFile,
}){
	const translate = useTranslate();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
	 // redux 
	const dispatch = useDispatch();
	const stageStore = useSelector((state) => state.stage.value);
	const stage = structuredClone(stageStore);
	const optionStore = useSelector((state) => state.option.value);
	const option = structuredClone(optionStore);

	 // init the selected tec and iso in stage 2_2, model manu stage
	 const [tecChosen] = useState(certsChosen.tec)
	 const [isoChosen] = useState(certsChosen.iso)
	const [modelsSection, setModelsSection] = useState([]);
	const [modelSec, setModelSec] = useState(null);
	const [paginatedData, setPaginatedData] = useState([]);
	const [scs, setScs] = useState([]);
	const [tecs, setTecs] = useState([]);

	// Section_CompList
	const [tableHeadCells, setTableHeadCells] = useState([])

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('client_user');
    const [selected, setSelected] = useState([]);
    const [selectedTec, setSelectedTec] = useState([])

    const [isLoading, setIsLoading] = useState(true);
    const [openLoadingModal, setOpenLoadingModal] = useState(true)
	const [openBackOptionsModal, setOpenBackOptionsModal] = useState(false);
	const [openLargeModalModelSec, setOpenLargeModalModelSec] = useState(false);

    useEffect(() => {
        if(option === "models"){
            if(stage === "2_3"){
                setTableHeadCells(headCells.components)
				setSelected([])
				setSelectedTec([])
            }
        }
    }, [stage])

    // setSort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // initialize data
    useEffect(() => {
        if(option === "models"){
            if(stage === "2_3"){
				let selectedModels = dataModel
				setModelsSection(selectedModels)
				setPaginatedData(selectedModels.slice(0, 5))
				
				setScs(selectedModels.flatMap(m => Object.keys(m?.componentsPopulated).flatMap(key => m?.componentsPopulated[key]?.map(v => {return `${v._id}-${m._id}`} ))))
				let allTecs = selectedModels.flatMap(m => Object.keys(m?.componentsPopulated).flatMap(key => m?.componentsPopulated[key]?.flatMap(v => v.type_test_certificate_id).map(v => `${v}-${m._id}`) ))
				setTecs(allTecs)

            }
        }
    }, [])


    useEffect(() => {
        if(isLoading === false) {
            setOpenLoadingModal(false)
        } else {
            setOpenLoadingModal(true)
        }

		if(modelsSection.length > 0){
			setIsLoading(false)
		}

    }, [modelsSection, isLoading])

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		setPaginatedData(modelsSection.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage));
	  };
	
	  const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setPaginatedData(modelsSection.slice(0, parseInt(event.target.value, 10)));
	  };

    // seletAll rows
    const handleSelectAllClick = (event, modelId) => {
        if (event.target.checked) {
            const newSelected = scs.map((n) => {
				if(n.split("-")[1] === modelId){
					return n
				} else {
					if(isSelected(n)){
						return n
					} else {
						return null
					}
				}
			});
            setSelected(newSelected.filter(v => v !== null));
            return;
        }
        setSelected(selected.filter(n => {
			return n.split("-")[1] !== modelId
		}));
    };

    // selectAll docs
    const handleSelectAllCertsClick = (event, modelId) => {
        if (event.target.checked) {
            const newSelected = tecs.flatMap((n) => n.split("-")[1] === modelId ? n : isTecOptionSelectedFn(n) ? n : null);
            setSelectedTec(newSelected.filter(v => v !== null));
            return;
        }
		setSelectedTec(selectedTec.filter(n => {
			return n.split("-")[1] !== modelId
		}));
    }

    // select one
    const handleClick = (event, _id) => {
        const selectedIndex = selected.indexOf(_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleDocOptionClick = (event, cert_type, cert_id) => {
        if(cert_type === "tec"){
            const selectedIndex = selectedTec.indexOf(cert_id);
            let newSelected = [];
    
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedTec, cert_id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedTec.slice(1));
            } else if (selectedIndex === selectedTec?.length - 1) {
                newSelected = newSelected.concat(selectedTec.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedTec.slice(0, selectedIndex),
                    selectedTec.slice(selectedIndex + 1),
                );
            }
            setSelectedTec(newSelected);
        }
    };

    const isSelected = (id) => selected.indexOf(id) !== -1 

    const isTecOptionSelectedFn = (id) => selectedTec.indexOf(id) !== -1 


    // row btn handlers
    const handleView = (rowInfo) => {
        window.open(`lift_rc#/components/${rowInfo._id}/show`, '_blank');
    }

	// init
	useEffect(() => {
		// stage 2_2 chosen iso and tec remain unchanged. 
		setCertsChosen({
			tec: tecChosen,
			iso: isoChosen
		})
	}, [])

	function handleSelectAllFoundItemsInAllPages(){
		if(selected.length > 0 || selectedTec.length > 0){
			setSelected([])
			setSelectedTec([])
		} else {
			setSelected(scs)
			setSelectedTec(tecs)
		}
    }

	const handleNext = (obj) => {
        obj.e.preventDefault()
		setModel_manu_sc_id_arr(model_manu_sc_id_arr.map(v => ({...v, relatedScChosen: selected.filter(scIdModelCode => scIdModelCode?.split("-")[1] === v.model).map(scIdModelCode => scIdModelCode?.split("-")[0])})))
		setCertsChosen({
			...certsChosen,
			tec: tecChosen?.concat(selectedTec?.map(v => v?.split("-")[0]))
		})
		if(option === 'models'){
            if(stage === '2_3'){
                dispatch(setStage("more options?"))
            }
        }
    }

	return (
		// eslint-disable-next-line react/jsx-pascal-case
        <Container component="main" maxWidth="xl" sx={{ mb:4 }}>
			{
				isLoading && (
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={openLoadingModal}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				)
			}
			<Box 
                component={"div"}
                sx={{
                    height: "5em",
                    display: "flex",
                    justifyContent: "space-between",
                }}
                aria-label="menu for the table"
            >
				{/* BackBtn */}
				<Box
                    component={"div"}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                    aria-label="Back button and Help Icon"
                >
                    <BackBtn 
                        onClick={() => setOpenBackOptionsModal(true)}
                        size="small"
                        sx={{
                            mb: 6,
                            border: "1px solid #434343",
                        }}
                    >
						{translate("button.back")}
                    </BackBtn>
                </Box>

				{
					(selected.length <= 0 && selectedTec.length <= 0) && (
						<Button
							onClick={handleSelectAllFoundItemsInAllPages}
							aria-label='select all found items'
							variant="contained"
							color="primary"
							size="small"
							sx={{
								float: "right",
								mb: 6
							}}
						>
								{translate("phase2.createRu.selectAllFoundItemsInAllPages")}
						</Button>
					)
				}

				{
					!(selected.length <= 0 && selectedTec.length <= 0) && (
						<Button
							onClick={handleSelectAllFoundItemsInAllPages}
							aria-label='select all found items'
							variant="outlined"
							color="primary"
							size="small"
							sx={{
								float: "right",
								mb: 6
							}}
						>
							{translate("phase2.createRu.deselect")}
						</Button>
					)
				}
			</Box>

			{
				paginatedData.length > 0 && paginatedData.map((m, i) => {
					return (

			
							<Box key={i}
								sx={{ 
									width: '100%',
									boxShadow: "0 0 0 2rem rgba(246,248,249)",
								}}
							>
								<Paper sx={{ width: '100%', mb: 2}}>
									<Accordion expanded={m === modelSec ? true : false} 
										sx={
											m === modelSec ? { bgcolor: "#D1D8C5" } : {}
										}
										onChange={(event, expanded) => {
											if(expanded){
												setModelSec(m)
												setOpenLargeModalModelSec(true)
											}
										}}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
										}}
									>
										<AccordionSummary
											aria-controls="panel1-content"
											id="panel1-header"
											sx={{
												'& .MuiAccordionSummary-content': {
												},
											}}
										>

											<TableTitle data={m} className="w-full"/>
											<div className='flex justify-end w-full'>
												{
													Object.keys(m?.componentsPopulated).length <= 0 ? (
														<div className='p-3 '>
															{translate("phase2.createRu.noComponent")}
														</div>
													): <div className='p-3'>
															{translate("phase2.createRu.clickToSelectComponents")}
														</div>
												}

											</div>
											
										</AccordionSummary>


									</Accordion>
				
								</Paper>
							</Box>
			

					)
				})
			}

			<TablePagination
				rowsPerPageOptions={[5, 10]}
				component="div"
				count={modelsSection.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
            <Button
                type="submit"
                sx={{
                    // center the button
                    display: 'flex',
                    m: "auto",
                    width: "95%",
                }}
                variant="contained"
                color="primary"
                onClick={(e) => handleNext({ e })}
                aria-label="submit"
                disabled={nextBtnDisabled}
            >
				{translate("phase2.createRu.next")}
            </Button>

			{/* model's components */}
			<BasicModalLarge open={openLargeModalModelSec} setOpen={setOpenLargeModalModelSec} setOpen2={setModelSec}>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby="tableTitle"
						size='medium'
					>

						{
							!isLoading && modelSec &&
							(
								<>
									<EnhancedTableHead
										numSelected={selected.filter(v => v.split("-")[1] === modelSec._id).length}
										order={order}
										orderBy={orderBy}
										onSelectAllClick={handleSelectAllClick}
										onRequestSort={handleRequestSort}
										rowCount={scs.filter(v => v.split("-")[1] === modelSec._id).length}
										tableHeadCells={tableHeadCells}
										tecTotalCount={tecs.filter(v => v.split("-")[1] === modelSec._id).length}
										numCertsSelected={selectedTec.filter(v => v.split("-")[1] === modelSec._id).length}
										onSelectAllCertsClick={handleSelectAllCertsClick}
										modelSec={modelSec}
									/>
									<TableBody>
										{ 
											Object.keys(modelSec?.componentsPopulated)?.length > 0 && Object.keys(modelSec?.componentsPopulated)?.map((key, index) => {
												return modelSec?.componentsPopulated[key].map((row, i) => {
													const isItemSelected = isSelected(`${row?._id}-${modelSec?._id}`);

													const labelId = `enhanced-table-checkbox-${index}`;
													
													let tecIds = row.type_test_certificate_id
													
													return (
														<TableRow
															key={row._id}
															role="checkbox"
															aria-checked={isItemSelected}
															tabIndex={-1}
															selected={isItemSelected}
														>
															<TableCell padding="checkbox">
																<Checkbox
																	color="primary"
																	checked={isItemSelected}
																	inputProps={{
																		'aria-labelledby': labelId,
																	}}
																	onClick={(event) => handleClick(event, `${row._id}-${modelSec._id}`)}
																	sx={{ cursor: 'pointer' }}
																	aria-checked={isItemSelected}
																/>
																<Box component="span" id={labelId} sx={visuallyHidden}>
																	{isItemSelected ? "selected" : "not yet selected"} 
																</Box>
															</TableCell>
															<TableCell
																component="th"
																id={labelId}
																scope="row"
																onClick={(event) => handleClick(event, `${row._id}-${modelSec._id}`)}
																sx={{ cursor: 'pointer' }}
															>
																{row.component_code}
															</TableCell>

															<TableCell
																sx={{ 
																	cursor: 'pointer', 
																	whiteSpace: "nowrap",
																	textOverflow: "ellipsis",
																	overflow: "hidden",
																	maxWidth: "8em"
																}}
																onClick={(event) => handleClick(event, `${row._id}-${modelSec._id}`)}
															>{row.provided_comp_name}</TableCell>

															<TableCell
																sx={{ cursor: 'pointer' }}
																onClick={(event) => handleClick(event, `${row._id}-${modelSec._id}`)}
															>
																{
																	row?.type
																}
															</TableCell>

															<TableCell>
																<TreeView
																	sx={{
																		flexGrow: 1,
																		overflowY: 'auto',
																	}}
																	aria-label="file system navigator"
																	defaultCollapseIcon={<ExpandMoreIcon />}
																	defaultExpandIcon={<ChevronRightIcon />}
																>
																	<TreeItem nodeId="1" label="TEC">
																		<ol>
																			{
																				!isEmpty(tecIds) && tecIds.length > 0 && tecIds.map((tecId, i) => {
																					const isTecOptionSelected = isTecOptionSelectedFn(`${tecId}-${modelSec._id}`);

																					return (
																						<li
																							key={`${i}-${tecId}`}
																						>
																							<FormControlLabel
																								label="tec"
																								control={
																									<Checkbox
																										color="primary"
																										checked={isTecOptionSelected}
																										inputProps={{
																											'aria-labelledby': labelId,
																										}}
																										onClick={(event) => handleDocOptionClick(event, 'tec', `${tecId}-${modelSec._id}`)}
																										sx={{ cursor: 'pointer' }}
																										aria-checked={isTecOptionSelected}
																									/>
																								}
																							>
																								<Box component="span" id={labelId} sx={visuallyHidden}>
																									{/* {isDocOptionSelectedFn ? "selected" : "not yet selected"} */}
																								</Box>
																							</FormControlLabel>
																							<Box component="span" id={labelId}>
																								{
																									modelSec?.compTecsPopulated?.filter(v => {
																										return v._id === tecId})[0]?.cert_type_metadata?.tec_certificate_number || "Not Available"
																								}
																							</Box>
																							<ShowFileBtn 
																								handleGetPDFFile={handleGetPDFFile}
																								id={tecId}
																							/>
																						</li>
																					) 
																				})
																			}
																		</ol>
																	</TreeItem>
																</TreeView>
																
															</TableCell>

															<TableCell
																size="small"
															>
																<ShowBtn
																	handleView={handleView}
																	row={row}
																	code={row?.component_code}
																	size='small'
																/>
															</TableCell>
														</TableRow>
													);
												})

											})
										}
									</TableBody>
								</>
							)
						}
					</Table>
				</TableContainer>
			</BasicModalLarge>


            {/* Back option modal */}
            <BasicModal open={openBackOptionsModal} setOpen={setOpenBackOptionsModal}>
                <Box className='flex flex-col gap-3'
                    component={"div"}
                >
                    {`Go back ( Changes will be discard )`}
                    <Button
                        onClick={() => {
                            if(!(selected.length <= 0 || selected.every(v => v === undefined ||  v === null || v === ""))){
								setSelected([])
								setSelectedTec([])
                            }
                            dispatch(setStage("2_2"))
                        }}
                    >
                        Confirm
                    </Button>
                </Box>
            </BasicModal>
		</Container>
	)
}