import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Button, TextField } from "@mui/material";

const ComponentNameInput = (props) => {
  const {
    edit,
    mode,
    name,
    componentTypeIndex,
    component_id,
    clickedModel,
    upload,
    options,
    setOptions,
    select,
    setSelect,
    inputValue,
    setInputValue,
    handleChange,
  } = props;
  const filter = createFilterOptions({
    stringify: (option) =>
      option.component_code
        ? option.provided_comp_name + option.component_code
        : option.provided_comp_name,
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "15px",
        alignItems: "center",
      }}
    >
      <Autocomplete
        value={select}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionDisabled={(option) => option.inputValue === ""}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setSelect({
              provided_comp_name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setSelect({
              provided_comp_name: newValue.inputValue,
            });
            setOptions([
              ...options,
              {
                provided_comp_name: newValue.inputValue,
                category: "New",
                type: name,
              },
            ]);
          } else {
            setSelect(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params).filter((comp) => {
            if (comp.category !== "Old") {
              return true;
            }

            const previousComps = Object.values(
              upload?.app_type?.components || {}
            )
              .flat()
              .map((comp) => comp.component_code);

            if (edit.mode === "f") {
              //exclude previously approved component in update Add new sc to LE model
              return (
                !previousComps.includes(comp.component_code) &&
                comp.type === name
              );
            }

            return comp.type === name;
          });
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.provided_comp_name && option.type === name
          );
          if (!isExisting) {
            filtered.push({
              inputValue,
              provided_comp_name:
                inputValue === ""
                  ? "Add component by typing here"
                  : `Add "${inputValue}"`,
              category: "New",
            });
          }

          return filtered;
        }}
        groupBy={(option) => option.category}
        selectOnFocus
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.provided_comp_name;
        }}
        renderOption={(props, option) => (
          <li
            {...props}
            key={`${
              option.component_code
                ? option.component_code
                : option.id
                ? option.id
                : "New"
            }: ${option.provided_comp_name}`}
          >
            {option.component_code
              ? `${option.component_code}: ${option.provided_comp_name}`
              : option.provided_comp_name}
          </li>
        )}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Input Component name here" />
        )}
      />
      <Button
        disabled={select === null}
        size="small"
        sx={{ ml: 1, height: "48px", mt: "6px" }}
        variant="outlined"
        onClick={() => {
          if (mode === "edit") {
            handleChange("editName", name, component_id, select);
          } else {
            handleChange(
              "addComponentLoad",
              name,
              componentTypeIndex,
              select,
              "",
              clickedModel?._id ?? clickedModel?.provided_model_name
            );
          }
          setSelect(null);
        }}
      >
        {mode === "edit" ? "Edit name" : "Add Component"}
      </Button>
    </div>
  );
};

export default ComponentNameInput;
