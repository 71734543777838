import {useState, useEffect} from 'react';

// components
import CreateStage1 from "../../../_components/CompRestrictedUrlMgt/CreateStage1/index"
import CreateStage2 from "../../../_components/CompRestrictedUrlMgt/CreateStage2/index"
import CreateStage3 from "../../../_components/CompRestrictedUrlMgt/CreateStage3/RemarkForm"
import CompConfirmOptions from '../../../_components/CompRestrictedUrlMgt/ConfirmOptionsStage/index';

// hooks
import { useNavigate, useLocation } from 'react-router-dom';
import { useSessionStorage } from "@uidotdev/usehooks";

// redux 
import {setStage} from "../../redux/stageSlice";
import { setOption } from '../../redux/optionSlice';
import { useDispatch, useSelector } from "react-redux";
import {setRuPageData} from "../../redux/ruCreate/ruPageDataSlice";

import BasicModal from '../../../_hoc/modals/BasicModal';

const PageCreateRestrictedUrl = () => {
    const [ openAlertModal, setOpenAlertModal]= useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();

    // redux 
    const stageStore = useSelector((state) => state.stage.value);
    const stage = structuredClone(stageStore);
    const optionStore = useSelector((state) => state.option.value);
    const option = structuredClone(optionStore);


    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)


    // for ru creation
    const [model_manu_sc_id_arr, setModel_manu_sc_id_arr] = useState([
        /*
        documenting the structure
        {
            model: "",
            relatedManufacturersChosen: [],
            relatedScChosen: [],
        }
        */
    ]);
    const [certsChosen, setCertsChosen] = useState({
        // tec: []
        // iso: [],
    });
    
    // for listing data from getList api
    const [dataModel, setDataModel] = useState([])
    const [dataComponents, setDataComponents] = useState([])
    
    const [sc_id_arr, setSc_id_arr] = useState([]);
    const [certsChosenForScPerspective, setCertsChosenForScPerspective] = useState({
        // tec: []
        // iso: []
    })

    // duplicate feature
    useEffect(() => {
        if(location?.state?.stage === 'more options?' && location?.state?.duplicatedData){
            dispatch(setStage('more options?'))
            dispatch(setOption('models'))
            setModel_manu_sc_id_arr(location.state.duplicatedData.model_manu_sc_id_arr)
            setSc_id_arr(location.state.duplicatedData.sc_id_arr)
            setCertsChosen(location.state.duplicatedData.certsChosenForModelPerspective)
            setCertsChosenForScPerspective(location.state.duplicatedData.certsChosenForScPerspective)
        }
        if(location?.state?.stage === "1"){
            dispatch(setStage("1"))
            setModel_manu_sc_id_arr([])
            setSc_id_arr([])
            setCertsChosen({})
            setCertsChosenForScPerspective({})
        }
    }, [location?.state?.duplicatedData, location?.state?.stage])

    useEffect(() => {
        // console.log("model_manu_sc_id_arr: ", model_manu_sc_id_arr.length)
        // console.log("certsChosen.tec & iso:", certsChosen?.tec?.length, certsChosen?.iso?.length)
        // console.log("sc_id_arr:", sc_id_arr.length)
        // console.log("certsChosenForScPerspective:", certsChosenForScPerspective?.tec?.length)
        // console.log("🚀 ~ useEffect ~ certsChosenForScPerspective?.iso?.length:", certsChosenForScPerspective?.iso?.length)
        dispatch(setRuPageData({model_manu_sc_id_arr, certsChosen, sc_id_arr, certsChosenForScPerspective}))
    }, [model_manu_sc_id_arr, sc_id_arr, certsChosen, certsChosenForScPerspective])

    
    useEffect(() => {
        // it auto-select models every time the stage in this component changes
        if(stage === "1"){
            dispatch(setOption("models"))
        }
        if(option !== "models" && stage !== "2_3"){
            setNextBtnDisabled(true)
        }
    }, [dispatch, stage])

    const handleNext = (obj) => {
        obj.e.preventDefault()
        if(stage === '1'){
            dispatch(setStage("2_1"))
        }
        if(option === "components"){
            if(stage === "2_1"){
                dispatch(setStage("more options?"))
            }
        }
    }

    return (
        <>
            {stage === "1" && (
                <CreateStage1 
                    handleNext={handleNext}
                />
            )}

            {(stage.startsWith("2")) && (
                <CreateStage2 
                    option={option} stage={stage} setStage={setStage} setOption={setOption}
                    //selected models , manufacturers and components
                    setModel_manu_sc_id_arr={setModel_manu_sc_id_arr}
                    model_manu_sc_id_arr={model_manu_sc_id_arr}
                    setSc_id_arr={setSc_id_arr}
                    sc_id_arr={sc_id_arr}
                    // selected certs
                    setCertsChosen={setCertsChosen}
                    certsChosen={certsChosen}
                    setCertsChosenForScPerspective={setCertsChosenForScPerspective}
                    certsChosenForScPerspective={certsChosenForScPerspective}
                    //btn
                    handleNext={handleNext}
                    nextBtnDisabled={nextBtnDisabled}
                    setNextBtnDisabled={setNextBtnDisabled}
                    //fetched data
                    setDataModel={setDataModel}
                    setDataComponents={setDataComponents}
                    dataModel={dataModel}
                    dataComponents={dataComponents}
                />
            )}

            {stage === "more options?" && (
                <CompConfirmOptions setStage={setStage} setOption={setOption}
                    // selected result
                    model_manu_sc_id_arr={model_manu_sc_id_arr}
                    sc_id_arr={sc_id_arr}
                    certsChosen={certsChosen}
                    certsChosenForScPerspective={certsChosenForScPerspective}
                    // set selected result
                    setModel_manu_sc_id_arr={setModel_manu_sc_id_arr}
                    setSc_id_arr={setSc_id_arr}
                    setCertsChosen={setCertsChosen}
                    setCertsChosenForScPerspective={setCertsChosenForScPerspective}
                    // fetched data
                    dataModel={dataModel}
                    dataComponents={dataComponents}
                />
            )}

            {stage === "3" && (
                <CreateStage3 
                    setModel_manu_sc_id_arr={setModel_manu_sc_id_arr}
                    setSc_id_arr={setSc_id_arr}
                    setCertsChosen={setCertsChosen}
                    setCertsChosenForScPerspective={setCertsChosenForScPerspective}
                />
            )}

            {openAlertModal && (
                <BasicModal
                    open={openAlertModal}
                    setOpen={setOpenAlertModal}
                    title="Alert"
                >
                    only RC can create restricted urls
                </BasicModal>
            )}
        </>
    )
}


export default PageCreateRestrictedUrl