import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import emsdLogo from "../../../admin/styles/logo/emsd_appendix.jpg";
import { Typography, Box, Container } from "@mui/material";
import { getRuRecordWithPwd } from "../../../admin/useHooks/restrictedURL/useRestrictedURL";
import CompLogin from "./CompLogin";
import RestricedUrlWebpage from "./RestrcitedUrl/RestricedUrlWebpage";

// misc 
import handleDateConversion from "../../../admin/utils/misc/noTimeZoneFormatDate";
import {fixInvalidDates} from "../../../admin/utils/misc/fixInvalidDates";

import {Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
const PageShowRuRecord = () => {
  const { t } = useTranslation("pages", { keyPrefix: "typeApprovalRecord" });
  const { encrypted_ruNo } = useParams();
  const [showContent, setShowContent] = useState(false);
  const [ruResult, setRuResult] = useState({});
  const [pwd, setPwd] = useState("");
  const [error, setError] = useState("");

  const fnCalled = useRef(false);

  // prevent user from right clicking and download the page
  const handleContextMenu = (e) => e.preventDefault();
  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const handleSubmit = async () => {
    setError("");
    if(fnCalled.current === true) return

    if (pwd.trim() === "") {
      setError("Please enter a token.");
      return;
    }
    try {
      const res = await getRuRecordWithPwd(encrypted_ruNo, pwd);
      setRuResult(res?.ruResult)
      setShowContent(true);
      fnCalled.current = true

    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message)
    }
  };

  return (
    <>
      {!showContent && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CompLogin
            pwd={pwd}
            setPwd={setPwd}
            handleSubmit={handleSubmit}
            error={error}
            emsdLogo={emsdLogo}
          />
        </Box>
      )}

      {showContent && (
        <Container
          component="main"
          maxWidth="xxl"
          sx={{ minHeight: "90vh", mb: "2em"}}
        >
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={emsdLogo} style={{ width: "200px" }} alt="EMSD logo" />
            <Typography
              component="h1"
              variant="h5"
              sx={{ fontFamily: "Frutiger_bold" }}
            >
              {t("Type Approval Record Collection")}
            </Typography>
          </Box>

          <div className="my-3 text-center space-x-3">
            <p className=""> {t("Registered Contractor")}: {ruResult?.rc_id?.address?.company || "Not Available"}</p>
          </div>
          <Divider />
          <div className="my-3 text-center space-x-3">
            <p className="inline">
              {t("Created at")}: {fixInvalidDates(new Date(ruResult?.createdAt))}
            </p>
            <p className="inline">
              {t("Created by")}: {ruResult?.user_id?.username}
            </p>
          </div>
          <Divider />


          <RestricedUrlWebpage 
            ruResult={ruResult}
            pwd={pwd}
            authToken={ruResult.auth_token}
            ruNo={encrypted_ruNo}
          />
        </Container>
      )}
    </>
  );
};

export default PageShowRuRecord;
