import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useState } from "react";
import { useLogin, useNotify } from "react-admin";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import emsdLogo from "../styles/logo/emsd_appendix.jpg";
import authProvider from "../utils/authProvider";

export default function LoginPage() {
  const { route } = useParams();
  const [mode, setMode] = useState("login");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [one_time_code, setCode] = useState("");
  const login = useLogin();
  const notify = useNotify();
  const navigate = useNavigate();
  const formData = () => {
    const result = {
      username: username,
      email: email,
      password: password,
      ...(mode === "forgot_password_confirm"
        ? { confirm_code: one_time_code }
        : { invite_code: one_time_code }),
      ...(mode === "reset_password" && {
        newPassword: password,
      }),
    };
    return result;
  };

  useEffect(() => {
    // Four mode: Login, Sign up, Forgot password (request confirm code), Forgot password (enter confirm code)
    const acceptedRoute = ["signup", "login", "forgot_password_request"];
    if (route && acceptedRoute.includes(route)) setMode(route);
    else navigate("/login");
  }, [route]);

  const path = window.location.pathname;
  const portal =
    path === "/lift_rc" ? "rc" : path === "/lift_emsd" ? "emsd" : "";
  const handleSubmit = (e, mode, data) => {
    e.preventDefault();
    switch (mode) {
      case "login":
        login({ data, navigate }).catch((e) => {
          notify(e.message);
          if (
            e.message === "Your password is expired, please reset your password"
          ) {
            setPassword("");
            setMode("reset_password"); //setMode instead of navigate to keep username state
          }
        });
        break;
      case "signup":
        if (password !== confirmPassword) return notify("Password not match");
        authProvider
          .signup({ data, navigate, notify })
          .catch((e) => notify(e.message));
        break;
      case "forgot_password_request":
        axios
          .post(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/auth/reset`, data, {
            headers: {
              "Content-Type": "application/json",
              portal: portal,
            },
          })
          .then(() => {
            notify(
              "Successfully send the confirmation code, please check your email"
            );
            setMode("forgot_password_confirm");
          })
          .catch((e) => notify(e.response.data));
        break;
      case "forgot_password_confirm":
        if (password !== confirmPassword) return notify("Password not match");
        axios
          .post(
            `${process.env.REACT_APP_ENV_ENDPOINT}/lift/auth/confirm_reset`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                portal: portal,
              },
            }
          )
          .then(() => {
            notify(
              "Successfully reset the password, please login with your new password"
            );
            navigate("/login");
          })
          .catch((e) => notify(e.response.data));
        break;
      case "reset_password":
        if (password !== confirmPassword) return notify("Password not match");
        axios
          .post(
            `${process.env.REACT_APP_ENV_ENDPOINT}/lift/auth/reset_password?mode=forced`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                portal: portal,
              },
            }
          )
          .then(() => {
            notify(
              "Successfully reset the password, please login with your new password"
            );
            setPassword("");
            setConfirmPassword("");
            setMode("login");
          })
          .catch((e) => notify(e.response.data));
      default:
        break;
    }
  };

  const resendCode = (data) => {
    // resend invite code or confirm code
    axios
      .post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/auth/resend_code/${mode}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            portal: portal,
          },
        }
      )
      .then(() => {
        notify("Successfully resend");
      })
      .catch((err) => notify(err.response.data));
  };

  const shownInput = (mode) => {
    switch (mode) {
      case "login":
        return ["username", "password"];
      case "signup":
        return ["username", "password", "confirmPassword", "one_time_code"];
      case "forgot_password_request":
        return ["username", "email"];
      case "forgot_password_confirm":
        return ["username", "one_time_code", "password", "confirmPassword"];
      case "reset_password":
        return ["password", "confirmPassword"];
      default:
        return [];
    }
  };

  return (
    <Container component="main" maxWidth="xl" sx={{ minHeight: "90vh" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: "1rem",
          borderBottom: "5px solid #7bbbbb",
          paddingBottom: "3px",
        }}
      >
        <img src={emsdLogo} style={{ width: "200px" }} alt="EMSD logo" />
        <Typography variant="h4" sx={{ fontFamily: "Frutiger_bold" }}>
          Type Approval Processing and Authentication System
        </Typography>
      </div>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          {mode === "login"
            ? "Sign in"
            : mode === "signup"
              ? "Activation"
              : mode === "forgot_password_request"
                ? "Forgot Password"
                : "Reset Password"}{" "}
          - {`Portal ${portal.toUpperCase()}`}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: "400px" }}
        >
          {shownInput(mode).includes("username") && (
            <TextField
              margin="normal"
              fullWidth
              name="username"
              label="Username"
              id="username"
              value={username}
              variant="outlined"
              onChange={(e) => setUsername(e.target.value)}
              disabled={mode === "forgot_password_confirm"}
            />
          )}
          {shownInput(mode).includes("email") && (
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              variant="outlined"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              disabled={mode === "forgot_password_confirm"}
            />
          )}
          {shownInput(mode).includes("password") && (
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
          {shownInput(mode).includes("confirmPassword") && (
            <TextField
              margin="normal"
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              variant="outlined"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          )}
          {shownInput(mode).includes("one_time_code") && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <TextField
                sx={{ flex: 1 }}
                margin="normal"
                name={mode === "signup" ? "invite_code" : "confirm_code"}
                label={mode === "signup" ? "Invite Code" : "Confirm Code"}
                type="password"
                value={one_time_code}
                variant="outlined"
                id="code"
                onChange={(e) => setCode(e.target.value)}
              />
              <Button
                variant="outlined"
                sx={{ mt: 1 }}
                onClick={() => resendCode(formData())}
              >
                Resend code
              </Button>
            </Box>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            onClick={(e) => handleSubmit(e, mode, formData())}
          >
            {mode === "login"
              ? "Sign in"
              : mode === "signup"
                ? "Activate"
                : mode === "forgot_password_request"
                  ? "Send Confirm Code"
                  : "Reset Password"}
          </Button>
          <Grid container>
            <Grid item xs={12}>
              <Link
                style={{ fontFamily: "Frutiger_light" }}
                to={mode === "login" ? "/login/signup" : "/login"}
              >
                {mode === "login" ? "Activation" : "Login"}
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link
                style={{ fontFamily: "Frutiger_light" }}
                to="/login/forgot_password_request"
              >
                Forgot password
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
